define(
	'modules/fake-select-mobile/app',[
		'backbone',
		'modules/fake-select-mobile/views/FakeSelectView.js',
	], 
	function(Backbone, FakeSelectView){

		var AppView = Backbone.View.extend({

			// init

			initialize: function() {
				var self = this;
			},
			// start module --------------------------------------------------------------------  /

			start: function(){
				var self = this;
				self.$el = new FakeSelectView();
				window.$vent.trigger('fakeSelectReady');
			}
		});

		return AppView;
	}
);
