define(
	'pagecontrollers/property-listing/views/SearchView',[
		'backbone',
		'utils/AbstractView',

		'text!../templates/SearchItemTemplate.html',
		'text!../templates/SearchNoItemsTemplate.html'	
	], 
	function(Backbone, AbstractView, Template, TemplateNoItems){

		var SearchItemView = Backbone.View.extend({

			// core vars

			$el: null,
			$container: null,
			options: null,
			abstractView: null,
			
			index: 0,
			autoShow: false,
			
			noItems: false,
			
			initialize: function(options){

				var self = this;

				self.options = options;
				self.$container = self.options.$container;
				self.el = self.options.el;
				self.index = self.options.index;
				self.noItems = self.options.noItems;

				//		
				if(!self.noItems){
					
					var location = $('.search-location').val();
					if(location){
						location = location[0].toUpperCase() + location.slice(1);					
						self.model.label = self.model.location.replace(location, '<span>'+location+'</span>');
					}else{
						self.model.label = self.model.location;				
					}
								
					self.template = _.template(Template);	
					
				}else{
					self.template = _.template(TemplateNoItems);
				}	
				
				self.abstractView = new AbstractView({
					view: self,
					autoShow: true
				});
			},
			
			onAddListeners: function(){

				var self = this;
				if(!self.noItems){
					self.$el.on('mousedown', $.proxy(self._onClick, self));	
				}					
			},
					
			_onClick: function(e){	
				var location = $(e.currentTarget).data('location');
				$('.search-location').val( location );	
									
				window.$vent.trigger('filterLocation', location);				
			}
		});
		
		return SearchItemView;		
	}
);	
