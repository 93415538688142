define(
    'modules/owner-area-payouts-filters/app',[
        'backbone',
    ],
    function (Backbone) {

        var AppView = Backbone.View.extend({

            $spinnerContainer: $('#user-payments-page'),
            $yearSelectLaptop: null,
            $yearSelectMobile: null,
            $propertySelectLaptop: null,
            $propertySelectMobile: null,

            desktop: 'desktop',
            mobile: 'mobile',

            start: function(){
                var self = this;

                self.addListeners();
                window.$vent.trigger('ownerAreaPayoutFiltersReady');
            },
            addListeners: function(){

                var self = this;

                self.$yearSelectLaptop = $('#year-payment-sorting')
                self.$yearSelectMobile = $('#year-payment-sorting-mobile')
                self.$propertySelectLaptop = $('#property-sorting')
                self.$propertySelectMobile = $('#property-sorting-mobile')

                self.$yearSelectLaptop.on('change', function(e){
                    window.$vent.trigger('showSpinner', self.$spinnerContainer);
                    self.updateFilters(self.desktop);
                });

                self.$propertySelectLaptop.on('change', function(e){
                    window.$vent.trigger('showSpinner', self.$spinnerContainer);
                    self.updateFilters(self.desktop);
                });

                self.$yearSelectMobile.on('change', function(e){
                    window.$vent.trigger('showSpinner', self.$spinnerContainer);
                    self.updateFilters(self.mobile);
                });

                self.$propertySelectMobile.on('change', function(e){
                    window.$vent.trigger('showSpinner', self.$spinnerContainer);
                    self.updateFilters(self.mobile);
                });
            },
            updateURL: function(params){

                window.location = window.location.pathname + "?" + params.join('&')
            },
            updateFilters: function(deviceType){
                var self = this;

                var params = [];

                if(deviceType == self.mobile){
                    params.push(self.$yearSelectMobile.attr('name') + '=' + self.$yearSelectMobile.val())
                    if(self.$propertySelectMobile.val() != ''){
                        params.push(self.$propertySelectMobile.attr('name') + '=' + self.$propertySelectMobile.val())
                    }
                }

                if(deviceType == self.desktop){
                    params.push(self.$yearSelectLaptop.attr('name') + '=' + self.$yearSelectLaptop.val())
                    if(self.$propertySelectLaptop.val() != ''){
                        params.push(self.$propertySelectLaptop.attr('name') + '=' + self.$propertySelectLaptop.val())
                    }
                }

                self.updateURL(params)
            }
        });

        return AppView;
    }
);
