define(
    'modules/home/views/DistrictPopup',[
        'backbone',
        'utils/AbstractView',
    ],
    function (Backbone, AbstractView) {

        var DistrictPopup = Backbone.View.extend({

            // core vars

            $el: null,
            $districtButtons: null,
            $districtSelected: null,
            $localStorageKey: 'district',
            /* $photosToChange: null,*/
            $localStorage: null,

            // init
            initialize: function (options) {
                var self = this;
                self.$localStorage = options.$localStorage;

                $(function () {
                    self.onDomReady();
                    self.onAddListeners();
                });

            },

            //grab the properties
            onDomReady: function () {
                var self = this;

                self.$el = $('#select-location-popUp');
                self.$districtButtons = self.$el.find('a');
                self.containerSpinner = self.$el.find('.spinner-container');

                if (!self.$localStorage) {
                    console.log("no existe localStorage");
                    self.onShowDistrictPopup();
                } else {
                    console.log("existe localStorage");
                }


            },

            onRender: function () {

                var self = this;
            },

            //add event handlers to them
            onAddListeners: function () {

                var self = this;

                self.$districtButtons.off('click', $.proxy(self._onDistrictSelected, self));
                self.$districtButtons.on('click', $.proxy(self._onDistrictSelected, self));

            },

            _onDistrictSelected: function (e) {
                e.preventDefault();

                var self = this;

                /* Show spinner */
                window.$vent.trigger('showSpinner', self.containerSpinner);

                /* Get the district selected*/
                var $currentLinkDistrict = $(e.currentTarget);
                self.$districtSelected = $($currentLinkDistrict).attr('href');
                self.$localStorage = self.$districtSelected;

                /* change option in district select in menu and main-menu changes the content in home and set the localStorage*/
                window.$vent.trigger('changeDistrictSelectInHome', [{localStorage: self.$localStorage}, self]);

                self.closeDistrictPopup(self.$el);

                /*self.reloadHomePage();*/
            },

            onShowDistrictPopup: function () {
                var self = this;

                setTimeout(self.showDistrictPopup, 250, self.$el);


            },

            showDistrictPopup: function (popUp) {
                var self = this;

                popUp.fadeIn(500);
            },

            closeDistrictPopup: function (popUp) {
                var self = this;

                /* Hide spinner */
                window.$vent.trigger('hideSpinner');

                popUp.fadeOut(500);
            },

            reloadHomePage: function () {
                var self = this;

                var currentUrl = window.location.href;

                var newUrl = currentUrl + '?' + self.$localStorageKey + '=' + self.$districtSelected;

                window.location.replace(newUrl);
            },

            toggleClassCity: function () {
                var self = this;

                var container = $('#home-page');

                $(container).removeClass('madrid');
                $(container).removeClass('ibiza');

                $(container).addClass(self.localStorageValue);
            }


        });

        return DistrictPopup;
    });
