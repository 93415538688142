define(
	'modules/home/views/ContentTabs',[
		'backbone',
		'utils/AbstractView',
	], 
	function(Backbone, AbstractView){

		var ContentTabs = Backbone.View.extend({

			// core vars
			$el:null,
			$currentElement: null,
			$tabsBoxes: null,
			$localStorage:null,

			// init
			initialize: function(options){

				var self = this;

				/* when we reload the page, we already have the value of localStorage so it comes from districtPopup */
				self.$localStorage = options.$localStorage;

				$(function () {
					self.onDomReady();
					self.onRemoveListeners();
					self.onAddListeners();
				});

			},

			//grab the properties
			onDomReady: function() {
				console.log("contentTabs onDom");
				var self = this;

				self.$el					= $('#home-page .tabs-container-offices');
				self.$currentElement 		= self.$el.find('.tabs li');
				self.$tabsBoxes				= self.$el.find('.tab-content-box');


				/* Show tabs depending of the localStorage */

				if(self.$localStorage){
					self.setTabsVisible();
				}


			},

			onRender: function(){
				
				var self = this;
			},

			//add event handlers to them
			onAddListeners: function() {
				
				var self = this;				
				self.$currentElement.on('click', $.proxy( self._onTabClick, self ));

				window.$vent.on('changeOfficeTabs', $.proxy( self._onDistrictChange, self ));

			},

			onRemoveListeners: function() {

				var self = this;
				self.$currentElement.off('click', $.proxy( self._onTabClick, self ));

				window.$vent.off('changeOfficeTabs', $.proxy( self._onDistrictChange, self ));

			},
			_onTabClick: function(e){
				e.preventDefault();
				var self = this;
				var $currentTab = $(e.currentTarget);
				var $target = $($currentTab.find('a').attr('href'));

				self.$currentElement.find('a').removeClass('active');
				self.$tabsBoxes.removeClass('active');

				$currentTab.find('a').addClass('active');
				$target.addClass('active');


				/* we call carousel plugin just in case the tab contains a carousel */
				window.$vent.trigger('reinitCarouselOffices');


/*				if( $($currentTab).prev().length > 0) {
					TweenMax.fromTo($target, 0.6, { left: 100, opacity:	0 }, { left: 0, opacity: 1, ease:Cubic.easeOut });
				} else {
					TweenMax.fromTo($target, 0.6, { right: 100, opacity: 0 }, { right: 0, opacity: 1, ease:Cubic.easeOut });
				}
							
				var currentIndex = $currentTab.index();
				var left = currentIndex*$currentTab.width();
												
				TweenMax.to(self.$navLine, 0.4, {left:left, opacity:1, ease:Quint.easeOut});
				$(window).trigger('scroll').trigger('resize');	*/
				return false;
			},

			_onDistrictChange:function(e,data){

				var self = this;

				self.$localStorage = data.localStorage;

				self.setTabsVisible();

				/* we call carousel plugin just in case the tab contains a carousel */
				window.$vent.trigger('reinitCarouselOffices');

			},

			setTabsVisible:function(){

				var self = this;
				var currentTab = self.$currentElement.find('a[href="#'+self.$localStorage+'"]');
				var currentBox =$('#'+self.$localStorage);

				self.$currentElement.find('a').removeClass('active');
				self.$tabsBoxes.removeClass('active');

				currentTab.addClass('active');
				currentBox.addClass('active');



			}


		});

	return ContentTabs;
});
