define(
	'modules/home-filters/views/SearchView',[
		'backbone',
		'utils/AbstractView',

		'text!../templates/SearchItemTemplate.html',
		'text!../templates/SearchNoItemsTemplate.html',
		'text!../templates/SearchItemAllTemplate.html'	
	], 
	function(Backbone, AbstractView, Template, TemplateNoItems, TemplateAllItems){

		var SearchItemView = Backbone.View.extend({

			// core vars

			$el: null,
			$container: null,
			options: null,
			abstractView: null,
			
			index: 0,
			autoShow: false,
			
			noItems: false,
			
			initialize: function(options){

				var self = this;

				self.options = options;
				self.$container = self.options.$container;
				self.el = self.options.el;
				self.index = self.options.index;
				self.noItems = self.options.noItems;
				self.allItems = self.options.allItems;
				
				//		
				if(self.noItems){
					
					self.template = _.template(TemplateNoItems);
				}else if(self.allItems){
					
					self.template = _.template(TemplateAllItems);
				}else{
					
					var location = $('.big-boy:visible').val();
					if(location){
						location = location[0].toUpperCase() + location.slice(1);					
						self.model.label = self.model.location.replace(location, '<span>'+location+'</span>');
					}else{
						self.model.label = self.model.location;				
					}
								
					self.template = _.template(Template);	
				}	
				
				self.abstractView = new AbstractView({
					view: self,
					autoShow: true
				});								
			},
						
			onAddListeners: function(){
				
				var self = this;				
				if(!self.noItems){					
					self.$el.on('mousedown', $.proxy(self._onClick, self));	
				}					
			},
					
			_onClick: function(e){	
				
				var self = this;
				var $target = $(e.currentTarget);
				var parent = $target.parent().parent();
				var linkTarget = $target.data('target');
				
				if(!linkTarget){
						
					var location = $(e.currentTarget).data('location');								
					parent.find('.big-boy').val( location );

					window.$vent.trigger('filterSelected');	
				}else{
					window.location = linkTarget;	
				}				
			}
		});
		
		return SearchItemView;		
	}
);	
