define(
    'modules/payment-buttons/views/PaymentButtonView',[
        'backbone',
        'utils/AbstractView',
        'utils/AbstractButtonView',
        'selectboxit'
    ],
    function (Backbone, AbstractView, AbstractButtonView) {

        var ButtonTemplateView = Backbone.View.extend({

            // core vars

            $el: null,
            $container: null,
            options: null,
            abstractView: null,
            abstractButtonView: null,

            // vars

            $addPayment: null,
            $paymentTypes: null,
            $creditCardForm: null,
            $bankForm: null,
            $overlayContainer: null,

            creditFormTemplate: null,
            creditFormMobileTemplate: null,

            bankFormTemplate: null,
            bankFormMobileTemplate: null,

            isNewOption: false,
            isOutListening: false,

            // init

            initialize: function (options) {

                var self = this;

                self.options = options;
                self.$container = self.options.$container;
                self.el = self.options.el;

                $("select").selectBoxIt({
                    isMobile: function () {
                        return false;
                    },
                    autoWidth: false
                });

                //

                //self.template = _.template(Template);
                self.model = $.extend({}, self.model);

                //

                self.abstractView = new AbstractView({
                    view: self,
                    autoShow: true
                });

                self.abstractButtonView = new AbstractButtonView({
                    abstractView: self.abstractView,
                });
            },

            // render

            onRender: function () {

                var self = this;

                if (self.$el.hasClass('new-option')) {
                    self.isNewOption = true;
                    self.$addPayment = self.$el.find('.pm-add-method');
                    self.$paymentTypes = self.$el.find('.pm-select-type');
                }

                self.$overlayContainer = self.$el.find('.form-overlay-container');
            },

            addPaymentForms: function (desktopCardTemplate, mobileCardTemplate, desktopBankTemplate, mobileBankTemplate) {

                var self = this;

                // credit card

                self.creditFormTemplate = _.template(desktopCardTemplate);
                self.creditFormMobileTemplate = _.template(mobileCardTemplate);

                self.$creditCardForm = $(self.creditFormTemplate());
                window.$rootContext(self.$creditCardForm.find('select')).selectBoxIt();

                // bank transfer

                self.bankFormTemplate = _.template(desktopBankTemplate);
                self.bankFormMobileTemplate = _.template(mobileBankTemplate);

                self.$bankForm = $(self.bankFormTemplate());
                window.$rootContext(self.$bankForm.find('select')).selectBoxIt();
            },

            onAddListeners: function () {

                var self = this;

                if (self.isNewOption) {
                    self.$el.find('.pm-inner').on('click', $.proxy(self._onAddPaymentClick, self));
                }
                else {
                    self.$el.find('.pm-inner').on('click', $.proxy(self._onEditCardClick, self));
                }
            },

            _onAddPaymentClick: function (e) {

                var self = this;
                self.showPaymentSelect();

                e.preventDefault();
            },

            _onEditCardClick: function (e) {

                var self = this;
                self.showCreditForm();

                e.preventDefault();
            },

            // step 2 - payment select

            showPaymentSelect: function () {

                var self = this;

                TweenMax.to(self.$addPayment, 0.5, {
                    y: 0 - self.$el.innerHeight(), ease: Quint.easeOut, onComplete: function () {
                        self.$addPayment.addClass('hidden');
                    }
                });

                TweenMax.fromTo(self.$paymentTypes, 0.5, {y: self.$el.innerHeight()}, {y: 0, ease: Quint.easeOut});
                self.$paymentTypes.removeClass('hidden');

                self.$paymentTypes.find('button').on('click', $.proxy(self._onPaymentMethodClick, self));
                self.addOutListener();
            },

            hidePaymentSelect: function () {

                var self = this;

                TweenMax.to(self.$addPayment, 0.5, {y: 0, ease: Quint.easeOut});
                self.$addPayment.removeClass('hidden');

                TweenMax.to(self.$paymentTypes, 0.5, {
                    y: self.$el.innerHeight(), ease: Quint.easeOut, onComplete: function () {
                        self.$paymentTypes.addClass('hidden');
                    }
                });

                self.removeOutListener();
            },

            //

            _onPaymentMethodClick: function (e) {

                var self = this;
                var $target = $(e.currentTarget);

                if ($target.hasClass('credit')) {
                    self.showCreditForm();
                }
                else if ($target.hasClass('bank')) {
                    self.showBankForm();
                }
            },

            _onCloseFormClick: function (e) {

                var self = this;
                self.hideCreditForm();
            },

            // show credit card form

            showCreditForm: function () {

                var self = this;

                self.$overlayContainer.html(self.$creditCardForm);
                self.$overlayContainer.removeClass('hidden');

                self.$creditCardForm.find('.close-btn').on('click', $.proxy(self._onCloseFormClick, self));

                TweenMax.set(self.$el, {
                    marginBottom: self.$creditCardForm.outerHeight() - 170 + 40
                });

                self.$overlayContainer.find('input[name="ccnumber"]').on('blur', $.proxy(self._validateCC, self));

                self.addOutListener();
            },

            hideCreditForm: function () {

                var self = this;

                self.$overlayContainer.empty();
                self.$overlayContainer.removeClass('hidden').addClass('hidden');

                self.$creditCardForm.find('.close-btn').off('click', $.proxy(self._onCloseFormClick, self));

                TweenMax.set(self.$el, {
                    clearProps: 'marginBottom'
                });

                self.$overlayContainer.find('input[name="ccnumber"]').off('blur', $.proxy(self._validateCC, self));


                self.removeOutListener();
            },

            _validateCC: function (e) {
                var self = this;
                var ccfield = $(e.currentTarget);

                var creditCardValidator = {};
                // Pin the cards to them
                creditCardValidator.cards = {
                    'mc': '5[1-5][0-9]{14}',
                    'ec': '5[1-5][0-9]{14}',
                    'vi': '4(?:[0-9]{12}|[0-9]{15})',
                    'ax': '3[47][0-9]{13}',
                    'dc': '3(?:0[0-5][0-9]{11}|[68][0-9]{12})',
                    'bl': '3(?:0[0-5][0-9]{11}|[68][0-9]{12})',
                    'di': '6011[0-9]{12}',
                    'jcb': '(?:3[0-9]{15}|(2131|1800)[0-9]{11})',
                    'er': '2(?:014|149)[0-9]{11}'
                };
                // Add the card validator to them
                creditCardValidator.validate = function (value, ccType) {
                    value = String(value).replace(/[- ]/g, ''); //ignore dashes and whitespaces

                    var cardinfo = creditCardValidator.cards, results = [];
                    if (ccType) {
                        var expr = '^' + cardinfo[ccType.toLowerCase()] + '$';
                        return expr ? !!value.match(expr) : false; // boolean
                    }

                    for (var p in cardinfo) {
                        if (value.match('^' + cardinfo[p] + '$')) {
                            results.push(p);
                        }
                    }
                    return results.length ? results.join('|') : false; // String | boolean
                };

                if (!creditCardValidator.validate(ccfield.val())) {
                    ccfield.parent('div').find('.bad-format-text').remove();
                    ccfield.parent('div').addClass('bad-format').append('<span class="bad-format-text">Invalid #</span>');
                } else {
                    ccfield.parent('div').removeClass('bad-format').find('.bad-format-text').remove();
                }
            },

            /*_validateCCmob: function(e){
             var self = this;
             var ccfield = $('input[name="ccnumber_mob"]');

             console.log('triggering');

             var creditCardValidator = {};
             // Pin the cards to them
             creditCardValidator.cards = {
             'mc':'5[1-5][0-9]{14}',
             'ec':'5[1-5][0-9]{14}',
             'vi':'4(?:[0-9]{12}|[0-9]{15})',
             'ax':'3[47][0-9]{13}',
             'dc':'3(?:0[0-5][0-9]{11}|[68][0-9]{12})',
             'bl':'3(?:0[0-5][0-9]{11}|[68][0-9]{12})',
             'di':'6011[0-9]{12}',
             'jcb':'(?:3[0-9]{15}|(2131|1800)[0-9]{11})',
             'er':'2(?:014|149)[0-9]{11}'
             };
             // Add the card validator to them
             creditCardValidator.validate = function(value,ccType) {
             value = String(value).replace(/[- ]/g,''); //ignore dashes and whitespaces

             var cardinfo = creditCardValidator.cards, results = [];
             if(ccType){
             var expr = '^' + cardinfo[ccType.toLowerCase()] + '$';
             return expr ? !!value.match(expr) : false; // boolean
             }

             for(var p in cardinfo){
             if(value.match('^' + cardinfo[p] + '$')){
             results.push(p);
             }
             }
             return results.length ? results.join('|') : false; // String | boolean
             };

             if(!creditCardValidator.validate(ccfield.val())) {
             ccfield.parent('div').find('.bad-format-text').remove();
             ccfield.parent('div').addClass('bad-format').append('<span class="bad-format-text">Invalid #</span>');
             } else {
             ccfield.parent('div').removeClass('bad-format').find('.bad-format-text').remove();
             }
             },*/

            // bank transfer form

            showBankForm: function () {

                var self = this;

                self.$overlayContainer.html(self.$bankForm);
                self.$overlayContainer.removeClass('hidden');

                self.$bankForm.find('.close-btn').on('click', $.proxy(self._onCloseFormClick, self));

                TweenMax.set(self.$el, {
                    marginBottom: self.$bankForm.outerHeight() - 170 + 40
                });

                self.addOutListener();
            },

            hideBankForm: function () {

                var self = this;

                self.$overlayContainer.empty();
                self.$overlayContainer.removeClass('hidden').addClass('hidden');

                self.$bankForm.find('.close-btn').off('click', $.proxy(self._onCloseFormClick, self));

                TweenMax.set(self.$el, {
                    clearProps: 'marginBottom'
                });

                self.removeOutListener();
            },

            //

            addOutListener: function () {

                var self = this;

                if (!self.isOutListening) {

                    self.isOutListening = true;

                    window.requestAnimationFrame(function () {
                        $(document).on('click', $.proxy(self._onOutClick, self));
                    });
                }
            },

            removeOutListener: function () {

                var self = this;

                $(document).off('click', $.proxy(self._onOutClick, self));

                self.isOutListening = false;
            },

            //

            _onOutClick: function (e) {

                var self = this;

                if (!self.$el[0].contains(e.target)) {
                    if (self.isNewOption) {
                        self.hidePaymentSelect();
                    }
                    self.hideCreditForm();
                    self.hideBankForm();
                    e.preventDefault();
                }
            },

            // ---------------------------------------------------------------------------------  /

        });

        return ButtonTemplateView;
    });
