(function ($) {


// property page

    if ($('#property-listing-page').length) {

        var $resultsGrid = $('#results-grid');
        $resultsGrid.css({
            'min-height': $(window).height() - 800
        });
    }

// global communications object

    window.$vent = $('<div></div>'); // global communication object

    // global consts

    window.MOBILE_WIDTH = 768;
    window.$rootContext = $;
    window.SEARCHFILTERS_COOKIE_EXPIRES = 3600*1000;
    /*$("select").selectBoxIt();*/

    /* the mockup for area guides map to make the tabs unselected when one of them is clicked */

    $('a[data-toggle="tab"]').on('shown.bs.tab', function (e) {
        e.preventDefault();
        $('.tm-filters li:not(.active) a').attr('aria-expanded', 'false');
    });
    /* end the mockup for area guides map to make the tabs unselected when one of them is clicked */

    $('.featured-item .img-wrapper').mouseenter(function (e) {
        $(this).parents('.featured-item').css('z-index', '150');
        $('.prop-grid-overlay').css('visibility', 'visible');
        $(this).parents('.featured-item').find('.prop-navigators').css('visibility', 'visible');
        /*hardcoded, just to ilustrate the change on hover*/
        $('.pin-7').addClass('active');
    });
    $('.featured-item .img-wrapper').mouseout(function (e) {
        e = event.toElement || event.relatedTarget;
        if (e == this || $(e).parents('.img-wrapper').length == 1)
            return;
        $(this).parents('.featured-item').css('z-index', '50');
        $('.prop-grid-overlay').css('visibility', 'hidden');
        $(this).parents('.featured-item').find('.prop-navigators').css('visibility', 'hidden');
        $('.pin-7').removeClass('active');
    });
    /* Footer Accordion */

    $('.fd-open').click(function (e) {
        e.preventDefault();
        $('.fd-open').not(this).each(function (i, el) {
            if ($(el).parent('.footer-accordion').hasClass('opened')) {
                $(el).parent('.footer-accordion').removeClass('opened');
                $(el).children('.btn-expand').removeClass('active');
            }
        });
        $(this).parents('.footer-accordion').toggleClass('opened');
        $(this).children('.btn-expand').toggleClass('active');
    });
    /* End Footer Accordion */

    /* Mobile Hamburger Menu/Accordion */

    /* This is done in app.js in _onHamburgerClick event
     $('.hamburger').click(function (e) {
     e.preventDefault();
     var mobileMenu = $('#mobile-main-menu');
     console.log("ENTRA EN BURGUER");
     mobileMenu.toggleClass('displayed');

     $('#mobile-profile-menu').removeClass('displayed');
     }); */

    $('.mobile-main-menu a.parent').not('.clickable').click(function (e) {
        e.preventDefault();
        $('.mobile-main-menu a.parent').not(this).each(function (i, el) {
            $(el).parent('.parent').removeClass('active');
            $(el).removeClass('active');
        });
        $(this).parent('li').toggleClass('active');
        $(this).toggleClass('active');
    });
    /* End of Mobile Hamburger Menu/Accordion */

    /* User-area-mobile-menu show and hide */
    /* $('.user-anchor-mobile').click(function (e) {
     e.preventDefault();

     var mobileProfileMenu = $('#mobile-profile-menu');
     var mobileExpnavWrapper = $('.mobile-expnav-wrapper');
     //          If i am logged
     if ($(".logged-in:not(.hide)").length > 0) {
     mobileProfileMenu.toggleClass('displayed');
     mobileExpnavWrapper.removeClass('displayed');
     }
     //        if Im not logged
     else {
     mobileProfileMenu.removeClass('displayed');
     mobileExpnavWrapper.removeClass('displayed');
     }

     });*/

    /*End user-area-mobile-menu control*/

    /*scroll to clicked links*/

    $('a.scroll').on('click', function (e) {
        e.preventDefault();
        var id = $(this).attr("href");
        scrollOffset = 0;
        /*if(windowWidth >= 768 ) {
         scrollOffset = 99;
         }*/

        $('html, body').animate({
            scrollTop: $(id).offset().top - scrollOffset
        }, 800, 'swing');
    });
})($);

require(['app'],
    function (AppView) {
        var app = new AppView({el: $('#main-wrapper')});
        app.start();
    }
);
require(['jquery', 'validate'], function ($) {


    // --- VALIDATION FORMS ---

    // validate form_connect_agent

    function submitAjax(form) {
        var form = $(".validation-form-feedback:visible");
        var data = {};
        var formData = form.serializeArray();

        $.each(formData, function () {
            if (formData[this.name]) {
                if (!data[this.name].push) {
                    data[this.name] = [data[this.name]];
                }
                data[this.name].push(this.value || '');
            } else {
                data[this.name] = this.value || '';
            }
        });

        //data = JSON.stringify(data);
        $.ajax({
            url: '/global/contactAgentForm',
            type: 'POST',
            dataType: "json",
            contentType: "application/json",
            data: JSON.stringify(data),
            crossDomain: true,
            success: function () {
                var form = $(".validation-form-feedback:visible");
                form[0].reset();
                form.validate().resetForm();
                window.$vent.trigger('hideSpinner');
                $("form #success-message").fadeIn(1000);
            },
            error: function () {
                var form = $(".validation-form-feedback:visible");
                form.validate().resetForm();
                window.$vent.trigger('hideSpinner');
                $("form #error-message").fadeIn(1000);
            }

        });

        return false;
    }

    function validateForm(form) {
        var requiredMessage = $('form #validation-required').text();
        var lengthMessage = $('form #validation-min-length').text();
        var emailMessage = $('form #validation-email').text();
        var phoneMessage = $('form #validation-phone').text();
        form.validate({
            rules: {
                comments: {
                    required: true,
                    minlength: 5
                },
                first_name: {
                    required: true,
                    minlength: 5
                },
                last_name: {
                    required: true,
                    minlength: 5
                },
                email: {
                    required: true,
                    email: true
                },
                cont_phone: {
                    required: true,
                    digits: true,
                    minlength: 9
                },
                method: {
                    required: true,
                    minlength: 2
                }
            },
            messages: {
                comments: {
                    required: requiredMessage,
                    minlength: lengthMessage
                },
                first_name: {
                    required: requiredMessage,
                    minlength: lengthMessage
                },
                last_name: {
                    required: requiredMessage,
                    minlength: lengthMessage
                },
                email: {
                    required: requiredMessage,
                    email: emailMessage
                },
                cont_phone: {
                    required: requiredMessage,
                    digits: phoneMessage,
                    minlength: phoneMessage
                }
            },
            submitHandler: function (form) {
                var button = $(form).find('.spinner-container');
                window.$vent.trigger('showSpinner', button);
                submitAjax(form);
            }
        });
    }

    //init
    var form = $(".validation-form-feedback:visible");
    if (form.length) {
        form.focusin(function () {
            $("form #success-message").fadeOut();
            $("form #error-message").fadeOut();
        });
        if (jQuery().validate) {
            validateForm(form);
        }
    }


    var accountProfileForm = $("#account-profile-form:visible");
    if (accountProfileForm.length) {
        accountProfileForm.focusin(function () {
            $("form #success-message").fadeOut();
            $("form #error-message").fadeOut();
        });
        if (jQuery().validate) {
            validateAccountProfileForm(accountProfileForm);
        }
    }

    function validateAccountProfileForm(accountProfileForm) {
        var requiredMessage = $('form #validation-required').text();
        var lengthMessage = $('form #validation-min-length').text();
        var emailMessage = $('form #validation-email').text();
        var phoneMessage = $('form #validation-phone').text();
//        var successMessage = $('#validation-success-message').text();
        accountProfileForm.validate({
//            success: function (label) {
//                label.addClass("valid").text(successMessage);
//            },
            ignore: "",
            rules: {
                first_name: {
                    required: true,
                    minlength: 3
                },
                last_name: {
                    required: true,
                    minlength: 3
                },
                doc_type: {
                    required: true
                },
                number_id: {
                    required: true,
                    minlength: 6
                },
                email: {
                    required: true,
                    email: true
                },
                prefix: {
                    required: true,
                },
                phone_number: {
                    required: true,
                    minlength: 6
                },
                address_street: {
                    required: true,
                    minlength: 6
                },
                address_number: {
                    required: true
                },
                address_city: {
                    required: true,
                    minlength: 5
                },
                address_zip: {
                    required: true,
                    minlength: 5
                },
                select_country: {
                    required: true
                }
            },
            messages: {
                first_name: {
                    required: requiredMessage,
                    minlength: lengthMessage
                },
                last_name: {
                    required: requiredMessage,
                    minlength: lengthMessage
                },
                doc_type: {
                    required: requiredMessage,
                    minlength: lengthMessage
                },
                number_id: {
                    required: requiredMessage,
                    minlength: lengthMessage
                },
                email: {
                    required: requiredMessage,
                    email: emailMessage
                },
                prefix: {
                    required: requiredMessage,
                },
                phone_number: {
                    required: requiredMessage,
                    minlength: lengthMessage
                },
                address_street: {
                    required: requiredMessage,
                    minlength: lengthMessage
                },
                address_number: {
                    required: requiredMessage
                },
                address_city: {
                    required: requiredMessage,
                    minlength: lengthMessage
                },
                address_zip: {
                    required: requiredMessage,
                    minlength: lengthMessage
                },
                select_country: {
                    required: requiredMessage
                }
            },
            errorPlacement: function (error, element) {
                if (element.is("select")) {
                    element.parent().append(error);
                } else
                    error.insertAfter(element);
            },
            submitHandler: function (accountProfileForm) {
                submitProfileAjax(accountProfileForm);
            }
        });
    }

    function submitProfileAjax(form) {
        var form = $("#account-profile-form:visible");
        var data = {};
        var formData = form.serializeArray();

        $.each(formData, function () {
            if (formData[this.name]) {
                if (!data[this.name].push) {
                    data[this.name] = [data[this.name]];
                }
                data[this.name].push(this.value || '');
            } else {
                data[this.name] = this.value || '';
            }
        });

        $.ajax({
            url: '/userArea/updateUserAjax',
            type: 'POST',
            contentType: "application/json",
            data: JSON.stringify(data),
            success: function () {
                var form = $("#account-profile-form:visible");
                console.log('form success!');
                //form[0].reset();
                //form.validate().resetForm();
                $(window).scrollTop(0);
                window.$vent.trigger('updateUserDone');
                $("#success-message").removeClass('hide').fadeIn(1000);
            },
            error: function () {
                var form = $("#account-profile-form:visible");
                console.log('form error!');
                form.validate().resetForm();
                $("form #error-message").fadeIn(1000);
            }

        });

        return false;
    }


    var accountSettingsForm = $("#account-settings-form:visible");
    if (accountSettingsForm.length) {
        accountSettingsForm.focusin(function () {
            $("form #success-message").fadeOut();
            $("form #error-message").fadeOut();
        });
        if (jQuery().validate) {
            validateAccountSettingsForm(accountSettingsForm);
        }
    }

    function validateAccountSettingsForm(accountSettingsForm) {
        var requiredMessage = $('form #validation-required').text();
        var lengthMessage = $('form #validation-min-length').text();
        var equalToMessage = $('form #validation-equal-to').text();
        var emailMessage = $('form #validation-email').text();
        var phoneMessage = $('form #validation-phone').text();
//        var successMessage = $('#validation-success-message').text();
        accountSettingsForm.validate({
//            success: function (label) {
//                label.addClass("valid").text(successMessage);
//            },
            rules: {
                current_password: {
                    required: true,
                    minlength: 8
                },
                new_password: {
                    required: true,
                    minlength: 8
                },
                confirm_new_password: {
                    required: true,
                    minlength: 8,
                    equalTo: ".new_password"
                }
            },
            messages: {
                current_password: {
                    required: requiredMessage,
                    minlength: lengthMessage
                },
                new_password: {
                    required: requiredMessage,
                    minlength: lengthMessage
                },
                confirm_new_password: {
                    required: requiredMessage,
                    minlength: lengthMessage,
                    equalTo: equalToMessage
                }
            },
            submitHandler: function (accountSettingsForm) {
                submitSettingsAjax(accountSettingsForm);
            }
        });
    }

    function submitSettingsAjax(form) {
        var form = $("#account-settings-form:visible");
        var data = {};
        var formData = form.serializeArray();

        $.each(formData, function () {
            if (formData[this.name]) {
                if (!data[this.name].push) {
                    data[this.name] = [data[this.name]];
                }
                data[this.name].push(this.value || '');
            } else {
                data[this.name] = this.value || '';
            }
        });

        $.ajax({
            url: '/userArea/updatePasswordAjax',
            type: 'POST',
            contentType: "application/json",
            data: JSON.stringify(data),
            success: function () {
                var form = $("#account-settings-form:visible");
                console.log('form success!');
                //form[0].reset();
                //form.validate().resetForm();
                $(window).scrollTop(0);
                $("#success-message-password").removeClass('hide').fadeIn(1000);
            },
            error: function () {
                var form = $("#account-settings-form:visible");
                console.log('form error!');
                form.validate().resetForm();
                $("form #error-message").fadeIn(1000);
            }

        });

        return false;
    }

    var ownerAccountProfileForm = $("#owner-account-profile-form:visible");
    if (ownerAccountProfileForm.length) {
        ownerAccountProfileForm.focusin(function () {
            $("form #success-message").fadeOut();
            $("form #error-message").fadeOut();
        });
        if (jQuery().validate) {
            validateOwnerAccountProfileForm(ownerAccountProfileForm);
        }
    }

    function validateOwnerAccountProfileForm(accountProfileForm) {
        var requiredMessage = $('form #validation-required').text();
        var lengthMessage = $('form #validation-min-length').text();
        var emailMessage = $('form #validation-email').text();
        var phoneMessage = $('form #validation-phone').text();
//        var successMessage = $('#validation-success-message').text();
        accountProfileForm.validate({
//            success: function (label) {
//                label.addClass("valid").text(successMessage);
//            },
            ignore: "",
            rules: {
                first_name: {
                    required: true,
                    minlength: 3
                },
                last_name: {
                    required: true,
                    minlength: 3
                },
                doc_type: {
                    required: true
                },
                number_id: {
                    required: true,
                    minlength: 6
                },
                email: {
                    required: true,
                    email: true
                },
                phone_number: {
                    required: true,
                    minlength: 6
                },
                address_street: {
                    required: true,
                    minlength: 6
                },
                address_number: {
                    required: true
                },
                address_city: {
                    required: true,
                    minlength: 5
                },
                address_zip: {
                    required: true,
                    minlength: 5
                },
                select_country: {
                    required: true
                }
            },
            messages: {
                first_name: {
                    required: requiredMessage,
                    minlength: lengthMessage
                },
                last_name: {
                    required: requiredMessage,
                    minlength: lengthMessage
                },
                doc_type: {
                    required: requiredMessage,
                    minlength: lengthMessage
                },
                number_id: {
                    required: requiredMessage,
                    minlength: lengthMessage
                },
                email: {
                    required: requiredMessage,
                    email: emailMessage
                },
                phone_number: {
                    required: requiredMessage,
                    minlength: lengthMessage
                },
                address_street: {
                    required: requiredMessage,
                    minlength: lengthMessage
                },
                address_number: {
                    required: requiredMessage
                },
                address_city: {
                    required: requiredMessage,
                    minlength: lengthMessage
                },
                address_zip: {
                    required: requiredMessage,
                    minlength: lengthMessage
                },
                select_country: {
                    required: requiredMessage
                }
            },
            errorPlacement: function (error, element) {
                if (element.is("select")) {
                    element.parent().append(error);
                } else
                    error.insertAfter(element);
            },
            submitHandler: function (accountProfileForm) {
                submitOwnerProfileAjax(accountProfileForm);
            }
        });
    }

    function submitOwnerProfileAjax(form) {
        var form = $("#owner-account-profile-form:visible");
        var data = {};
        var formData = form.serializeArray();

        $.each(formData, function () {
            if (formData[this.name]) {
                if (!data[this.name].push) {
                    data[this.name] = [data[this.name]];
                }
                data[this.name].push(this.value || '');
            } else {
                data[this.name] = this.value || '';
            }
        });

        $.ajax({
            url: '/ownerArea/updateUserAjax',
            type: 'POST',
            contentType: "application/json",
            data: JSON.stringify(data),
            success: function () {
                var form = $("#owner-account-profile-form:visible");
                console.log('form success!');
                //form[0].reset();
                //form.validate().resetForm();
                $(window).scrollTop(0);
                window.$vent.trigger('updateUserDone');
                $("#success-message").removeClass('hide').fadeIn(1000);
            },
            error: function () {
                var form = $("#owner-account-profile-form:visible");
                console.log('form error!');
                form.validate().resetForm();
                $("form #error-message").fadeIn(1000);
            }

        });

        return false;
    }

    var OwnerAccountSettingsForm = $("#owner-account-settings-form:visible");
    if (OwnerAccountSettingsForm.length) {
        OwnerAccountSettingsForm.focusin(function () {
            $("form #success-message").fadeOut();
            $("form #error-message").fadeOut();
        });
        if (jQuery().validate) {
            validateOwnerAccountSettingsForm(OwnerAccountSettingsForm);
        }
    }

    function validateOwnerAccountSettingsForm(accountSettingsForm) {
        var requiredMessage = $('form #validation-required').text();
        var lengthMessage = $('form #validation-min-length').text();
        var equalToMessage = $('form #validation-equal-to').text();
        var emailMessage = $('form #validation-email').text();
        var phoneMessage = $('form #validation-phone').text();
//        var successMessage = $('#validation-success-message').text();
        accountSettingsForm.validate({
//            success: function (label) {
//                label.addClass("valid").text(successMessage);
//            },
            rules: {
                current_password: {
                    required: true,
                    minlength: 8
                },
                new_password: {
                    required: true,
                    minlength: 8
                },
                confirm_new_password: {
                    required: true,
                    minlength: 8,
                    equalTo: ".new_password"
                }
            },
            messages: {
                current_password: {
                    required: requiredMessage,
                    minlength: lengthMessage
                },
                new_password: {
                    required: requiredMessage,
                    minlength: lengthMessage
                },
                confirm_new_password: {
                    required: requiredMessage,
                    minlength: lengthMessage,
                    equalTo: equalToMessage
                }
            },
            submitHandler: function (accountSettingsForm) {
                submitOwnerSettingsAjax(accountSettingsForm);
            }
        });
    }

    function submitOwnerSettingsAjax(form) {
        var form = $("#owner-account-settings-form:visible");
        var data = {};
        var formData = form.serializeArray();

        $.each(formData, function () {
            if (formData[this.name]) {
                if (!data[this.name].push) {
                    data[this.name] = [data[this.name]];
                }
                data[this.name].push(this.value || '');
            } else {
                data[this.name] = this.value || '';
            }
        });

        $.ajax({
            url: '/ownerArea/updatePasswordAjax',
            type: 'POST',
            contentType: "application/json",
            data: JSON.stringify(data),
            success: function () {
                var form = $("#owner-account-settings-form:visible");
                console.log('form success!');
                //form[0].reset();
                //form.validate().resetForm();
                $(window).scrollTop(0);
                $("#success-message-password").removeClass('hide').fadeIn(1000);

                var redirectUrl = form.data('redirect-url');
                if (typeof(redirectUrl) !== 'undefined') {
                  window.location = redirectUrl;
                }
            },
            error: function () {
                var form = $("#owner-account-settings-form:visible");
                console.log('form error!');
                form.validate().resetForm();
                $("form #error-message").fadeIn(1000);
            }

        });

        return false;
    }

    var resetPasswordForm = $("#reset-password-form:visible");
    if (resetPasswordForm.length) {
        resetPasswordForm.focusin(function () {
            $("form #success-message").fadeOut();
            $("form #error-message").fadeOut();
        });
        if (jQuery().validate) {
            validateResetPasswordForm(resetPasswordForm);
        }
    }

    function validateResetPasswordForm(resetPasswordForm) {
        var requiredMessage = $('form #validation-required').text();
        var lengthMessage = $('form #validation-min-length').text();
        var equalToMessage = $('form #validation-equal-to').text();

        resetPasswordForm.validate({
            rules: {
                new_password: {
                    required: true,
                    minlength: 8
                },
                confirm_new_password: {
                    required: true,
                    minlength: 8,
                    equalTo: "#new-password"
                }
            },
            messages: {
                new_password: {
                    required: requiredMessage,
                    minlength: lengthMessage
                },
                confirm_new_password: {
                    required: requiredMessage,
                    minlength: lengthMessage,
                    equalTo: equalToMessage
                }
            },
            submitHandler: function (resetPasswordForm) {
                submitResetPasswordAjax(resetPasswordForm);
            }
        });
    }

    function submitResetPasswordAjax(form) {
        var form = $("#reset-password-form:visible");
        var data = {};
        var formData = form.serializeArray();

        $.each(formData, function () {
            if (formData[this.name]) {
                if (!data[this.name].push) {
                    data[this.name] = [data[this.name]];
                }
                data[this.name].push(this.value || '');
            } else {
                data[this.name] = this.value || '';
            }
        });

        var jsonData = JSON.stringify(data);

        $.ajax({
            url: '/global/saveNewPasswordAjax',
            type: 'POST',
            contentType: "application/json",
            data: jsonData,
            success: function (data) {
                var form = $("#reset-password-form:visible");
                console.log('form success!');

                $(location).attr('href', '/')
                //$("#success-message-password").removeClass('hide').fadeIn(1000);
                //$(".goto-home").removeClass('hide');
                //$(".submit-password").addClass('hide');
            },
            error: function () {
                var form = $("#reset-password-form:visible");
                console.log('form error!');
                form.validate().resetForm();
                $("form #error-message").fadeIn(1000);
            }

        });

        return false;
    }


    /* Scroll in menu
     * ============== */
    var previousTop = 0;
    $(window).scroll(function () {
        var currentTop = $(window).scrollTop();

        var $el = $('.main-menu');
        var $menuBlog = $('.blog-social');

        if (currentTop > 80) {
            $el.addClass('sticky-menu');
            $menuBlog.addClass('fixed-blog-social');
            //Control weather the scroll is positive or negative
            //check if user is scrolling up
            if (currentTop < previousTop) {
                $el.addClass('visible');
                $menuBlog.addClass('blog-fixed-visible');
            } else {
                $el.removeClass('visible');
                $menuBlog.removeClass('blog-fixed-visible');
            }
        } else {
            $el.removeClass('visible');
            $menuBlog.removeClass('blog-fixed-visible');
            $el.removeClass('sticky-menu');
            $menuBlog.removeClass('fixed-blog-social');
        }

        previousTop = currentTop;
    });
});

define("init", function(){});

