define(
	'modules/user-pages/views/UserPagesView',[
		'backbone',
		'utils/AbstractView',
		'utils/AbstractButtonView',
		
		'text!../templates/GuestServices.html',
		'text!../templates/GuestServices_es.html'
	], 
	function(Backbone, AbstractView, AbstractButtonView, Template, Template_es){

		var UserPagesView = Backbone.View.extend({

			// core vars

			$el: null,
			$container: null,

			options: null,
			abstractView: null,
			template: null,
			
			$imgContainer: null,
			$detailsContainer: null,
			
			section_class:null,
			mainElement:null,
			$guestCloseBtn: null,
			
			el_hide_class: null,

		
			// init

			initialize: function(options){

				var self = this;
				
				self.section_class='upct-section';
				self.mainElement=$('.usrres-text');
				
				self.$imgContainer=$('.upct-img');
				self.$detailsContainer=$('.upct-details');

				self.options = options;
				self.$el = self.options.el;
				
				self.el_hide_class='hide-ambassador'

				self.$lang = $('#lang').html();
				if (self.$lang == 'en') {
					var __html = _.template($(Template).html());
				} else  {
					var __html = _.template($(Template_es).html());
				}
				
				self.$el.html(__html);
				
				
				self.addListeners();
				//

				self.model = $.extend({}, self.model);

				// 

				self.abstractView = new AbstractView({
					view: self,
					autoShow: true
				});

				$('select').selectBoxIt({
					isMobile: function () {
						return false;
					},
					autoWidth: false
				});
				
				
			},
			
			addListeners: function(){
				var self = this;
				self.$guestCloseBtn=self.$el.find('.dashboard-close-icon');
				self.$guestCloseBtn.on('click', $.proxy(self._onGuestPanelClose, self));
				
			},
			
			_onGuestPanelClose:function(e){
				var self = this;
				self.$el.addClass(self.el_hide_class);
				self.mainElement.addClass(self.section_class);
				self.$imgContainer.show();
				self.$detailsContainer.show();
			},
			
			onShow: function(){
				var self = this;
				self.$imgContainer.hide();
				self.$detailsContainer.hide();
				self.mainElement.removeClass(self.section_class);
				self.$el.removeClass(self.el_hide_class);
			},
			

		});

	return UserPagesView;
});
