define(
    'modules/content-tabs/views/ContentTabs.js',[
        'backbone',
        'utils/AbstractView',
    ],
    function (Backbone, AbstractView) {

        var WhyTeam = Backbone.View.extend({

            // core vars
            $el: null,
            $tabs: null,

            // init
            initialize: function (options) {

                var self = this;

                $(function () {
                    self.onDomReady();
                    self.onRemoveListeners();
                    self.onAddListeners();
                });

            },

            //grab the properties
            onDomReady: function () {

                var self = this;

                self.$el = $('.tabs-container');
                self.$tabs = self.$el.find('.tabs li');

            },

            onRender: function () {

                var self = this;
                TweenMax.set(self.$navLine, {transformOrigin: '0% 0%'});
            },

            //add event handlers to them
            onAddListeners: function () {

                var self = this;
                self.$tabs.on('click', $.proxy(self._onTabClick, self));
            },
            onRemoveListeners: function () {

                var self = this;
                self.$tabs.off('click', $.proxy(self._onTabClick, self));
            },

            _onTabClick: function (e) {
                e.preventDefault();
                var self = this;
                var $tabClicked = $(e.currentTarget);
                var $containerTabClicked = $tabClicked.parents('.featured-tabs');
                var $tabsInContainerTabClicked = $containerTabClicked.find('.tabs li');
                var $boxToShow = $tabClicked.find('a').attr('href');

                $tabsInContainerTabClicked.find('a').removeClass('active');
                $containerTabClicked.find('.tab-content-box').removeClass('active');

                $tabClicked.find('a').addClass('active');
                $containerTabClicked.find($boxToShow).addClass('active');

                /* we call carousel plugin just in case the tab contains a carousel */
                window.$vent.trigger('reinitCarousel');


                /*				if( $($currentTab).prev().length > 0) {
                 TweenMax.fromTo($target, 0.6, { left: 100, opacity:	0 }, { left: 0, opacity: 1, ease:Cubic.easeOut });
                 } else {
                 TweenMax.fromTo($target, 0.6, { right: 100, opacity: 0 }, { right: 0, opacity: 1, ease:Cubic.easeOut });
                 }

                 var currentIndex = $currentTab.index();
                 var left = currentIndex*$currentTab.width();

                 TweenMax.to(self.$navLine, 0.4, {left:left, opacity:1, ease:Quint.easeOut});
                 $(window).trigger('scroll').trigger('resize');	*/
                return false;
            },

        });

        return WhyTeam;
    });
