define(
	'modules/hero-filters/app',[
		'backbone',

		'modules/calendars/views/CalendarMenuView',
		'modules/guests/views/GuestsMenuView',
		'modules/hero-filters/views/LocationsMenuView',

		'text!../../config/hero-filters.json',
		
		'clndr',
	], 
	function(Backbone, CalendarMenuView, GuestsMenuView, LocationsMenuView, HeroFiltersConfig){

		var AppView = Backbone.View.extend({

			// vars

			filtersConfig: null,

			locationsMenu: null,
			calendarMenu: null,
			guestsMenu: null,

			dateQuery: null,
			guestsQuery: null,
			locationQuery: null,

			$heroContainer: null,
			$heroContent: null,
			$heroInfo: null,

			$filtersContainer: null,
			$overlayContainer: null,
			$filterOptions: null,
			
			currentMenu: null,
			$activeFilterButton: null,

			isModalOpen: false,
			isMobile: false,
			isHome: false,

			queryResult: null,
			lastQueryString: null,			

			// init

			initialize: function(){

				var self = this;
				self.options = self.options || {};
				self.filtersConfig = JSON.parse(HeroFiltersConfig);

				self.$heroContainer = $('#hero');
				self.$heroContent = self.$heroContainer.find('.hero-content-middle');
				self.$heroInfo = self.$heroContainer.find('.hero-info');
				self.$filtersContainer = $('#hero-filters');
				self.$overlayContainer = $('#hero-filters-overlays');				
				self.$filterOptions = self.$filtersContainer.find('ul.filter-options');

				console.log('self.filtersConfig');
				console.dir(self.filtersConfig);

				window.$vent.trigger('heroFiltersReady');
			},

			// start

			start: function(){

				var self = this;
				self.rebuildMenus();

				//

				$(function(){
					self.addListeners();
					self.show();
				});
			},

			// rebuild menus (if switched from desktop to mobile or otherwise)

			rebuildMenus: function(){

				var self = this;

				if(self.locationsMenu){ self.locationsMenu.exitImmediately(); }
				if(self.calendarMenu){ self.calendarMenu.exitImmediately(); }
				if(self.guestsMenu){ self.guestsMenu.exitImmediately(); }

				// determine overlay container

				if(window.innerWidth >= window.MOBILE_WIDTH){
					self.$overlayContainer = $('#hero-filters-overlays');
				}
				else {
					self.$overlayContainer = $('#modal-content-wrapper');
				}

				// locations

				self.locationsMenu = new LocationsMenuView({
					$container: self.$overlayContainer,
					model: self.filtersConfig,
					autoShow: false,
				});

				// calendars
				
				self.calendarMenu = new CalendarMenuView({
					$container: self.$overlayContainer,
					$mobileWrap: $('#modal-content-wrapper'),
					$desktopWrap: $('#hero-filters-overlays'),
					model: self.filtersConfig,
					autoShow: false,
					hasOverlay: true,
				});	
				
				// guests

				self.guestsMenu = new GuestsMenuView({
					$container: self.$overlayContainer,
					model: self.filtersConfig,
					autoShow: false,
				});

				// menu listeners

				window.requestAnimationFrame(function(){

					self.locationsMenu.on('optionSelected', self._onLocationSelected, self);
					self.locationsMenu.on('closeMenu', self._onCloseMenu, self);
					self.locationsMenu.on('hide', self._onMenuClosed, self);
					
					self.calendarMenu.on('clearDates', self._onClearDates, self);
					self.calendarMenu.on('optionSelected', self._onDateSelected, self);
					self.calendarMenu.on('closeMenu', self._onCloseMenu, self);
					self.calendarMenu.on('hide', self._onMenuClosed, self);

					self.guestsMenu.on('optionSelected', self._onGuestsSelected, self);
					self.guestsMenu.on('closeMenu', self._onCloseMenu, self);
					self.guestsMenu.on('hide', self._onMenuClosed, self);
				});
			},

			// listeners -----------------------------------------------------------------------  /

			addListeners: function(){

				var self = this;

				_.each(self.$filterOptions.find('li'), function(filterButton, i){
					
					var $filterButton = $(filterButton);
					var defaultText = $filterButton.find('figcaption').html();
					$filterButton.attr('data-default-text', defaultText);
					$filterButton.on('click', $.proxy(self._onFilterOptionClick, self));
				});

				// select default option for location

				//self.locationsMenu.triggerOption();

				//
				window.$vent.on('homeFiltersReady', $.proxy(self._onHomeFiltersReady, self));
				window.$vent.on('homeRefreshQuery', $.proxy(self.refreshQuery, self));
				
				$(window).on('resize', $.proxy(self.onWindowResize, self));
				self.onWindowResize();
			},

			show: function(){

				var self = this;
				TweenMax.fromTo(self.$heroContent, 0.6, {opacity:0}, {opacity:1, ease:Cubic.easeInOut});
			},

			// listener methods

			_onHomeFiltersReady: function(){
				
				var self = this;
				self.isHome = true;				
			},	
			
			_onFilterOptionClick: function(e){

				var self = this;
				var $target = $(e.currentTarget);
				var $nextFilterButton = $target;
				var nextMenu;

				self.isModalOpen = false;
			
				if($target.hasClass('location')){

					nextMenu = self.locationsMenu;

				} else if($target.hasClass('check-in')){
					
					$nextFilterButton = self.$filterOptions.find('.date-option');
					nextMenu = self.calendarMenu;							

				} else if($target.hasClass('check-out')){
					
					$nextFilterButton = self.$filterOptions.find('.date-option');
					nextMenu = self.calendarMenu;

				} else if($target.hasClass('guests')){

					nextMenu = self.guestsMenu;
				}

				if(nextMenu && nextMenu !== self.currentMenu){

					console.log("---------------- _onFilterOptionClick",self.$el);

					self.isModalOpen = true;

					if(e){ e.stopPropagation(); }
					if(self.currentMenu){ self.closeCurrentMenu(); }

					self.currentMenu = nextMenu;
					self.$activeFilterButton = $nextFilterButton;

					self.currentMenu.show();
					self.hideCurrentFilterButton();

					self.$el.off('click', $.proxy(self._onOutClick, self));
					self.$el.on('click', $.proxy(self._onOutClick, self));
					
					self.$overlayContainer.removeClass('hidden');

					window.$vent.trigger('openFiltersMenu');

					e.preventDefault();
				}
			},

			_onLocationSelected: function(e){

				var self = this;
				
				self.isLocationSelected = true;
				self.locationQuery = e.query;

				self.updateFilterButtonText('location', e.results);
			},

			_onDateSelected: function(e){

				var self = this;
				
				console.dir(e);

				self.isDateSelected = true;
				self.dateCheckInQuery = e.checkInQuery;
				self.dateCheckOutQuery = e.checkOutQuery;

				self.updateFilterButtonText('check-in', e.results);
				self.updateFilterButtonText('check-out', e.results2);	

				//update for home filters
				/*self._onCheckLocation();*/
				
				window.$vent.trigger('filterSelected', {checkIn:e.checkInQuery, checkOut:e.checkOutQuery});	
			},
			
			_onCheckLocation: function(){
				
				var self = this;
				var currentTab = $('.search-rent:visible');	
				if(currentTab && currentTab.val().length){
					self.isLocationSelected = true;
					self.locationQuery = $('.search-rent:visible').val();
				}else{
					self.isLocationSelected = false;
				}	
			},

			_onClearDates: function(e){

				var self = this;

				self.updateFilterButtonText('check-in');
				self.updateFilterButtonText('check-out');

				window.$vent.trigger('dateSelectedClear');
			},

			_onGuestsSelected: function(e){

				var self = this;
				
				self.isGuestsSelected = true;
				self.guestsQuery = e.query;

				var pos = self.guestsQuery.indexOf('guests=');
				if(pos === 0){ self.guestsQuery = self.guestsQuery.substr(7); }

				self.updateFilterButtonText('guests', e.results);				
			},

			_onCloseMenu: function(e){
				console.log("---------------- _onCloseMenu");

				var self = this;
				self.closeCurrentMenu();
			},

			_onMenuClosed: function(e){

				console.log("---------------- _onMenuClosed");

				var self = this;				
				self.refreshQuery();
			},

			_onOutClick: function(e){

				console.log("_onOutClick");
				var self = this;

				if(e){
					
					if(!self.$overlayContainer[0].contains(e.target) && $('body')[0].contains(e.target)){
						self.closeCurrentMenu();
					}	
				}
				else { self.closeCurrentMenu(); }
			},

			// close menu

			closeCurrentMenu: function(e){

				console.log("---------------- close currentMenu");
				var self = this;

				if(self.currentMenu && self.isModalOpen){

					self.restoreCurrentFilterButton();

					self.currentMenu.on('hideComplete', function(e){
						if(!self.currentMenu){
							self.$overlayContainer.removeClass('hidden').addClass('hidden');
						}
					});

					self.currentMenu.hide();
					self.currentMenu = null;

					self.$el.off('click', $.proxy(self._onOutClick, self));
				}
			},

			// hide / restore clicked filter button values

			hideCurrentFilterButton: function(){
				
				var self = this;
				
				_.each(self.$activeFilterButton, function(filter, i){
					var $filter = $(filter);
					TweenMax.to($filter.find('a'), 0.3, {opacity:0, ease:Cubic.easeOut});
				});
			},

			restoreCurrentFilterButton: function(){
				
				var self = this;

				_.each(self.$activeFilterButton, function(filter, i){
					var $filter = $(filter);
					TweenMax.fromTo($filter.find('a'), 0, {opacity:0}, {opacity:1, ease:Quint.easeInOut});
				});
			},

			updateFilterButtonText: function(buttonClass, text){

				var self = this;
				var $filterButton = self.$el.find('.filter-option.' + buttonClass);

				if(text){
					$filterButton.find('figcaption').html(text);
					$filterButton.removeClass('selected').addClass('selected');
				}
				else {
					$filterButton.find('figcaption').html($filterButton.data('default-text'));
					$filterButton.removeClass('selected');
				}
			},

			// server query and results --------------------------------------------------------  /

			refreshQuery: function(){
				
				var self = this;
				
				//update for home filters
				/*self._onCheckLocation();*/
											
				var queryString = self.$filtersContainer.data('query-url') || '';
                console.log("***** query string herofilters: " + queryString);

				if(self.isLocationSelected){ 
					queryString = self.replaceURLParam(queryString, 'location', self.locationQuery); 
				}
				if(self.isDateSelected){ 
					queryString = self.replaceURLParam(queryString, 'checkIn', self.dateCheckInQuery); 
					queryString = self.replaceURLParam(queryString, 'checkOut', self.dateCheckOutQuery); 
				}
				if(self.isGuestsSelected){ 
					queryString = self.replaceURLParam(queryString, 'guests', self.guestsQuery); 
				}
				
				//////				
								
				if(!self.isLocationSelected){
					self.showLocationMessage();
				}			
				else {
										
					if(queryString && queryString != self.lastQueryString){

						// TODO - show loading status
						
						var loadStartTime = (new Date()).getTime();
						var minTime = 1;

						self.lastQueryString = queryString;
						self.showQueryProgress();

						$.when($.proxy(self.queryServer, self)()).then(function(e){
							var d = Math.max((minTime - ((new Date()).getTime() - loadStartTime)/1000), 0);
							console.dir(e);
                            self.$lang = $('#lang').html();
                            if (self.$lang == 'en') {
                                self.queryResult = '<span class="count">' + e[0].count + '</span> Available Apartments.<span class="hidden-xs"> See Results</span>';
                            } else {
                                self.queryResult = '<span class="count">' + e[0].count + '</span> apartamentos disponibles.<span class="hidden-xs"> Ver Resultados</span>';
                            }   
							self.showQueryResults(d);
						});
					}
				}
			},

			queryServer: function(){

				var self = this;
				var dfd = $.Deferred();
                // Checks type currently selected
                self.typeQuery = $('.search-item:visible').data('type');
                console.log("TypeQuery selected: " + self.typeQuery);
                
                self.lastQueryString = self.lastQueryString + "&type=" + self.typeQuery;
                console.log("*** last query string: " + self.lastQueryString);
                
				$.ajax({

					type: 'GET',
					url: self.lastQueryString,
					async: false,
					jsonpCallback: 'callBack',
					contentType: 'application/json',
					dataType: 'jsonp',

					success: function(json) {
						dfd.resolve(json.results);
					},
					error: function(e) {
						console.log('JSON Load Error heroFilters', self);
						console.log(e.message);
						dfd.reject();
					}
				});

				return dfd.promise();
			},

			showQueryProgress: function(){

				var self = this;
				var $resultsButton = self.$heroContainer.find('.hero-btn');
				var $loadingText = $resultsButton.find('.idle figcaption');	
				var $resultsState = $resultsButton.find('.results');	
				var $resultsText = $resultsState.find('a');			

				if($resultsState.hasClass('hidden')){

					TweenMax.to($loadingText, 0.3, {opacity:0, ease:Cubic.easeOut, onComplete:function(){
                        self.$lang = $('#lang').html();
                        if (self.$lang == 'en') {
                            $loadingText.html('Searching...');
                        } else {
                            $loadingText.html('Buscando...');
                        }    
						TweenMax.fromTo($loadingText, 0.3, {y:6}, {y:0, opacity:1, ease:Sine.easeOut});
					}});
				} 
				else {

					TweenMax.to($resultsText, 0.3, {opacity:0, ease:Cubic.easeOut, onComplete:function(){
                        self.$lang = $('#lang').html();
                        if (self.$lang == 'en') {
                            $resultsText.find('figcaption').html('Searching...');
                        } else {
                            $resultsText.find('figcaption').html('Buscando...');
                        }    
						TweenMax.fromTo($resultsText, 0.3, {y:6}, {y:0, opacity:1, ease:Sine.easeOut});
					}});
				}
			},

			showLocationMessage: function(){

				var self = this;
				var $resultsButton = self.$heroContainer.find('.hero-btn');
				var $loadingText = $resultsButton.find('.idle figcaption');	
				
				TweenMax.to($loadingText, 0.3, {opacity:0, ease:Cubic.easeOut, onComplete:function(){
                    self.$lang = $('#lang').html();
                    if (self.$lang == 'en') {
                        $loadingText.html('Please Select Location');
                    } else {
                        $loadingText.html('Selecciona una ubicación');
                    }
					
					TweenMax.fromTo($loadingText, 0.3, {y:6}, {y:0, opacity:1, ease:Sine.easeOut});
				}});
			},

			showQueryResults: function(d){

				var self = this;
				var $resultsButton = self.$heroContainer.find('.hero-btn');
				var $loadingText = $resultsButton.find('.idle figcaption');	
				var $resultsState = $resultsButton.find('.results');	
				var $resultsText = $resultsState.find('a');
				
				if(self.queryResult){

					var resultsLink = $resultsText.attr('href');

					if(self.isLocationSelected){ resultsLink = self.replaceHashParam(resultsLink, 'location', self.locationQuery); }
					if(self.isDateSelected){ 
						resultsLink = self.replaceHashParam(resultsLink, 'checkIn', self.dateCheckInQuery); 
						resultsLink = self.replaceHashParam(resultsLink, 'checkOut', self.dateCheckOutQuery); 
					}
					if(self.isGuestsSelected){ 
						resultsLink = self.replaceHashParam(resultsLink, 'guests', self.guestsQuery); 
					}

					$resultsText.attr('href', resultsLink);

					if($resultsState.hasClass('hidden')){

						TweenMax.to($loadingText, 0.3, {opacity:0, ease:Cubic.easeOut, delay:d, onComplete:function(){
							
							$resultsState.removeClass('hidden');
							$resultsText.find('figcaption').html(self.queryResult);
							
							TweenMax.fromTo($resultsState, 0.5, {opacity:0}, {opacity:1, ease:Cubic.easeOut});
						}});
					}
					else {

						TweenMax.to($resultsText, 0.3, {opacity:0, ease:Cubic.easeOut, delay:d, onComplete:function(){
							
							$resultsText.find('figcaption').html(self.queryResult);

							TweenMax.fromTo($resultsText, 0.3, {opacity:0}, {opacity:1, ease:Cubic.easeOut});
						}});
					}
				}
				else {
						
					d = 0.3;

					if(!$resultsState.hasClass('hidden')){

						TweenMax.to($resultsState, 0.3, {opacity:0, ease:Cubic.easeOut, onComplete:function(){
							$resultsState.removeClass('hidden').addClass('hidden');
						}});
					}
					else { 
						TweenMax.to($loadingText, 0.3, {opacity:0, ease:Cubic.easeOut});
					}

					TweenMax.delayedCall(d, function(){					
						$loadingText.html('Searching...');
						TweenMax.fromTo($loadingText, 0.3, {y:6}, {y:0, opacity:1, ease:Sine.easeOut});
					});
				}
			},

			replaceHashParam: function(url, paramName, paramValue){

			    var pattern = new RegExp('('+paramName+'=).*?(&|$)');
			    var newUrl=url;
			    
			    if(url.search(pattern)>=0){
			        newUrl = url.replace(pattern,'$1' + paramValue + '$2');
			    }
			    else{
			        newUrl = newUrl + (newUrl.indexOf('#')>=0 ? '&' : '#') + paramName + '=' + paramValue;
			    }
			    return newUrl;
			},
			
			replaceURLParam: function(url, paramName, paramValue){

			    var pattern = new RegExp('('+paramName+'=).*?(&|$)');
			    var newUrl=url;
			    
			    if(url.search(pattern)>=0){
			        newUrl = url.replace(pattern,'$1' + paramValue + '$2');
			    }
			    else{
			        newUrl = newUrl + (newUrl.indexOf('?')>=0 ? '&' : '?') + paramName + '=' + paramValue;
			    }
			    return newUrl;
			},	

			// window resizing

			onWindowResize: function(e){

				var self = this;

				self.winWidth = window.innerWidth;
				self.winHeight = window.innerWidth;
				self.docHeight = $(document).outerHeight();

				if(self.winWidth >= window.MOBILE_WIDTH){

					var filtersScale = 1;
					var infoScale = 1;
					var paddingScale = 1;

					if(self.winWidth < 1300 && self.winWidth >= 768){

						var minFiltersScale = (768 - 80) / 1200;
						var minInfoScale = 0.8;
						var minPaddingScale = 0;
						var widthDiff = 1300 - 768;

						filtersScale = (widthDiff - (1300 - self.winWidth)) / widthDiff * (1 - minFiltersScale) + minFiltersScale;
						infoScale = (widthDiff - (1300 - self.winWidth)) / widthDiff * (1 - minInfoScale) + minInfoScale;
						paddingScale = (widthDiff - (1300 - self.winWidth)) / widthDiff * (1 - minPaddingScale) + minPaddingScale;
					}

					TweenMax.set(self.$filtersContainer, {scale:filtersScale});
					TweenMax.set(self.$heroContent, {bottom:18 * (filtersScale * filtersScale) + '%'});
					TweenMax.set(self.$heroInfo, {transformOrigin:'50% 100%', scale:infoScale, paddingBottom:105 * paddingScale + 'px'});

					if(self.isMobile){
						//self.$filtersContainer.append(self.$overlayContainer);
					}

					//TweenMax.set(self.$overlayContainer, {clearProps:'zIndex,top,height'});

					self.isMobile = false;
				}
				else {

					TweenMax.set(self.$filtersContainer, {scale:1});
					TweenMax.set(self.$heroContent, {bottom:0 + '%'});
					TweenMax.set(self.$heroInfo, {transformOrigin:'50% 100%', scale:1, paddingBottom:20 + 'px'});

					if(!self.isMobile){
						//$('.page-wrapper').append(self.$overlayContainer);
					}

					//TweenMax.set(self.$overlayContainer, {height:self.docHeight, top:0, zIndex:10000});
					self.isMobile = true;


				}
			},

			// ---------------------------------------------------------------------------------  /

		});

		return AppView;
	}
);
