define(
    'modules/cookies/app',[
        'backbone'
    ],
    function (Backbone) {

        var AppView = Backbone.View.extend({
            // vars

            $localStorage: null,


            initialize: function () {

                var self = this;

            },
            // start module --------------------------------------------------------------------  /

            setCookie: function (key, value, exdays, page) {
                /*console.log("======== setCookie ========", key);*/
                var d = new Date();
                d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
                var expires = "expires=" + d.toUTCString();
                var path = "/";
                if (page) {
                    path = page;
                }
                /*console.log("======== cookie seted ========", key + "=" + value + ";" + expires + ";path=" + path);*/
                document.cookie = key + "=" + value + ";" + expires + ";path=" + path;
            },
            getCookie: function (key) {
                var name = key + "=";
                var decodedCookie = decodeURIComponent(document.cookie);
                var ca = decodedCookie.split(';');
                for (var i = 0; i < ca.length; i++) {
                    var c = ca[i];
                    while (c.charAt(0) == ' ') {
                        c = c.substring(1);
                    }
                    if (c.indexOf(name) == 0) {
                       /* console.log("======== get cookie ========", c.substring(name.length, c.length));*/
                        return c.substring(name.length, c.length);
                    }
                }
                /*console.log("======== no cookie ========");*/
                return null;
            },
            deleteCookie: function (key, value, page) {
                var path = "/";
                if (page) {
                    path = page;
                }
                /*console.log("======== delete cookie ========", key);*/
                document.cookie = key + "=" + value + ";" + "expires=Thu, 01 Jan 1970 00:00:00 UTC;" + ";path=/" + path;
            },


            // ---------------------------------------------------------------------------------  /

        });

        return AppView;
    }
);
