define(
	'modules/why-team/app',[
		'backbone',
		'modules/why-team/views/WhyTeam.js',
	], 
	function(Backbone, WhyTeam){

		var AppView = Backbone.View.extend({

			// init

			initialize: function() {

				var self = this;

				$(function () {
					self.start();
				});
			},
			
			// start module --------------------------------------------------------------------  /

			start: function(){

				var self = this;
				self.$el = new WhyTeam();
				window.$vent.trigger('whyTeamReady');

				window.$vent.on('domUpdate', $.proxy(self._onDomUpdate, self));
			}
		});

		return AppView;
	}
);
