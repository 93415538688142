define(
    'modules/properties-slider/app',[
        'backbone',
    ],
    function (Backbone) {

        var AppView = Backbone.View.extend({

            // init

            initialize: function () {

                var self = this;

                window.$vent.trigger('propertiesSliderReady');
            },

            // start module --------------------------------------------------------------------  /
            start: function () {

                var self = this;

                $(function () {
                    self.onDomReady();
                });

            },
            onDomReady: function () {
                var self = this;

                self.$el = $('.properties-slider-section').not('.hide');
                self.$container = self.$el.find('.properties-slider-container:visible');
                self.numItems = self.$container.attr('data-shownItems-slider');
                self.numItems = parseInt(self.numItems);

                self.sliderSettings = {
                    slidesToShow: self.numItems,
                    slidesToScroll: self.numItems,
                    prevArrow: '<button type="button" class="slick-prev icon icon-leftArrow"></button>',
                    nextArrow: '<button type="button" class="slick-next icon icon-rightArrow"></button>',
                    arrows: true,
                    responsive: [
                        {
                            breakpoint: 767,
                            settings: {
                                slidesToShow: 1,
                                slidesToScroll: 1,
                                arrows: true,
                                fade: true,
                            }
                        }
                    ]
                };
                self.$container.not('.slick-initialized').slick(self.sliderSettings);

                window.$vent.off('reinitCarousel');
                window.$vent.on('reinitCarousel', $.proxy(self._onReinitCarousel, self));
            },
            _onReinitCarousel: function () {

                var self = this;

                self.$el = $('.properties-slider-section').not('.hide');
                self.$container = self.$el.find('.properties-slider-container:visible');
                self.numItems = self.$container.attr('data-shownItems-slider');
                self.numItems = parseInt(self.numItems);
                self.sliderSettings = {
                    slidesToShow: self.numItems,
                    slidesToScroll: self.numItems,
                    prevArrow: '<button type="button" class="slick-prev icon icon-leftArrow"></button>',
                    nextArrow: '<button type="button" class="slick-next icon icon-rightArrow"></button>',
                    arrows: true,
                    responsive: [
                        {
                            breakpoint: 767,
                            settings: {
                                slidesToShow: 1,
                                slidesToScroll: 1,
                                arrows: true,
                                fade: true,
                            }
                        }
                    ]
                };

                if (self.$container.not('.slick-initialized').length > 0) {
                    self.$container.not('.slick-initialized').slick(self.sliderSettings);
                } else {
                    self.$container.slick('unslick');
                    self.$container.slick(self.sliderSettings);
                }
            }
        });

        return AppView;
    }
);
