define(
    'modules/main-menu/app',[
        'backbone',
        'modules/cookies/app',
        'utils/components/app/ScrollTriggeredElement',
    ],
    function (Backbone, Cookies) {

        var AppView = Backbone.View.extend({
            // vars
            $el: null,
            lang: null,
            $hamburgerButton: null,
            $profileMenuButton: null,
            $mobileProfileMenu: null,
            $mobileMenu: null,
            $logOutButton: null,
            $logInButton: null,
            $linksContainer: null,
            $linksExpandMenu: null,
            previousTop: null,
            $blurMenuExpanded: null,

            localStorageKey: 'district',
            localStorageValue: null,
            selectLocationsMadrid: null,
            selectLocationsIbiza: null,
            $selectDistrictInMobileMenu: null,

            $cookies: null,

            // init

            initialize: function (options) {

                var self = this;
                self.$el = $('.main-menu');
                self.previousTop = 0;
                self.lang = $('#lang').html();

                self.$cookies = new Cookies();
                window.mycookies = self.$cookies;
            },
            // start module --------------------------------------------------------------------  /

            start: function () {

                var self = this;
                window.$vent.trigger('mainMenuReady');

                $(function () {
                    self.onDomReady();
                    self.removeListeners();
                    self.addListeners();
                });
            },
            // dom ready

            onDomReady: function () {

                var self = this;

                self.$hamburgerButton = self.$el.find('.hamburger');
                self.$profileMenuButton = self.$el.find('.logged-in:not(.hide)');
                self.$mobileMenu = $('#mobile-main-menu');
                self.$mobileProfileMenu = $('#mobile-profile-menu');
                self.$logOutButton = $('.logout');
                self.$logInButton = $('#main-menu .logged-out:not(.hide)');
                self.$linksContainer = $('.pull-right');
                self.$linksExpandMenu = $('.menu-expanded-link');
                self.$blurMenuExpanded = $('.blur-menu-expanded');
                self.$elMobileMenu = $('.mobile-main-menu');

                self.$selectDistrictInMenu = $('#selectDistrictInMenu');
                self.$selectDistrictInMobileMenu = self.$elMobileMenu.find('input[type=radio][name=city]');
                self.selectLocationsMadrid = [{value: 'madrid', text: 'Madrid'}, {value: 'ibiza', text: 'Ibiza'}];
                self.selectLocationsIbiza = [{value: 'ibiza', text: 'Ibiza'}, {value: 'madrid', text: 'Madrid'}];

                self.$selectDistrictInMenu.selectBoxIt({
                    isMobile: function () {
                        return false;
                    },
                    autoWidth: true
                });

                self.localStorageValue = self.$cookies.getCookie(self.localStorageKey);
                /* If district is not selected we set madrid by default*/
                if (!self.localStorageValue) {
                    self.localStorageValue = 'madrid';
                }

                /* Set menu links */
                self.changeLinksInMenu();

                // setting menu based on current property location when on Property Detail
                console.log('****** setting property detail city', self.$selectDistrictInMenu.data('location'));
                if (self.$selectDistrictInMenu.data('location')) {
                    var location = self.$selectDistrictInMenu.data('location');
                    var city = location.split(',')[1].toLowerCase().trim();
                    self.localStorageValue = city;
                    console.log('****** localStorageKey =>', self.localStorageValue);
                }

                self.setDistrictSelect();
            },
            // listeners

            addListeners: function () {

                var self = this;


                self.$hamburgerButton.on('click', $.proxy(self._onHamburgerClick, self));
                self.$profileMenuButton.on('click', $.proxy(self._onProfileMobileClick, self));
                self.$mobileMenu.on('click', $.proxy(self._onMobileMenuClickOut, self));
                self.$mobileProfileMenu.on('click', $.proxy(self._onProfileMobileClickOut, self));
                self.$logOutButton.on('click', $.proxy(self._onLogoutClick, self));
                self.$logInButton.on('click', $.proxy(self._onLogInClick, self));
                self.$linksContainer.on('mouseenter', $.proxy(self._onLinkHover, self));
                self.$linksExpandMenu.on('mouseenter', $.proxy(self._onLinkExpandMenu, self));
                self.$linksContainer.on('mouseleave', $.proxy(self._onLinkHoverLeave, self));
                self.$linksExpandMenu.on('mouseleave', $.proxy(self._onLinkExpandMenuLeave, self));

                self.$selectDistrictInMenu.on('change', $.proxy(self._onDistrictChanged, self));
                self.$selectDistrictInMobileMenu.on('change', $.proxy(self._onDistrictChanged, self));

                window.$vent.on('changeDistrictSelectInHome');
                window.$vent.on('changeDistrictSelectInHome', $.proxy(self._onDistrictChangedInHome, self));


            },
            removeListeners: function () {

                var self = this;

                self.$hamburgerButton.off('click', $.proxy(self._onHamburgerClick, self));
                self.$profileMenuButton.off('click', $.proxy(self._onProfileMobileClick, self));
                self.$mobileMenu.off('click', $.proxy(self._onMobileMenuClickOut, self));
                self.$mobileProfileMenu.off('click', $.proxy(self._onProfileMobileClickOut, self));
                self.$logOutButton.off('click', $.proxy(self._onLogoutClick, self));
                self.$logInButton.off('click', $.proxy(self._onLogInClick, self));
                self.$linksContainer.off('mouseenter', $.proxy(self._onLinkHover, self));
                self.$linksExpandMenu.off('mouseenter', $.proxy(self._onLinkExpandMenu, self));
                self.$linksContainer.off('mouseleave', $.proxy(self._onLinkHoverLeave, self));
                self.$linksExpandMenu.off('mouseleave', $.proxy(self._onLinkExpandMenuLeave, self));

                self.$selectDistrictInMenu.off('change', $.proxy(self._onDistrictChanged, self));
                self.$selectDistrictInMobileMenu.off('change', $.proxy(self._onDistrictChanged, self));

                window.$vent.off('changeDistrictSelectInHome');
                window.$vent.off('changeDistrictSelectInHome', $.proxy(self._onDistrictChangedInHome, self));
            },
            // listener methods
            _onLogoutClick: function (e) {

                var self = this;

                var form = document.createElement("form");
                form.method = "POST";
                form.action = "/logoff";

                form.submit();
            },
            _onLogInClick: function (e) {
                var self = this;

                window.$vent.trigger('openModal', '/modals/login');
            },

            _onProfileMobileClick: function () {
                var self = this;

                $(self.$mobileProfileMenu).toggleClass('displayed');
                $(self.$mobileMenu).removeClass('displayed');

            },
            _onHamburgerClick: function () {
                var self = this;

                self.isHamburgerExpanded = !self.isHamburgerExpanded;

                self.$mobileMenu.toggleClass('displayed');
                self.$mobileProfileMenu.removeClass('displayed');

                /*  self._onWindowResize();*/
            },
            _onProfileMobileClickOut: function (e) {

                var self = this;

                if (!self.$mobileProfileMenu[0].contains(e.target) || e.target == self.$mobileProfileMenu[0]) {
                    self.$mobileProfileMenu.removeClass('displayed');
                }

                /*self._onWindowResize();*/
            },
            _onMobileMenuClickOut: function (e) {
                var self = this;

                if (!self.$mobileMenu[0].contains(e.target) || e.target == self.$mobileMenu[0]) {
                    self.$mobileMenu.removeClass('displayed');
                }
            },

            _onLinkHover: function () {
                var self = this;

                self.$el.addClass('showBackground');
            },
            _onLinkExpandMenu: function (e) {
                var self = this;

                self.$el.addClass('showBlurMenuExpanded');
                self.$blurMenuExpanded.addClass('showBlurMenuExpanded');
            },
            _onLinkHoverLeave: function (e) {
                var self = this;

                self.$el.removeClass('showBackground');
            },
            _onLinkExpandMenuLeave: function () {
                var self = this;

                self.$el.removeClass('showBlurMenuExpanded');
                self.$blurMenuExpanded.removeClass('showBlurMenuExpanded');
            },

            _onScroll: function () {
                var self = this;

                var currentTop = document.body.scrollTop;

                console.log("scroll", currentTop);

                if (currentTop > $('#hero').height()) {
                    self.$el.addClass('sticky');
                    //Control weather the scroll is positive or negative
                    //check if user is scrolling up
                    if (currentTop < self.previousTop) {
                        self.$el.addClass('visible');
                    } else {
                        self.$el.removeClass('visible');
                    }
                } else {
                    self.$el.removeClass('visible');
                    self.$el.removeClass('sticky');
                }

                self.previousTop = currentTop;
            },

            // district functions
            _onDistrictChanged: function (e, data) {
                e.preventDefault();

                var self = this;
                var districtSelected;
                var url = window.location.origin + '/' + self.lang + '/';

                console.log('_onDistrictChanged triggered', data);

                /* For select in laptop and tablet */
                if (data) {
                    /* It can come from the popup district in home o the searcher in the home page*/
                    if (data.localStorage && data.localStorage != 'undefined') {

                        districtSelected = data.localStorage;
                    } else {
                        /* It can come from the selectDistrict in main menu */
                        var currentLinkDistrict = $(e.currentTarget);
                        districtSelected = $(currentLinkDistrict).val();
                    }
                } else {
                    /* For mobile radio buttons */
                    var currentLinkDistrict = $(e.currentTarget);
                    districtSelected = $(currentLinkDistrict).val();
                }

                console.log(url + districtSelected);
                window.location.assign(url + districtSelected);

                /*

                 /!* 1 Set the district selected in the localStorage *!/
                 self.localStorageValue = districtSelected;
                 self.$cookies.setCookie(self.localStorageKey, districtSelected, 1000);

                 /!* 2 change option in select in the menu*!/
                 self.changeDistrictSelect();
                 /!* 3 change links in menu *!/
                 self.changeLinksInMenu();
                 /!* 4 change look and feel of homepage *!/
                 $(window).trigger('changeHomeContent', [{$localStorage: self.localStorageValue}, self]);
                 */

            },
            _onDistrictChangedInHome: function (e, data) {
                e.preventDefault();

                var self = this;
                var districtSelected;

                /* For select in laptop and tablet */
                if (data) {
                    /* It can come from the popup district in home o the searcher in the home page*/
                    if (data.localStorage && data.localStorage != 'undefined') {

                        districtSelected = data.localStorage;
                    } else {
                        /* It can come from the selectDistrict in main menu */
                        var currentLinkDistrict = $(e.currentTarget);
                        districtSelected = $(currentLinkDistrict).val();
                    }
                } else {
                    /* For mobile radio buttons */
                    var currentLinkDistrict = $(e.currentTarget);
                    districtSelected = $(currentLinkDistrict).val();
                }


                /* 1 Set the district selected in the localStorage */
                self.localStorageValue = districtSelected;
                self.$cookies.setCookie(self.localStorageKey, districtSelected, 1000);

                /* 2 change option in select in the menu*/
                self.changeDistrictSelect();
                /* 3 change links in menu */
                self.changeLinksInMenu();
                /* 4 change look and feel of homepage */
                $(window).trigger('changeHomeContent', [{$localStorage: self.localStorageValue}, self]);

            },

            setDistrictSelect: function () {
                var self = this;

                /* laptop */
                if (self.localStorageValue == 'madrid') {
                    self.$selectDistrictInMenu.data("selectBox-selectBoxIt").remove();
                    self.$selectDistrictInMenu.data("selectBox-selectBoxIt").add(self.selectLocationsMadrid);
                }
                else {
                    self.$selectDistrictInMenu.data("selectBox-selectBoxIt").remove();
                    self.$selectDistrictInMenu.data("selectBox-selectBoxIt").add(self.selectLocationsIbiza);
                }

                /* movil */
                self.$elMobileMenu.find("input[type=radio][value='" + self.localStorageValue + "']").attr('checked', 'checked');


            },
            changeDistrictSelect: function () {
                var self = this;

                /*Laptop*/
                // Refreshes the drop down to reflect the current state of the select box: it should be work but it doesnt
                /*   self.$selectDistrictInMenu.find("[data-val='" + self.localStorageValue + "']").attr('selected', 'selected');
                 self.$selectDistrictInMenu.data("selectBox-selectBoxIt").refresh();*/

                if (self.localStorageValue == 'ibiza') {
                    self.$selectDistrictInMenu.data("selectBox-selectBoxIt").remove();
                    self.$selectDistrictInMenu.data("selectBox-selectBoxIt").add(self.selectLocationsIbiza);

                } else {
                    self.$selectDistrictInMenu.data("selectBox-selectBoxIt").remove();
                    self.$selectDistrictInMenu.data("selectBox-selectBoxIt").add(self.selectLocationsMadrid);
                }

                /* mobile */
                self.$elMobileMenu.find("input[type=radio][value='" + self.localStorageValue + "']").attr('checked', 'checked');

            },
            changeLinksInMenu: function () {
                var self = this;

                var links = $('.link-change-by-district');

                $(links).each(function (i, contentBox) {
                    var contentToHide = $(contentBox).find('[data-district-content]');
                    var contentToShow = $(contentBox).find('[data-district-content="' + self.localStorageValue + '"]');

                    contentToHide.addClass('hide');
                    contentToShow.removeClass('hide');
                });

            },

            // ---------------------------------------------------------------------------------  /

        });

        return AppView;
    }
);

