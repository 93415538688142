define(
    'modules/home/views/PropertiesSliderOfficesView',[
        'backbone',
        'utils/AbstractView',
        'slick'
    ],
    function (Backbone, AbstractView, slick) {

        var propertiesSliderOfficesView = Backbone.View.extend({

            // core vars
            $el: null,
            $container: null,
            slick: null,

            numItems: null,
            sliderSettingsHome: null,

            // init
            initialize: function (options) {

                var self = this;

                self.onDomReady();

            },

            //grab the properties
            onDomReady: function () {
                var self = this;

                self.$el = $('.properties-slider-section-offices').not('.hide');
                self.$container = self.$el.find('.properties-slider-container:visible');
                self.numItems = self.$container.attr('data-shownItems-slider');
                self.numItems = parseInt(self.numItems);

                self.sliderSettingsHome = {
                    slidesToShow: self.numItems,
                    slidesToScroll: self.numItems,
                    prevArrow: '<button type="button" class="slick-prev icon icon-leftArrow"></button>',
                    nextArrow: '<button type="button" class="slick-next icon icon-rightArrow"></button>',
                    arrows: true
                };
                self.$container.not('.slick-initialized').slick(self.sliderSettingsHome);

                window.$vent.off('reinitCarouselOffices', $.proxy(self._onReinitCarousel, self));
                window.$vent.on('reinitCarouselOffices', $.proxy(self._onReinitCarousel, self));
            },

            _onReinitCarousel: function () {

                var self = this;

                self.$el = $('.properties-slider-section-offices').not('.hide');
                self.$container = self.$el.find('.properties-slider-container:visible');
                self.numItems = self.$container.attr('data-shownItems-slider');
                self.numItems = parseInt(self.numItems);
                self.sliderSettingsHome = {
                    slidesToShow: self.numItems,
                    slidesToScroll: self.numItems,
                    prevArrow: '<button type="button" class="slick-prev icon icon-leftArrow"></button>',
                    nextArrow: '<button type="button" class="slick-next icon icon-rightArrow"></button>',
                    arrows: true
                };

                if (self.$container.not('.slick-initialized').length > 0) {
                    self.$container.not('.slick-initialized').slick(self.sliderSettingsHome);
                } else {
                    self.$container.slick('unslick');
                    self.$container.slick(self.sliderSettingsHome);
                }
            }

        });

        return propertiesSliderOfficesView;
    });
