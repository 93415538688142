define(
	'modules/mobile-interactions/app',[
		'backbone',
		'modules/mobile-interactions/views/MobileGuideSelectorView',
		'selectboxit',
		'jqueryhammer'

	], 
	function(Backbone, MobileGuideSelectorView,selectBoxIt){

		var AppView = Backbone.View.extend({

			// core vars

			$context: null,

			winWidth: null,
			modules: null,

			$accountSettingsFormMobile: null,



			// init

			initialize: function(options){

				var self = this;
				self.$context = $;
				self.modules = [];

				window.$vent.trigger('mobileInteractionsReady');
			},

			// start module --------------------------------------------------------------------  /

			start: function(){

				var self = this;
				window.$vent.on('domUpdate', $.proxy(self._onDomUpdate, self));
				$(window).on('resize', $.proxy(self._onWindowResize, self));

				$(function(){
					self.onDomReady();
				});
			},

			onDomReady: function(){

				var self = this;
				self._onWindowResize();
			},

			_onDomUpdate: function(){

				var self = this;

				// Validate account settings form on profile menu click (mobile)
				self.$accountProfileFormMobile = $('#account-profile-form-mobile:visible');
				self.$accountSettingsFormMobile = $('#account-settings-form-mobile:visible');
				self.$ownerAccountProfileFormMobile = $('#owner-account-profile-form-mobile:visible');
				self.$ownerAccountSettingsFormMobile = $('#owner-account-settings-form-mobile:visible');



				if (self.$accountProfileFormMobile.length) {
					self.$accountProfileFormMobile.focusin(function () {
						$("form #success-message").fadeOut();
						$("form #error-message").fadeOut();
					});
					if (jQuery().validate) {
						self.validateAccountProfileForm(self.$accountProfileFormMobile);
					}
				}
				if (self.$accountSettingsFormMobile.length) {
					self.$accountSettingsFormMobile.focusin(function () {
						$("form #success-message").fadeOut();
						$("form #error-message").fadeOut();
					});
					if (jQuery().validate) {
						self.validateAccountSettingsForm(self.$accountSettingsFormMobile);
					}
				}

				if (self.$ownerAccountProfileFormMobile.length) {
					self.$ownerAccountProfileFormMobile.focusin(function () {
						$("form #success-message").fadeOut();
						$("form #error-message").fadeOut();
					});
					if (jQuery().validate) {
						self.validateOwnerAccountProfileForm(self.$ownerAccountProfileFormMobile);
					}
				}
				if (self.$ownerAccountSettingsFormMobile.length) {
					self.$ownerAccountSettingsFormMobile.focusin(function () {
						$("form #success-message").fadeOut();
						$("form #error-message").fadeOut();
					});
					if (jQuery().validate) {
						self.validateOwnerAccountSettingsForm(self.$ownerAccountSettingsFormMobile);
					}
				}

				self.refreshModules();

			},

			validateAccountProfileForm: function(form){
				var self = this;

				var requiredMessage = $('form #validation-required').text();
				var lengthMessage = $('form #validation-min-length').text();
				var emailMessage = $('form #validation-email').text();
				var phoneMessage = $('form #validation-phone').text();
//        var successMessage = $('#validation-success-message').text();
				form.validate({
					ignore: "",
					rules: {
						first_name: {
							required: true,
							minlength: 3
						},
						last_name: {
							required: true,
							minlength: 3
						},
						doc_type: {
							required: true
						},
						number_id: {
							required: true,
							minlength: 6
						},
						email: {
							required: true,
							email: true
						},
						prefix: {
							required: true,
						},
						phone_number: {
							required: true,
							minlength: 6
						},
						address_street: {
							required: true,
							minlength: 6
						},
						address_number: {
							required: true
						},
						address_city: {
							required: true,
							minlength: 5
						},
						address_zip: {
							required: true,
							minlength: 5
						},
						select_country: {
							required: true
						}
					},
					messages: {
						first_name: {
							required: requiredMessage,
							minlength: lengthMessage
						},
						last_name: {
							required: requiredMessage,
							minlength: lengthMessage
						},
						doc_type: {
							required: requiredMessage,
							minlength: lengthMessage
						},
						number_id: {
							required: requiredMessage,
							minlength: lengthMessage
						},
						email: {
							required: requiredMessage,
							email: emailMessage
						},
						prefix: {
							required: requiredMessage,
						},
						phone_number: {
							required: requiredMessage,
							minlength: lengthMessage
						},
						address_street: {
							required: requiredMessage,
							minlength: lengthMessage
						},
						address_number: {
							required: requiredMessage
						},
						address_city: {
							required: requiredMessage,
							minlength: lengthMessage
						},
						address_zip: {
							required: requiredMessage,
							minlength: lengthMessage
						},
						select_country: {
							required: requiredMessage
						}
					},
					errorPlacement: function (error, element) {
						if (element.is("select")) {
							element.parent().append(error);
						} else
							error.insertAfter(element);
					},
					submitHandler: function (form) {
						self.submitAjaxProfile(form);
					}
				});

			},

			submitAjaxProfile: function(form){
				var form = $("#account-profile-form-mobile:visible");
				var data = {};
				var formData = form.serializeArray();

				$.each(formData, function () {
					if (formData[this.name]) {
						if (!data[this.name].push) {
							data[this.name] = [data[this.name]];
						}
						data[this.name].push(this.value || '');
					} else {
						data[this.name] = this.value || '';
					}
				});

				$.ajax({
					url: '/userArea/updateUserAjax',
					type: 'POST',
					data: JSON.stringify(data),
					async: false,
					contentType: "application/json; charset=utf-8",
					success: function () {
						var form = $("#account-profile-form-mobile:visible");
						console.log('Form processed!');
						//form[0].reset();
						//form.validate().resetForm();
						$(window).scrollTop(0);
						window.$vent.trigger('updateUserDone');
						$("#success-message-mobile").removeClass('hide').fadeIn(1000);
					},
					error: function () {
						console.log('Form error!');
						var form = $("#account-profile-form-mobile:visible");
						form.validate().resetForm();
						$("form #error-message").fadeIn(1000);
					}

				});
			},
			validateAccountSettingsForm:function(accountSettingsForm)  {
				var self = this;
				var requiredMessage = $('form #validation-required-mobile').text();
				var lengthMessage = $('form #validation-min-length-mobile').text();
				var emailMessage = $('form #validation-email-mobile').text();
				var phoneMessage = $('form #validation-phone-mobile').text();
				var equalToMessage = $('form #validation-equal-to-mobile').text();
	//        var successMessage = $('#validation-success-message').text();
				accountSettingsForm.validate({

				rules: {
					current_password: {
						required: true,
						minlength: 8
					},
					new_password: {
						required: true,
						minlength: 8
					},
					confirm_new_password: {
						required: true,
						minlength: 8,
						equalTo: ".new_password"
					}
				},
				messages: {
					current_password: {
						required: requiredMessage,
						minlength: lengthMessage
					},
					new_password: {
						required: requiredMessage,
						minlength: lengthMessage
					},
					confirm_new_password: {
						required: requiredMessage,
						minlength: lengthMessage,
						equalTo: equalToMessage
					}
				},
				submitHandler: function (accountSettingsForm) {
					self.submitSettingsAjax(accountSettingsForm);
				}
				});
			},

			submitSettingsAjax: function(form) {
				var form = $("#account-settings-form-mobile:visible");
				var data = {};
				var formData = form.serializeArray();

				$.each(formData, function () {
					if (formData[this.name]) {
						if (!data[this.name].push) {
							data[this.name] = [data[this.name]];
						}
						data[this.name].push(this.value || '');
					} else {
						data[this.name] = this.value || '';
					}
				});

				$.ajax({
					url: '/userArea/updatePasswordAjax',
					type: 'POST',
					contentType: "application/json",
					data: JSON.stringify(data),
					success: function () {
						var form = $("#account-settings-form-mobile:visible");
						console.log('form success!');
						//form[0].reset();
						//form.validate().resetForm();
						$(window).scrollTop(0);
						$("#success-message-mobile").removeClass('hide').fadeIn(1000);
					},
					error: function () {
						var form = $("#account-settings-form-mobile:visible");
						console.log('form error!');
						//form.validate().resetForm();
						$("form #error-message").fadeIn(1000);
					}

				});

			//return false;
			},


			validateOwnerAccountProfileForm: function(form){
				var self = this;

				var requiredMessage = $('form #validation-required').text();
				var lengthMessage = $('form #validation-min-length').text();
				var emailMessage = $('form #validation-email').text();
				var phoneMessage = $('form #validation-phone').text();
//        var successMessage = $('#validation-success-message').text();
				form.validate({
					ignore: "",
					rules: {
						first_name: {
							required: true,
							minlength: 3
						},
						last_name: {
							required: true,
							minlength: 3
						},
						doc_type: {
							required: true
						},
						number_id: {
							required: true,
							minlength: 6
						},
						email: {
							required: true,
							email: true
						},
						phone_number: {
							required: true,
							minlength: 6
						},
						address_street: {
							required: true,
							minlength: 6
						},
						address_number: {
							required: true
						},
						address_city: {
							required: true,
							minlength: 5
						},
						address_zip: {
							required: true,
							minlength: 5
						},
						select_country: {
							required: true
						}
					},
					messages: {
						first_name: {
							required: requiredMessage,
							minlength: lengthMessage
						},
						last_name: {
							required: requiredMessage,
							minlength: lengthMessage
						},
						doc_type: {
							required: requiredMessage,
							minlength: lengthMessage
						},
						number_id: {
							required: requiredMessage,
							minlength: lengthMessage
						},
						email: {
							required: requiredMessage,
							email: emailMessage
						},
						phone_number: {
							required: requiredMessage,
							minlength: lengthMessage
						},
						address_street: {
							required: requiredMessage,
							minlength: lengthMessage
						},
						address_number: {
							required: requiredMessage
						},
						address_city: {
							required: requiredMessage,
							minlength: lengthMessage
						},
						address_zip: {
							required: requiredMessage,
							minlength: lengthMessage
						},
						select_country: {
							required: requiredMessage
						}
					},
					errorPlacement: function (error, element) {
						if (element.is("select")) {
							element.parent().append(error);
						} else
							error.insertAfter(element);
					},
					submitHandler: function (form) {
						self.submitOwnerAjaxProfile(form);
					}
				});

			},

			submitOwnerAjaxProfile: function(form){
				var form = $("#owner-account-profile-form-mobile:visible");
				var data = {};
				var formData = form.serializeArray();

				$.each(formData, function () {
					if (formData[this.name]) {
						if (!data[this.name].push) {
							data[this.name] = [data[this.name]];
						}
						data[this.name].push(this.value || '');
					} else {
						data[this.name] = this.value || '';
					}
				});

				$.ajax({
					url: '/ownerArea/updateUserAjax',
					type: 'POST',
					data: JSON.stringify(data),
					async: false,
					contentType: "application/json; charset=utf-8",
					success: function () {
						var form = $("#owner-account-profile-form-mobile:visible");
						console.log('Form processed!');
						//form[0].reset();
						//form.validate().resetForm();
						$(window).scrollTop(0);
						window.$vent.trigger('updateUserDone');
						$("#success-message-mobile").removeClass('hide').fadeIn(1000);
					},
					error: function () {
						console.log('Form error!');
						var form = $("#owner-account-profile-form-mobile:visible");
						form.validate().resetForm();
						$("form #error-message").fadeIn(1000);
					}

				});
			},
			validateOwnerAccountSettingsForm:function(accountSettingsForm)  {
				var self = this;
				var requiredMessage = $('form #validation-required-mobile').text();
				var lengthMessage = $('form #validation-min-length-mobile').text();
				var emailMessage = $('form #validation-email-mobile').text();
				var phoneMessage = $('form #validation-phone-mobile').text();
				var equalToMessage = $('form #validation-equal-to-mobile').text();
				//        var successMessage = $('#validation-success-message').text();
				accountSettingsForm.validate({

					rules: {
						current_password: {
							required: true,
							minlength: 8
						},
						new_password: {
							required: true,
							minlength: 8
						},
						confirm_new_password: {
							required: true,
							minlength: 8,
							equalTo: ".new_password"
						}
					},
					messages: {
						current_password: {
							required: requiredMessage,
							minlength: lengthMessage
						},
						new_password: {
							required: requiredMessage,
							minlength: lengthMessage
						},
						confirm_new_password: {
							required: requiredMessage,
							minlength: lengthMessage,
							equalTo: equalToMessage
						}
					},
					submitHandler: function (accountSettingsForm) {
						self.submitOwnerSettingsAjax(accountSettingsForm);
					}
				});
			},

			submitOwnerSettingsAjax: function(form) {
				var form = $("#owner-account-settings-form-mobile:visible");
				var data = {};
				var formData = form.serializeArray();

				$.each(formData, function () {
					if (formData[this.name]) {
						if (!data[this.name].push) {
							data[this.name] = [data[this.name]];
						}
						data[this.name].push(this.value || '');
					} else {
						data[this.name] = this.value || '';
					}
				});

				$.ajax({
					url: '/ownerArea/updatePasswordAjax',
					type: 'POST',
					contentType: "application/json",
					data: JSON.stringify(data),
					success: function () {
						var form = $("#account-settings-form-mobile:visible");
						console.log('form success!');
						//form[0].reset();
						//form.validate().resetForm();
						$(window).scrollTop(0);
						$("#success-message-mobile").removeClass('hide').fadeIn(1000);
					},
					error: function () {
						var form = $("#account-settings-form-mobile:visible");
						console.log('form error!');
						//form.validate().resetForm();
						$("form #error-message").fadeIn(1000);
					}

				});

				//return false;
			},

			_onWindowResize: function(){

				var self = this;
				self.winWidth = window.innerWidth;
				self.refreshModules();
			},

			refreshModules: function(){

				var self = this;

				if(self.winWidth <= 992){

					// carousel

					_.each(self.$el.find('.mobile-gi-carousel'), function(module, i){
						
						if(!self.checkExisting(module, 'Carousel')){
							
							var $module = window.$rootContext(module);
							
							var $module = window.$rootContext(module);
							var $moduleParent = $(module).parent();

							var options = { preventDefault: true };

							$moduleParent.hammer(options).on('swiperight', function(){
								$module.carousel('prev');
							});

							$moduleParent.hammer(options).on('swipeleft', function(){
								$module.carousel('next');
							});
							
							self.modules.push({
								type: 'Carousel',
								el: $module
							});

							$module.carousel();

							if($module.find('.carousel-counter').length){

								var totalItems = $module.find('.item').length;
								$module.find('.count').html('1/'+totalItems+'');
								$module.$module = $module;

								$module.find('.carousel-btn.left').on('click', function(){
									$module.carousel('prev');
								});

								$module.find('.carousel-btn.right').on('click', function(){
									$module.carousel('next');
								});

								$module.on('slid.bs.carousel', function(){
								   var currentIndex = $module.find('.active').index() + 1;
								   $module.find('.count').html(''+currentIndex+'/'+totalItems+'');
								});
							}
						}
					});
				}

				if(self.winWidth <= 768){

					// ACTIONS OCCURRING WHEN WINDOW IS MOBILE SIZE

					// guide selector

					_.each(self.$el.find('#mobile-guide-selector'), function(module, i){
						
						if(!self.checkExisting(module, 'MobileGuideSelectorView')){
							
							var $module = window.$rootContext(module);

							self.modules.push({
								module: new MobileGuideSelectorView({$menu:$module}),
								type: 'MobileGuideSelectorView',
								el: $module
							});
						}
					});
				}
				else {

					// BACKUP ACTIONS WHEN WINDOW IS LARGER THAN MOBILE

					_.each(self.$el.find('#mobile-guide-selector'), function(module, i){
						
						var $guideSelector = $(module);
						var defaultId = $guideSelector.data('default');

						if(defaultId){
							
							var $defaultContainer = $('#' + defaultId);
							var defaultTemplate = _.template($('#' + defaultId + '-template').html());

							if(!$defaultContainer.html()){

								$defaultContainer.html(defaultTemplate());

								TweenMax.set($defaultContainer, {display:'block'});

								window.$vent.trigger('domUpdate');
							}
						}
					});
				}
			},

			// check for existing modules ------------------------------------------------------  /

			checkExisting: function(el, type){

				var self = this;
				var exists = false;

				if(typeof(el.context) !== 'undefined'){ el = el[0]; }

				_.each(self.modules, function(moduleObj, i){

					var moduleEl = moduleObj.el;

					if(typeof(moduleEl.context) !== 'undefined'){ moduleEl = moduleEl[0]; }

					if(moduleEl === el && type === moduleObj.type){
						exists = true;
					}
				});

				return exists;
			},

			// ---------------------------------------------------------------------------------  /

		});

		return AppView;
	}
);
