define(
    'modules/global-modals/app',[
        'backbone',
        'modules/global-modals/views/GenericModalView',
        'modules/global-modals/views/CreateABlockModalView',
        'modules/global-modals/views/AirportTransferModalView',
        'selectboxit'
    ],
    function (Backbone, GenericModalView, CreateABlockModalView, AirportTransferModalView) {

        var AppView = Backbone.View.extend({

            // core vars

            $modalContainer: null,
            $modalVeil: null,

            currentModal: null,
            currentModalId: null,

            isModalLoading: false,

            // init

            initialize: function (options) {

                var self = this;
                window.$vent.trigger('globalModalsReady');

            },

            // start module --------------------------------------------------------------------  /

            start: function () {

                var self = this;

                $(function () {
                    self.onDomReady();
                });
            },

            onDomReady: function () {

                var self = this;

                self.$modalContainer = $('#modal-content-wrapper');

                window.$vent.on('openStaticModal', $.proxy(self._onOpenStaticModal, self));
                window.$vent.on('openModal', $.proxy(self._onOpenModal, self));
                window.$vent.on('closeModal', $.proxy(self._onCloseModal, self));
                window.$vent.on('switchModal', $.proxy(self._onSwitchModal, self));

                $(window).on('resize', $.proxy(self.onWindowResize, self));
            },

            // modal event listeners
            _onOpenStaticModal: function(e, params) {
              var self = this;

              if (_.isString(params)) {
                  modalId = params;
                  params = {};
              } else {
                  modalId = params.modalId;
              }

              self.isModalLoading = false;
              var content = $(modalId).html();

              self.createModalView('', {}, content);
            },

            _onOpenModal: function (e, params) {
                var self = this;
                var modalId = null;

                console.log('_onOpenModal', e, params);


                if (_.isString(params)) {
                    modalId = params;
                    params = {};
                }
                else {
                    modalId = params.modalId;
                }

                if (modalId && modalId != self.currentModalId) {
                    self.currentModalId = modalId;
                    self.loadModalTemplate(modalId, params);
                }
            },

            _onCloseModal: function (e) {

                var self = this;
                self.removeModal();
            },

            _onSwitchModal: function (e, params) {

                var self = this;
                var modalId = null;

                if (_.isString(params)) {
                    modalId = params;
                    params = {};
                }
                else {
                    modalId = params.modalId;
                }

                if (modalId && modalId != self.currentModalId) {

                    var d = 0;

                    if (self.currentModalId) {
                        d = 0.3;
                        self.removeModal(true);
                    }

                    self.currentModalId = modalId;

                    TweenMax.delayedCall(d, function () {
                        self.loadModalTemplate(modalId, params);
                    });
                }
            },

            // load modal template

            loadModalTemplate: function (modalId, params) {
                var self = this;
                var suffix = (!_.isUndefined(params) && _.isString(params.contentId)) ? '?v=' + params.contentId : '';
                var modalURL;

                if (modalId.indexOf('/') >= 0) {
                    modalURL = modalId;
                }
                if (modalId.indexOf('.') >= 0) {
                    modalURL = modalURL + suffix;
                } // allow custom extension
                else {
                    modalURL = modalURL + suffix;
                }

                console.log('loadModalTemplate', modalURL, "PARAMS", params);

                if (!self.isModalLoading) {

                    self.isModalLoading = true;

                    if (self.currentModal) {
                        self.currentModal.exit();
                    }

                    $.ajax({

                        type: 'GET',
                        url: modalURL,
                        context: document.body,
                        async: true,

                        success: function (data) {
                            self.isModalLoading = false;
                            self.createModalView(modalId, params, data);
                        },

                        error: function (e) {

                            console.log('Template Load Error');
                            console.log(e.message);
                            self.isModalLoading = false;
                        }
                    });
                }

            },

            // create modal object

            createModalView: function (modalId, params, template) {

                var self = this;
                var split = modalId.split('/');
                modalId = split[split.length - 1];

                console.log("createModalView", modalId, params);

                switch (modalId) {

                    case 'CreateABlock':
                        self.currentModal = new CreateABlockModalView({
                            $container: self.$modalContainer,
                            template: template,
                            model: params || {},
                            autoShow: false,

                        });
                        break;

                    case 'AirportTransfer':
                        self.currentModal = new AirportTransferModalView({
                            $container: self.$modalContainer,
                            template: template,
                            model: params || {},
                            autoShow: false,
                        });
                        break;

                    default:
                        self.currentModal = new GenericModalView({
                            $container: self.$modalContainer,
                            template: template,
                            model: params || {},
                            autoShow: false,
                        });
                }

                self.currentModal.on('closeClick', self._onModalCloseClick, self);
                self.showModal();
            },

            _onModalCloseClick: function () {

                var self = this;
                self.removeModal();
            },

            // show / hide

            showModal: function () {
                var self = this;
                self.currentModal.show();

                /*if (!self.$modalVeil) {

                    self.$modalVeil = $('<div class="modal-veil veil"></div>').prependTo(self.$el.find('.modal'));
                    self.$modalVeil.on('click', $.proxy(self._onModalCloseClick, self));

                    if (self.currentModal.model.whiteVeil) {
                        self.$modalVeil.addClass('white');
                    }
                }*/

                self.$modalVeil = $('<div class="modal-veil veil"></div>').prependTo(self.$el.find('.modal'));
                self.$modalVeil.on('click', $.proxy(self._onModalCloseClick, self));

                /* unable scroll */
                $('body').addClass('no-scroll');

                TweenMax.killTweensOf(self.$modalVeil);
                TweenMax.to(self.$modalVeil, 0.8, {opacity: 1, ease: Cubic.easeOut});

                var tweenObj = {scrollTop: $(window).scrollTop()};
                TweenMax.to(tweenObj, 0.8, {
                    scrollTop: 0, ease: Cubic.easeOut, onUpdate: function () {
                        //$(window).scrollTop(tweenObj.scrollTop);
                    }
                });

                $(window).resize();
                /* hide any spinner */
                window.$vent.trigger('hideSpinner');
                window.$vent.trigger('domUpdate');

                TweenMax.delayedCall(1, function () {
                    $(window).resize();
                });
            },

            hideModal: function () {

                var self = this;
                self.currentModal.hide();

                TweenMax.killTweensOf(self.$modalVeil);
                TweenMax.to(self.$modalVeil, 0.8, {opacity: 0, ease: Cubic.easeOut});
            },

            // remove

            removeModal: function (switching) {

                var self = this;
                $('body').removeClass('no-scroll');

                if (self.currentModal) {
                    self.currentModal.off('closeClick', self._onModalCloseClick, self);
                    self.currentModal.exit();
                    self.currentModal = null;
                    self.currentModalId = null;
                }

                if (switching !== true) {

                    self.$modalVeil.off('click', $.proxy(self._onModalCloseClick, self));

                    TweenMax.killTweensOf(self.$modalVeil);
                    TweenMax.to(self.$modalVeil, 0.8, {
                        opacity: 0, ease: Cubic.easeOut, onComplete: function () {
                            self.$modalVeil.remove();
                            self.$modalVeil = null;
                            window.$vent.trigger('recalculateWidth');
                        }
                    });
                }
            },

            // window resize

            onWindowResize: function () {

                var self = this;
                var windowHeight = window.innerHeight;

                if (self.currentModal) {

                    var modalHeight = self.currentModal.$el.find('.inner-container').outerHeight();

                    if (modalHeight >= windowHeight) {
                        if ($(window).scrollTop() < 150) {
                            TweenMax.set(self.$modalContainer, {top: '75px'});
                        }
                        //TweenMax.set(self.$modalContainer, {position:'absolute'});
                    }
                    else {
                        //TweenMax.set(self.$modalContainer, {position:'fixed'});
                    }
                }
            },

            // ---------------------------------------------------------------------------------  /

        });

        return AppView;
    }
);

