
define(
	'modules/sticky-nav/views/TrackingStickyNavView',[
		'backbone',
		'utils/AbstractView',
	], 
	function(Backbone, AbstractView){

		var TrackingStickyNavView = Backbone.View.extend({

			// core vars

			$el: null,
			$container: null,
			options: null,
			abstractView: null,

			// vars

			$hero: null,
			$scrollSections: null,
			$navItems: null,

			scrollSectionIndex: -1,
			scrollSectionPositions: null,
			winHeight: null,
			heroBottom: null,

			isTicked: false,
			isShowing: false,

			// init

			initialize: function(options){

				var self = this;

				// options vars

				self.options = options;
				self.$container = self.options.$container;
				self.$hero = self.options.$hero;

				//console.log(self.$hero);
				self.$scrollSections = self.options.$scrollSections;

				// template config

				//self.template = _.template(Template);
				self.model = $.extend({}, self.model);

				// initialize AbstractView

				self.abstractView = new AbstractView({
					view: self,
					autoShow: true,
				});
			},

			// dom ready

			onDomReady: function(){

				var self = this;
				self.$navItems = self.$el.find('li');
				self.stickyNavHeight = self.$el.outerHeight();

				TweenMax.set(self.$el, {y:-self.stickyNavHeight});
				TweenMax.set(self.$navItems, {y:self.stickyNavHeight});
			},

			// add listeners

			onAddListeners: function(){

				var self = this;
				
				$(window).on('scroll', $.proxy(self._onScroll, self));
				$(window).mousewheel($.proxy(self._onMouseWheel, self));
				$(window).on('resize', $.proxy(self._onWindowResize, self));

				window.$vent.on('domUpdate', $.proxy(self.updateScrollPositions, self));

				self._onWindowResize();
			},

			_onMouseWheel: function(e){

				var self = this;
				self.scrollTick();
			},

			_onScroll: function(){

				var self = this;
				self.scrollTick();
			},

			scrollTick: function(){

				var self = this;

				if(!self.isTicked){
					self.isTicked = true;
					window.requestAnimationFrame(self.scrollNow.bind(self));
				}
			},

			// scroll listener

			scrollNow: function(){

				var self = this;
				var scrollTop = $(window).scrollTop();

				if(scrollTop >= self.heroBottom - self.stickyNavHeight){

					//console.log('branch a');

					if(!self.isShowing){
						
						TweenMax.to(self.$el, 0.6, {y:0, ease:Quint.easeOut});
						
						_.each(self.$navItems, function(li, i){
							TweenMax.to($(li), 0.5, {y:0, delay:0.1 + 0.02 * i, ease:Quint.easeOut});
						});

						self.$el.removeClass('hidden');
						
						self.isShowing = true;
					}

					var triggerPoint = Math.round(self.winHeight * 0.67) + scrollTop;
					var nextSectionIndex = 0;
					var isFound = false;

					for(var i=1; i<self.scrollSectionPositions.length; i++){
						var curSection = self.scrollSectionPositions[i].pos;
						if(curSection.top > triggerPoint){
							nextSectionIndex = i;
							isFound = true;
							break;
						}
					}

					if(!isFound){ nextSectionIndex = self.scrollSectionPositions.length; }

					var curSectionIndex = Math.max(nextSectionIndex - 1, 0);

					
					
					if(curSectionIndex != self.scrollSectionIndex && self.scrollSectionPositions.length>0){
						
						if(self.scrollSectionPositions[curSectionIndex].enabled){
						
							self.scrollSectionIndex = curSectionIndex;

							self.$el.find('.active').removeClass('active');
							$(self.$navItems[curSectionIndex]).addClass('active');
						}
					}
				}
				else {
					//console.log('branch b');
					if(self.isShowing){
						
						TweenMax.to(self.$el, 0.5, {y:-self.stickyNavHeight, ease:Cubic.easeInOut, onComplete:function(){
							TweenMax.set(self.$navItems, {y:self.stickyNavHeight});
							self.$el.removeClass('hidden').addClass('hidden');
						}});

						self.isShowing = false;

					}
				}

				self.isTicked = false;
			},

			// 

			updateScrollPositions: function(){

				var self = this;

				// position of each block to track

				self.scrollSectionPositions = [];

				_.each(self.$scrollSections, function(scrollSection, i){
					
					var $scrollSection = $(scrollSection);
					var sectionOffset = $scrollSection.offset();
					var scrollPosition = {
						top: sectionOffset.top,
						right: sectionOffset.left + $scrollSection.outerWidth(),
						bottom: sectionOffset.top + $scrollSection.outerHeight(),
						left: sectionOffset.left
					};

					self.scrollSectionPositions.push({
						pos: scrollPosition,
						enabled: !$scrollSection.hasClass('disabled')
					});
				});

				self.scrollTick();
			},

			// window resize

			_onWindowResize: function(){

				var self = this;

				self.winHeight = $(window).innerHeight();
				
				if(self.$hero.length>0)
					self.heroBottom = self.$hero.offset().top + self.$hero.innerHeight();

				self.updateScrollPositions();
			},

		});

	return TrackingStickyNavView;
});
