
define(
	'pagecontrollers/area-map/views/MapMenuView',[
		'backbone',
		'utils/AbstractView',

		'pagecontrollers/area-map/views/MapMenuExpandedView',
	], 
	function(Backbone, AbstractView, MapMenuExpandedView){

		var BaseViewTemplate = Backbone.View.extend({

			// core vars

			$el: null,
			$container: null,
			options: null,
			abstractView: null,

			// vars

			$locationButton: null,
			$recommendationButton: null,

			$expandedContent: null,
			$expandedTabPanes: null,

			expandedView: null,

			isExpanded: false,

			// init

			initialize: function(options){

				var self = this;

				self.options = options;
				self.$container = self.options.$container;
				self.el = self.options.el;

				//self.template = _.template(Template);

				self.model = $.extend({

				}, self.model);

				//

				self.$expandedContent = self.options.$expandedContent;

				// 

				self.abstractView = new AbstractView({
					view: self,
					autoShow: self.options.autoShow === true,
				});
			},

			// on render

			onDomReady: function(){

				var self = this;

				self.$locationButton = self.$el.find('.tm-location');
				self.$recommendationButton = self.$el.find('.tm-recommendation');

				self.expandedView = new MapMenuExpandedView({
					el: self.$expandedContent,
					hideClass: 'display-none',
					autoShow: false,
				});

				window.$vent.on('citySelected', $.proxy(self._onCitySelected, self));
				window.$vent.on('neighborhoodSelected', $.proxy(self._onNeighborhoodSelected, self));
				window.$vent.on('typeFilterQuery', $.proxy(self._onTypeFilterQuery, self));
				window.$vent.on('closeMenu', $.proxy(self._onCloseMenu, self));
			},

			// listeners

			onAddListeners: function(){

				var self = this;

				self.$locationButton.on('click', $.proxy(self._onLocationClick, self));
				self.$recommendationButton.on('click', $.proxy(self._onRecommendationClick, self));

				window.requestAnimationFrame(function(){
					$(document).on('click', $.proxy(self._onOutClick, self));
				});
			},

			//

			_onCitySelected: function(e, obj){

				var self = this;
				console.log('_onCitySelected', obj);

				self.$locationButton.find('span').html(obj.cityTitle);

				window.$vent.trigger('newListingsQuery', {queryURL:obj.queryURL});

				TweenMax.delayedCall(0.2, function(){
					self.closeMenu();			
				});
			},

			_onNeighborhoodSelected: function(e, obj){

				var self = this;
				console.log('_onNeighborhoodSelected', obj);

				self.$locationButton.find('span').html(obj.title + '<font class="hidden-xs hidden-sm">, ' + obj.cityTitle + '</font>');

				window.$vent.trigger('newListingsQuery', {queryURL:obj.queryURL});

				TweenMax.delayedCall(0.2, function(){
					self.closeMenu();			
				});
			},

			_onTypeFilterQuery: function(e, obj){

				var self = this;
				console.log('_onTypeFilterQuery', obj);

				window.$vent.trigger('newListingsQuery', {queryURL:obj.queryURL});	

				TweenMax.delayedCall(0.2, function(){
					self.closeMenu();			
				});
			},

			_onCloseMenu: function(){
				var self = this;
				self.closeMenu();
			},

			// listener methods

			_onLocationClick: function(e){

				var self = this;

				if(self.expandedView.shouldClose(0)){
					self.closeMenu();
				}
				else {
					self.expandMenu(0);
					e.preventDefault();
				}
			},

			_onRecommendationClick: function(e){

				var self = this;

				if(self.expandedView.shouldClose(1)){
					self.closeMenu();
				}
				else {
					self.expandMenu(1);
					e.preventDefault();
				}
			},

			_onOutClick: function(e){

				var self = this;

				if(self.isExpanded && !self.$el[0].contains(e.target) && !self.$expandedContent[0].contains(e.target)){
					self.closeMenu();
					e.preventDefault();
				}
			},

			//

			expandMenu: function(tabIndex){

				var self = this;
				self.expandedView.changeTab(tabIndex);
				self.isExpanded = true;
			},

			closeMenu: function(){

				var self = this;
				self.expandedView.hide();
				self.isExpanded = false;
			}

			// ---------------------------------------------------------------------------------  /

		});

	return BaseViewTemplate;
});
