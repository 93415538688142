define(
    'modules/home/app',[
        'backbone',
        'modules/cookies/app',
        'modules/home/views/ChangeContentView',
        'modules/home-filters/views/SearchView',
        'morphext',
        'modules/home/views/ContentTabs',
        'modules/home/views/DistrictPopup',
        'modules/home/views/PropertiesSliderOfficesView',
        'modules/home/views/SearcherHomeView',
        '../calendars/views/CalendarMenuView',
        'clndr',
    ],
    function (Backbone, Cookies, ChangeContent, SearchView, morphext, ContentTabs, DistrictPopup, PropertiesSliderOfficesView, SearcherHomeView, CalendarMenuView) {

        var AppView = Backbone.View.extend({
            // vars

            $localStorage: null,
            localStorageKey: 'district',
            $el: null,
            $searchOption: null,
            lang: null,

            $photosToChange: null,

            $cookies: null,
            $changeContent: null,
            $districtPopup: null,
            $contentTabs: null,
            $searcherHomeView: null,
            selectLanguageOptions: null,

            // init

            initialize: function (options) {

                var self = this;

                self.$cookies = new Cookies();

                $("select").selectBoxIt({
                    isMobile: function () {
                        return false;
                    },
                    autoWidth: false
                });

            },
            // start module --------------------------------------------------------------------  /

            start: function () {

                var self = this;

                self.lang = $('#lang').text();
                /* Get the district for the first load of home page */
                self.$localStorage = self.$cookies.getCookie(self.localStorageKey);
                self.selectLanguageOptions = $('[id=ch_language]');

                self.$changeContent = new ChangeContent({
                    $localStorage: self.$localStorage
                });

                self.$districtPopup = new DistrictPopup({
                    $localStorage: self.$localStorage
                });

                self.$contentTabs = new ContentTabs({
                    $localStorage: self.$localStorage
                });

                self.$searcherHomeView = new SearcherHomeView({
                    $localStorage: self.$localStorage
                });

                //load the slider for the offices
                self.$el = new PropertiesSliderOfficesView();


                window.$vent.trigger('homeReady');

                self.onDomReady();
                self.onRemoveListeners();
                self.onAddListeners();
            },
            onDomReady: function () {

                var self = this;

                /* title effect */
                $("#js-rotating").Morphext({
                    // The [in] animation type. Refer to Animate.css for a list of available animations.
                    animation: "zoomIn",
                    // An array of phrases to rotate are created based on this separator. Change it if you wish to separate the phrases differently (e.g. So Simple | Very Doge | Much Wow | Such Cool).
                    separator: ",",
                    // The delay between the changing of each phrase in milliseconds.
                    speed: 1500,
                    complete: function () {
                        // Called after the entrance animation is executed.
                    }
                });

                /* Show message cookie */
                self.showCookiesMessage();

                /* Add the district into the url */
                /*self.changeUrlDistrict();
                self.changeLanguageOptionFooter();*/


            },
            onAddListeners: function () {

                var self = this;

                window.$vent.on('openCalendar', $.proxy(self._onOpenCalendar, self));

                $(window).on('changeHomeContent', $.proxy(self._onChangeHomeContent, self));

            },
            onRemoveListeners: function () {

                var self = this;

                window.$vent.off('openCalendar', $.proxy(self._onOpenCalendar, self));
                $(window).off('changeHomeContent', $.proxy(self._onChangeHomeContent, self));

            },
            showCookiesMessage: function () {
                var self = this;

                var cookieHomeSelect = false;
                cookieHomeSelect = self.$cookies.getCookie('cookieHomeSelect');

                if (!cookieHomeSelect) {
                    $('#cookies-message').slideDown(200);
                    $("[href]").click(function () {
                        $('#cookies-message').fadeOut(300);
                        self.$cookies.setCookie('cookieHomeSelect', true, 1000);
                    });
                    $('#acept-cookies').click(function () {
                        $('#cookies-message').fadeOut(300);
                        self.$cookies.setCookie('cookieHomeSelect', true, 1000);
                    });
                }
            },
            changeUrlDistrict: function () {
                var self = this;

                var host = window.location.origin;
                var currentUrl = window.location.pathname;
                var newUrl = host + '/' + self.lang + '/' + (self.$localStorage ? self.$localStorage : 'madrid');
                window.history.replaceState({}, '', newUrl);
            },
            changeLanguageOptionFooter: function () {
                var self = this;

                self.selectLanguageOptions.each(function(i,select){
                    var options = $(select).find('option');
                    $(options[0]).val('/en/' + (self.$localStorage?self.$localStorage:'madrid'));
                    $(options[1]).val('/es/' + (self.$localStorage?self.$localStorage:'madrid'));
                    $(select).data("selectBox-selectBoxIt").refresh();
                });
            },

            // listener methods
            _onOpenCalendar: function () {

                var self = this;
                self.openMenu(self.calendarMenu);

                window.$vent.trigger('seekToTop');
            },

            _onChangeHomeContent: function (e, data) {
                var self = this;
                self.$localStorage = data.$localStorage;
                console.log("_onChangeHomeContent");

                /* change district in url */
                self.changeUrlDistrict();

                self.changeLanguageOptionFooter();

                window.$vent.trigger('changeContent', [{localStorage: self.$localStorage}, self]);

                /* change tab office selected */
                window.$vent.trigger('changeOfficeTabs', [{localStorage: self.$localStorage}, self]);

                /* change select searcher selected */
                window.$vent.trigger('changeDistrictSearcher', [{localStorage: self.$localStorage}, self]);

                /* we call carousel plugin just in case the tab contains a carousel */
                window.$vent.trigger('reinitCarousel');

                /* change language links in footer */
            },

            // ---------------------------------------------------------------------------------  /

        });

        return AppView;
    }
);
