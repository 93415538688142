define(
    'modules/property-filters/app',[
        'backbone',
        'modules/property-filters/views/FiltersMenuView',
        'modules/property-filters/views/TagItemView',
        'modules/calendars/views/CalendarMenuView',
        'modules/property-filters/views/GuestsMenuView',
        'text!../../config/hero-filters.json',
    ],
    function (Backbone, FiltersMenuView, TagItemView, CalendarMenuView,
              GuestsMenuView, HeroFiltersConfig) {

        var AppView = Backbone.View.extend({
            // vars

            $filterMenuContainer: null,
            $moreFiltersMenu: null,
            $tagsContainer: null,
            $showFiltersButton: null,
            locationsMenu: null,
            calendarMenu: null,
            guestsMenu: null,
            dateCheckInQuery: null,
            dateCheckOutQuery: null,
            guestsQuery: null,
            locationQuery: null,
            $heroContainer: null,
            $filtersContainer: null,
            $overlayContainer: null,
            $filterOptions: null,
            $activeFilterButton: null,
            $mobileOverlaysContainer: null,
            $sortDropdown: null,
            currentMenu: null,
            isModalOpen: false,
            isMobile: false,
            queryResult: null,
            lastQueryString: null,
            filtersConfig: null,
            filtersMenu: null,
            tags: null,
            winWidth: 0,
            winHeight: 0,
            lang: null,
            stringOfTags: null,
            // init

            initialize: function () {

                var self = this;
                self.options = self.options || {};
                self.filtersConfig = JSON.parse(HeroFiltersConfig);

                self.$heroContainer = $('#property-hero-filters-wrapper');
                self.$filtersContainer = $('#property-hero-filters');
                self.$overlayContainer = $('#property-hero-filters .property-hero-filters-overlays');
                self.$filterOptions = self.$filtersContainer.find('ul.filter-options');

                self.$filterMenuContainer = $('#all-filters-wrapper');
                self.$moreFiltersMenu = self.$filterMenuContainer.find('#filters-menu');

                self.$tagsContainer = self.$heroContainer.find('#tags-container');
                self.$showFiltersButton = self.$heroContainer.find('.show-filters');

                self.lang = $('#lang').html();
                self.stringOfTags = self.$heroContainer.find('#tags-to-load').html();

                self.searchListingProperties = $('#searchListingProperties');

                // init vars

                self.tags = [];
                if (self.urlParam('tags')) {
                    self.stringOfTags = self.urlParam('tags');
                }
                //self.initTags();

                // fire event to page controller

                window.$vent.trigger('propertyFiltersReady');
            },
            // start

            start: function () {

                var self = this;


                $(function () {

                    console.log('property-filters start self.stringOfTags',self.stringOfTags );

                    self.initFiltersMenu();

                    // locations

                    /*
                     self.locationsMenu = new LocationsMenuView({
                     $container: self.$overlayContainer,
                     $mobileContainer: self.$mobileOverlaysContainer,
                     model: self.filtersConfig,
                     });


                    // calendars
                     if (window.innerWidth >= window.MOBILE_WIDTH) {
                     self.$overlayContainer = $('.property-hero-filters-overlays');
                     } else {
                     self.$overlayContainer = $('#modal-content-wrapper');
                     }*/

                    /*self.calendarMenu = new CalendarMenuView({
                     $container: self.$overlayContainer,
                     $mobileWrap: $('#modal-content-wrapper'),
                     $desktopWrap: $('.property-hero-filters-overlays'),
                     hideClass: 'display-none',
                     model: self.filtersConfig,
                     hasOverlay: true,
                     });*/

                    // guests
                    // self.guestsMenu = new GuestsMenuView({
                    // $container: self.$overlayContainer,
                    // $mobileContainer: self.$mobileOverlaysContainer,
                    // model: self.filtersConfig,
                    // });
                    self.addListeners();
                    //self.updateQuery();
                    //self.loadFirstQuery();

                    // start

                    window.requestAnimationFrame(function () {

                        // this is only if there is no map

                        window.$vent.trigger('requestListings');
                        window.$vent.trigger('requestLocation');
                    });
                });
            },
            // listeners -----------------------------------------------------------------------  /

            addListeners: function () {

                var self = this;

                window.$vent.on('requestLocation', $.proxy(self._onRequestLocation, self));
                window.$vent.on('updatetLocation', $.proxy(self._onUpdateLocation, self));

                 //self.locationsMenu.on('optionSelected', self._onLocationSelected, self);
                 //self.locationsMenu.on('closeMenu', self._onCloseMenu, self);
                 //self.locationsMenu.on('hide', self._onMenuClosed, self);
                 //
                 //self.calendarMenu.on('optionSelected', self._onDateSelected, self);
                 //self.calendarMenu.on('closeMenu', self._onCloseMenu, self);
                 //self.calendarMenu.on('hide', self._onMenuClosed, self);

                 //self.guestsMenu.on('optionSelected', self._onGuestsSelected, self);
                 //self.guestsMenu.on('closeMenu', self._onCloseMenu, self);
                 //self.guestsMenu.on('hide', self._onMenuClosed, self);

                 self.$showFiltersButton.on('click', $.proxy(self._onFilterButtonClick, self));


                  _.each(self.$filterOptions.find('li'), function (filterButton, i) {

                 var $filterButton = $(filterButton);
                 var defaultText = $filterButton.find('figcaption').html();
                 $filterButton.attr('data-default-text', defaultText);
                 $filterButton.on('click', $.proxy(self._onFilterOptionClick, self));
                 });

                // select default option for location

                //self.applyParams();
                //window.$vent.trigger('applyParams');

                //

                $(window).on('resize', $.proxy(self.onWindowResize, self));
                self.onWindowResize();
            },
            //

            _onRequestLocation: function () {

                var self = this;
                var locationParam = Utils.DOMUtils.getHashParameter('location');
                /*self.locationsMenu.triggerOption(locationParam);*/
            },
            _onUpdateLocation: function (e, param) {
                console.log('_onUpdateLocation in filters', param);

                var self = this;
                self.isLocationSelected = true;
                self.locationQuery = param;

                //self.updateQuery();
                window.$vent.trigger('updateQuery');

            },
            // listener methods

            _onFilterOptionClick: function (e) {

                var self = this;
                var $target = $(e.currentTarget);
                var $nextFilterButton = $target;
                var nextMenu;

                self.isModalOpen = false;

                if ($target.hasClass('location')) {

                    /*nextMenu = self.locationsMenu;*/

                } else if ($target.hasClass('check-in')) {

                    $nextFilterButton = self.$filterOptions.find('.date-option');
                    nextMenu = self.calendarMenu;

                } else if ($target.hasClass('check-out')) {

                    $nextFilterButton = self.$filterOptions.find('.date-option');
                    nextMenu = self.calendarMenu;

                } else if ($target.hasClass('guests')) {

                    nextMenu = self.guestsMenu;
                }

                if (nextMenu && nextMenu !== self.currentMenu) {

                    self.isModalOpen = true;

                    if (e) {
                        e.stopPropagation();
                    }
                    if (self.currentMenu) {
                        self.closeCurrentMenu(true);
                    }

                    self.currentMenu = nextMenu;
                    self.$activeFilterButton = $nextFilterButton;

                    self.currentMenu.show();
                    //self.hideCurrentFilterButton();

                    self.$el.off('click', $.proxy(self._onOutClick, self));
                    self.$el.on('click', $.proxy(self._onOutClick, self));

                    if (self.winWidth < 768) {
                        self.$mobileOverlaysContainer.removeClass('display-none');
                    }


                    window.$vent.trigger('openFiltersMenu');
                }
            },
            //_onLocationSelected: function (e) {
            //
            //    var self = this;
            //
            //    self.isLocationSelected = true;
            //    self.locationQuery = e.query;
            //
            //    self.updateFilterButtonText('location', e.results);
            //
            //    //console.log('_onLocationSelected', e.model);
            //
            //    //window.$vent.trigger('changeMapLocation', e.model);
            //},
            //_onDateSelected: function (e) {
            //
            //    var self = this;
            //
            //    //self.isDateSelected = true;
            //    //self.dateCheckInQuery = e.checkInQuery;
            //    //self.dateCheckOutQuery = e.checkOutQuery;
            //
            //    console.dir("onDateSelected in propertyFilters", e);
            //
            //    //self.updateFilterButtonText('check-in', e.results);
            //    //self.updateFilterButtonText('check-out', e.results2);
            //
            //    window.$vent.trigger('filterSelected', {checkIn: e.checkInQuery, checkOut: e.checkOutQuery});
            //},
            _onGuestsSelected: function (e) {

                var self = this;

                self.isGuestsSelected = true;
                self.guestsQuery = e.query;

                self.updateFilterButtonText('guests', e.results);
            },
            _onCloseMenu: function (e) {

                var self = this;
                self.closeCurrentMenu();
            },
            _onOutClick: function (e) {

                var self = this;

                if (!$('#modal-content-wrapper .calendars').hasClass('display-none'))
                    return;

                if (e) {

                    if (!self.$filtersContainer[0].contains(e.target) && !self.filtersMenu.$el[0].contains(e.target) && document.contains(e.target)) {
                        self.closeCurrentMenu();
                    }

                    if ($(e.target).hasClass('close')) {
                        self.closeCurrentMenu();
                    }
                } else {
                    self.closeCurrentMenu();
                }
            },

            // close menu

            closeCurrentMenu: function (maintainVeil) {

                var self = this;

                if (self.currentMenu && self.isModalOpen) {

                    self.restoreCurrentFilterButton();

                    if (self.winWidth < 768 && !maintainVeil) {
                        self.currentMenu.on('hideComplete', function () {
                            self.$mobileOverlaysContainer.removeClass('display-none').addClass('display-none');
                        });
                    }

                    self.currentMenu.hide();
                    self.currentMenu = null;

                    self.$el.off('click', $.proxy(self._onOutClick, self));

                    if (maintainVeil !== true) {
                        window.$vent.trigger('closeFiltersMenu');
                    }
                }
            },
            // hide / restore clicked filter button values

            hideCurrentFilterButton: function () {

                var self = this;

                _.each(self.$activeFilterButton, function (filter, i) {
                    var $filter = $(filter);
                    TweenMax.to($filter.find('a'), 0.3, {opacity: 0, ease: Cubic.easeOut});
                });
            },
            restoreCurrentFilterButton: function () {

                var self = this;

                _.each(self.$activeFilterButton, function (filter, i) {
                    var $filter = $(filter);
                    TweenMax.fromTo($filter.find('a'), 0, {opacity: 0}, {opacity: 1, ease: Quint.easeInOut});
                });
            },
            // filters menu
            initFiltersMenu: function () {

                var self = this;

                var priceRangeFrom = null;
                var priceRangeTo = null;

                //if (self.urlParam('tags')) {
                //    self.stringOfTags = self.urlParam('tags');
                //}

                if (self.stringOfTags != "") {
                    var tagArray = self.stringOfTags.split("|");
                    for (var i = 0; i < tagArray.length; i++) {
                        var cut = tagArray[i].split(":");

                        if (cut[0] == "pricerange") {
                            var prices = cut[1].split("-");
                            priceRangeFrom = parseInt(prices[0]);
                            priceRangeTo = parseInt(prices[1]);
                        }
                    }
                }

                console.log('initFiltersMenu self.stringOfTags', self.stringOfTags);

                self.filtersMenu = new FiltersMenuView({
                    el: $('#filters-menu-container'),
                    priceRangeFrom: priceRangeFrom,
                    priceRangeTo: priceRangeTo
                });
                //self.initTags();
                self.setInitFilters();

                self.filtersMenu.on('addTag', self._onAddTag, self);
                self.filtersMenu.on('removeTag', self._onRemoveTag, self);
                self.filtersMenu.on('hide', self._onFiltersMenuHide, self);

                // mobile filters buttons

                _.each(self.filtersMenu.$el.find('.top-level-filters .filter-option'), function (filterButton, i) {

                    var $filterButton = $(filterButton);
                    var defaultText = $filterButton.find('figcaption').html();
                    $filterButton.attr('data-default-text', defaultText);
                    $filterButton.on('click', $.proxy(self._onFilterOptionClick, self));
                });

                self.$mobileOverlaysContainer = self.filtersMenu.$el.find('.menu-mobile-overlays');

                window.$vent.trigger('loadFirstQuery');
            },

            //set filters
            setInitFilters: function () {
                console.log("SET INIT FILTERS");
                var self = this;

                if (self.stringOfTags != "") {
                    var tagArray = self.stringOfTags.split("|");
                    for (var i = 0; i < tagArray.length; i++) {
                        var cut = tagArray[i].split(":");
                        var labelValue = cut[1].split('.')[0];
                        if (cut[0] == "bedrooms") {
                            console.log('setInitFilters cut[1]', cut[1]);
                            $(".filter-rooms-wrapper select").val(labelValue);
                        }
                        else if (cut[0] == "bathrooms") {
                            $(".filter-bathrooms-wrapper select").val(labelValue);
                        }
                        else if (cut[0] == "propertyCategory") {
                            $(".filter-category-wrapper select").val(cut[1]);
                            /*$("input[data-id='" + cut[1] + "']").prop("checked", true);*/
                        }
                        else if (cut[0] == "areas") {
                            $('.filter-areas-wrapper option').eq(parseInt(cut[1].slice(-1)) - 1).prop('selected', true);
                        }
                        else if (cut[0] == "keyword") {
                            $("input[name='keyword']").val(cut[1].replace('%20',' '));
                        }
                        else if (cut[0] == "facilities") {
                            $("input[data-id='" + cut[1] + "']").prop("checked", true);
                        }
                    }
                    self.initTags();
                    self.updateTags();
                }

            },

            // tags

            initTags: function () {
                console.log("INIT TAGS");
                var self = this;

                if (self.stringOfTags != "") {
                    var tagArray = self.stringOfTags.split("|");
                    for (var i = 0; i < tagArray.length; i++) {
                        var cut = tagArray[i].split(":");

                        var appendI18 = "";
                        var labelValue = cut[1].split('.')[0];
                        if (cut[0] == "bedrooms") {
                            if (self.lang == "es") appendI18 = labelValue + " Camas";
                            else appendI18 = labelValue + " Beds";
                        }
                        else if (cut[0] == "bathrooms") {
                            if (self.lang == "es") appendI18 = labelValue + " Baños";
                            else appendI18 = labelValue + " Baths";
                        }
                        else if (cut[0] == "pricerange") {
                            if (self.lang == "es") appendI18 = cut[1] + " €";
                            else appendI18 = "$ " + cut[1];
                        }
                        else if (cut[0] == "propertyCategory") {
                            switch (cut[1]) {
                                case "superior":
                                    appendI18 = "Superior";
                                    break;
                                case "premium":
                                    appendI18 = "Premium";
                                    break;
                                case "grand class":
                                    appendI18 = "Grand Class";
                                    break;
                            }
                        }
                        else if (cut[0] == "areas") {
                            switch (cut[1]) {
                                case "area2":
                                    if (self.lang == "es") appendI18 = "Menos de 50m2";
                                    else appendI18 = "Less than 50m2";
                                    break;
                                case "area3":
                                    appendI18 = "50m2-100m2";
                                    break;
                                case "area4":
                                    appendI18 = "100m2-150m2";
                                    break;
                                case "area5":
                                    appendI18 = "200m2-300m2";
                                    break;
                                case "area6":
                                    appendI18 = "300m2+";
                                    break;
                            }
                        }
                        else if (cut[0] == "keyword") {
                            appendI18 = cut[1].replace('%20',' ');
                        }
                        else if (cut[0] == "facilities") {
                            switch (cut[1]) {
                                case "terrace":
                                    if (self.lang == "es") appendI18 = "Terraza";
                                    else appendI18 = "Terrace";
                                    break;
                                case "parking":
                                    appendI18 = "Parking";
                                    break;
                                case "storage":
                                    if (self.lang == "es") appendI18 = "Trastero";
                                    else appendI18 = "Storage";
                                    break;
                                case "concierge":
                                    if (self.lang == "es") appendI18 = "Portero";
                                    else appendI18 = "Concierge";
                                    break;
                                case "heating":
                                    if (self.lang == "es") appendI18 = "Calefacción";
                                    else appendI18 = "Heating";
                                    break;
                                case "lift":
                                    if (self.lang == "es") appendI18 = "Ascensor";
                                    else appendI18 = "Lift";
                                    break;
                                case "swimming-pool":
                                    if (self.lang == "es") appendI18 = "Piscina";
                                    else appendI18 = "Swimming pool";
                                    break;
                                case "garden":
                                    if (self.lang == "es") appendI18 = "Jardín";
                                    else appendI18 = "Garden";
                                    break;
                                case "gym":
                                    if (self.lang == "es") appendI18 = "Gimnasio";
                                    else appendI18 = "Gym";
                                    break;
                                case "full-concierge":
                                    if (self.lang == "es") appendI18 = "Portero 24H";
                                    else appendI18 = "24H Concierge";
                                    break;
                                case "exterior":
                                    appendI18 = "Exterior";
                                    break;
                                case "fully-fitted-kitchen":
                                    if (self.lang == "es") appendI18 = "Cocina Amueblada";
                                    else appendI18 = "Fully Fitted Kitchen";
                                    break;
                                case "air-conditioning":
                                    if (self.lang == "es") appendI18 = "Aire acondicionado";
                                    else appendI18 = "Air conditioning";
                                    break;
                            }
                        }

                        var tagItem = new TagItemView({
                            $container: self.$tagsContainer,
                            model: {type: cut[0], id: cut[0] + ":" + cut[1], shortLabel: cut[1], label: appendI18},
                            id: tagArray[i],
                        });

                        console.log('initTags tagItem', tagItem);
//
                        tagItem.on('destroy', self._onDestroyTag, self);
                        self.tags.push(tagItem);
                    }
                }
            },
            _onAddTag: function (e) {
                console.log("ADDTAG");
                console.log(e);
                var self = this;
                if (!_.findWhere(self.tags, {id: e.id})) {

                    var tagItem = new TagItemView({
                        $container: self.$tagsContainer,
                        model: e,
                        id: e.id
                    });

                    console.log('_onAddTag tagItem', tagItem);

                    tagItem.on('destroy', self._onDestroyTag, self);
                    self.tags.push(tagItem);
                    self.updateTags();
                }

            },
            _onDestroyTag: function (e) {

                var self = this;
                var tagItem = e.target;

                if (tagItem) {

                    tagItem.off('destroy', self._onRemoveTag, self);
                    self.tags = _.without(self.tags, tagItem);

                    if (self.filtersMenu && tagItem.clickClosed) {
                        self.filtersMenu.removeTag(tagItem.model);
                        self.updateTags();
                    }
                }
            },
            _onRemoveTag: function (e) {

                var self = this;
                var tagItem = _.findWhere(self.tags, {id: e.id});

                console.log('_onRemoveTag', e);

                if (tagItem) {

                    tagItem.off('destroy', self._onRemoveTag, self);
                    tagItem.exit();

                    self.tags = _.without(self.tags, tagItem);
                }
                self.updateTags();
            },
            _onFiltersMenuHide: function () {

                var self = this;
                //self.updateQuery();
            },

            updateTags: function () {
                var self = this;

                var tagQuery = '';

                if (self.tags.length) {

                    _.each(self.tags, function (tag, i) {

                        var $tag = tag.id;
                        if (i > 0) {
                            tagQuery += '|';
                        }
                        tagQuery += tag.id;
                    });

                }

                console.log("QueryString in updateTags of property-filters ", tagQuery);
                window.$vent.trigger('updateTags', tagQuery);

            },

            // listeners -----------------------------------------------------------------------  /

            _onFilterButtonClick: function (e) {

                var self = this;

                if (!self.filtersMenu) {
                    self.initFiltersMenu();
                }

                $('.filter-btn-more').show();
                $('#more_container').show();
                $('.more-filters-options').removeAttr('style');
                self.filtersMenu.show();
                e.preventDefault();
            },
            // window resizing

            onWindowResize: function (e) {

                var self = this;

                self.winWidth = $(window).width();
                self.winHeight = $(window).height();
            },


            //Functions might not be in use
            updateFilterButtonText: function (buttonClass, text) {

                var self = this;
                var $filterButton = self.$el.find('.filter-option.' + buttonClass);

                if (text) {
                    $filterButton.find('figcaption').html(text);
                    $filterButton.removeClass('selected').addClass('selected');
                } else {
                    $filterButton.find('figcaption').html($filterButton.data('default-text'));
                    $filterButton.removeClass('selected');
                }
            },
            _onMenuClosed: function (e) {

                var self = this;
                //self.updateQuery();
                //window.$vent.trigger('applyParams');
            },
            urlParam: function(name){
                var results = new RegExp('[?&]' + name + '=([^&#]*)').exec(window.location.href);
                if(results){
                    return results[1]
                }else{
                    return 0
                }
            },

            removeParam: function(url, parameter) {
                return url
                    .replace(new RegExp('[?&]' + parameter + '=[^&#]*(#.*)?$'), '$1')
                    .replace(new RegExp('([?&])' + parameter + '=[^&]*&'), '$1');
            }

        });

        return AppView;
    }
);



