
define(
	'modules/calendars/views/CalendarMenuButtonsView',[
		'backbone',
		'utils/AbstractView',

		'modules/calendars/views/CalendarMenuView',

		'moment',
		'clndr'
	], 
	function(Backbone, AbstractView, CalendarMenuView){

		var CalendarMenuButtonsView = Backbone.View.extend({

			// core vars

			$el: null,
			$container: null,
			options: null,
			abstractView: null,

			//

			$context: null,
			$calendarsContainer: null,

			overlayMenu: null,
			$checkIn: null,
			$checkOut: null,

			isModalOpen: false,

			// init

			initialize: function(options){

				var self = this;

				self.options = options;
				self.$container = self.options.$container;
				self.$context = self.options.$context;
				self.selectedDates = self.options.selectedDates;
				self.$calendarsContainer = self.options.$calendarsContainer || self.$el;

				//self.template = _.template(Template);
				self.model = $.extend({

				}, self.model);

				// 

				self.abstractView = new AbstractView({
					view: self,
					autoShow: true,
				});

				//

				$(window).on('resize', $.proxy(self._onWindowResize, self));
				self._onWindowResize();
			},

			//

			onDomReady: function(){

				var self = this;

				self.$checkIn = self.$el.find('.calendar-button.check-in');
				self.$checkOut = self.$el.find('.calendar-button.check-out');

				self.$checkIn.attr('data-default-text', self.$checkIn.find('input').val());
				self.$checkOut.attr('data-default-text', self.$checkOut.find('input').val());

				if(self.selectedDates){
					self.updateFilterButtonText('check-in','',self.selectedDates.checkInDate.format('DD/MM/YY'));
					self.updateFilterButtonText('check-out','',self.selectedDates.checkOutDate.format('DD/MM/YY'));
				}
			},

			onAddListeners: function(){

				var self = this;

				self.overlayMenu = new CalendarMenuView({
					$context: self.$context,
					$container: self.getCalendarContainer(),
					propertyCalendar: false,
					hideMobileWrapper: self.$el.data('mobile-overlay-id') !== false,
					autoShow: false,
					shortDate: true,
					selectedDates: self.selectedDates,
					hasOverlay: self.$el.data('depature') !== false && self.$el.data('has-default-dates') !== true
				});

				self.overlayMenu.on('clearDates', self._onClearDates, self);
				self.overlayMenu.on('optionSelected', self._onDateSelected, self);
				self.overlayMenu.on('closeMenu', self._onCloseMenu, self);
				self.overlayMenu.on('hide', self._onMenuClosed, self);

				// individual button listeners
				if(self.$el.data('has-default-dates') == true) {
					self.overlayMenu.reRenderCalendars();
					self.overlayMenu.withDefaults = true;
					self.overlayMenu.rightCalendar.forward();
					self.overlayMenu.triggerOption(moment().format('YYYY-MM-DD'), moment().add(1, 'month').format('YYYY-MM-DD'));
					self.overlayMenu.disableLeftCalendar();
					self.overlayMenu.tweakCalendar();
				}

				self.$checkIn.on('click', $.proxy(self._onCheckInClick, self));
				self.$checkOut.on('click', $.proxy(self._onCheckOutClick, self));
			},

			// listener methods ----------------------------------------------------------------  /

			_onClearDates: function(e){

				var self = this;

				self.resetFilterButtonText('check-in');
				self.resetFilterButtonText('check-out');
			},

			_onDateSelected: function(e){

				var self = this;
				
				self.isDateSelected = true;
				self.dateQuery = e.query;

				self.updateFilterButtonText('check-in', e, e.results);
				self.updateFilterButtonText('check-out', e, e.results2);			
			},

			_onCloseMenu: function(e){

				var self = this;
				self.closeCurrentMenu();
			},

			_onMenuClosed: function(e){

				var self = this;
			},

			_onCheckInClick: function(e){

				var self = this;
				self.showOverlayMenu();
				e.preventDefault();	

				window.requestAnimationFrame(function(){
					$(':focus').blur();
				});
			},

			_onCheckOutClick: function(e){

				var self = this;
				self.showOverlayMenu();
				e.preventDefault();

				window.requestAnimationFrame(function(){
					$(':focus').blur();
				});
			},

			// show menu -----------------------------------------------------------------------  /

			showOverlayMenu: function(){

				var self = this;

				if(!self.isModalOpen){

					self.isModalOpen = true;
					self.overlayMenu.show();
					if (self.$calendarsContainer.length <= 0){
						self.$calendarsContainer = self.$el.find('.filter-overlays-container');
					}
					
					self.$calendarsContainer.removeClass('display-none');

					$(document).off('click', $.proxy(self._onOutClick, self));
					$(document).on('click', $.proxy(self._onOutClick, self));

					window.$vent.trigger('openFiltersMenu');
					
					if(window.innerWidth < 768){
						
						if(self.$el.data('mobile-match-scrolltop') !== false){

							TweenMax.set(self.overlayMenu.$el, {top:$(window).scrollTop() + 60});

							var scrollOffset = $('#main-menu-wrapper').hasClass('absolute-nav') ? 0 : 60;							
							window.$vent.trigger('seekToPosition', {scrollY: self.overlayMenu.$el.offset().top - scrollOffset});
						}

						if(self.$el.data('mobile-match-height-id')){

							var $matchModal = $('#' + self.$el.data('mobile-match-height-id'));
							var modalHeight = ($matchModal && $matchModal.length && $matchModal.find('>.inner-container')) ? $matchModal.find('>.inner-container').height() : $matchModal.height();
							
							TweenMax.set(self.overlayMenu.$el, {'min-height': modalHeight});
						}
					}
				}
			},

			_onOutClick: function(e){

				var self = this;
				console.log('self.$calendarsContainer:', self.$calendarsContainer);
				if(e){					
					if(!self.$el[0].contains(e.target) && !self.$calendarsContainer[0].contains(e.target) && $('body')[0].contains(e.target)){
						self.closeCurrentMenu();
					}	
				}
			},

			// close menu ----------------------------------------------------------------------  /

			closeCurrentMenu: function(e){

				var self = this;

				if(self.isModalOpen){

					if(self.overlayMenu.$el[0] != self.$el[0]){
						self.overlayMenu.$el.removeClass('display-none').addClass('display-none');
					}

					self.overlayMenu.hide();
					self.isModalOpen = false;

					$(document).off('click', $.proxy(self._onOutClick, self));
				}
			},

			// hide / restore clicked filter button values

			updateFilterButtonText: function(inputClass, e, text){

				var self = this;
				var date;
				
				if(e && e.date) date = e.date;
				if(!text && e && e.results){ text = e.results; }

				var $filterButton = self.$el.find('.' + inputClass);
				var $filterInput = $filterButton.find('input');

				if(!$filterInput.length && $filterButton.is('input')){ $filterInput = $filterButton; }

				if(text){
					
					self.$el.data('date', date);

					if($filterInput.length){ $filterInput.attr('value', text); }
					else { $filterButton.html(text); }

					$filterButton.removeClass('selected').addClass('selected');
				}
				else {

					self.$el.data('date', '');
					
					var defaultText = $filterButton.data('default-text') || '';

					if($filterInput.length){ $filterInput.attr('value', defaultText); }
					else { $filterButton.html(defaultText); }

					$filterButton.removeClass('selected');
				}
			},

			resetFilterButtonText: function(inputClass){

				var self = this;
				self.updateFilterButtonText(inputClass);
			},

			//

			getCalendarContainer: function(){
				var self = this;	
				if(self.winWidth >= window.MOBILE_WIDTH){

					var $desktopContainer = self.$el.find('.filter-overlays-container');
				
					if(!$desktopContainer.length){
						$desktopContainer = $('<div class="filter-overlays-container"></div>').appendTo(self.$el);
					}

					return $desktopContainer;
				}
				else 				
				{
					return self.$calendarsContainer;
				}

				return self.$el;
			},

			//

			onShow: function(){

				var self = this;

				window.requestAnimationFrame(function(){
					self.trigger('showComplete');
				});
			},

			// window resizing

			_onWindowResize: function(e){

				var self = this;

				self.winWidth = window.innerWidth;
				self.winHeight = window.innerHeight;
				self.docHeight = $(document).outerHeight();

				if(self.winWidth >= window.MOBILE_WIDTH){
							
					if(self.isMobile !== false){	
					
						if(typeof self.overlayMenu != undefined && self.isModalOpen ){
							self.closeCurrentMenu();
						}
						
						self.onAddListeners();	
					}
					
					self.isMobile = false;
				}
				else {
															
					if(self.isMobile !== true){	
						
						if(typeof self.overlayMenu != undefined && self.isModalOpen ){
							self.closeCurrentMenu();
						}
						
						self.onAddListeners();						
					}		
					
					self.isMobile = true;
				}
			},

			//

		});

	return CalendarMenuButtonsView;
});
