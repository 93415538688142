define(
    'modules/spinner/app',[
        'backbone',
    ],
    function (Backbone) {

        var AppView = Backbone.View.extend({

                // core vars
                lang: null,
                $spinnerContainers: null,

                // init

                initialize: function (options) {

                    window.$vent.trigger('spinnerReady');

                    var self = this;

                },

                // start module --------------------------------------------------------------------  /

                start: function () {

                    var self = this;
                    $(function () {
                        self.onDomReady();
                    });


                },

                onDomReady: function () {

                    var self = this;

                    self.getSpinnerContainers();
                    console.log("spinner containers ", self.$spinnerContainers);

                    window.$vent.on('spinnerButtonInDom', $.proxy(self._onGetSpinnerContainers, self));
                    window.$vent.on('showSpinner', $.proxy(self._onShowSpinner, self));
                    window.$vent.on('hideSpinner', $.proxy(self._onHideSpinner, self));


                },

                _onGetSpinnerContainers: function () {
                    var self = this;
                    console.log("self.$spinnerContainers",self.$spinnerContainers);

                    self.getSpinnerContainers();
                },
                _onShowSpinner: function (e,data) {
                    var self = this;
                    var button = $(data);

                    console.log("data ",data);
                    console.log("button ",button);

                    button.addClass('btn-disabled');
                    button.find('.spinner').removeClass('hide');

                },
                _onHideSpinner: function () {
                    var self = this;
                    self.getSpinnerContainers();

                    self.$spinnerContainers.removeClass('btn-disabled');
                    self.$spinnerContainers.find('.spinner').addClass('hide');

                },
                getSpinnerContainers: function () {
                    var self = this;

                    self.$spinnerContainers = $('.spinner-container');
                }
                ,

            })
            ;

        return AppView;
    }
);
