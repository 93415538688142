define(
	'modules/sticky-nav/app',[
		'backbone',
		'modules/sticky-nav/views/SimpleStickyNavView',
		'modules/sticky-nav/views/TrackingStickyNavView',
	], 
	function(Backbone, SimpleStickyNavView, TrackingStickyNavView){

		var AppView = Backbone.View.extend({

			// vars

			stickyNavView: null,

			// init

			initialize: function(options){

				var self = this;
			},

			// start module --------------------------------------------------------------------  /

			start: function(){

				var self = this;
				window.$vent.trigger('stickyNavReady');

				$(function(){
					self.onDomReady();
					self.onAddListeners();
				});
			},

			onAddListeners: function(){

				var self = this;
			},

			onDomReady: function(){

				var self = this;
				var $stickyNav = self.$el.find('.sticky-nav');
				var $hero = self.$el.find('.sticky-nav-hero-block');
				
				if($hero.length<1)
					$hero=self.$el.find('#main-menu-wrapper');
								

				if($stickyNav.length){

					if($stickyNav.hasClass('scroll-tracking')){

						var $scrollSections = self.$el.find('.scroll-section');
						
						self.stickyNavView = new TrackingStickyNavView({
							el: $stickyNav,
							$hero: $hero,
							$scrollSections: $scrollSections,
						});
					}
					else {

						self.stickyNavView = new SimpleStickyNavView({
							el: $stickyNav,
							$hero: $hero,
						});
					}
				}
			},
			
			// ---------------------------------------------------------------------------------  /

		});

		return AppView;
	}
);
