define(
        'modules/user-pages/app',[
            'backbone',
            'modules/user-pages/views/UserPagesView',
            'modules/user-pages/views/UserNotesBtn',
        ],
        function (Backbone, UserPagesView, UserNotesBtn) {

            var AppView = Backbone.View.extend({
                // core vars
                $guestServiceContainer: null,
                $guestServicesBtn: null,
                $guestServicesPanel: null,
                $userNotes: null,
                $saveNotesButtons: null,
                $saveNotesMobile: null,
                tweenDelay: null,
                fadeDelay: null,
                $noteButton: null,
                // init

                initialize: function (options) {
                    var self = this;
                    window.$vent.trigger('userPageReady');
                },
                // start module --------------------------------------------------------------------  /

                start: function () {

                    var self = this;

                    $(function () {
                        self.onDomReady();
                        self.addListeners();
                    });
                },
                onDomReady: function () {

                    var self = this;
                    self.$guestServiceContainer = $('.guest-services-ambassador');
                    self.$guestServicesBtn = $('.contact-guest-services');
                    self.$userNotes = $('.fav-notes-textarea');
                    self.$saveNotesButtons = '.save-note-btn';
                    self.$notesButtons = '.save-note-btn';
                    self.$saveNotesMobile = '.row-btn-note';
                    self.$hideAlert = $('.hide-alert');
//                    self.$notesButtons = $('.save-note-btn');
                },
                addListeners: function () {

                    var self = this;

                    self.$guestServicesBtn.on('click', $.proxy(self._onGuestServicesButtonClick, self));
                    self.$userNotes.on('keydown', $.proxy(self._onNoteWrite, self));
                    self.$hideAlert.on('click', $.proxy(self._onHideAlert, self));
                    
                                        
//                    self.$notesButtons.on('click', $.proxy(self._onUpdateNote, self));

                    console.log('listeners added', self.$hideAlert);
                },
                _onGuestServicesButtonClick: function (e) {
                    var self = this;
                    self.guestServicesPanel = new UserPagesView({el: self.$guestServiceContainer});
                    self.guestServicesPanel.show();
             },
                _onNoteWrite: function (e) {
                    var self = this;
                    var active_el = $(e.currentTarget);

                    if (self.$noteButton == null || self.$noteButton.el != active_el.parent().find(self.$saveNotesButtons)) {
                        self.$noteButton = new UserNotesBtn({el: active_el.parent().find(self.$saveNotesButtons)});
                        self.$noteButton.show();
                    }
                    //var show_notes_mobile = new UserNotesBtn({el: active_el.parent().find(self.$saveNotesMobile)});
//                    show_notes_btn.show();
//                    show_notes_mobile.show();
                },
//                _onUpdateNote: function (e) {
//                    var self = this;
//                    
//                    var favId = self.$removeButton[0].id;
//                    var data = {};
//                    var attrs = favId.split("-");
//                    data["propertyId"] = attrs[0];
//                    data["propertyType"] = attrs[1];
//
//                    $.ajax({
//                        type: 'POST',
//                        url: '/userArea/toogleFavoriteAjax',
//                        data: JSON.stringify(data),
//                        async: false,
//                        contentType: "application/json; charset=utf-8",
//                        success: function (response) {
//                            self.exit();
//                        },
//                        error: function (e) {
//                            console.log("favoriteClick: " + e.message);
//                        }
//
//                    });
//                },
                _onHideAlert: function (e) {
                    var self = this;
                    console.log('hide alert clicked', self.$hideAlert);

                    var $target = $(e.currentTarget);
                    $target.closest('.static-alert').addClass('hide');
                    $target.closest('.static-alert').removeClass('show');
                }

                // ---------------------------------------------------------------------------------  /

            });

            return AppView;
        }
);
