
define(
	'modules/global-scripted-buttons/views/SelectableButtonView',[
		'backbone',
		'utils/AbstractView',
		'utils/AbstractButtonView',
	], 
	function(Backbone, AbstractView, AbstractButtonView){

		var SelectableButtonView = Backbone.View.extend({

			// core vars

			$el: null,
			$container: null,
			options: null,
			abstractView: null,
			abstractButtonView: null,

			// vars

			$navContainer: null,

			// init

			initialize: function(options){

				var self = this;

				// options vars

				self.options = options;
				self.$container = self.options.$container;

				// template config

				//self.template = _.template(Template);
				self.model = $.extend({}, self.model);

				// initialize AbstractView and AbstractButtonView

				self.abstractView = new AbstractView({
					view: self,
					autoShow: self.options.autoShow !== false,
				});


				self.abstractButtonView = new AbstractButtonView({
					abstractView: self.abstractView,
					selectable: false,
				});

				//

				if(self.$el.data('container-id')){
					self.$navContainer = $('#' + self.$el.data('container-id'));
				}
				else {
					self.$navContainer = self.$el.parent();
				}
			},

			onAnimateClick: function(){

				var self = this;

				if ($("#userIsLogged").length == 0 && self.$el.hasClass('favorite')) {

				} else {

					if(self.$el.hasClass('selected')){
						self.$el.removeClass('selected');
					}
					else {

						if(!!self.$el.data('radio-behavior') || self.$el.hasClass('btn-radio-behavior')){
							self.$navContainer.find('.selected').removeClass('selected');
						}

						self.$el.removeClass('selected').addClass('selected');
					}
				}
			},

			// ---------------------------------------------------------------------------------  /

		});

	return SelectableButtonView;
});
