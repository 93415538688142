define(
    'modules/property-detail-bar/views/SidebarStep2View',[
        'backbone',
        'utils/AbstractView',
        'modules/property-detail-bar/views/UpgradeView',
        'text!../../../../../../views/partials/_SidebarStep2Template.gsp',
        'text!../templates/FeeTemplate.html',
        'text!../templates/UpgradeTemplate.html',
        'text!../templates/StayCostTemplate.html',
    ],
    function (Backbone, AbstractView, UpgradeView, Template, FeeTemplate, UpgradeTemplate, StayCostTemplate) {

        var SidebarStep2View = Backbone.View.extend({
            // core vars

            $el: null,
            $container: null,
            options: null,
            abstractView: null,
            lang: null,
            messages: null,
            // vars

            $centeredContent: null,
            $floatingSocials: null,
            $upgradesContainer: null,
            $feesContainer: null,
            $favoriteButton: null,
            $closeNote: null,
            $addNote: null,
            $stayCostContainer: null,
            $stayCost: null,
            $subTotal: null,
            $bookingButton: null,
            $holdButton: null,
            $upgrades: null,
            $currentCheckIn: null,
            $currentCheckOut: null,
            // init

            initialize: function (options) {

                var self = this;
                console.log('sidebar 2 initialize')

                self.lang = $('#lang').html();
                self.options = options;
                self.$container = self.options.$container;
                self.el = self.options.el;
                self.messages = self.options.messages;
                self.model = $.extend({}, self.model);

                console.log("options ", options);
                console.log("self.option ", self.options);
                console.log("self.messages ", self.messages);

                self.abstractView = new AbstractView({
                    view: self,
                    autoShow: self.options.autoShow === true,
                });
            },
            //Function to recalculate and print the new data
            initPromise: function () {

                var self = this;
                var deferred = $.Deferred();
                self.checkIn = Utils.MiscUtils.getCookie('checkIn');
                self.checkOut = Utils.MiscUtils.getCookie('checkOut');
                var cost = $('.cost').text().trim();
                if ($('.ss-property-detail-rent').length > 0) {
                    self.template = _.template(Template);
                    console.log('sidebar 2 rent')

                } else {

                    console.log('sidebar 2 start ajax load');
                    $.ajax({
                        url: "/shortlet/sidebar2Ajax",
                        data: $.param({
//                                parseCost: parseCost,
//                                cost: cost,
                            selected_check_in_step2: self.checkIn,
                            selected_check_out_step2: self.checkOut
                        }),
                        success: function (data) {
                            console.log('sidebar 2 ajax loaded');
                            self.template = _.template(data);
                            self.model = $.extend({}, self.model);
                            //

                            self.abstractView = new AbstractView({
                                view: self,
                                autoShow: self.options.autoShow === true,
                            });
                            deferred.resolve();
                        }
                    });
                }

                return deferred.promise();
            },
            onRender: function () {

                var self = this;
                self.idBo = $('.idBo').text();
                self.$noLoggedButton = self.$el.find('.no-logged-button');
                self.$bookingButton = self.$el.find('#btn-req-booking-modal');
                self.$availabilityButton = self.$el.find('#btn-availability-modal');
                self.seeServicesIncludedButton = self.$el.find('#btn-see-value');
                self.$centeredContent = self.$el.find('.vert-center-content');
                self.$floatingSocials = self.$el.find('.floating-socials');
                self.$stayCostContainer = self.$el.find('.stay-costs');
                self.$upgradesContainer = self.$el.find('.upgrades');
                self.$feesContainer = self.$el.find('.fees');
                self.$subTotal = self.$el.find('.subtotal-item .item-price');
                self.$favoriteButton = self.$el.find('.btn-add-to-favorites');
                self.$closeNote = self.$el.find('.close-note');
                self.$addNote = self.$el.find('.add-note');
                // Current Data
                self.$currentCheckIn = $('.fig-check-in-step2').text();
                self.$currentCheckOut = $('.fig-check-out-step2').text();

                self.$facebookShare = self.$el.find('.facebook-share');
                self.$twitterShare = self.$el.find('.twitter-share');
                self.socialUrl = $('#social-url').text();

                self.upgrades = [];
                _.each(self.model.upgrades, function (upgrade, i) {

                    var upgradeView = new UpgradeView({
                        el: self.$upgradesContainer.find('*[data-id="' + upgrade.id + '"]'),
                        $upgradesContainer: self.$upgradesContainer,
                        $feesContainer: self.$feesContainer,
                        model: $.extend({item: upgrade}, self.model)
                    });
                    upgradeView.on('upgradeActive', self._onUpgradeActive, self);
                    self.upgrades.push(upgradeView);
                });
            },
            onDomReady: function () {

                var self = this;
                window.$vent.trigger('domUpdate');
            },
            // listeners

            onAddListeners: function () {

                var self = this;

                self.$noLoggedButton.on('click', $.proxy(self._onOpenLoginModal, self));
                self.$bookingButton.on('click', $.proxy(self._onBookingReqClick, self));
                self.$availabilityButton.on('click', $.proxy(self._onAvailabilityReqClick, self));
                self.seeServicesIncludedButton.on('click', $.proxy(self._onSeeServicesIncluded, self));
                self.$favoriteButton.on('click', $.proxy(self._onFavoriteMouseOver, self));
                self.$closeNote.on('click', $.proxy(self._onCloseNote, self));
                self.$addNote.on('click', $.proxy(self._onAddNote, self));
                self.$favoriteButton.on('click', $.proxy(self._onFavoriteClick, self));

                self.$facebookShare.on('click', $.proxy(self._onFacecbookShareClick, self));
                self.$twitterShare.on('click', $.proxy(self._onTwitterShareClick, self));

                window.$vent.on('dateChanged', $.proxy(self.recalculateCost, self));
                window.$vent.on('resetCostData', $.proxy(self.resetCostData, self));
            },
            onRemoveListeners: function () {

                var self = this;
                self.$noLoggedButton.off('click', $.proxy(self._onOpenLoginModal, self));
                self.$bookingButton.off('click', $.proxy(self._onBookingReqClick, self));
                self.$availabilityButton.off('click', $.proxy(self._onAvailabilityReqClick, self));
                self.seeServicesIncludedButton.off('click', $.proxy(self._onSeeServicesIncluded, self));
                //self.$favoriteButton.off('click', '.favorite', $.proxy(self._onFavoriteClick, self));
                //self.$favoriteButton.off('click', '.not-favorite', $.proxy(self._onSelectedFavoriteClick, self));
                self.$favoriteButton.off('click', $.proxy(self._onFavoriteMouseOver, self));
                self.$closeNote.off('click', $.proxy(self._onCloseNote, self));
                self.$addNote.off('click', $.proxy(self._onAddNote, self));
            },
            _onFavoriteMouseOver: function (e) {
                var self = this;
                var $target = $(e.currentTarget);
                var $note = $target.siblings('.add-favorite-note');
                console.log($note);
                if (!$note.is(':visible')) {
                    $note.show();
                    if (!$target.hasClass('shortlet')) {
                        TweenMax.set($note, {top: $target.position().top + parseInt(40) - 10});
                    } else {
                        TweenMax.set($note, {top: -10});
                    }
                    TweenMax.fromTo($note, 0.6, {bottom: -60, opacity: 0}, {
                        bottom: 0,
                        opacity: 1,
                        ease: Quint.easeOut
                    });
                }
            },

            _onFacecbookShareClick: function () {
                var self = this;

                var popup = window.open("https://www.facebook.com/sharer/sharer.php?u=" + self.socialUrl, "pop", "width=600, height=400, scrollbars=no");
            },
            _onTwitterShareClick: function () {
                var self = this;

                var popup = window.open("https://twitter.com/share?url=" + self.socialUrl, "pop", "width=600, height=400, scrollbars=no");

            },

            _onCloseNote: function () {
                var self = this;
                var $note = self.$container.find('.add-favorite-note');
                if ($note.is(':visible')) {
                    TweenMax.fromTo($note, 0.6, {opacity: 1}, {
                        opacity: 0, ease: Quint.easeOut, onComplete: function () {
                            $note.hide();
                        }
                    });
                }
            },
            _onAddNote: function () {
                var self = this;
                var $note = self.$container.find('.add-favorite-note');
                //add code here to save the note etc

                if ($note.is(':visible')) {
                    TweenMax.fromTo($note, 0.6, {opacity: 1}, {
                        opacity: 0, ease: Quint.easeOut, onComplete: function () {
                            $note.hide();
                        }
                    });
                }
            },
            _onBookingReqClick: function (e) {
                var self = this;


                var shorletPropertyName = $('.shorletPropertyName').text();
                var numGuests = $('.num-guests').text();
                var checkIn = $('.fig-check-in-step2').text();
                var checkOut = $('.fig-check-out-step2').text();
                var origin = 'sidebar-step-2-pre-reserve';
                var data = {

                    'trackAction': origin ,
                    'trackLabel': {
                        'propReference': $('.idBo').text() ,
                        'propLocation':  $('.intro-location').text(),
                        'checkIn': checkIn ,
                        'checkOut':checkOut ,
                    }
                }


                Utils.DOMUtils.triggerTrackEvent(data);
                window.$vent.trigger('showSpinner', [e.currentTarget]);
                window.$vent.trigger('openModal', '/shortlet/PropertyHoldModal?shorletPropertyName=' + shorletPropertyName + '&checkIn=' + checkIn + '&checkOut=' + checkOut + '&idBo=' + self.idBo + '&maxNumPeople=' + numGuests);


            },
            _onAvailabilityReqClick: function (e) {
                var self = this;

                var shorletPropertyName = $('.shorletPropertyName').text();
                var numGuests = $('.num-guests').text();
                var checkIn = $('.fig-check-in-step2').text();
                var checkOut = $('.fig-check-out-step2').text();
                var origin = 'sidebar-step-2-hold'
                var data = {
                    'trackAction': origin ,
                    'trackLabel': {
                        'propReference': $('.idBo').text() ,
                        'propLocation': $('.intro-location').text() ,
                        'checkIn': checkIn,
                        'checkOut': checkOut ,
                        'guests': numGuests
                    }
                }

                Utils.DOMUtils.triggerTrackEvent(data);
                window.$vent.trigger('showSpinner', [e.currentTarget]);
                window.$vent.trigger('openModal', '/shortlet/PropertyAvailabilityModal?shorletPropertyName=' + shorletPropertyName + '&checkIn=' + checkIn + '&checkOut=' + checkOut + '&idBo=' + self.idBo + '&maxNumPeople=' + numGuests);

//                    var self = this;
//                    self.trigger('completeBooking');

            },
            _onOpenLoginModal: function (e) {
                var self = this;

                window.$vent.trigger('showSpinner', [e.currentTarget]);
                window.$vent.trigger('openModal', '/modals/login?');

            },
            _onFavoriteClick: function (e) {
                var self = this;
                var $target = $(e.currentTarget);
                if ($target.hasClass('selected')) {
                    window.$vent.trigger('setAlertCount', {count: 'decrement'});
                } else {
                    window.$vent.trigger('setAlertCount', {count: 'increment'});
                    $target.addClass('selected');
                }

                e.preventDefault();
                return false;
            },
            _onSeeServicesIncluded: function(e){
                var self = this;

                window.$vent.trigger('showSpinner', e.currentTarget);
                window.$vent.trigger('openModal', '/modals/seeValueModal?id=' + self.idBo);
            },
            /*        setDates: function (checkIn, checkOut) {
             $('.fig-check-in-step2').html(checkIn);
             $('.fig-check-out-step2').html(checkOut);
             },*/
            // calculate costs
            //Print results
            printResults: function (array, newValue) {
                array.each(function (i, item) {
                    $(item).text(" " + newValue + " ");
                });
            },
            recalculateCost: function (e, data) {
                var self = this;

                var newCheckIn = data.calendarResults.checkInDate.format('DD/MM/YY');
                var newCheckOut = data.calendarResults.checkOutDate.format('DD/MM/YY');
                var idBo = $('.idBo').text();
                if ((newCheckIn && newCheckOut) && newCheckIn != newCheckOut) {
                    if (self.$currentCheckIn != newCheckIn || self.$currentCheckOut != newCheckOut) {
                        if ($('.property-bar-step-2').length > 0) {
                            var monthlysubtotalReplaced = $('.monthly-subtotal-event');
                            var durationReplaced = $('.replace-months-event');
                            var costMonthly = $('.replace-cost-event');
                            var discountAmount = $('.replace-discount-amount-event');
                            var discountStay = $('.replace-discount-stay-event');
                            var deposit = $('.replace-deposit-event');
                            var bookingfee = $('.replace-bookingfee-event');
                            var clientType = $('#client-type');

                            $.ajax({
                                type: 'POST',
                                url: "/shortlet/getBookingCost?newCheckIn=" + newCheckIn +
                                "&newCheckOut=" + newCheckOut +
                                "&id=" + idBo,
                                context: document.body,
                                success: function (data) {
                                    console.log('booking cost data', data);
                                    monthlysubtotalReplaced.each(function (i, item) {
                                        $(item).text(" " + data.monthlySubtotal + " ");
                                    });
                                    durationReplaced.each(function (i, item) {
                                        $(item).text(" " + data.duration + " ");
                                    });
                                    costMonthly.each(function (i, item) {
                                        $(item).text(" " + data.monthlyFeeRaw + " ");
                                    });
                                    if (data.discountAmount && data.discountStay) {
                                        $('#discountSection').show();
                                        var appliedDiscountId = "#discount-" + data.discountId;
                                        $('.blue').addClass('hide');
                                        $(appliedDiscountId).removeClass('hide');
                                        discountAmount.each(function (i, item) {
                                            $(item).text(" " + data.discountAmount + " ");
                                        });
                                        discountStay.each(function (i, item) {
                                            $(item).text(" " + data.discountStay + " ");
                                        });

                                        $('.floating-socials').removeClass('step2');
                                        $('.floating-socials').addClass('tall');
                                    } else {
                                        $('#discountSection').hide();
                                        $('.blue').addClass('hide');
                                    }
                                    deposit.each(function (i, item) {
                                        $(item).text(" " + data.deposit + " ");
                                    });
                                    bookingfee.each(function (i, item) {
                                        $(item).text(" " + data.bookingfee + " ");
                                    });

                                },
                                error: function (e) {
                                    self.resetCostData();
                                    var errorMessage = self.messages.calculate_costs_error;
                                    self.showErrorMessage(errorMessage);
                                    self.disableBooking();
                                }
                            });

                            return false;
                        }
                    }
                }
            },
            resetCostData: function () {
                var self = this;

                if ($('.property-bar-step-2').length > 0) {
                    var monthlysubtotalReplaced = $('.monthly-subtotal-event');
                    var durationReplaced = $('.replace-months-event');
                    var discountAmount = $('.replace-discount-amount-event');
                    var discountStay = $('.replace-discount-stay-event');
                    var deposit = $('.replace-deposit-event');
                    var bookingfee = $('.replace-bookingfee-event');
                    var clientType = $('#client-type');


                    monthlysubtotalReplaced.each(function (i, item) {
                        $(item).text("");
                    });
                    durationReplaced.each(function (i, item) {
                        $(item).text("");
                    });
                    deposit.each(function (i, item) {
                        $(item).text("");
                    });
                    bookingfee.each(function (i, item) {
                        $(item).text("");
                    });
                    $('#discountSection').hide();
                    $('.blue').addClass('hide');
                }
            },
            recalculate: function (calendarResults, guestsResults) {
                /* Comentada para que no de errores
                 *
                 *
                 var self = this;
                 var totalPrice = 0;
                 if (self.model.deposit) {
                 totalPrice += Number(self.model.deposit.price);
                 }

                 if (calendarResults) {

                 if (self.$stayCost) {
                 self.$stayCost.remove();
                 }

                 var stayCostTemplate = _.template(StayCostTemplate);
                 self.$stayCost = $(stayCostTemplate($.extend({
                 priceBreakdown: calendarResults.priceBreakdown
                 }, self.model)));
                 self.$stayCostContainer.html(self.$stayCost);
                 totalPrice += calendarResults.priceBreakdown.totalPrice;
                 }

                 if (guestsResults) {

                 }

                 // check default fees

                 _.each(self.model.fees, function (fee, i) {

                 var feePrice = 0;
                 if (fee.price) {

                 if (fee.costPeriod && fee.perPerson) {

                 if (fee.costPeriod == 'day') {
                 feePrice += Number(fee.price) * calendarResults.priceBreakdown.totalNights * guestsResults.total;
                 }
                 } else {

                 if (fee.costPeriod) {

                 if (fee.costPeriod == 'day') {
                 feePrice += Number(fee.price) * calendarResults.priceBreakdown.totalNights;
                 }
                 }

                 if (fee.perPerson) {
                 feePrice += Number(fee.price) * guestsResults.total;
                 }
                 }

                 if (!fee.costPeriod && !fee.perPerson) {
                 feePrice = Number(fee.price);
                 }
                 }

                 totalPrice += feePrice;
                 });
                 // check active upgrades

                 _.each(self.upgrades, function (upgrade, i) {

                 if (upgrade.active) {

                 var upgradePrice = 0;
                 var fee = upgrade.model.item;
                 if (Number(fee.price)) {

                 if (fee.costPeriod && fee.perPerson) {

                 if (fee.costPeriod == 'day') {
                 upgradePrice += Number(fee.price) * calendarResults.priceBreakdown.totalNights * guestsResults.total;
                 }
                 } else {

                 if (fee.costPeriod) {

                 if (fee.costPeriod == 'day') {
                 upgradePrice += Number(fee.price) * calendarResults.priceBreakdown.totalNights;
                 }
                 }

                 if (fee.perPerson) {
                 upgradePrice += Number(fee.price) * guestsResults.total;
                 }
                 }

                 if (!fee.costPeriod && !fee.perPerson) {
                 upgradePrice = Number(fee.price);
                 }
                 }

                 if (upgradePrice > 0) {
                 upgrade.$fee.find('.item-price').html(self.model.currencySymbol + upgradePrice);
                 }

                 totalPrice += upgradePrice;
                 }

                 });
                 //                    self.$subTotal.html(self.model.currencySymbol + totalPrice);

                 */

            },
            // listener methods ----------------------------------------------------------------  /

            _onUpgradeActive: function (e) {
                var self = this;
                self.trigger('change');
            },
            allowBooking: function () {
                var self = this;
                self.$container.find('.btn-hold.disabled').removeClass('disabled');
            },
            disableBooking: function () {
                var self = this;
                self.$container.find('.btn-hold').removeClass('disabled').addClass('disabled');
            },
            showErrorMessage: function (text) {
                var self = this;
                var content = $('.property-bar-step-2 #date-message-container');
                TweenMax.to(content, 0, {
                    opacity: 0, ease: Cubic.easeOut, onComplete: function () {
                        $(content).html(text);
                        TweenMax.fromTo(content, 0, {y: 0}, {y: 0, opacity: 1, ease: Sine.easeOut});
                    }
                })

                var socials =$('.floating-socials');
                socials.removeClass('step2').addClass('tall');
            },
            hideErrorMessage: function () {
                var self = this;
                var content = $('.property-bar-step-2 #date-message-container');
                var text = "";
                TweenMax.to(content, 0, {
                    opacity: 0, ease: Cubic.easeOut, onComplete: function () {
                        $(content).html(text);
                        TweenMax.fromTo(content, 0, {y: 0}, {y: 0, opacity: 1, ease: Sine.easeOut});
                    }
                })
            },
            // show / hide ---------------------------------------------------------------------  /

            onShow: function () {

                var self = this;
                self.trigger('showComplete');
                window.requestAnimationFrame(self.onWindowResize.bind(self));
            },
            // getters -------------------------------------------------------------------------  /

            getMinHeight: function () {

                var self = this;
                var centeredContentHeight = self.$centeredContent.outerHeight(true);
                return centeredContentHeight + 65 * 2;
            },
            // window resize

            onWindowResize: function () {
                var self = this;
                var winHeight = window.innerHeight;
                //var centeredContentHeight = self.$centeredContent.outerHeight(true) ;
                var centeredContentHeight = self.$centeredContent.outerHeight(true) + 130;
                if (winHeight >= centeredContentHeight) {

                    TweenMax.set(self.$el, {
                        clearProps: 'height'
                    });
                    TweenMax.set(self.$centeredContent, {
                        top: Math.round((winHeight - centeredContentHeight) / 2),
                    });
                    TweenMax.set(self.$floatingSocials, {
                        //top: winHeight - 45,
                        // top: winHeight - self.$floatingSocials.outerHeight(true),
                    });
                    self.trigger('setFixed');
                } else if (winHeight <= 750) {
                    TweenMax.set(self.$centeredContent, {
                        top: Math.round((winHeight - centeredContentHeight) / 2) + 130,
                        //top: 60
                    });
                } else {


                    TweenMax.set(self.$el, {
                        height: self.getMinHeight()
                    });
                    TweenMax.set(self.$centeredContent, {
//                            top: Math.round((winHeight - centeredContentHeight) / 2) + 105,
                        top: Math.round((winHeight - centeredContentHeight) / 2) + 80,
                    });
                    TweenMax.set(self.$floatingSocials, {
                        clearProps: 'top',
                    });
                    self.trigger('setAbsolute');
                }

            }

            // ---------------------------------------------------------------------------------  /

        });
        return SidebarStep2View;
    });


