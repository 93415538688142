define(
        'modules/calendars/views/CalendarMenuView',[
            'backbone',
            'utils/AbstractView',
            'modules/property-detail-bar/views/ToolTipView',
            '../../../libs/vendor/rangeslider/ion.rangeSlider.min.js',
            'text!../templates/CalendarMenuTemplateEs.html',
            'text!../templates/CalendarMenuTemplateEn.html',
            'text!../templates/CalendarTemplate.html',
            'text!../templates/CalendarOverlayEs.html',
            'text!../templates/CalendarOverlayEn.html'
        ],
        function (Backbone, AbstractView, ToolTipView, RangeSlider, TemplateEs, TemplateEn, CalendarTemplate, OverlayTemplateEs, OverlayTemplateEn) {

            var CalendarMenuView = Backbone.View.extend({
                // core vars

                $el: null,
                $container: null,
                options: null,
                abstractView: null,
                // vars

                lang: $('#lang').html(),
                $context: null,
                clndr: null,
                multidayArray: null,
                isDateSelected: false,
                queryString: null,
                endDate: null,
                startDate: null,
                $leftCalendarContainer: null,
                $rightCalendarContainer: null,
                $clearDatesButton: null,
                $closeButton: null,
                $mobileWrap: null,
                $desktopWrap: null,
                leftCalendar: null,
                rightCalendar: null,
                leftMonth: null,
                rightMonth: null,
                toolTip: null,
                toolTipTimeout: null,
                serverQuery: null,
                availabilityData: null,
                selectedDates: null,
                queryObj: null,
                minNights: null,
                maxNights: null,
                isShortDate: false,
                isReadOnly: false,
                isMonthChange: false,
                isOwnerListing: false,
                isEditBlock: false,
                isFirstShow: true,
                autoPopulate: false,
                hasDayPrices: false,
                hasOverlay: false,
                withDefaults: false,
                JSONdisabledDates: null,
                ownerBlockCalendar: null,
                ownerBlockCalendarLeftStartDate: null,
                ownerBlockCalendarHideOldPrices: true,
                dayStyles: {
                    onHold: 'on-hold',
                    owner:  'owner',
                    occupied: 'occupied',
                    maintenance: 'maintenance',
                    other: 'other'
                },
                // init
                initialize: function (options) {
                    var self = this;

                    //Internationalization of moment
                    if (self.lang == "es") {
                        moment.locale("es");
                    } else {
                        moment.locale("en");
                    }

                    self.options = options;
                    self.hasOverlay = self.options.hasOverlay;

                    self.$container = self.options.$container;
                    self.$context = self.options.$context || $;

                    self.isShortDate = self.options.shortDate === true;
                    self.selectedDates = self.options.selectedDates;
                    self.$mobileWrap = self.options.$mobileWrap;
                    self.$desktopWrap = self.options.$desktopWrap;
                    self.propertyId = self.options.propertyId;

                    if (self.lang == 'es') {
                        self.template = _.template(TemplateEs);
                    } else {
                        self.template = _.template(TemplateEn);
                    }

                    //Load the disabledDates if its mobile
                    self.model = $.extend({}, self.model);

                    self.minNights = 30;
                    self.maxNights = 5000;
                    console.log("-- los datos de dias no disponibles procedente de la app ", self.model);

                    if (self.propertyId) {
                        console.log("ownerBlockCalendar - propertyId: ", self.propertyId)

                        self.ownerBlockCalendar = $('.availability-prop-' + self.propertyId);
                        self.ownerBlockCalendarStartDate = moment().startOf('year').subtract(1, 'years')
                        self.loadDisabledDates();
                    }

                    // defaults in case of availability data

                    self.occupiedBlocks = [];

                    // default start / end dates

                    self.startDate = moment(moment().format('YYYY-MM-DD'));
                    self.endDate = moment().add(24, 'months');

                    //

                    self.selectedBlockIndex = self.$container.data('block-index');

                    if (_.isNumber(self.selectedBlockIndex) && self.selectedBlockIndex >= 0) {
                        self.isEditBlock = true;
                    }

                    //

                    self.abstractView = new AbstractView({
                        view: self,
                        autoShow: (self.options.autoShow === true),
                        hideClass: self.options.hideClass || 'display-none'
                    });

                    //

                    $(window).on('resize', $.proxy(self._onWindowResize, self));
                    self._onWindowResize();
                },
                // initialize ----------------------------------------------------------------------  /

                onInitialize: function () {

                    var self = this;

                    self.clndr = {};
                    self.multidayArray = [];
                    self.isDateSelected = false;
                    self.queryString = '';
                },
                // onRender ------------------------------------------------------------------------  /

                onRender: function () {

                    var self = this;

                    // clear dates button

                    self.$clearDatesButton = self.$container.find('.clear-dates');
                    self.$closeButton = self.$el.find('.fa.close').add(self.$el.find('.close-btn'));

                    //

                    if (self.$container.hasClass('read-only') || self.$container.data('read-only')) {
                        self.isReadOnly = true;
                    }
                    if (self.$container.hasClass('owner-listing') || self.$container.data('owner-listing')) {
                        self.isOwnerListing = true;
                    }

                    self.resetEvents();

                    // combine all occupied blocks

                    window.$vent.on('setAvailabilityData', $.proxy(self._onSetAvailabilityData, self));
                    var availabilityData = self.model.availability;

                    if (availabilityData) {
                        self.occupiedBlocks = self.occupiedBlocks.concat(availabilityData.holdOccupiedDates);
                        self.configureAvailabilityData(availabilityData);

                        if (self.ownerBlockCalendar) {
                            self.availabilityData.dateRangePrices = self.model.dateRangePrices
                            self.hasDayPrices = true;
                        }
                        self.reRenderCalendars();
                    } else {
                        window.$vent.trigger('availabilityDataRequest', {target: self});
                        if (self.$container.data('pending-data') !== true) {
                            self.reRenderCalendars();
                        }
                    }

                    // if no availability data, disable right calendar
                    if (!self.isReadOnly && (!availabilityData || !self.autoPopulate)) {
                        self.disableRightCalendar();
                    }

                },
                createOverlay: function () {
                    var self = this;

                    if(!self.ownerBlockCalendar) {
                        if (self.lang == 'es') {
                            self.overlaytemplate = _.template(OverlayTemplateEs);
                        } else {
                            self.overlaytemplate = _.template(OverlayTemplateEn);
                        }
                        self.$rightCalendarContainer.append(self.overlaytemplate);
                    }


                    self.$rightCalendarContainer.on('click', 'a.btn', function (e) {
                        if ($(this).data('behavior') == 'hide') {
                            $('.calendar_overlay_class').hide();
                        } else {
                            $('.overlay_links_class').hide();
                            $('.' + $(this).data('behavior')).removeClass("hidden").show();


                            self.$rightCalendarContainer.find("#month-range").ionRangeSlider({
                                min: 1,
                                max: 18,
                                from: 1,
                                to: 18,
                                step: 1,
                                onChange: function (data) {
                                    if (data.from == 1) {
                                        if (self.lang == 'es') {
                                            self.$rightCalendarContainer.find('#MonthsPreview').text('(1) Mes');
                                        } else {
                                            self.$rightCalendarContainer.find('#MonthsPreview').text('(1) Month');
                                        }
                                        ;
                                    } else {
                                        if (self.lang == 'es') {
                                            self.$rightCalendarContainer.find('#MonthsPreview').text('(' + data.from + ') Meses');
                                        } else {
                                            self.$rightCalendarContainer.find('#MonthsPreview').text('(' + data.from + ') Months');
                                        }
                                        ;
                                    }
                                },
                                onFinish: function (data) {

                                    if (self.multidayArray.length) {

                                        self.endDate = moment(self.multidayArray[0].startDate).add(data.from, 'months');
                                        self.multidayArray[0].endDate = self.endDate;

                                        window.requestAnimationFrame(function () {

                                            self.isMonthChange = true;
	                                        console.log('createOverlay isdateSelected true');
                                            self.isDateSelected = true;

                                            self.completeInstruction();
                                            self.tweakCalendar();
                                            self.closeMenu();
                                        });
                                    }
                                },
                            });

                        }
                    });
                },
                reRenderCalendars: function () {

                    var self = this;


                    if (self.leftCalendar) {
                        self.$leftCalendarContainer.remove();
                    }
                    if (self.rightCalendar) {
                        self.$rightCalendarContainer.remove();
                    }

                    // start dates

                    var firstRange = self.isEditBlock ? self.getSelectedOwnersBlock() : self.getFirstAvailableDate();
                    var startWithMonthLeftCalendar = firstRange.startDate
                    var startWithMonthRightCalendar = startWithMonthLeftCalendar

                    if (self.multidayArray && self.multidayArray.length) {
                        var leftStartDate = moment(firstRange.startDate);
                        var rightStartDate = moment(firstRange.endDate);
                    } else {
                        var leftStartDate = moment(moment().format('YYYY-MM-DD')); // moment(firstRange.startDate);
                        var rightStartDate = moment(moment().format('YYYY-MM-DD')); //moment(firstRange.startDate).add(self.minNights, 'days');
                    }

                    if (self.isReadOnly) {
                        rightStartDate = moment(moment().format('YYYY-MM-DD')).add(1, 'months');
                    }

                    if (self.ownerBlockCalendar) {
                        leftStartDate = self.ownerBlockCalendarStartDate;
                        rightStartDate = self.ownerBlockCalendarStartDate;

                        startWithMonthLeftCalendar = moment(firstRange.startDate)
                        startWithMonthRightCalendar = moment(firstRange.startDate).add(1, 'months');
                    }

                    self.leftMonth = leftStartDate.format('MM');
                    self.rightMonth = rightStartDate.format('MM');

                    // append calendars
                    var $calendarsContainer = self.$el.find('.calendars-inner-container');
                    if (!$calendarsContainer.length)
                        $calendarsContainer = self.$el;

                    // Left Calendar (check-in)
                    var daysOfTheWeek =['S', 'M', 'T', 'W', 'T', 'F', 'S'];
                    var extrasHeader = self.ownerBlockCalendar ? 'Start' : 'Check In'
                    if (self.lang == 'es') {
                        daysOfTheWeek = ['L', 'M', 'X', 'J', 'V', 'S','D'];
                        extrasHeader = self.ownerBlockCalendar ? 'Inicio' : 'Entrada';
                    }

                    self.$leftCalendarContainer = self.$context('<div class="calendar-container left"></div>').appendTo($calendarsContainer);
                    self.leftCalendar = self.clndr.passInATemplate = self.$leftCalendarContainer.clndr({
                        daysOfTheWeek: daysOfTheWeek,
                        template: CalendarTemplate,
                        events: [],
                        multiDayEvents: {
                            startDate: 'startDate',
                            endDate: 'endDate'
                        },
                        startWithMonth: startWithMonthLeftCalendar,
                        forceSixRows: true,
                        weekOffset: 0,
                        // TODO here you limit the beginning and the end of the calendar
                        constraints: {
                            startDate: leftStartDate,
                            endDate: self.endDate
                        },
                        clickEvents: {
                            click: self._onLeftCalendarClick.bind(self),
                            nextMonth: self._onNextMonth.bind(self),
                            previousMonth: self._onPrevMonth.bind(self),
                        },
                        extras: {
                            header: extrasHeader,
                            multidayArray: self.multidayArray,
                            availabilityData: self.availabilityData,
                            traverseDateRange: self.traverseDateRange,
                            isOwnerListing: self.isOwnerListing,
                            isEditBlock: self.isEditBlock,
                            selectedBlockIndex: self.selectedBlockIndex,
                        }
                    });

                    // right (check-out)
                    if (self.lang == 'es') {
                        extrasHeader = self.ownerBlockCalendar ? 'Fin' : 'Salida';
                    }

                    self.$rightCalendarContainer = self.$context('<div class="calendar-container right"></div>').appendTo($calendarsContainer);
                    self.rightCalendar = self.clndr.passInATemplate = self.$rightCalendarContainer.clndr({
                        daysOfTheWeek: daysOfTheWeek,
                        template: CalendarTemplate,
                        events: [],
                        multiDayEvents: {
                            startDate: 'startDate',
                            endDate: 'endDate'
                        },
                        startWithMonth: startWithMonthRightCalendar,
                        forceSixRows: true,
                        weekOffset: 0,
                        constraints: {
                            startDate: rightStartDate,
                            endDate: self.endDate
                        },
                        clickEvents: {
                            click: self._onRightCalendarClick.bind(self),
                            nextMonth: self._onNextMonth.bind(self),
                            previousMonth: self._onPrevMonth.bind(self),
                        },
                        extras: {
                            header: extrasHeader,
                            multidayArray: self.multidayArray,
                            availabilityData: self.availabilityData,
                            traverseDateRange: self.traverseDateRange,
                            isOwnerListing: self.isOwnerListing,
                            isEditBlock: self.isEditBlock,
                            selectedBlockIndex: self.selectedBlockIndex,
                        }
                    });

                    if (self.hasOverlay) {
                        self.createOverlay();
                    }

                    self.tweakCalendar();
                },
                // listeners -----------------------------------------------------------------------  /

                onAddListeners: function () {

                    var self = this;

                    window.$vent.on('requestCalendarQuery', $.proxy(self._onRequestCalendarQuery, self));

                    self.$clearDatesButton.on('click', $.proxy(self._onClearDatesClick, self));
                    self.$closeButton.on('click', $.proxy(self._onCloseClick, self));
                },
                onRemoveListeners: function () {

                    var self = this;

                    if (self.$clearDatesButton) {
                        self.$clearDatesButton.off('click', $.proxy(self._onClearDatesClick, self));
                    }

                    if (self.$closeButton) {
                        self.$closeButton.off('click', $.proxy(self._onCloseClick, self));
                    }
                },
                // listener methods ----------------------------------------------------------------  /

                triggerOption: function (checkInDate, checkOutDate) {

                    var self = this;

                    if (checkInDate && checkOutDate) {
                        self.changeStartDate({}, moment(checkInDate));
                        self.changeEndDate({}, moment(checkOutDate));
                        self.tweakCalendar();
                    }
                },
                _onLeftCalendarClick: function (target) {
                    console.log("HE CLICADO EN EL ONLEFTCALENDAR");
                    var self = this;

                    var $target = $(target.element);

                    if (!$target.hasClass('inactive') && !$target.hasClass('disabled')) {

                        var startDate = moment(target.date);
                        var success = self.changeStartDate(target, startDate);

                        var lastLeftMonth = startDate.format('MM');
                        var lastRightMonth = '';


                        if (success) {

                            if (lastLeftMonth != success.startDate.format('MM')) {
                                self.leftCalendar.setMonth(success.startDate.format('MM') - 1);
                            }

                            if (lastRightMonth != success.endDate.format('MM')) {
                                self.rightCalendar.setYear(success.endDate.format('YYYY'));
                                self.rightCalendar.setMonth(success.endDate.format('MM')-1);
                            }

                            window.requestAnimationFrame(function () {

                                self.isMonthChange = true;
                                if (self.lang == 'es') {
                                    self.showInstruction('Selecciona fecha de salida');
                                } else {
                                    self.showInstruction('Select Check-Out Date');
                                }

                                self.disableLeftCalendar();
                                self.enableRightCalendar();

                                self.tweakCalendar();
                            });
                        }
                    }
                },
                _onRightCalendarClick: function (target) {

                    var self = this;
                    var $target = $(target.element);

                    if (!$target.hasClass('inactive') && !$target.hasClass('disabled')) {

                        var startDate = moment(self.multidayArray[0].startDate);
                        var endDate = moment(target.date);
                        var success = self.changeEndDate(target, endDate);

                        var lastLeftMonth = startDate.format('MM');
                        var lastRightMonth = endDate.format('MM');

                        if (success) {

                            if (lastLeftMonth != success.startDate.format('MM')) {
                                self.leftCalendar.setMonth(success.startDate.format('MM') - 1);
                            }

                            if (lastRightMonth != success.endDate.format('MM')) {
                                self.rightCalendar.setMonth(success.endDate.format('MM') - 1);
                            }

                            window.requestAnimationFrame(function () {

                                self.isMonthChange = true;
                                self.isDateSelected = true;

                                self.completeInstruction();
                                self.tweakCalendar();
                                self.closeMenu();
                            });
                        }
                    }
                },
                _onNextMonth: function (e) {
                    var self = this;

                    if (self.isReadOnly) {

                        if (e.target === self.leftCalendar) {
                            self.rightCalendar.forward();
                        } else if (e.target === self.rightCalendar) {
                            self.leftCalendar.forward();
                        }
                    }

                    self._onMonthChange(e);
                },
                _onPrevMonth: function (e) {

                    var self = this;

                    if (self.isReadOnly) {

                        if (e.target === self.leftCalendar) {
                            self.rightCalendar.back();
                        } else if (e.target === self.rightCalendar) {
                            self.leftCalendar.back();
                        }
                    }

                    self._onMonthChange(e);
                },
                _onMonthChange: function (e) {
                    var self = this;
                    window.$vent.trigger('calendarMonthClick');
                    self.isMonthChange = true;
                    if (self.withDefaults)
                        self.tweakCalendar(true); //keep the window open
                    else
                        self.tweakCalendar();
                },
                _onClearDatesClick: function (e) {
                    var self = this;
                    var $target = $(e.currentTarget);
                    if (!$target.hasClass('disabled')) {
                        self.clearDates();
                    }
                },
                _onRequestCalendarQuery: function (e, obj) {
                    var self = this;
                    if (obj.$container[0].contains(self.$el[0])) {
                        self.sendQueryObj();
                    }
                },
                _onCloseClick: function () {
                    var self = this;
                    self.trigger('closeMenu');
                },
                closeMenu: function () {
                    var self = this;
                    self.trigger('closeMenu');
                },
                // date selection blocks -----------------------------------------------------------  /

                changeStartDate: function (target, startDate) {

                    var self = this;
                    var $target = $(target.element);
                    var nightTranslated;
                    if (self.lang == 'es') {
                        nightTranslated = 'Noches';
                    } else {
                        nightTranslated = 'Nights';
                    }

                    var minEndDate = moment(startDate).add(self.minNights, 'days');

                    // check for conflicts with occupied dates

                    if (self.availabilityData) {

                        var nextOccupiedDate = self.getNextOccupiedDate(startDate);

                        if ((nextOccupiedDate.format('x') - startDate.format('x')) / 86400000 < self.minNights + 1) {

                            self.showToolTip($target, 'Min ' + self.minNights + nightTranslated, 'red');
                            return false;
                        }
                    }

                    // if valid, continue

                    if (!self.multidayArray.length) {
                        self.multidayArray.push({});
                    }
                    self.multidayArray[0].startDate = startDate;
                    console.log("EL NUEVO MULTIDAYARRAY ES ", startDate);

                    return {startDate: startDate, endDate: minEndDate};
                },
                changeEndDate: function (target, endDate) {

                    var self = this;
                    var $target = $(target.element);
                    var startDate = self.multidayArray[0].startDate;
                    var nightTranslated
                    if (self.lang == 'es') {
                        nightTranslated = ' Noches';
                    } else {
                        nightTranslated = ' Nights';
                    }

                    var maxStartDate = moment(endDate).subtract(self.minNights, 'days');
                    var minEndDate = moment(startDate).add(self.minNights, 'days');
                    var maxEndDate = moment(startDate).add(self.maxNights, 'days');

                    if (self.availabilityData) {

                        var nextOccupiedDate = self.getNextOccupiedDate(startDate);

                        if (!self.isAvailableDate(endDate.format('x'))) {

                            self.showToolTip($target, 'Day Not Available', 'red');
                            return false;

                        } else {

                            // within same date range

                            if (endDate.format('x') > maxEndDate.format('x')) {

                                // invalid, too far

                                self.showToolTip($target, 'Max ' + self.maxNights + nightTranslated, 'red');
                                return false;
                            } else if (endDate.format('x') < startDate.format('x')) {

                                self.showToolTip($target, 'Day Not Available', 'red');
                                return false;
                            } else if (endDate.format('x') < minEndDate.format('x')) {

                                // invalid, too close

                                self.showToolTip($target, 'Min ' + self.minNights + nightTranslated, 'red');
                                return false;
                            } else if (endDate.format('x') > nextOccupiedDate.format('x')) {

                                // invalid, occupied block in between

                                self.showToolTip($target, 'Day Not Available', 'red');
                                return false;
                            } else {

                                // valid, no further changes needed

                                self.multidayArray[0].endDate = endDate;
	                            console.log('changeEndDate1 isdateSelected true');
                                self.isDateSelected = true;
                                return {startDate: startDate, endDate: endDate};
                            }

                        }

                    } else {

                        // no data, assume no blockout dates

                        if (endDate.format('x') >= minEndDate.format('x')) {

                            self.multidayArray[0].endDate = endDate;
	                        console.log('changeEndDate2 isdateSelected true');
                            self.isDateSelected = true;
                            return {startDate: startDate, endDate: endDate};
                        } else {

                            self.showToolTip($target, 'Min ' + self.minNights + nightTranslated, 'red');
                            return false;
                        }
                    }

                    return {startDate: startDate, endDate: endDate};
                },
                // instructions text ---------------------------------------------------------------  /

                resetClearButton: function () {

                    var self = this;
                    var selectCheckInButton;
                    var selectCheckOutButton;
                    if (self.lang == 'es') {
                        selectCheckInButton = "Seleccionar fecha de entrada";
                        selectCheckOutButton = "Seleccionar fecha de salida";
                    } else {
                        selectCheckInButton = "Select Check-In Date";
                        selectCheckOutButton = "Select Check-Out Date";
                    }

                    if (self.multidayArray && self.multidayArray.length && self.multidayArray[0].startDate) {
                        self.showInstruction(selectCheckOutButton, 0);
                    } else {
                        self.showInstruction(selectCheckInButton, 0);
                    }
                },
                showInstruction: function (instructionMessage, delay) {

                    var self = this;
                    var $messageText = self.$clearDatesButton.find('h3');
                    var d = delay || 0;

                    TweenMax.to($messageText, 0.3, {opacity: 0, ease: Cubic.easeOut, onComplete: function () {
                            self.$clearDatesButton.removeClass('disabled').addClass('disabled');
                            $messageText.html(instructionMessage);
                            TweenMax.fromTo($messageText, 0.3, {y: 6}, {y: 0, opacity: 1, delay: d, ease: Sine.easeOut});
                        }});
                },
                completeInstruction: function () {

                    var self = this;
                    var $messageText = self.$clearDatesButton.find('h3');

                    TweenMax.to($messageText, 0.3, {opacity: 0, ease: Cubic.easeOut, onComplete: function () {

                            self.$clearDatesButton.removeClass('disabled');

                            if (self.isEditBlock) {
                                if (self.lang == 'es') {
                                    $messageText.html('Restablecer Fechas');
                                } else {
                                    $messageText.html('reset dates');
                                }

                            } else {
                                if (self.lang == 'es') {
                                    $messageText.html('Borrar Fechas');
                                } else {
                                    $messageText.html('clear dates');
                                }
                            }

                            TweenMax.fromTo($messageText, 0.3, {y: 6}, {y: 0, opacity: 1, ease: Sine.easeOut});
                        }});
                },
                // tooltips ------------------------------------------------------------------------  /

                showToolTip: function ($target, message, color, width) {

                    var self = this;

                    if (self.toolTip && self.toolTip.$el[0] !== $target[0]) {
                        self.removeToolTip();
                        self.toolTip = null;
                    }

                    if (self.toolTipTimeout) {
                        window.clearTimeout(self.toolTipTimeout);
                    }

                    self.toolTipTimeout = window.setTimeout(function () {
                        self.showToolTipNow($target, message, color, width);
                    }, 25);
                },
                showToolTipNow: function ($target, message, color, width) {

                    var self = this;

                    self.toolTip = new ToolTipView({
                        $container: $target,
                        model: {text: message},
                        color: color,
                        width: width,
                        autoShow: true,
                    });
                },
                removeToolTip: function () {

                    var self = this;

                    if (self.toolTip) {
                        self.toolTip.exit();
                        self.toolTip = null;
                    }
                },
                // clndr event object --------------------------------------------------------------  /

                clearDates: function () {

                    var self = this;
                    self.resetEvents(true);

                    var $eventDays = self.$leftCalendarContainer.find('.day.event');
                    $eventDays = $eventDays.add(self.$rightCalendarContainer.find('.day.event'));
                    _.each($eventDays, function (eventDay) {
                        self.animateDayOut($(eventDay));
                    });

                    // reset text fields if not edit block

                    if (!self.isEditBlock) {
                        self.trigger('clearDates', {target: self});
                        window.$vent.trigger('calendarClearDates', {target: self});
                    }

                    TweenMax.delayedCall(0.2, function () {

                        var curMonth = parseInt(moment().format('MM'));
                        var curYear = parseInt(moment().format('YYYY'));

                        self.leftCalendar.setEvents(self.getSingleEvent());
                        self.leftCalendar.setYear(curYear);
                        self.leftCalendar.setMonth(curMonth - 1);

                        self.rightCalendar.setEvents(self.multidayArray);
                        self.rightCalendar.setYear(curYear);
                        self.rightCalendar.setMonth(curMonth - 1);

                        self.reRenderCalendars();
                        self.enableLeftCalendar();
                        self.disableRightCalendar();

                        self.resetClearButton();
                        self.tweakCalendar();
                    });
                },
                resetEvents: function (clear) {
                    var self = this;
                    console.log("7. ENTRA EN EL RESETEVENTS Y COMPRUEBA SI HAY FECHAS SELECCIONADAS Y FECHAS NO DISPONIBLES");

                    if (!clear && (self.autoPopulate || self.availabilityData || (self.selectedDates && !$.isEmptyObject(self.selectedDates)))) {

                        var firstRange = self.isOwnerListing ? self.getSelectedOwnersBlock() : self.getFirstAvailableDate();
                        var eventStartDate = firstRange.startDate;
                        var eventEndDate = firstRange.endDate;

                        if (clear !== true && self.selectedDates && !$.isEmptyObject(self.selectedDates)) {
                            eventStartDate = moment(self.selectedDates.checkInDate);
                            eventEndDate = moment(self.selectedDates.checkOutDate);
	                        self.isDateSelected = true;
                        }
                        else {
	                        self.isDateSelected = false;
                        }
                        /* configura el multiarray para luego pintar las fechas seleccionadas */
                        self.multidayArray = [
                            {
                                startDate: eventStartDate,
                                endDate: eventEndDate,
                                title: 'Your Itinerary'
                            }
                        ];

                        console.log('resetEvents self.isDateSelected', self.isDateSelected);

                    } else {

                        if (self.isEditBlock && self.availabilityData) {

                            var dateRange = self.availabilityData.ownerOccupiedDates[self.selectedBlockIndex];
                            var startDate = moment(dateRange['startDate']);
                            var endDate = moment(dateRange['endDate']);

                            // set event

                            if (!self.multidayArray.length) {
                                self.multidayArray.push({});
                            }

                            self.multidayArray[0].startDate = startDate;
                            self.multidayArray[0].endDate = endDate;
                        } else {
                            self.multidayArray = [];
                            self.isDateSelected = false;
                        }
                    }
                },
                getSingleEvent: function () {

                    var self = this;

                    if (self.multidayArray && self.multidayArray.length) {
                        return [{date: self.multidayArray[0].startDate, title: 'Check-In Date'}];
                    }

                    return [];
                },
                setEventClasses: function ($days, maxNights) {
                    var self = this;
                    maxNights = maxNights || $days.length;
                    console.log("ENTRA EN EL SETEVENTCLASES");
                    if (self.multidayArray.length > 0) {
                        console.log("---- ENTRA EN EL IF DEL SETEVENTCLASSES -----", self.multidayArray[0]);
                        var eventStart = self.multidayArray[0].startDate ? moment(self.multidayArray[0].startDate) : null;
                        var eventEnd = self.multidayArray[0].endDate ? moment(self.multidayArray[0].endDate) : moment(eventStart).add(Math.max(self.minNights - 1, 0), 'days');
                        var eventDayCount = 0;

                        for (var i = 0; i < $days.length; i++) {

                            var $day = $($days[i]);
                            var dayClasses = $day.attr('class');
                            var classIndex = dayClasses.indexOf('calendar-day-');
                            var dateClass = dayClasses.slice(classIndex, dayClasses.indexOf(' ', classIndex));
                            var dayDate = moment(dateClass.split('calendar-day-')[1]);
                            if (dayDate.format('x') >= eventStart.format('x') && (dayDate.format('x') <= eventEnd.format('x') || !eventEnd) && eventDayCount < maxNights) {

                                $day.removeClass('event').addClass('event');

                                if (eventDayCount === 0) {
                                    $day.removeClass('first-event-day').addClass('first-event-day');
                                }

                                eventDayCount++;
                            } else {
                                $day.removeClass('event');
                            }
                        }
                    }
                },
                //

                animateDayIn: function ($day, i) {
                    console.log("-- ENTRA EN ANIMATEDAYIN --");
                    var self = this;
                    i = i || 0;

                    var $eventBg = $day.find('figure.bg');
                    var $eventText = $day.find('figcaption');

                    TweenMax.fromTo($eventBg, 0.3, {opacity: 0}, {opacity: 1, delay: 0.01 * i, ease: Sine.easeInOut});
                    TweenMax.to($eventText, 0.3, {color: '#fff', delay: 0.01 * i, ease: Sine.easeInOut});

                    $day.removeClass('animated').addClass('animated');
                    $eventBg.removeClass('hidden');
                },
                animateDayOut: function ($day, i) {

                    var self = this;

                    var $eventBg = $day.find('figure.bg');
                    var $lastBg = $day.find('figure.last-bg');
                    var $eventText = $day.find('figcaption');

                    $day.removeClass('event-out').addClass('event-out');
                    $day.removeClass('animated');

                    TweenMax.to($lastBg, 0.2, {opacity: 0, ease: Cubic.easeOut});

                    TweenMax.set($eventBg, {transformOrigin: '50% 100%', opacity: 0});
                    TweenMax.to($eventBg, 0.3, {ease: Cubic.easeInOut, onComplete: function () {
                            $day.removeClass('event-out');
                            $eventBg.removeClass('hidden').addClass('hidden');
                        }});

                    TweenMax.to($eventText, 0.1, {color: '#808081', ease: Cubic.easeOut});
                },
                tweakCalendar: function (keeptheWindowOpen) {
                    console.log("9. ENTRA EN EL TWEAK PARA PINTAR LOS DIAS OCUPADOS Y SELECCIONADOS");
                    var self = this;

                    if (self.leftCalendar && self.rightCalendar) {


                        self.leftMonth = self.leftCalendar.month.format('MM');
                        self.rightMonth = self.rightCalendar.month.format('MM');

                        var $leftDaysContainer = self.$leftCalendarContainer.find('.days');
                        var $rightDaysContainer = self.$rightCalendarContainer.find('.days');


                        // mark availability
                        if (self.availabilityData) {
                            // occupied dates
                            if (self.availabilityData.bookedOccupiedDates) {
                                self.setDatesStyles(self.availabilityData.bookedOccupiedDates, $leftDaysContainer,
                                    $rightDaysContainer, {dayStyle: self.dayStyles.occupied} )
                            }

                            if (self.availabilityData.holdOccupiedDates) {
                                self.setDatesStyles(self.availabilityData.holdOccupiedDates, $leftDaysContainer,
                                    $rightDaysContainer, {dayStyle: self.dayStyles.onHold} )
                            }

                            if (self.availabilityData.ownerOccupiedDates) {

                                for (var i = 0; i < self.availabilityData.ownerOccupiedDates.length; i++) {

                                    if (!self.isOwnerListing || (
                                            self.isOwnerListing && !self.isEditBlock && i !== self.selectedBlockIndex)) {

                                        var dateRange = self.availabilityData.ownerOccupiedDates[i];

                                        self.traverseDateRange(dateRange, function (date, formattedDate) {
                                            self.setDayClasses($leftDaysContainer.find('.calendar-day-' + formattedDate), dateRange.calendarLabel);
                                            self.setDayClasses($rightDaysContainer.find('.calendar-day-' + formattedDate), dateRange.calendarLabel);
                                        });
                                    }
                                }
                            }

                            // price ranges
                            var dateRangePrices = self.availabilityData.dateRangePrices
                            if (dateRangePrices) {

                                for (var i = 0; i < dateRangePrices.length; i++) {

                                    var dateRange = dateRangePrices[i];

                                    self.traverseDateRange(dateRange, function (date, formattedDate, dateRange) {

                                        if (date.format('MM') === self.leftMonth) {
                                            var $leftDay = $leftDaysContainer.find('.calendar-day-' + formattedDate);

                                            if (self.ownerBlockCalendar && (self.ownerBlockCalendarHideOldPrices && $leftDay.hasClass('past'))) {
                                                return;
                                            }

                                            $leftDay.attr('data-price', dateRange.price);
                                            $leftDay.attr('data-price-unit', dateRange.priceUnit);
                                        }

                                        if (date.format('MM') === self.rightMonth) {
                                            var $rightDay = $rightDaysContainer.find('.calendar-day-' + formattedDate);

                                            if (self.ownerBlockCalendar && (self.ownerBlockCalendarHideOldPrices && $rightDay.hasClass('past'))) {
                                                return;
                                            }

                                            $rightDay.attr('data-price', dateRange.price);
                                            $rightDay.attr('data-price-unit', dateRange.priceUnit);
                                        }
                                    });
                                }
                            }
                        }

                        // add proper classes to date items for styling
                        // left
                        var $leftDays = $leftDaysContainer.find('.day');

                        for (var i = 0; i < $leftDays.length; i++) {
                            var $day = $($leftDays[i]);
                            if (self.availabilityData) {
                                if ($day.attr('data-price')) {
                                    $day.attr('data-price', self.availabilityData.defaultPrice);
                                }


                                // remove old listeners
                                $day.off('mouseenter', $.proxy(self._onDayOver, self));
                                $day.off('mouseleave', $.proxy(self._onDayOut, self));
                                // add new listeners
                                $day.on('mouseenter', $.proxy(self._onDayOver, self));
                                $day.on('mouseleave', $.proxy(self._onDayOut, self));

                                // Disable click in ownerBlockCalendar
                                if (self.ownerBlockCalendar) {
                                    $day.on('click', function(e) {
                                        e.preventDefault();
                                        e.stopPropagation();
                                     });
                                }
                            }
                        }

                        if (!self.isReadOnly) {
                            self.setEventClasses($leftDays);
                        }

                        // right
                        var $rightDays = $rightDaysContainer.find('.day');

                        for (var i = 0; i < $rightDays.length; i++) {
                            var $day = $($rightDays[i]);
                            if (self.availabilityData) {
                                if ($day.attr('data-price')) {
                                    $day.attr('data-price', self.availabilityData.defaultPrice);
                                }

                                // remove old listeners
                                $day.off('mouseenter', $.proxy(self._onDayOver, self));
                                $day.off('mouseleave', $.proxy(self._onDayOut, self));

                                // add new listeners
                                $day.on('mouseenter', $.proxy(self._onDayOver, self));
                                $day.on('mouseleave', $.proxy(self._onDayOut, self));

                                // Disable click in ownerBlockCalendar
                                if (self.ownerBlockCalendar) {
                                    $day.on('click', function(e) {
                                        e.preventDefault();
                                        e.stopPropagation();
                                     });
                                }
                            }
                        }

                        if (!self.isReadOnly) {
                            self.setEventClasses($rightDays);
                        }

                        // Function to show or no right and left sides
                        self.animateCalendarEvents();

                        // update click to open buttons
                        var checkInDate, checkOutDate, checkInDateShort, checkOutDateShort, checkInDateParam, checkOutDateParam;

                        if (self.multidayArray && self.multidayArray.length) {

                            if (self.lang == "es") {
                                checkInDate = moment(self.multidayArray[0].startDate).format('DD MMM YYYY');
                                checkOutDate = moment(self.multidayArray[0].endDate).format('DD MMM YYYY');
                            } else {
                                checkInDate = moment(self.multidayArray[0].startDate).format('MMM Do, YYYY');
                                checkOutDate = moment(self.multidayArray[0].endDate).format('MMM Do, YYYY');
                            }



                            checkInDateShort = moment(self.multidayArray[0].startDate).format('DD/MM/YY');
                            checkOutDateShort = moment(self.multidayArray[0].endDate).format('DD/MM/YY');

                            checkInDateParam = moment(self.multidayArray[0].startDate).format('MM/DD/YY');
                            checkOutDateParam = moment(self.multidayArray[0].endDate).format('MM/DD/YY');

                            if (self.isShortDate) {
                                checkInDate = checkInDateShort;
                                checkOutDate = checkOutDateShort;
                            }
                        }

                        console.log('isDateSelected', self.isDateSelected);

                        if (self.isDateSelected) {

                            // get costs based on days

                            var priceBreakdown = {};

                            priceBreakdown.ranges = [];
                            priceBreakdown.totalNights = 0;
                            priceBreakdown.totalPrice = 0;

                            if (self.availabilityData) {

                                var rangePrice = null;
                                var rangeStartDate = null;
                                var currentRange = null;

                                self.traverseDateRange(self.multidayArray[0], function (eventDate, formattedDate, eventRange) {

                                    var eventDateTime = eventDate.format('x');
                                    var matchedRange = null;

                                    if (self.availabilityData.dateRangePrices) {

                                        for (var i = 0; i < self.availabilityData.dateRangePrices.length; i++) {

                                            var dateRange = self.availabilityData.dateRangePrices[i];
                                            var rangeStartTime = moment(dateRange.startDate).format('x');
                                            var rangeEndTime = moment(dateRange.endDate).format('x');

                                            if (eventDateTime >= rangeStartTime && eventDateTime <= rangeEndTime) {
                                                matchedRange = dateRange;
                                                rangePrice = dateRange.price;
                                                break;
                                            }
                                        }

                                        self.hasDayPrices = true;
                                    }

                                    if (!matchedRange) {
                                        rangePrice = self.availabilityData.defaultPrice;
                                    }

                                    if (!currentRange || rangePrice != currentRange.price) {

                                        if (currentRange) {

                                            currentRange.endDate = eventDate;
                                            currentRange.nights = parseInt((currentRange.endDate.format('x') - currentRange.startDate.format('x')) / 86400000);
                                            currentRange.totalPrice = currentRange.nights * currentRange.price;

                                            priceBreakdown.totalNights += currentRange.nights;
                                            priceBreakdown.totalPrice += currentRange.totalPrice;
                                        }

                                        currentRange = {
                                            startDate: eventDate,
                                            price: rangePrice
                                        };

                                        priceBreakdown.ranges.push(currentRange);
                                    }

                                    if (eventDate.format('x') === eventRange.endDate.format('x')) {

                                        currentRange.endDate = eventRange.endDate;
                                        currentRange.nights = parseInt((currentRange.endDate.format('x') - currentRange.startDate.format('x')) / 86400000);
                                        currentRange.totalPrice = currentRange.nights * currentRange.price;

                                        priceBreakdown.totalNights += currentRange.nights;
                                        priceBreakdown.totalPrice += currentRange.totalPrice;
                                    }
                                });

                                var lastPos = priceBreakdown.ranges.length - 1;
                                if (!priceBreakdown.ranges[lastPos].length) {
                                    priceBreakdown.ranges.splice(lastPos);
                                }
                            }

                            //
                            self.serverQuery = 'checkIn=' + checkInDate + '&checkOut=' + checkOutDate;
                            self.queryObj = {
                                target: self,
                                query: self.serverQuery,
                                checkInQuery: checkInDateParam,
                                checkOutQuery: checkOutDateParam,
                                checkInQueryLong: checkInDate,
                                checkOutQueryLong: checkOutDate,
                                results: checkInDate,
                                results2: checkOutDate,
                                resultsObj: {
                                    checkInDate: moment(self.multidayArray[0].startDate),
                                    checkOutDate: moment(self.multidayArray[0].endDate),
                                    priceBreakdown: priceBreakdown,
                                }
                            };

                            console.log('CalendarMenuView serverQuery', self.serverQuery);
                            console.log(self);

                            self.trigger('optionSelected', self.queryObj);
                            window.$vent.trigger('dateSelectedInMobile', [{datesSelected:self.queryObj},self]);

                            window.$vent.trigger('calendarQuery', self.queryObj);
                            console.log('calendar event: calendarQuery', self.queryObj);

                            if (keeptheWindowOpen) //don't close the calendar
                                return;

                            if (self.$leftCalendarContainer.parents('.shortlet-calendar-holder').length > 0) {
                                var zacalendars = self.$leftCalendarContainer.parents('.shortlet-calendar-holder');
                                zacalendars.find('.filter-options .filter-option.check-out').trigger('click');
                            }
                            return;
                        }

                        self.isMonthChange = false;
                    }
                },
                animateCalendarEvents: function () {

                    var self = this;

                    if (!self.isReadOnly) {

                        // animate left calendar

                        var $removeLeftDays = self.$leftCalendarContainer.find('.day.animated').not('.event');

                        _.each($removeLeftDays, function (eventDay, i) {
                            self.animateDayOut($(eventDay), i);
                        });

                        var $leftEventDays = self.$leftCalendarContainer.find('.day.event').not('.animated');

                        _.each($leftEventDays, function (eventDay, i) {
                            self.animateDayIn($(eventDay), i);
                        });

                        // animate right calendar

                        var $removedRightDays = self.$rightCalendarContainer.find('.day.animated').not('.event');

                        _.each($removedRightDays, function (eventDay, i) {
                            self.animateDayOut($(eventDay), i);
                        });

                        var $rightEventDays = self.$rightCalendarContainer.find('.day.event').not('.animated');

                        _.each($rightEventDays, function (eventDay, i) {
                            self.animateDayIn($(eventDay), i);
                        });

                        // mark last day in calendar

                        if (self.multidayArray && self.multidayArray.length && self.multidayArray[0].endDate) {

                            var $lastDay = self.$rightCalendarContainer.find('.calendar-day-' + moment(self.multidayArray[0].endDate).format('YYYY-MM-DD'));
                            var $prevLastDay = self.$rightCalendarContainer.find('.last');

                            if ($prevLastDay[0] != $lastDay[0]) {

                                $prevLastDay.removeClass('last');
                                TweenMax.to($prevLastDay.find('.last-bg'), 0.3, {opacity: 0, ease: Cubic.easeOut});

                                if ($lastDay.length) {

                                    $lastDay.removeClass('last').addClass('last');

                                    var d = 0.01 * $rightEventDays.length;

                                    TweenMax.set($lastDay.find('.last-bg'), {transformOrigin: '50% 100%'});
                                    TweenMax.fromTo($lastDay.find('.last-bg'), 0.3, {opacity: 0}, {opacity: 1, delay: d, ease: Cubic.easeOut});

                                    if (!self.isMonthChange) {
                                        TweenMax.fromTo($lastDay.find('.last-bg'), 0.3, {scaleY: 0}, {scaleY: 1, delay: d, ease: Sine.easeIn});
                                        TweenMax.to($lastDay.find('.last-bg'), 0.3, {scaleY: 1, delay: d + 0.1, ease: Quint.easeOut});
                                    }
                                }
                            }
                        }
                    }
                },
                sendQueryObj: function () {

                    var self = this;
                    window.$vent.trigger('calendarQuery', self.queryObj);
                },
                setDatesStyles: function(dates, $leftDaysContainer, $rightDaysContainer, options) {
                    var self = this;
                    var dayStyle = options.dayStyle;

                    for (var i = 0; i < dates.length; i++) {

                        var dateRange = dates[i];

                        self.traverseDateRange(dateRange, function (date, formattedDate) {
                            self.setDayClasses($leftDaysContainer.find('.calendar-day-' + formattedDate), dayStyle);
                            self.setDayClasses($rightDaysContainer.find('.calendar-day-' + formattedDate), dayStyle);
                        });
                     }
                },
                setDayClasses: function (occupiedDay, type) {

                    var self = this;
                    occupiedDay.removeClass(type).addClass(type);
                    occupiedDay.removeClass('disabled').addClass('disabled');

                    // TODO should be in translations
                    var reserveTitle = self.lang == 'es' ? 'Reserva' : 'Booked'
                    var preReserveTitle = self.lang == 'es' ? 'Pre-Reserva' : 'On Hold'
                    var blockedTitle = self.lang == 'es' ? 'Bloqueo' : 'Blocked'
                    var maintenanceTitle = self.lang == 'es' ? 'Mantenimiento' : 'Maintenance'
                    var otherTitle = self.lang == 'es' ? 'Otro' : 'Other'

                    switch(type) {
                        case self.dayStyles.occupied:
                            occupiedDay.data('title', reserveTitle);
                            break;
                        case self.dayStyles.onHold:
                            occupiedDay.data('title', preReserveTitle);
                            break;
                        case self.dayStyles.owner:
                            occupiedDay.data('title', blockedTitle);
                            break;
                        case self.dayStyles.maintenance:
                            occupiedDay.data('title', maintenanceTitle);
                            break;
                        case self.dayStyles.other:
                            occupiedDay.data('title', otherTitle);
                            break;
                    }
                },
                traverseDateRange: function (dateRange, callback) {

                    var self = this;
                    var startDate = dateRange['startDate'];
                    var endDate = dateRange['endDate'];

                    if (_.isString(startDate)) {
                        startDate = moment(self.formatDate(startDate));
                    }
                    if (_.isString(endDate)) {
                        endDate = moment(self.formatDate(endDate));
                    }

                    var startDateTime = startDate.format('x');
                    var endDateTime = endDate.format('x');

                    var currentDate = moment(startDate);
                    var currentDateTime = startDate.format('x');

                    while (currentDateTime <= endDateTime) {

                        callback.apply(null, [moment(currentDate), currentDate.format('YYYY-MM-DD'), dateRange]);

                        currentDate.add(1, 'day');
                        currentDateTime = currentDate.format('x');
                    }
                },
                // enable / disable calendars

                disableLeftCalendar: function () {

                    var self = this;
                    if (self.$leftCalendarContainer && self.$leftCalendarContainer.length) {
                        self.$leftCalendarContainer.removeClass('disabled').addClass('disabled');
                    }
                },
                enableLeftCalendar: function () {

                    var self = this;
                    if (self.$leftCalendarContainer && self.$leftCalendarContainer.length) {
                        self.$leftCalendarContainer.removeClass('disabled');
                    }
                },
                disableRightCalendar: function () {

                    var self = this;
                    if (self.$rightCalendarContainer && self.$rightCalendarContainer.length) {
                        self.$rightCalendarContainer.removeClass('disabled').addClass('disabled');
                    }
                },
                enableRightCalendar: function () {

                    var self = this;
                    if (self.$rightCalendarContainer && self.$rightCalendarContainer.length) {
                        self.$rightCalendarContainer.removeClass('disabled');
                    }
                },
                // config availability data --------------------------------------------------------  /

                _onSetAvailabilityData: function (e, obj) {

                    var self = this;
                    self.setAvailabilityData(obj);
                },
                setAvailabilityData: function (json) {

                    var self = this;
                    self.configureAvailabilityData(json);

                    if (self.isEditBlock) {

                        var dateRange = self.availabilityData.ownerOccupiedDates[self.selectedBlockIndex];
                        var startDate = moment(dateRange['startDate']);
                        var endDate = moment(dateRange['endDate']);

                        // set event

                        if (!self.multidayArray.length) {
                            self.multidayArray.push({});
                        }

                        self.multidayArray[0].startDate = startDate;
                        self.multidayArray[0].endDate = endDate;

	                    console.log('setAvailabilityData isdateSelected true');
                        self.isDateSelected = true;

                        // if calendars are on-screen, move months

                        if (self.leftCalendar && self.rightCalendar) {
                            self.leftCalendar.setMonth(startDate.format('MM') - 1);
                            self.rightCalendar.setMonth(endDate.format('MM') - 1);
                        }
                    }

                    self.enableRightCalendar();
                    self.reRenderCalendars();
                },
                configureAvailabilityData: function (availabilityData) {

                    var self = this;

                    if (availabilityData) {

                        self.occupiedBlocks = [];
                        self.availabilityData = self.formatAvailabilityData(availabilityData);

                        if (self.availabilityData.bookedOccupiedDates) {
                            self.occupiedBlocks = self.occupiedBlocks.concat(self.availabilityData.bookedOccupiedDates);
                        }
                        if (self.availabilityData.holdOccupiedDates) {
                            self.occupiedBlocks = self.occupiedBlocks.concat(self.availabilityData.holdOccupiedDates);
                        }
                        if (self.availabilityData.ownerOccupiedDates) {
                            self.occupiedBlocks = self.occupiedBlocks.concat(self.availabilityData.ownerOccupiedDates);
                        }

                        self.occupiedBlocks.sort(
                                function (a, b) {

                                    var aStartTime = moment(a.startDate).format('x');
                                    var aEndTime = moment(a.endDate).format('x');
                                    var bStartTime = moment(b.startDate).format('x');
                                    var bEndTime = moment(b.endDate).format('x');

                                    if (aStartTime > bEndTime) {
                                        return 1;
                                    }
                                    if (aEndTime < bStartTime) {
                                        return -1;
                                    }

                                    return 0;
                                }
                        );

                        if (!self.minNights) {
                            self.minNights = _.isNumber(self.availabilityData.minNightsStay) ? parseInt(self.availabilityData.minNightsStay) : 30;
                        }
                        self.maxNights = _.isNumber(self.availabilityData.maxNightsStay) ? parseInt(self.availabilityData.maxNightsStay) : 5000;

                    }
                },
                formatAvailabilityData: function (availabilityData) {

                    var self = this;

                    if (availabilityData) {

                        _.each(availabilityData, function (obj) {

                            if (_.isArray(obj)) {
                                for (var i = 0; i < obj.length; i++) {
                                    var arrayItem = obj[i];
                                    for (var prop in arrayItem) {
                                        if (prop === 'startDate' || prop === 'endDate') {
                                            arrayItem[prop] = self.formatDate(arrayItem[prop]);
                                        }
                                    }
                                }
                            }

                        });
                    }

                    return availabilityData;
                },
                formatDate: function (dateString) {

                    var self = this;

                    if (dateString && _.isString(dateString)) {

                        var split = dateString.split('-');

                        for (var i = 0; i < split.length; i++) {
                            if (split[i].length < 2) {
                                split[i] = '0' + split[i];
                            }
                        }

                        return split.join('-');
                    }

                    return dateString;
                },
                // calculate next occupied date  ---------------------------------------------------  /

                getNextOccupiedDate: function (dateTime, minDate) {

                    var self = this;

                    if (self.availabilityData) {

                        minDate = minDate || self.endDate;

                        minDate = self.getMinRangeTime(self.availabilityData.bookedOccupiedDates, dateTime, minDate);
                        minDate = self.getMinRangeTime(self.availabilityData.holdOccupiedDates, dateTime, minDate);
                        minDate = self.getMinRangeTime(self.availabilityData.ownerOccupiedDates, dateTime, minDate);


                        return minDate;
                    }

                    return false;
                },
                getMinRangeTime: function (rangeObj, dateTime, minDate) {

                    var self = this;
                    var minDateTime = minDate ? minDate.format('x') : false;

                    for (var i = 0; i < rangeObj.length; i++) {

                        var isSelectedBlock = (self.isOwnerListing && rangeObj === self.availabilityData.ownerOccupiedDates && i === self.selectedBlockIndex);

                        if (!isSelectedBlock) {

                            var dateRange = rangeObj[i];
                            var startDate = moment(dateRange['startDate']);
                            var rangeStartTime = startDate.format('x');

                            if (rangeStartTime > dateTime && (rangeStartTime < minDateTime || !minDateTime)) {
                                minDate = startDate;
                                minDateTime = dateTime;
                            }
                        }
                    }

                    return minDate;
                },
                getPrevOccupiedDate: function (dateTime, maxDate) {

                    var self = this;

                    if (self.availabilityData) {

                        maxDate = maxDate || self.startDate;

                        maxDate = self.getMaxRangeTime(self.availabilityData.bookedOccupiedDates, dateTime, maxDate);
                        maxDate = self.getMaxRangeTime(self.availabilityData.holdOccupiedDates, dateTime, maxDate);
                        maxDate = self.getMaxRangeTime(self.availabilityData.ownerOccupiedDates, dateTime, maxDate);

                        return maxDate;
                    }

                    return false;
                },
                getMaxRangeTime: function (rangeObj, dateTime, maxDate) {

                    var self = this;
                    var maxDateTime = maxDate ? maxDate.format('x') : false;

                    for (var i = 0; i < rangeObj.length; i++) {

                        var isSelectedBlock = (self.isOwnerListing && rangeObj === self.availabilityData.ownerOccupiedDates && i === self.selectedBlockIndex);

                        if (!isSelectedBlock) {

                            var dateRange = rangeObj[i];
                            var endDate = moment(dateRange['endDate']);
                            var rangeEndTime = endDate.format('x');

                            if (rangeEndTime < dateTime && (rangeEndTime > maxDateTime || !maxDateTime)) {
                                maxDate = endDate;
                                maxDateTime = dateTime;
                            }
                        }
                    }

                    return maxDate;
                },
                isAvailableDate: function (dateTime) {

                    var self = this;

                    var isInBooked = self.isWithinDateRange(self.availabilityData.bookedOccupiedDates, dateTime);
                    var isInHold = self.isWithinDateRange(self.availabilityData.holdOccupiedDates, dateTime);
                    var isInOwner = self.isWithinDateRange(self.availabilityData.ownerOccupiedDates, dateTime);

                    return !(isInBooked || isInHold || isInOwner);
                },
                isWithinDateRange: function (rangeObj, dateTime) {

                    var self = this;

                    for (var i = 0; i < rangeObj.length; i++) {

                        var dateRange = rangeObj[i];
                        var startDateTime = moment(dateRange['startDate']).format('x');
                        var endDateTime = moment(dateRange['endDate']).format('x');

                        if (dateTime >= startDateTime && dateTime <= endDateTime) {
                            return true;
                        }
                    }

                    return false;
                },
                getSelectedOwnersBlock: function () {
                    console.log("ENTRA POR EL GETSELECTEDOWNERS");
                    var self = this;
                    var blockIndex = self.selectedBlockIndex || 0;

                    var selectedOwnersBlock = self.availabilityData.ownerOccupiedDates[blockIndex];

                    var startDate = moment(selectedOwnersBlock.startDate);
                    var endDate = moment(selectedOwnersBlock.endDate);

                    return {startDate: startDate, endDate: endDate};
                },
                getFirstAvailableDate: function () {
                    console.log("ENTRA EN EL GET FIRSTAVAILABILITY");
                    var self = this;
                    var startDate = moment(moment().format('YYYY-MM-DD')).add(1, 'days'); // round day down
                    var startDate = "";
                    var endDate = "";

                    if (self.multidayArray && self.multidayArray.length) {
                        console.log("EXISTE EL MULTIARRAY EN EL getFirstAvailableDate");
                        startDate = moment(self.multidayArray[0].startDate);
                        endDate = moment(self.multidayArray[0].endDate);
                    } else {
                        console.log("NO EXISTE EL MULTIARRAY EN EL getFirstAvailableDate");
                        startDate = moment(moment().format('YYYY-MM-DD')).add(1, 'days'); // round day down
                        endDate = moment(startDate).add(self.minNights, 'days');
                    }

                    return {startDate: startDate, endDate: moment(startDate).add(self.minNights, 'days')};
                },
                // day even listeners
                _onDayOver: function (e) {


                    var self = this;
                    var $target = $(e.currentTarget);

                    if(self.ownerBlockCalendar && (self.ownerBlockCalendarHideOldPrices && $target.hasClass('past'))) {
                        return;
                    }

                    if ((self.ownerBlockCalendar || !$target.hasClass('disabled')) && !$target.hasClass('inactive') && self.hasDayPrices) {
                        if (self.ownerBlockCalendar) {
                            self.showToolTip($target, $target.data('price') + $target.data('priceUnit'), 'black', 100);
                        } else {
                            self.showToolTip($target, self.availabilityData.currencySymbol + $target.data('price'), 'black', 56);
                        }
                    }
                },
                _onDayOut: function (e) {

                    var self = this;
                    var $target = $(e.currentTarget);

                    self.removeToolTip();
                },
                // initial intro
                onShow: function () {

                    var self = this;

                    if (self.isFirstShow) {
                        self.animateCalendarEvents();
                        self.isFirstShow = false;
                    }

                    if (self.$clearDatesButton && self.$clearDatesButton[0]) {
                        TweenMax.set(self.$clearDatesButton.find('h3'), {opacity: 0});
                    }

                    if (self.isDateSelected) {
                        self.completeInstruction();
                    } else {
                        self.resetClearButton();
                    }

                    if (window.innerWidth < 768) {

                        window.requestAnimationFrame(function () {

                            self._onWindowResize();

                            if (!self.options.isEmbedded) {
                                window.$vent.trigger('lockScrollZone', {$target: self.$el});
                            }

                            self.trigger('showComplete');
                        });
                    } else {

                        TweenMax.fromTo(self.$el, 0.5, {opacity: 0, y: 0}, {opacity: 1, y: 0, ease: Cubic.easeOut, onComplete: function () {
                                self.trigger('showComplete');
                            }});
                    }
                },
                onHide: function () {

                    var self = this;

                    if (!self.isDateSelected) {
                        self.clearDates();
                    }

                    if (window.innerWidth < 768) {

                        if (!self.options.isEmbedded) {
                            window.$vent.trigger('restoreScrolling', {$target: self.$el, hideMobileWrapper: self.options.hideMobileWrapper});
                        }

                        self.trigger('hideComplete');
                    } else {

                        TweenMax.to(self.$el, 0.3, {opacity: 0, y: 0, ease: Cubic.easeOut, onComplete: function () {
                                self.trigger('hideComplete');
                            }});
                    }
                },
                _onScroll: function (e) {

                    var self = this;

                    if (self.abstractView.isShowing) {
                        e.preventDefault();
                        e.stopPropagation();
                        return false;
                    }
                },
                // window resizing

                _onWindowResize: function (e) {

                    var self = this;
                    var docHeight = $(document).height();
                    self.winWidth = window.innerWidth;
                    self.winHeight = window.innerHeight;

                    if (self.winWidth < 768 && !self.options.isEmbedded && self.$mobileWrap) {
                        TweenMax.set(self.$el, {'min-height': Math.max(self.winHeight, self.$el.find('.inner-container').height())});

                        if (self.$container[0] !== self.$mobileWrap[0]) {
                            self.$mobileWrap.append(self.$el);
                            self.$container[0] = self.$mobileWrap[0];
                            self.$desktopWrap.html('');

                            if (self.abstractView.isShowing) {
                                self.$el.removeAttr('style');
                                window.$vent.trigger('lockScrollZone', {$target: self.$el});
                            }
                            ;
                        }
                    } else {
                        TweenMax.set(self.$el, {clearProps: 'min-height'});

                        if (self.$desktopWrap && self.$container[0] !== self.$desktopWrap[0]) {
                            self.$desktopWrap.append(self.$el);
                            self.$container[0] = self.$desktopWrap[0];
                            self.$mobileWrap.html('');

                            if (self.abstractView.isShowing) {
                                window.$vent.trigger('restoreScrolling', {$target: self.$el});
                                self.$desktopWrap.removeClass('hidden');
                            }
                        }
                    }
                },
                loadDisabledDates: function () {

                    var self = this;
                    var idBo = self.propertyId ? self.propertyId : ($('.idBo')).text();

                    console.log('self.ownerBlockCalendar');

                    var url = "/shortlet/getDisabledDatesShort?id=" + idBo;
                    if (self.ownerBlockCalendar && self.ownerBlockCalendar.length) {
                        url =  "/shortlet/getOwnPropertyBlocks?id=" + idBo;
                    }

                    $.ajax({
                        type: 'GET',
                        url: url,
                        async: false,
                        jsonpCallback: 'callBack',
                        contentType: 'application/json',
                        dataType: 'json',
                        success: function (json) {
                            self.model = json;
                            self.minNights = json.minNightsStay ? json.minNightsStay : 30;
                            console.log("JSON en la funcion", json);
                        },
                        error: function (e) {
                            console.log('JSON Load Error en el Calendar View', self);
                            console.log(e.message);
                            //self._onPropertyConfigFail(e);
                        }
                    });
                }

            });

            return CalendarMenuView;
        });
