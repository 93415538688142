define(
	'modules/global-modals/views/GenericModalView',[
		'backbone',
		'utils/AbstractView'
	], 
	function(Backbone, AbstractView){

		var GenericModalView = Backbone.View.extend({

			// core vars

			$el: null,
			$container: null,
			$innerContainer: null,

			options: null,
			abstractView: null,

			$inputLabels: null,

			// vars

			initialScrollTop: 0,
			isLongModal: false,
			isDomUpdate: false,

			// init

			initialize: function(options){

				var self = this;

				self.options = options;
				self.$container = self.options.$container;
				self.el = self.options.el;

				self.template = _.template(self.options.template);
				self.model = $.extend({
					
				}, self.model);

				//

				self.isLongModal = self.model.longModal;

				// 

				self.abstractView = new AbstractView({
					view: self,
					autoShow: self.options.autoShow === true,
				});
			},

			onRender: function(){

				var self = this;
				self.$innerContainer = self.$el.find('>.inner-container');
				self.initialScrollTop = $(window).scrollTop();

				self.$inputLabels = self.$el.find('label');

				window.$rootContext(self.$el.find('select')).selectBoxIt({
					isMobile: function () {
						return false;
					},
					autoWidth: false
				});
				self._onWindowResize();		
			},

			onAddListeners: function(){

				var self = this;

				self.$el.find('.close-modal').on('click', $.proxy(self._onCloseClick, self));
				self.$el.find('.request-val-send').on('click', $.proxy(self._onSendRequestVal, self));
				self.$el.find('.cierre-modal').on('click', $.proxy(self._onCloseClick, self));
                                self.$el.find('.request-val-send').on('click', $.proxy(self._onCloseClick, self));
				self.$el.find('.btn-close-modal').on('click', $.proxy(self._onCloseClick, self));
				self.$el.find('.btn-close').on('click', $.proxy(self._onCloseClick, self));

				//there's no need for proxy on this event
				self.$inputLabels.on('click', self._onLabelClick);
				self.veil = self.$el.find('.modal-veil');
				self.veil.off('click', $.proxy(self._onOutClick, self));
				self.veil.on('click', $.proxy(self._onOutClick, self));
				$(window).on('resize', $.proxy(self._onWindowResize, self));
				window.$vent.on('domUpdate', $.proxy(self._onDomUpdate, self));	
				
				window.requestAnimationFrame(function(){
					self._onWindowResize();
				});

				window.$vent.on('mobileCCReady', $.proxy(self._validateCCmob, self));
			},

			onRemoveListeners: function(){

				var self = this;

				$(document).off('click', $.proxy(self._onOutClick, self));
				$(window).off('resize', $.proxy(self._onWindowResize, self));
				self.$inputLabels.off('click', self._onLabelClick);
			},

			// listener methods
                        
                        _onSendRequestVal:function(e){
                            
                            var form = $("#pop_up_valuation");
                            var data = {};
                            var formData = form.serializeArray();
                            
                            $.each(formData, function() {
                                if (formData[this.name]) {
                                    if (!data[this.name].push) {
                                        data[this.name] = [data[this.name]];
                                    }
                                    data[this.name].push(this.value || '');
                                } else {
                                    data[this.name] = this.value || '';
                                }
                            });
                                 
                            //data = JSON.stringify(data);
                            //alert(JSON.stringify(data));
                                
                            $.ajax({
                                url:'https://owners.homeselect.com/communication/sendContact',
                                type:'POST',
                                dataType : "json",
                                contentType: "application/json",
                                data : JSON.stringify(data),
                                success:function () {
                                    console.log("ajax success");
                                    $(".contactus-modal").append(" hide");
                                       
                                }
                                                                  
                            });
				
                            //alert();
                                
                            return false;
                       
                        
                            var self = this;
                            self.closeModal();

                            e.preventDefault();
            
                        },

			_validateCCmob: function(e){
				var self = this;
				var ccfield = $('input[name="ccnumber_mob"]');

				console.log('triggering');
				
				var creditCardValidator = {};
				// Pin the cards to them
				creditCardValidator.cards = {
					'mc':'5[1-5][0-9]{14}',
					'ec':'5[1-5][0-9]{14}',
					'vi':'4(?:[0-9]{12}|[0-9]{15})',
					'ax':'3[47][0-9]{13}',
					'dc':'3(?:0[0-5][0-9]{11}|[68][0-9]{12})',
					'bl':'3(?:0[0-5][0-9]{11}|[68][0-9]{12})',
					'di':'6011[0-9]{12}',
					'jcb':'(?:3[0-9]{15}|(2131|1800)[0-9]{11})',
					'er':'2(?:014|149)[0-9]{11}'
				};
				// Add the card validator to them
				creditCardValidator.validate = function(value,ccType) {
					value = String(value).replace(/[- ]/g,''); //ignore dashes and whitespaces

					var cardinfo = creditCardValidator.cards, results = [];
					if(ccType){
						var expr = '^' + cardinfo[ccType.toLowerCase()] + '$';
						return expr ? !!value.match(expr) : false; // boolean
					}

					for(var p in cardinfo){
						if(value.match('^' + cardinfo[p] + '$')){
							results.push(p);
						}
					}
					return results.length ? results.join('|') : false; // String | boolean
				};

				if(!creditCardValidator.validate(ccfield.val())) {
					ccfield.parent('div').find('.bad-format-text').remove();
					ccfield.parent('div').addClass('bad-format').append('<span class="bad-format-text">Invalid #</span>');
				} else {
					ccfield.parent('div').removeClass('bad-format').find('.bad-format-text').remove();
				}
			},

			_onOutClick: function(e){
				var self = this;

				if(window.innerWidth > 768 && e){
					
				/*	if(!self.$el[0].contains(e.target) && $('body')[0].contains(e.target)){
						self.closeModal();
					}*/
					self.closeModal();
				}

				e.preventDefault();
			},

			_onCloseClick: function(e){

				var self = this;
				self.closeModal();

				e.preventDefault();
			},

			_onLabelClick: function () {

				console.log('_onLabelClick');
				var theInput = $(this).find('input');
				theInput.prop('checked', true);				
			},

			// close modal

			closeModal: function(){

				var self = this;

				//if(self.isLongModal){ window.$vent.trigger('seekToPosition', {scrollY:0}); }

				self.trigger('closeClick');
			},

			// show / hide

			onShow: function(){

				var self = this;
				
				if(window.innerWidth < 768){

					self.trigger('showComplete');
					window.$vent.trigger('lockScrollZone', {$target:self.$el});
					window.$vent.trigger('updateScrollZone');
				}
				else {

					TweenMax.fromTo(self.$innerContainer, 0.6, {y:10, opacity:0}, {y:0, opacity:1, delay:0.12, ease:Cubic.easeOut, onComplete:function(){
						self.trigger('showComplete');
					}});
				}

				var $closeButton = self.$el.find('.close-modal');
				TweenMax.fromTo($closeButton, 0.6, {opacity:0}, {opacity:1, delay:0.4, ease:Cubic.easeOut});
			},

			onHide: function(){

				var self = this;

				if(window.innerWidth < 768){

					window.$vent.trigger('restoreScrolling', {$target:self.$el});
					self.trigger('hideComplete');
				}
				else {

					TweenMax.to(self.$el, 0.4, {opacity:0, ease:Cubic.easeOut, onComplete:function(){
						TweenMax.set($('body'), {'clearProps' : 'min-height'});
						self.trigger('hideComplete');
					}});
				}
			},

			// window resize

			_onDomUpdate: function(){

				var self = this;
				self.isDomUpdate = true;

				TweenMax.set(self.$innerContainer, {clearProps: 'min-height'});

				window.requestAnimationFrame(function(){
					self.isDomUpdate = false;
					self._onWindowResize();
				});
			},

			_onWindowResize: function(){

				var self = this;
				var modalHeight = self.$innerContainer.outerHeight();
				var winWidth = window.innerWidth;
				var winHeight = window.innerHeight;
				var modalContainerHeight = Math.max(winHeight, modalHeight);
				var minHeight = 'initial';
				
				if(self.$el.hasClass('mobile-only') && winWidth >= 768){
					self.closeModal();
				}

				if(!self.isDomUpdate){

					if(self.isLongModal || self.$el.hasClass('full-height')){
						modalHeight = Math.max(modalHeight, winHeight);
						minHeight = modalHeight + 'px';
					}

					if(winWidth < 768 && self.$el.hasClass('full-height-mobile')){
						modalHeight = Math.max(modalHeight, winHeight);
						minHeight = modalHeight + 'px';
					}

					TweenMax.set(self.$innerContainer, {'min-height': minHeight});

					window.requestAnimationFrame(function(){
						
						var topY = 0;

						modalHeight = self.$innerContainer.outerHeight();
						if(minHeight != 'initial'){ modalHeight = Math.max(modalHeight, winHeight); }

						TweenMax.set(self.$el, {'min-height': modalHeight});

						if(winWidth < 768){}
						else {

							if(modalHeight > winHeight){ 

								TweenMax.set(self.$el, {clearProps:'position'});
								//topY = Math.round((modalContainerHeight - modalHeight)/2) + self.initialScrollTop + 80;
								topY = Math.round((modalContainerHeight - modalHeight)/2) + self.initialScrollTop;
								TweenMax.set(self.$el, {top:topY});
							}
							else { 

								if(modalHeight == 0) {
									modalHeight = self.$innerContainer.find('.the-modal').outerHeight();
									topY = Math.round(modalHeight/2) + self.initialScrollTop + 80;
									//topY = Math.round((winHeight - modalHeight)/2) + self.initialScrollTop;
								}
								else {
									topY = Math.round((winHeight - modalHeight)/2) + self.initialScrollTop + 80;
								}

								TweenMax.set(self.$el, {top: topY});

							}

							var minScrollHeight = parseInt(minHeight) + topY;

							if($('body').height() < parseInt(minScrollHeight)){
								TweenMax.set($('body'), {'min-height':minScrollHeight + topY});
							}
						}

						window.$vent.trigger('updateScrollZone');
					});
					window.$vent.trigger('updateScrollZone');
				}
			},

		});

	return GenericModalView;
});
