define(
	'modules/global-scripted-buttons/app',[
		'backbone',
		'modules/global-scripted-buttons/views/AnchorButtonView',
		'modules/global-scripted-buttons/views/AVReqItemButtonView',
		'modules/global-scripted-buttons/views/ClassTogglerButtonView',
		'modules/global-scripted-buttons/views/CommentsFieldButtonView',
		'modules/global-scripted-buttons/views/EventTriggerButtonView',
		'modules/global-scripted-buttons/views/FavoriteButtonView',
		'modules/global-scripted-buttons/views/FocusButtonView',
		'modules/global-scripted-buttons/views/HideDetailsButtonView',
		'modules/global-scripted-buttons/views/HideElementButtonView',		
		'modules/global-scripted-buttons/views/TitleSwapButtonView',
		'modules/global-scripted-buttons/views/TooltipButtonView',
		'modules/global-scripted-buttons/views/SelectableButtonView',
		'modules/global-scripted-buttons/views/MorePhotosButtonView',
		'modules/global-scripted-buttons/views/VentTriggerButtonView',
		'modules/global-scripted-buttons/views/ModalTriggerButtonView',
		'modules/global-scripted-buttons/views/SwapButtonView',
		'modules/global-scripted-buttons/views/ShowContentsButtonView',		
		'modules/global-scripted-buttons/views/UpgradeButtonView',
		'modules/global-scripted-buttons/views/REMShowPhotos',
	], 
	function(Backbone, AnchorButtonView, AVReqItemButtonView, ClassTogglerButtonView, CommentsFieldButtonView,
		EventTriggerButtonView, FavoriteButtonView, FocusButtonView, HideDetailsButtonView, HideElementButtonView, 
		TitleSwapButtonView, TooltipButtonView, SelectableButtonView, MorePhotosButtonView, VentTriggerButtonView, 
		ModalTriggerButtonView, SwapButtonView, ShowContentsButtonView, UpgradeButtonView, REMShowPhotos){

		var AppView = Backbone.View.extend({

			// core vars

			buttons: null,

			// init

			initialize: function(options){

				var self = this;
				window.$vent.trigger('globalFancyButtonsReady');
			},

			// start module --------------------------------------------------------------------  /

			start: function(){

				var self = this;
				window.$vent.on('domUpdate', $.proxy(self._onDomUpdate, self));
				
				$(function(){
					self.onDomReady();
				});
			},

			onDomReady: function(){

				var self = this;
				self.buttons = [];
				self._onDomUpdate();
			},

			_onDomUpdate: function(){

				var self = this;
				self.refreshButtons();
			},

			refreshButtons: function(){

				var self = this;

				// comments field replacement

				_.each(self.$el.find('.btn-anchor-link'), function(button, i){
					
					if(!self.checkExisting(button, 'AnchorButtonView')){
						var $button = $(button);
						self.buttons.push({
							type: 'AnchorButtonView',
							btn: new AnchorButtonView({el:$button})
						});
					}
				});

				// comments field replacement

				_.each(self.$el.find('.av-req-item'), function(button, i){
					
					if(!self.checkExisting(button, 'AVReqItemButtonView')){
						var $button = $(button);
						self.buttons.push({
							type: 'AVReqItemButtonView',
							btn: new AVReqItemButtonView({el:$button})
						});
					}
				});

				// class toggling button

				_.each(self.$el.find('.btn-toggle-class'), function(button, i){
					
					if(!self.checkExisting(button, 'ClassTogglerButtonView')){
						var $button = $(button);
						self.buttons.push({
							type: 'ClassTogglerButtonView',
							btn: new ClassTogglerButtonView({el:$button})
						});
					}
				});

				// comments field replacement

				_.each(self.$el.find('.btn-comments-field'), function(button, i){
					
					if(!self.checkExisting(button, 'CommentsFieldButtonView')){
						var $button = $(button);
						self.buttons.push({
							type: 'CommentsFieldButtonView',
							btn: new CommentsFieldButtonView({el:$button})
						});
					}
				});

				// comments field replacement

				_.each(self.$el.find('.btn-event-trigger'), function(button, i){
					
					if(!self.checkExisting(button, 'EventTriggerButtonView')){
						var $button = $(button);
						self.buttons.push({
							type: 'EventTriggerButtonView',
							btn: new EventTriggerButtonView({el:$button})
						});
					}
				});

				// favorites buttons

				_.each(self.$el.find('.btn-add-to-favorites'), function(button, i){
					
					if(!self.checkExisting(button, 'FavoriteButtonView')){
						var $button = $(button);
						self.buttons.push({
							type: 'FavoriteButtonView',
							btn: new FavoriteButtonView({el:$button})
						});
					}
				});

				// favorites buttons

				_.each(self.$el.find('.btn-focus'), function(button, i){
					
					if(!self.checkExisting(button, 'FocusButtonView')){
						var $button = $(button);
						self.buttons.push({
							type: 'FocusButtonView',
							btn: new FocusButtonView({el:$button})
						});
					}
				});

				// favorites buttons

				_.each(self.$el.find('.btn-hide-details'), function(button, i){
					
					if(!self.checkExisting(button, 'HideDetailsButtonView')){
						var $button = $(button);
						self.buttons.push({
							type: 'HideDetailsButtonView',
							btn: new HideDetailsButtonView({el:$button})
						});
					}
				});

				// favorites buttons

				_.each(self.$el.find('.btn-hide-element'), function(button, i){
					
					if(!self.checkExisting(button, 'HideElementButtonView')){
						var $button = $(button);
						self.buttons.push({
							type: 'HideElementButtonView',
							btn: new HideElementButtonView({el:$button})
						});
					}
				});

				// title swap buttons buttons

				_.each(self.$el.find('.btn-title-swap'), function(button, i){
					
					if(!self.checkExisting(button, 'TitleSwapButtonView')){
						var $button = $(button);
						self.buttons.push({
							type: 'TitleSwapButtonView',
							btn: new TitleSwapButtonView({el:$button})
						});
					}
				});

				// tooltips

				_.each(self.$el.find('.btn-tooltip'), function(button, i){
					
					if(!self.checkExisting(button, 'TooltipButtonView')){
						
						var $button = $(button);

						self.buttons.push({
							type: 'TooltipButtonView',
							btn: new TooltipButtonView({
								el: $button,
								model: {
									text: $button.data('tooltip'),
									customClass: $button.data('tooltip-class')
								}
							})
						});
					}
				});

				// selectable buttons

				_.each(self.$el.find('.btn-selectable'), function(button, i){
					
					if(!self.checkExisting(button, 'SelectableButtonView')){						
						var $button = $(button);
						self.buttons.push({
							type: 'SelectableButtonView',
							btn: new SelectableButtonView({el: $button})
						});
					}
				});

				// more photos

				_.each(self.$el.find('.btn-more-photos'), function(button, i){
					
					if(!self.checkExisting(button, 'MorePhotosButtonView')){						
						var $button = $(button);
						self.buttons.push({
							type: 'MorePhotosButtonView',
							btn: new MorePhotosButtonView({el: $button})
						});
					}
				});

				// vent trigger button

				_.each(self.$el.find('.btn-vent-trigger'), function(button, i){
					
					if(!self.checkExisting(button, 'VentTriggerButtonView')){						
						var $button = $(button);
						self.buttons.push({
							type: 'VentTriggerButtonView',
							btn: new VentTriggerButtonView({el: $button})
						});
					}
				});

				// vent trigger button

				_.each(self.$el.find('.btn-modal-trigger'), function(button, i){

					if(!self.checkExisting(button, 'ModalTriggerButtonView')){						
						var $button = $(button);
						self.buttons.push({
							type: 'ModalTriggerButtonView',
							btn: new ModalTriggerButtonView({el: $button})
						});
					}
				});

				// swap button

				_.each(self.$el.find('.btn-swap'), function(button, i){
					
					if(!self.checkExisting(button, 'SwapButtonView')){						
						var $button = $(button);
						self.buttons.push({
							type: 'SwapButtonView',
							btn: new SwapButtonView({el: $button})
						});
					}
				});

				// show contents on click button

				_.each(self.$el.find('.btn-show-contents'), function(button, i){
					
					if(!self.checkExisting(button, 'ShowContentsButtonView')){						
						var $button = $(button);
						self.buttons.push({
							type: 'ShowContentsButtonView',
							btn: new ShowContentsButtonView({el: $button})
						});
					}
				});				

				// upgrade button

				_.each(self.$el.find('.btn-upgrade'), function(button, i){
					
					if(!self.checkExisting(button, 'UpgradeButtonView')){						
						var $button = $(button);
						self.buttons.push({
							type: 'UpgradeButtonView',
							btn: new UpgradeButtonView({el: $button})
						});
					}
				});

				_.each(self.$el.find('.remMorePhotos'), function(button, i){
					
					if(!self.checkExisting(button, 'REMShowPhotos')){						
						var $button = $(button);
						self.buttons.push({
							type: 'REMShowPhotos',
							btn: new REMShowPhotos({el: $button})
						});
					}
				});
			},

			// check for existing buttons ------------------------------------------------------  /

			checkExisting: function(el, type){

				var self = this;
				var exists = false;

				if(typeof(el.context) !== 'undefined'){ el = el[0]; }

				_.each(self.buttons, function(button, i){

					var buttonEl = button.btn.el;
					if(typeof(buttonEl.context) !== 'undefined'){ buttonEl = buttonEl[0]; }

					if(buttonEl === el && type === button.type){
						exists = true;
					}
				});

				return exists;
			},

			// ---------------------------------------------------------------------------------  /

		});

		return AppView;
	}
);
