
define(
	'modules/accordian/views/AccordianView',[
		'backbone',
		'utils/AbstractView',
	], 
	function(Backbone, AbstractView){

		var AccordianView = Backbone.View.extend({

			// core vars

			$el: null,
			$container: null,
			options: null,
			abstractView: null,

			$accordianItems: null,

			// init

			initialize: function(options){

				var self = this;

				self.options = options;
				self.$container = self.options.$container;
				self.el = self.options.el;

				self.model = $.extend({

				}, self.model);

				// 

				self.abstractView = new AbstractView({
					view: self,
					autoShow: true
				});
			},

			onAddListeners: function(){

				var self = this;

				self.$accordianItems = self.$el.find('.accordian-item');

				self.$accordianItems.off('click', $.proxy(self._onAccordianItemClick, self));
				self.$accordianItems.on('click', $.proxy(self._onAccordianItemClick, self));

				$(window).on('hashchange', $.proxy(self._onHashChange, self));
				self._onHashChange();
			},

			_onAccordianItemClick: function(e){

				var self = this;
				var $target = $(e.currentTarget);
				var hash = window.location.hash.substr(1);
				var anchorValue = $target.data('anchor') || '';

				if($target.hasClass('active')){

					if(hash === anchorValue){
						window.location.hash = '';
					}

					self.collapse($target);
				}
				else {

					self.expand($target);
					window.location.hash = anchorValue;
				}
			},

			_onHashChange: function(e){

				var self = this;
				var hash = window.location.hash.substr(1);

				_.each(self.$accordianItems, function(accordianItem, i){

					var $accordianItem = $(accordianItem);
					var anchorValue = $accordianItem.data('anchor') || '';

					if(anchorValue && hash && anchorValue === hash && !$accordianItem.hasClass('active')){
						
						self.expand($accordianItem);

						window.requestAnimationFrame(function(){

							var centeredScrollTop = Math.round($accordianItem.offset().top - (window.innerHeight - $accordianItem.height())/2);

							window.$vent.trigger('seekToPosition', {scrollY:centeredScrollTop});
						});
					}
				});
			},

			expand: function($target){

				var self = this;
				$target.removeClass('active').addClass('active');
			},

			collapse: function($target){

				var self = this;
				$target.removeClass('active');
			},

		});

	return AccordianView;
});
