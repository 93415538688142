define(
    'pagecontrollers/property-listing/app',[
        'backbone',
        'pagecontrollers/property-listing/views/GridItemView',
        'pagecontrollers/property-listing/views/PaginationView',
        'pagecontrollers/property-listing/views/SearchView',
        'modules/calendars/views/CalendarMenuView',
        'text!../../config/propertyListingMessages-es.json',
        'text!../../config/propertyListingMessages-en.json',
        'modules/cookies/app',
        'utils/components/app/ScrollTriggeredElement',
        'moment',
        'jqueryhammer',
        'jquerymousewheel',
        'clndr',
        'selectboxit',
        'tagging',
        'rangeslider'
    ],
    function (Backbone, GridItemView, PaginationView, SearchView, CalendarMenuView, MessagesEs, MessagesEn, Cookies) {

        var AppView = Backbone.View.extend({
            // vars

            winWidth: 0,
            winHeight: 0,
            scrollTop: 0,
            isSticky: false,
            HEADER_HEIGHT: 60,
            MAX_THUMBS_PER_PAGE: 10,
            heightOffset: 0,
            scriptsArray: null,
            scriptsLoaded: 0,
            isReady: false,
            $contentWrapper: null,
            $gridContainer: null,
            $resultsGrid: null,
            $resultsText: null,
            $noResults: null,
            gridItems: null,
            resizeTimeout: null,
            isQuerying: false,
            isPendingRefresh: false,
            paginationView: null,
            paginationIndex: 0,
            queryStartTime: 0,
            queryDelay: null,
            queryResult: null,
            filterQuery: null,
            listingsArray: null,
            scrollTriggeredElements: null,
            isTicked: false,
            isNoResults: false,
            firstLoad: true,

            $mobileOverlaysContainer: null,
            $filtersCalendarContainer: null,
            isMobile: false,

            calendarMenu: null,
            $filterOptions: null,
            messages: null,

            selectLocationsMadrid: null,
            selectLocationsIbiza: null,
            $selectCitySearcher: null,
            $selectAreasSearcher: null,
            listingType: null,
            searchListingProperties: null,
            dateCheckInQuery: null,
            dateCheckOutQuery: null,
            $sortDropdown: null,
            sortValue: null,
            lastQueryString: null,
            // initialize ----------------------------------------------------------------------  /

            initialize: function () {

                //window.onbeforeunload = function () {
                //    window.location.reload();
                //}

                var self = this;
                self.$el = $('#property-listing-page');

                self.tags = [];

                self.$cookies = new Cookies();

                window.$vent.trigger('propertyListingPageReady');
            },
            // start ---------------------------------------------------------------------------  /

            start: function () {

                var self = this;

                //recargar la pagina si no es nueva ( si viene del botón back del navegador -> para que use la sesión)
                //var d = new Date();
                //d = d.getTime();
                //if ($('#reloadValue').val().length == 0) {
                //    $('#reloadValue').val(d);
                //} else {
                //    $('#reloadValue').val('');
                //    location.reload();
                //}
                //
                //var currentUrl = document.location.href;
                //currentUrl = currentUrl.replace("?lang=es", "").replace("?lang=en", "");
                //currentUrl = currentUrl.replace("?state=true", "");
                //currentUrl = currentUrl.replace("&state=true", "");
                //if ((currentUrl.indexOf("#") !== -1)) {
                //    currentUrl = currentUrl + "&state=true"
                //}
                //else {
                //    currentUrl = currentUrl + "?state=true"
                //}
                //
                //
                //window.history.replaceState("cache-listing", document.title, currentUrl);


                self.$contentWrapper = $('.pl-content-wrapper');
                self.$pageFooter = $('.bottom-hero-wrapper');
                self.$searchLocation = $('.search-location');
                self.$searchItem = $('.search-item');
                self.$pseudoInput = $('.pseudo-search-input');
                self.$locationContainer = $('#locations-preview');
                self.$lpSelect = window.$rootContext('#listing-page-select');

                var paginationIx = $('#pagination-index').html();
                if (paginationIx != undefined && paginationIx != null && paginationIx != "" && paginationIx != 0) {
                    self.paginationIndex = parseInt(paginationIx);
                } else {
                    self.paginationIndex = 0;
                }


                self.paginationView = new PaginationView({$container: self.$el.find('.pagination-container')});

                self.scrollTriggeredElements = [];

                if (window.typeFilter) {
                    self.typeQuery = 'type=' + window.typeFilter;
                }

                $(function () {
                    self.loadScripts();
                    self.onDomReady();
                    self.addListeners();
                });
            },
            onDomReady: function () {

                var self = this;

                self.$lang = $('#lang').html();
                if (self.$lang == "es") {
                    self.messages = JSON.parse(MessagesEs);
                } else {
                    self.messages = JSON.parse(MessagesEn);
                }

                self.$filtersCalendarContainer = $('#property-hero-filters');

                self.$selectCitySearcher = $('#selectCityInListings');
                self.$selectAreasSearcher = $('#selectAreaInListings');
                self.searchListingProperties = $('#searchListingProperties');
                self.$sortDropdown = $('#sort-filter');
                self.selectLocationsMadrid = [{value: 'madrid', text: 'Madrid'}, {value: 'ibiza', text: 'Ibiza'}];
                self.selectLocationsIbiza = [{value: 'ibiza', text: 'Ibiza'}, {value: 'madrid', text: 'Madrid'}];
                //
                //// calendars
                self.$filterOptions = self.$filtersCalendarContainer.find('ul.filter-options');
                //
                if (window.innerWidth >= window.MOBILE_WIDTH) {
                    self.$overlayContainer = $('.property-hero-filters-overlays');
                } else {
                    self.$overlayContainer = $('#modal-content-wrapper');
                }
                //Init calendar with selected dates if they exist
                var checkInParamRaw = Utils.DOMUtils.getURLParameter('checkIn');
                var checkOutParamRaw = Utils.DOMUtils.getURLParameter('checkOut');
                if (checkInParamRaw && checkOutParamRaw) {
                    self.dateCheckInQuery = checkInParamRaw;
                    self.dateCheckOutQuery = checkOutParamRaw;

                    Utils.MiscUtils.setCookie('checkIn', self.dateCheckInQuery, window.SEARCHFILTERS_COOKIE_EXPIRES);
                    Utils.MiscUtils.setCookie('checkOut', self.dateCheckOutQuery, window.SEARCHFILTERS_COOKIE_EXPIRES);

                    self.updateFilterButtonText('check-in', moment(self.dateCheckInQuery,'MM/DD/YY').format('DD MMM YYYY'));
                    self.updateFilterButtonText('check-out', moment(self.dateCheckOutQuery,'MM/DD/YY').format('DD MMM YYYY'));
                    self.isDateSelected = true;
                }

                var selectedDates = {};
                if (self.dateCheckInQuery && self.dateCheckOutQuery) {
                    selectedDates = {
                            checkInDate: moment(self.dateCheckInQuery,'MM/DD/YY'),
                            checkOutDate: moment(self.dateCheckOutQuery,'MM/DD/YY')
                    }
                }

                self.calendarMenu = new CalendarMenuView({
                    $container: self.$overlayContainer,
                    $mobileWrap: $('#modal-content-wrapper'),
                    $desktopWrap: $('.property-hero-filters-overlays'),
                    hideClass: 'display-none',
                    model: self.filtersConfig,
                    hasOverlay: true,
                    selectedDates: selectedDates
                });

            },
            // script loading

            loadScripts: function () {

                var self = this;
                self.scriptsArray = [];

                window.$vent.on('propertyFiltersReady', $.proxy(self._onScriptLoaded, self));

                self.checkScriptLoadStatus();
            },
            checkScriptLoadStatus: function () {

                var self = this;

                if (!self.isReady && (!self.scriptsArray.length || self.scriptsLoaded / self.scriptsArray.length === 1)) {
                    self.isReady = true;
                    self._onModulesLoaded();
                }
            },
            _onScriptLoaded: function () {

                var self = this;
                self.scriptsLoaded++;
                self.checkScriptLoadStatus();
            },
            _onModulesLoaded: function () {

                var self = this;
            },
            // listeners

            addListeners: function () {

                var self = this;

                self.$gridContainer = $('#prop-grid-container');
                self.$resultsGrid = $('#results-grid');
                self.$resultsText = self.$gridContainer.find('.grid-subtitle');

                window.$vent.on('applyParams', $.proxy(self.applyParams, self));
                window.$vent.on('updateQuery', $.proxy(self.updateQuery, self));
                window.$vent.on('loadFirstQuery', $.proxy(self.loadFirstQuery, self));
                window.$vent.on('updateTags', $.proxy(self._onUpdateTags, self));

                self.$selectCitySearcher.on('change', $.proxy(self._onChangeSearcher, self));
                self.$selectAreasSearcher.on('change', $.proxy(self._onLocationSelected, self))

                self.$searchLocation.on('keyup', $.proxy(self._onSearchKeyup, self));
                self.$searchLocation.on('blur', $.proxy(self._onSearchBlur, self));
                self.$searchLocation.on('keydown', $.proxy(self._onSearchKeydown, self));
                window.$vent.on('filterLocation', $.proxy(self._onFilterLocation, self));

                self.$lpSelect.on('changed', $.proxy(self._onPageSelect, self));
                self.searchListingProperties.on('click', $.proxy(self._onSearchListingProperties, self));
                self.$sortDropdown.on('changed', $.proxy(self._onSortClick, self));
                /*window.$vent.on('filterSelected', $.proxy(self._onDateSelected, self));*/

                self.calendarMenu.on('clearDates', self._onClearDates, self);
                self.calendarMenu.on('optionSelected', self._onDateSelected, self);
                self.calendarMenu.on('closeMenu', self._onCloseMenu, self);
                self.calendarMenu.on('hide', self._onMenuClosed, self);

                _.each(self.$filterOptions.find('li'), function (filterButton, i) {

                    var $filterButton = $(filterButton);
                    var defaultText = $filterButton.data('default-text');
                    $filterButton.attr('data-default-text', defaultText);
                    $filterButton.on('click', $.proxy(self._onFilterOptionClick, self));
                });

                window.$vent.on('paginate', $.proxy(self._onPaginate, self));
                window.$vent.on('filterResults', $.proxy(self._onFilterResults, self));
                window.$vent.on('openFiltersMenu', $.proxy(self._onOpenFiltersMenu, self));
                window.$vent.on('closeFiltersMenu', $.proxy(self._onCloseFiltersMenu, self));
                window.$vent.on('filterQueryUpdate', $.proxy(self._onFilterQueryUpdate, self));
                window.$vent.on('requestListings', $.proxy(self._onListingsRequest, self));
                window.$vent.on('paginateListings', $.proxy(self._onPaginateListings, self));
                window.$vent.on('paginationResults', $.proxy(self._onPaginationResults, self));
                window.$vent.on('listingsQueryComplete', $.proxy(self._onListingsQueryComplete, self));
                window.$vent.on('mapResults', $.proxy(self._onMapResults, self));

                $(window).on('resize', $.proxy(self._onWindowResize, self));
                $(window).on('scroll', $.proxy(self._onScroll, self));
                $(window).mousewheel($.proxy(self._onMouseWheel, self));

                self._onWindowResize();
                //self.applyParams();
                self.applyParams();

            },
            //

            applyParams: function () {

                var self = this;
                var locationParam = Utils.DOMUtils.getURLParameter('location');
                var cityParam = Utils.DOMUtils.getURLParameter('city');
                console.log("locationParam in PropertyLisintgs", locationParam);
                console.log("cityParam PropertyLisintgs", cityParam);

                /* Listing Type */
                if (!self.listingType) {
                    var rawlistingType = $('#url-code').html();
                    var array = rawlistingType.split("-");
                    self.listingType = array[0];
                }

                console.log("listingType PropertyListings", self.listingType);

                //if (cityParam && locationParam && locationParam != 'all') {
                //    console.log("we link to buy, rent o shortlet with city and area");
                //
                //}
                if (self.listingType == 'shortlet') {
                    if (!locationParam || locationParam == 'all') {
                        cityParam = 'madrid';
                        locationParam = 'all';
                    }
                    console.log("we link shortlet without city and location or with location all or we get shortlet from direct link");

                } else {
                    if (!cityParam) {
                        console.log("we link rent or buy directly and the district is ", self.$cookies.getCookie('district'));

                        if (self.$cookies.getCookie('district')) {
                            cityParam = self.$cookies.getCookie('district');
                        } else {
                            cityParam = 'madrid';
                        }
                        //locationParam = 'all';

                    } else if (cityParam && locationParam == 'all') {
                        console.log("we link rent or buy without location or location all");
                        locationParam = 'all';
                    }
                }

                if (cityParam) {
                    self.cityQuery = cityParam;
                    self.isCitySelected = true;
                    self.setDistrictSearcher(cityParam);
                }
                if (locationParam) {
                    self.locationQuery = locationParam;
                    self.isLocationSelected = true;
                    self.setSelectedOption(locationParam);
                }

            },
            loadFirstQuery: function () {

                var self = this;

                self.updateQuery();
                self.initSort();
                self.initPage();
                if (self.lastQueryString) {
                    console.log("--------- loadFirstQuery ----------", self.lastQueryString);
                    window.$vent.trigger('filterQueryUpdate', self.lastQueryString);
                }

            },
            _onLocationSelected: function (e) {

                var self = this;

                var currentTarget = e.currentTarget;
                var selectedLocation = $(currentTarget).val();
                self.isLocationSelected = true;
                self.locationQuery = selectedLocation;
                var newUrl = self.replaceParam(window.location.search, 'location', selectedLocation);
                window.history.replaceState("", "", newUrl);

                self.updateQuery()

            },
            _onPageSelect: function (e) {

                var self = this;
                var $target = $(e.currentTarget);
                var uri = $target.data('uri');
                var page = $target.val();

                if (uri && page) {
                    window.location = uri + page;
                }
            },
            _onSearchKeyup: function (e) {

                if (e.keyCode == 9 || e.keyCode == 13) {
                    return false;
                }

                var self = this;
                var searchTerm = self.$searchLocation.val();
                var listings = null;
                var queryString = (searchTerm) ? '?location=' + searchTerm : '';
                self.$locationContainer.html('');

                var listingType;
                var listingURL = $('#url-code').html();
                if (listingURL == 'buy-listing') {
                    listingType = 'buy'
                } else if (listingURL == 'rent-listing') {
                    listingType = 'rent'
                } else if (listingURL == 'shortlet-listing') {
                    listingType = 'shortlet'
                }

                var listings = null;
                var queryString = (searchTerm) ? '?location=' + searchTerm : '';
                self.$locationContainer.html('');
                queryString += '&type=' + listingType;


                if (searchTerm) {
                    $.ajax({
                        type: 'GET',
                        url: '/global/locationAjax' + queryString,
                        async: false,
                        jsonpCallback: 'callBack',
                        contentType: 'application/json',
                        dataType: 'jsonp',
                        success: function (json) {
                            result = json.results;
                        },
                        error: function (e) {
                            console.log('JSON Load Error', self);
                            console.log(e.message);
                        }
                    });

                    if (result.length > 0) {

                        var loc = searchTerm[0].toUpperCase() + searchTerm.slice(1);
                        loc = result[0].location.replace(loc, '<span>' + loc + '</span>');
                        self.$pseudoInput.html(loc);
                        self.lastLocationFilterResult = result;

                        for (var i in result) {
                            var searchItem = new SearchView({
                                $container: self.$locationContainer,
                                $el: self.$searchItem,
                                model: result[i],
                                index: i
                            });

                            searchItem.show();
                        }

                    } else {

                        self.$pseudoInput.html('');

                        self.$lang = $('#lang').html();
                        var message
                        var note
                        if (self.$lang == 'en') {
                            message = "Sorry, we couldn't find any results.";
                            note = "Please redo your term.";
                        } else {
                            message = "Lamentamos no ofrecerte ningún resultado.";
                            note = "Por favor prueba con otra búsqueda o con una de las opciones de abajo.";
                        }

                        var searchItem = new SearchView({
                            $container: self.$locationContainer,
                            $el: self.$locationContainer,
                            model: {
                                "message": message,
                                "note": note
                            },
                            noItems: true
                        });

                        searchItem.show();
                    }

                } else {
                    self.$pseudoInput.html('');
                }
            },
            _onSearchKeydown: function (e) {

                var self = this;

                //autocomplete on tab
                if (e.keyCode == 9) {

                    self.$searchLocation.val(self.lastLocationFilterResult[0].location);
                    self.$locationContainer.html('');
                    self.$pseudoInput.html('');

                    e.preventDefault();
                    return false;
                }

                //submit filter on enter
                if (e.keyCode == 13) {

                    var location = self.$searchLocation.val();
                    window.$vent.trigger('filterLocation', location);

                    e.preventDefault();
                    return false;
                }
            },
            _onSearchBlur: function () {

                var self = this;
                self.$locationContainer.html('');
                self.$pseudoInput.html('');
                if (self.lastLocationFilter && (typeof self.lastLocationFilter === 'string')) {
                    self.$searchLocation.val(self.lastLocationFilter);
                }
            },
            _onFilterLocation: function (e, location) {
                console.log('onFilterLocation', location);
                var self = this;
                self.lastLocationFilter = location;
                self.isLocationSelected = true;

                if (location) {
                    window.$vent.trigger('updatetLocation', [location]);
                }
            },
            _onPaginate: function (e, index) {

                var self = this;

                if (index != self.paginationIndex) {

                    var startIndex = index * self.MAX_THUMBS_PER_PAGE;
                    var endIndex = startIndex + self.MAX_THUMBS_PER_PAGE;

                    self.paginationIndex = index;

                    var currentUrl = window.location.search;
                    if (Utils.DOMUtils.getURLParameter('page')) {
                        currentUrl = self.removeParam(currentUrl, 'page');
                    }

                    var newUrl = currentUrl + "&page=" + self.paginationIndex;
                    window.history.replaceState({}, "", newUrl);

                    window.$vent.trigger('paginateListings', {startIndex: startIndex, endIndex: endIndex});
                }

                window.$vent.trigger('seekToTop');
            },
            _onPaginateListings: function (e, params) {

                var self = this;
                var startIndex = params.startIndex;
                var endIndex = params.endIndex;

                console.log("_onPaginateListings");

                self.paginatedListings = [];

                for (var i = startIndex; i < endIndex; i++) {

                    var listing = self.inBoundsListings[i];
                    self.paginatedListings.push(listing);
                }

                window.$vent.trigger('paginationResults', [self.paginatedListings.concat(), self.isDraggingMap]);
            },
            _onFilterResults: function () {

                var self = this;
            },
            _onMapResults: function (e, visibleListings, isDraggingMap) {

                var self = this;

                if (visibleListings && window.innerWidth > 992) {
                    if (isDraggingMap) {
                        self.prepListingsForRefresh();
                    } else {
                        self.rebuildListingsGrid(visibleListings, true);
                    }
                }
            },
            _onListingsQueryComplete: function (e, inBoundsListings, isDraggingMap) {

                var self = this;
                self.inBoundsListings = inBoundsListings;

                console.log('_onListingsQueryComplete', inBoundsListings);

                window.$vent.trigger('paginateListings', {startIndex: 0, endIndex: self.MAX_THUMBS_PER_PAGE});

            },
            _onPaginationResults: function (e, inBoundsListings, isDraggingMap) {

                var self = this;
                console.log('_onPaginationResults');

                if (isDraggingMap) {
                    self.prepListingsForRefresh();
                } else {
                    self.rebuildListingsGrid(inBoundsListings);
                }
            },
            _onUnchangedMapResults: function () {

                var self = this;
                console.log('_onUnchangedMapResults');
                self.restorePreppedListings();
            },
            _onOpenFiltersMenu: function () {

                var self = this;
                self.prepListingsForRefresh();
            },
            _onCloseFiltersMenu: function () {

                var self = this;
                self.restorePreppedListings();
            },
            _onFilterQueryUpdate: function (e, query) {

                var self = this;
                console.log("------------- _onFilterQueryUpdate ---------------", query);
                //if (self.lastQueryString === null || (query && query != self.lastQueryString)) {
                //    self.lastQueryString = query;
                self.filterQuery = query;
                //}

                self.reloadListings();
            },
            _onListingsRequest: function () {
                var self = this;

                if (self.listingsArray) {
                    window.$vent.trigger('listingsQueryComplete', [self.listingsArray]);
                }
            },
            // filter controls ---------------------------------------------------------------------  /
            _onUpdateTags: function (e, tags) {
                console.log("UPDATE TAGS self.tagsQuery", tags);
                var self = this;

                if (tags) {
                    self.tagQuery = tags;
                } else {
                    self.tagQuery = null
                }
                self.updateQuery();

            },
            updateQuery: function () {
                var self = this;

                console.log('updateQuery self.cityQuery', self.cityQuery);

                var queryString = 'location=';

                if (self.isLocationSelected) {
                    queryString += self.locationQuery;
                }

                if (self.isCitySelected) {
                    queryString += '&city=' + self.cityQuery;
                }

                if (self.isDateSelected) {
                    queryString += (queryString ? '&checkIn=' : '') + self.dateCheckInQuery;
                    queryString += (queryString ? '&checkOut=' : '') + self.dateCheckOutQuery;
                }

                var tagQuery;
                if (self.tagQuery) {
                    queryString += (queryString ? '&tags=' : '') + self.tagQuery;
                } else {
                    queryString = self.removeParam(queryString, 'tags');
                }

                var page = Utils.DOMUtils.getURLParameter('page') ? Utils.DOMUtils.getURLParameter('page') : 0;
                var sort = Utils.DOMUtils.getURLParameter('sort') ? Utils.DOMUtils.getURLParameter('sort') : 1;
                var newUrl = "?" + queryString + "&sort=" + sort + "&page=" + page;
                window.history.replaceState("", "", newUrl);
                console.log('******replaceState listing url', newUrl);

                self.lastQueryString = queryString;

                var origin= '';
                var checkIn = moment(self.dateCheckInQuery, 'MM/DD/YY').format('YYYY/MM/DD');
                var checkOut = moment(self.dateCheckOutQuery, 'MM/DD/YY').format('YYYY/MM/DD');
                var data = {};

                if(self.listingType ==='shorlet') {
                    origin= 'propiertyListingSearch-'+self.listingType;
                    data = {
                        'trackAction': origin,
                        'trackLabel': {
                            'propLocation': self.locationQuery,
                            'checkIn': checkIn,
                            'checkOut': checkOut,
                        }
                    }
                }else{
                    origin= 'propiertyListingSearch-'+self.listingType;
                    data = {
                        'trackAction': origin,
                        'trackLabel': {
                                'propLocation': self.locationQuery,

                            }
                    }
                }

                Utils.DOMUtils.triggerTrackEvent(data);

                console.log("QueryString in updateQuery of property-listing", self.lastQueryString);

            },
            reloadListings: function () {

                var self = this;
                var minTime = 1.6;

                if (!self.isQuerying) {

                    window.$vent.trigger('reloadListings');

                    self.isQuerying = true;
                    self.queryStartTime = (new Date()).getTime();

                    TweenMax.to(self.$resultsText, 0.3, {
                        opacity: 0, ease: Cubic.easeOut, onComplete: function () {
                            self.$lang = $('#lang').html();
                            if (self.$lang == 'en') {
                                self.$resultsText.html('Searching...');
                            } else {
                                self.$resultsText.html('Buscando...');
                            }

                            TweenMax.fromTo(self.$resultsText, 0.3, {y: 6}, {y: 0, opacity: 1, ease: Sine.easeOut});
                        }
                    });

                    self.prepListingsForRefresh();

                    $.when($.proxy(self.queryListings, self)(self.filterQuery)).then(function (listingsArray) {

                        self.listingsArray = listingsArray;

                        if (self.listingsArray.length > 0) {

                            var d = Math.max((minTime - ((new Date()).getTime() - self.queryStartTime) / 1000), 0);
                            TweenMax.to(self.$resultsText, 0.3, {
                                opacity: 0, ease: Sine.easeOut, delay: d, onComplete: function () {

                                    self.queryResult = self.messages.searchListing['propertiesFound'] + listingsArray.length + self.messages.searchListing['properties'];

                                    self.$resultsText.html(self.queryResult);

                                    TweenMax.fromTo(self.$resultsText, 0.3, {y: 6}, {
                                        y: 0,
                                        opacity: 1,
                                        ease: Sine.easeOut
                                    });
                                }
                            });

                        }

                        /* Hide spinner */
                        window.$vent.trigger('hideSpinner');
                        self.isQuerying = false;
                        window.$vent.trigger('listingsQueryComplete', [self.listingsArray]);
                    }, function (listingsArray) {
                        self.listingsArray = listingsArray;
                        console.log("Error en el API");
                    });
                }
            },
            queryListings: function () {

                var self = this;
                var dfd = $.Deferred();
                var queryString = (self.filterQuery) ? '?' + self.filterQuery : '';
                var typeQuery = (self.typeQuery) ? '&' + self.typeQuery : '';
                var listingType;
                var listingsArray;
                var listingURL = $('#url-code').html();
                if (listingURL == 'buy-listing') {
                    listingURL = 'buy';
                    listingType = 'buy';
                } else if (listingURL == 'rent-listing') {
                    listingURL = 'rent';
                    listingType = 'rent';
                } else if (listingURL == 'shortlet-listing') {
                    listingURL = 'shortlet';
                    listingType = 'shortlet';
                }

                queryString = '/' + listingURL + '/listingAjax' + queryString + typeQuery;
                queryString += '&type=' + listingType;
                console.log('queryListings', queryString);

                if (self.firstLoad == false) {
                    self.paginationIndex = 0;

                } else {
                    self.firstLoad = false;
                    queryString += '&loadSession=true';
                }

                var selectValue = parseInt($('#sort-filter').val());
                if (selectValue && queryString.indexOf("sort=") == -1) {
                    queryString += '&sort=' + selectValue;
                }

                $.ajax({
                    type: 'GET',
                    url: queryString,
                    async: true,
                    jsonpCallback: 'callBack',
                    contentType: 'application/json',
                    dataType: 'jsonp',
                    success: function (json) {
                        listingsArray = json.results;
                        dfd.resolve(listingsArray);
                    },
                    error: function (e) {
                        console.log('JSON Load Error', self);
                        console.log(e.message);
                        listingsArray = {};
                        dfd.reject(listingsArray);
                    }
                });


                return dfd.promise();
            },
            //

            prepListingsForRefresh: function () {

                var self = this;

                if (!self.isPendingRefresh) {

                    self.isPendingRefresh = true;
                    self.paginationView.hide();

                    _.each(self.gridItems, function (gridItem, i) {
                        gridItem.showVeil();
                    });
                }
            },
            restorePreppedListings: function () {

                var self = this;

                if (self.isPendingRefresh) {

                    self.isPendingRefresh = false;
                    self.paginationView.show();

                    _.each(self.gridItems, function (gridItem, i) {
                        gridItem.hideVeil();
                    });
                }
            },
            // rebuild grid

            rebuildListingsGrid: function (visibleListings, mapQuery) {

                var self = this;

                if (!self.inBoundsListings.length) {
                    self.showNoResults();
                } else if (mapQuery === true && visibleListings.length === 0) {
                    self.showNoResults();
                } else if (mapQuery === true && visibleListings.length > 0) {
                    self.createListingsGrid(visibleListings);
                } else {

                    if (self.gridItems && self.gridItems.length) {

                        // validate pagination bounds

                        if (self.paginationIndex < 0) {
                            self.paginationIndex = 0;
                        } else if (self.paginationIndex * self.MAX_THUMBS_PER_PAGE > self.inBoundsListings.length) {
                            self.paginationIndex = parseInt(self.inBoundsListings.length / self.MAX_THUMBS_PER_PAGE);
                        }

                        // remove existing items

                        self.removeListingsGrid(true);
                        self.isPendingRefresh = true;
                    } else {
                        self.createListingsGrid(self.inBoundsListings);
                    }
                }
            },
            removeListingsGrid: function (rebuild) {

                var self = this;

                if (self.gridItems && self.gridItems.length) {

                    TweenMax.to(self.$resultsText, 0.3, {opacity: 0, ease: Sine.easeOut});

                    for (var i = 0; i < self.gridItems.length; i++) {

                        var gridItem = self.gridItems[i];

                        if (i === self.gridItems.length - 1) {

                            gridItem.on('hideComplete', function () {

                                TweenMax.delayedCall(0.3, function () {

                                    gridItem.exitImmediately();
                                    self.$resultsGrid.find('.desktop_clear').remove();

                                    if (rebuild === true) {
                                        self.createListingsGrid(self.inBoundsListings);
                                    }
                                });
                            });
                        }

                        gridItem.hide();
                    }
                }
            },
            createListingsGrid: function (mapListings) {

                var self = this;

                if (mapListings.length) {
                    var inBoundsListings = self.inBoundsListings;
                } else {
                    var inBoundsListings = self.inBoundsListings;
                }

                self.gridItems = [];
                self.$resultsGrid.empty();

                var startIndex = self.paginationIndex * self.MAX_THUMBS_PER_PAGE;
                var endIndex = Math.min(startIndex + self.MAX_THUMBS_PER_PAGE, inBoundsListings.length);
                var count = 0;

                console.log(inBoundsListings);

                if (!endIndex) {
                    self.showNoResults();
                } else if (!inBoundsListings) {
                    self.showNoResults();
                } else {

                    $.when($.proxy(self.hideNoResults, self)()).then(function () {

                        var visibleListings = [];

                        for (var i = startIndex; i < endIndex; i++) {

                            var listing = inBoundsListings[i];
                            listing.area = Utils.DOMUtils.getURLParameter('location') ? Utils.DOMUtils.getURLParameter('location') : '';
                            listing.city = Utils.DOMUtils.getURLParameter('city') ? Utils.DOMUtils.getURLParameter('city') : '';
                            if (Utils.DOMUtils.getURLParameter('checkIn') && Utils.DOMUtils.getURLParameter('checkOut')) {
                                listing.checkIn = Utils.DOMUtils.getURLParameter('checkIn') && Utils.DOMUtils.getURLParameter('checkIn') != 0 ? Utils.DOMUtils.getURLParameter('checkIn') : '';
                                listing.checkOut = Utils.DOMUtils.getURLParameter('checkOut') && Utils.DOMUtils.getURLParameter('checkOut') != 0 ? Utils.DOMUtils.getURLParameter('checkOut') : '';
                            } else {
                                listing.checkIn = '';
                                listing.checkOut = '';
                            }
                            listing.tags = Utils.DOMUtils.getURLParameter('tags') ? Utils.DOMUtils.getURLParameter('tags') : '';
                            listing.page = Utils.DOMUtils.getURLParameter('page') ? Utils.DOMUtils.getURLParameter('page') : 0;
                            listing.sort = Utils.DOMUtils.getURLParameter('sort') ? Utils.DOMUtils.getURLParameter('sort') : 0;
                            var gridItem = new GridItemView({
                                $container: self.$resultsGrid,
                                model: listing,
                                index: count,
                            });

                            gridItem.show();
                            self.gridItems.push(gridItem);

                            visibleListings.push(listing);
                            count++;
                        }

                        // refresh listing grid

                        if (inBoundsListings.length % 2 !== 0) {
                            self.$resultsGrid.append('<li class="desktop_clear"></li>');
                        }

                        TweenMax.killTweensOf(self.$resultsText);
                        TweenMax.to(self.$resultsText, 0.3, {
                            opacity: 0, ease: Sine.easeOut, onComplete: function () {
                                var currentLocation = '';

                                if (self.lastLocationFilter && (typeof self.lastLocationFilter === 'string')) {
                                    currentLocation = self.lastLocationFilter;
                                } else if (self.lastLocationFilter && (typeof self.lastLocationFilter === 'object')) {
                                    currentLocation = Utils.DOMUtils.getHashParameter('location');
                                }

                                if (!currentLocation) {
                                    self.$lang = $('#lang').html();
                                    if (self.$lang == 'en') {
                                        self.queryResult = 'We have found <span class="strong-class">' + inBoundsListings.length + '</span> apartments.';
                                    } else {
                                        self.queryResult = 'Hemos encontrado <span class="strong-class">' + inBoundsListings.length + ' propiedades.';
                                    }
                                } else {
                                    self.$lang = $('#lang').html();
                                    if (self.$lang == 'en') {
                                        self.queryResult = 'We&#8217;ve found <span class="strong-class">' + inBoundsListings.length + '</span> apartments for ' + Utils.StringUtils.toFormalCase(currentLocation);
                                    } else {
                                        self.queryResult = 'Hemos encontrado <span class="strong-class">' + inBoundsListings.length + '</span> pisos en ' + Utils.StringUtils.toFormalCase(currentLocation);
                                    }

                                }

                                self.$resultsText.html(self.queryResult);

                                TweenMax.fromTo(self.$resultsText, 0.3, {y: 6}, {y: 0, opacity: 1, ease: Sine.easeOut});
                            }
                        });

                        //

                        window.$vent.trigger('mapIgnoreResize', true);
                        //window.$vent.trigger('domUpdate');

                        var currentUrl = window.location.search;
                        if (Utils.DOMUtils.getURLParameter('page')) {
                            self.paginationIndex = Utils.DOMUtils.getURLParameter('page');
                            currentUrl = self.removeParam(currentUrl, 'page');
                        }

                        var newUrl = currentUrl + "&page=" + self.paginationIndex;
                        window.history.replaceState({}, "", newUrl);

                        self.paginationView.rebuild(self.paginationIndex, inBoundsListings.length, self.MAX_THUMBS_PER_PAGE);
                        self._onWindowResize();

                        self.isQuerying = false;
                        self.isPendingRefresh = false;

                        window.$vent.trigger('createListingsComplete', [visibleListings]);
                    });
                }

                //Tapando el problema del doble click en los enlaces
                $("body a").not(".filter-btn-close").click(function () {
                    location.href = $(this).attr('href');
                });

                //Eliminando parámetros si vienen de la home que afectan a la sesión
                //var currentUrl = document.location.href;
                //currentUrl = currentUrl.replace("?lang=es", "").replace("?lang=en", "");
                //currentUrl = currentUrl.replace("?state=true", "");
                //var pos = currentUrl.indexOf("#");
                //if (pos !== -1) {
                //    currentUrl = currentUrl.substring(0, pos) + "?state=true"
                //    window.history.replaceState("cache-listing", document.title, currentUrl);
                //}

                window.$vent.trigger('domUpdate');
            },
            showNoResults: function () {

                var self = this;

                if (!self.$noResults) {

                    window.$vent.trigger('seekToTop');

                    $('#results-grid').removeClass('hide').addClass('hide');
                    $('#succes-message-container').removeClass('hide').addClass('hide');
                    $('#summary-and-filter-row').removeClass('hide').addClass('hide');
                    $('#pagination-wrapper').removeClass('hide').addClass('hide');
                    $('#mobile-bottom-hero').removeClass('hide').addClass('hide');


                    var text = self.messages.searchListing['noPropertiesFound'];


                    self.$noResults = $('#no-results-container').html(text);
                    TweenMax.fromTo(self.$noResults, 0.4, {opacity: 0}, {opacity: 1, ease: Cubic.easeOut});

                    window.$vent.trigger('domUpdate');
                }
            },
            hideNoResults: function () {

                var self = this;
                var dfd = $.Deferred();

                if (!self.$noResults) {
                    dfd.resolve();
                } else {

                    window.$vent.trigger('seekToTop');

                    TweenMax.to(self.$noResults, 0.4, {
                        opacity: 0, ease: Cubic.easeOut, onComplete: function () {

                            /* self.$noResults.remove();*/
                            self.$noResults = null;

                            $('#no-results-container').html();
                            $('#succes-message-container').removeClass('hide');
                            $('#results-grid').removeClass('hide');
                            $('#summary-and-filter-row').removeClass('hide');
                            $('#pagination-wrapper').removeClass('hide');
                            $('#mobile-bottom-hero').removeClass('hide');

                            window.$vent.trigger('domUpdate');

                            dfd.resolve();

                        }
                    });
                }

                return dfd.promise();
            },
            showNoResultsMessage: function (listingsArray) {
                var self = this;
                var minTime = 1.6;

                var d = Math.max((minTime - ((new Date()).getTime() - self.queryStartTime) / 1000), 0);
                TweenMax.to(self.$resultsText, 0.3, {
                    opacity: 0, ease: Sine.easeOut, delay: d, onComplete: function () {

                        self.$lang = $('#lang').html();
                        if (self.$lang == 'en') {
                            self.queryResult = 'We&#8217;ve found <span class="strong-class">';
                        } else {
                            self.queryResult = 'No hemos encontrado viviendas.';
                        }

                        self.$resultsText.html(self.queryResult);

                        TweenMax.fromTo(self.$resultsText, 0.3, {y: 6}, {
                            y: 0,
                            opacity: 1,
                            ease: Sine.easeOut
                        });
                    }
                });
            },
            // check map boundaries

            checkMapBounds: function () {

                var self = this;
                window.$vent.trigger('resizeMap');
            },
            // scroll --------------------------------------------------------------------------  /

            _onMouseWheel: function (e) {

                var self = this;
                self.scrollTick();
            },
            _onScroll: function () {

                var self = this;
                self.scrollTick();
            },
            scrollTick: function () {

                var self = this;

                if (!self.isTicked) {
                    self.isTicked = true;
                    window.requestAnimationFrame(self.scrollNow.bind(self));
                }
            },
            scrollNow: function (e) {

                var self = this;
                self.scrollTop = $(window).scrollTop();

                var availableScreenHeight = Math.min(self.winHeight, self.docHeight - self.footerHeight - self.scrollTop);
                var yPos = 0 - self.scrollTop;

                if (self.lastYPos != yPos) {

                }

                self.isTicked = false;
            },
            _onWindowResize: function (e) {

                var self = this;
                var lastWidth = self.winWidth;
                var lastHeight = self.winHeight;

                self.winWidth = window.innerWidth;
                self.winHeight = window.innerHeight;
                self.docHeight = $(document).outerHeight();
                self.footerHeight = self.docHeight - self.$pageFooter.offset().top;

                // change filter containers

                if (self.$mobileOverlaysContainer) {

                    if (self.winWidth >= 768 && self.isMobile) {

                        self.isMobile = false;

                        /*   self.$overlayContainer.append(self.locationsMenu.$el);*/
                        self.$overlayContainer.append(self.calendarMenu.$el);
                        //self.$overlayContainer.append(self.guestsMenu.$el);

                        /*  self.locationsMenu.$container = self.$overlayContainer;*/
                        self.calendarMenu.$container = self.$overlayContainer;
                        //self.guestsMenu.$container = self.$overlayContainer;
                    } else if (self.winWidth < 768 && !self.isMobile) {

                        self.isMobile = true;

                        /*      self.$mobileOverlaysContainer.append(self.locationsMenu.$el);*/
                        self.$mobileOverlaysContainer.append(self.calendarMenu.$el);
                        //self.$mobileOverlaysContainer.append(self.guestsMenu.$el);

                        /*   self.locationsMenu.$container = self.$mobileOverlaysContainer;*/
                        self.calendarMenu.$container = self.$mobileOverlaysContainer;
                        //self.guestsMenu.$container = self.$mobileOverlaysContainer;
                    }
                }

                self._onScroll();

                var $leftContent = $('.property-listing-inner');
                var leftContentWidth = $leftContent.offset().left + $leftContent.outerWidth();

                TweenMax.set(self.$contentWrapper, {
                    minHeight: self.winHeight,
                });
            },

            // Select Searchers

            getAreasSearcher: function (cityParam) {

                var self = this;

                var queryString = '?city=' + cityParam + '&type=' + self.listingType;
                var result = null;

                console.log('queryString to getAreasSearcher', queryString);

                $.ajax({
                    type: 'GET',
                    url: '/global/getAreasForSearcher' + queryString,
                    async: false,
                    jsonpCallback: 'callBack',
                    contentType: 'application/json',
                    dataType: 'jsonp',
                    success: function (json) {
                        result = json.results;
                    },
                    error: function (e) {
                        console.log('JSON Load Error in getAreasSearcher', self);
                        console.log(e.message);
                        result = {};
                    }
                });

                return result;

            },
            setAreasSearcher: function (areas) {

                var self = this;
                var optionSelectArea = '';
                var optionNoAreas = '';
                var optionAllAreas = '';

                /* Set first option text */
                if (self.$lang == 'es') {
                    optionSelectArea = "Selecciona un área";
                    optionNoAreas = 'No hay áreas <span class="hidden-xs"> para esta zona</span>';
                    optionAllAreas = 'Todas';
                } else {
                    optionSelectArea = "Select an area";
                    optionNoAreas = 'There\'s no areas <span class="hidden-xs"> for this location</span>';
                    optionAllAreas = "All";
                }

                var optionsArray = [];

                /* Create the list of objects to pass to selectboxit function */
                if ($(areas.zones).size() > 0) {

                    optionsArray.push({value: 'select', text: optionSelectArea}, {value: 'all', text: optionAllAreas});

                    $(areas.zones).each(function (i, item) {
                        var singleObj = {}
                        singleObj['value'] = item;
                        singleObj['text'] = item;
                        optionsArray.push(singleObj);
                    });
                } else {
                    optionsArray.push({value: 'empty', text: optionNoAreas});
                }

                self.$selectAreasSearcher.data("selectBox-selectBoxIt").remove();
                self.$selectAreasSearcher.data("selectBox-selectBoxIt").add(optionsArray);
            },
            setDistrictSearcher: function (cityParam) {
                var self = this;
                console.log('listingType and cityParam in setDistrictSearcher', self.listingType + cityParam);


                if (self.listingType == 'shortlet') {
                    self.$selectCitySearcher.data("selectBox-selectBoxIt").remove();
                    self.$selectCitySearcher.data("selectBox-selectBoxIt").add({
                        value: 'madrid',
                        text: 'Madrid'
                    });
                } else {
                    if (cityParam == 'ibiza') {
                        self.$selectCitySearcher.data("selectBox-selectBoxIt").remove();
                        self.$selectCitySearcher.data("selectBox-selectBoxIt").add(self.selectLocationsIbiza);

                    } else {
                        cityParam = 'madrid';
                        self.$selectCitySearcher.data("selectBox-selectBoxIt").remove();
                        self.$selectCitySearcher.data("selectBox-selectBoxIt").add(self.selectLocationsMadrid);
                    }
                }

                //self.cityQuery = cityParam;
                //self.isCitySelected = true;
                //

                /* every time we change the district searcher we need to load the areas */
                var areas = self.getAreasSearcher(cityParam);
                if (areas) {
                    self.setAreasSearcher(areas);
                }

                //self.updateQuery()

            },
            getLocalStorage: function (key) {
                var self = this;

                var localStorageValue = localStorage.getItem(key);

                if (localStorageValue) {
                    return localStorageValue;
                } else {
                    return null;
                }
            },
            setSelectedOption: function (locationParam) {
                var self = this;

                var locationValue = locationParam.replace(/%20/g, ' ');
                self.$selectAreasSearcher.find("option[value='" + locationValue + "']").attr('selected', 'selected');
                // Refreshes the drop down to reflect the current state of the select box
                self.$selectAreasSearcher.data("selectBox-selectBoxIt").refresh();

            },

            //Search
            _onSearchListingProperties: function (e) {
                var self = this;
                self.updateQuery();
                var newUrl = self.replaceParam(window.location.search, 'page', 0);
                self.lastQueryString = newUrl.replace('?', '');
                window.history.replaceState({}, "", newUrl);

                /* Show spinner */
                window.$vent.trigger('showSpinner', e.currentTarget);

                window.$vent.trigger('filterQueryUpdate', self.lastQueryString);
            },
            _onChangeSearcher: function (e) {

                var self = this;

                var $currentElement = $(e.currentTarget);

                var cityParam = $currentElement.val();
                var areas = self.getAreasSearcher(cityParam);

                self.cityQuery = cityParam;
                self.isCitySelected = true;

                if (areas) {
                    self.setAreasSearcher(areas);
                }

                self.updateQuery()
            },

            initSort: function () {
                var self = this;
                var sort = 0;
                if (Utils.DOMUtils.getURLParameter('sort')) {
                    sort = Utils.DOMUtils.getURLParameter('sort');
                    self.sortValue = "sort=" + sort;
                    self.$sortDropdown.data("selectBox-selectBoxIt").selectOption(sort);
                } else {
                    self.sortValue = "sort=" + 1;
                    self.$sortDropdown.data("selectBox-selectBoxIt").selectOption(0);
                }

            },
            initPage: function () {
                var self = this;
                if (Utils.DOMUtils.getURLParameter('page')) {
                    var page = 0;
                    page = Utils.DOMUtils.getURLParameter('page');
                    self.lastQueryString = self.removeParam(self.lastQueryString, 'page') + '&page=' + page;
                }
            },
            _onSortClick: function (e) {
                var self = this;
                self.checkSortValue();
                self.searchListingProperties.trigger('click');
            },
            checkSortValue: function () {

                var self = this;
                var selectValue = parseInt(self.$sortDropdown.val());
                var sort = 0;
                if (selectValue) {
                    self.sortValue = selectValue;
                } else {
                    self.sortValue = 1
                }
                var page = 0;
                var currentUrl = window.location.search;
                var queryString = self.lastQueryString;
                if (Utils.DOMUtils.getURLParameter('page')) {
                    page = Utils.DOMUtils.getURLParameter('page');
                    currentUrl = self.removeParam(currentUrl, 'page');
                }
                if (Utils.DOMUtils.getURLParameter('sort')) {
                    currentUrl = self.removeParam(currentUrl, 'sort');
                }

                var newUrl = currentUrl + "&sort=" + self.sortValue + "&page=" + page;

                self.lastQueryString = newUrl.replace('?', '');
                window.history.replaceState({}, "", newUrl);

                if (selectValue && selectValue != self.sortValue) {

                    window.$vent.trigger('filterQueryUpdate', queryString);
                }
            },

            //Calendar
            closeCurrentMenu: function (maintainVeil) {

                var self = this;

                if (self.currentMenu && self.isModalOpen) {

                    self.restoreCurrentFilterButton();

                    self.currentMenu.hide();
                    self.currentMenu = null;

                    self.$el.off('click', $.proxy(self._onOutClick, self));

                    if (maintainVeil !== true) {
                        window.$vent.trigger('closeFiltersMenu');
                    }
                }
            },
            // hide / restore clicked filter button values
            hideCurrentFilterButton: function () {

                var self = this;

                _.each(self.$activeFilterButton, function (filter, i) {
                    var $filter = $(filter);
                    TweenMax.to($filter.find('a'), 0.3, {opacity: 0, ease: Cubic.easeOut});
                });
            },
            restoreCurrentFilterButton: function () {

                var self = this;

                _.each(self.$activeFilterButton, function (filter, i) {
                    var $filter = $(filter);
                    TweenMax.fromTo($filter.find('a'), 0, {opacity: 0}, {opacity: 1, ease: Quint.easeInOut});
                });
            },
            updateFilterButtonText: function (buttonClass, text) {

                var self = this;
                var $filterButton = self.$el.find('.filter-option.' + buttonClass);

                console.log("$filterButton", $filterButton);
                console.log("text", text);
                console.log("buttonClass", buttonClass);

                if (text) {
                    $filterButton.find('figcaption').html(text);
                    $filterButton.removeClass('selected').addClass('selected');
                }
                else {
                    $filterButton.find('figcaption').html($filterButton.data('default-text'));
                    $filterButton.removeClass('selected');

                    console.log("$filterButton data", $filterButton.data('default-text'));
                }
            },
            _onOutClick: function (e) {

                var self = this;

                if (e) {

                    if (!self.$overlayContainer[0].contains(e.target) && $('body')[0].contains(e.target)) {
                        self.closeCurrentMenu();
                    }
                }
                else {
                    self.closeCurrentMenu();
                }
            },
            _onFilterOptionClick: function (e) {

                var self = this;
                var $target = $(e.currentTarget);
                var $nextFilterButton = $target;
                var nextMenu;

                self.isModalOpen = false;

                if ($target.hasClass('check-in')) {

                    $nextFilterButton = self.$filterOptions.find('.date-option');
                    nextMenu = self.calendarMenu;

                } else if ($target.hasClass('check-out')) {

                    $nextFilterButton = self.$filterOptions.find('.date-option');
                    nextMenu = self.calendarMenu;

                }

                if (nextMenu && nextMenu !== self.currentMenu) {

                    self.isModalOpen = true;

                    if (e) {
                        e.stopPropagation();
                    }
                    if (self.currentMenu) {
                        self.closeCurrentMenu(true);
                    }

                    self.currentMenu = nextMenu;
                    self.$activeFilterButton = $nextFilterButton;

                    self.currentMenu.show();
                    //self.hideCurrentFilterButton();

                    self.$el.off('click', $.proxy(self._onOutClick, self));
                    self.$el.on('click', $.proxy(self._onOutClick, self));

                    window.$vent.trigger('openFiltersMenu');
                }
            },
            _onDateSelected: function (e) {
                var self = this;

                self.isDateSelected = true;
                self.dateCheckInQuery = e.checkInQuery;
                self.dateCheckOutQuery = e.checkOutQuery;
                self.dateCheckInText = e.results;
                self.dateCheckOutText = e.results2;

                self.isDateSelected = true;

                console.info("onDateSelected in propertyFilters", e);
                //Change the url
                var newUrl;
                if (Utils.DOMUtils.getURLParameter('checkIn') && Utils.DOMUtils.getURLParameter('checkOut')) {
                    newUrl = self.replaceParam(window.location.search, 'checkIn', self.dateCheckInQuery);
                    newUrl = self.replaceParam(newUrl, 'checkOut', self.dateCheckOutQuery);
                } else {
                    newUrl = window.location.search + "&checkIn=" + self.dateCheckInQuery + "&checkOut=" + self.dateCheckOutQuery;
                }
                console.log('_onDateSelected newUrl', newUrl);
                window.history.replaceState("", "", newUrl);
                //Set dates cookies
                Utils.MiscUtils.setCookie('checkIn', self.dateCheckInQuery, window.SEARCHFILTERS_COOKIE_EXPIRES);
                Utils.MiscUtils.setCookie('checkOut', self.dateCheckOutQuery, window.SEARCHFILTERS_COOKIE_EXPIRES);

                self.updateFilterButtonText('check-in', self.dateCheckInText);
                self.updateFilterButtonText('check-out', self.dateCheckOutText);

                self.updateQuery();
            },
            _onCloseMenu: function (e) {

                var self = this;
                self.closeCurrentMenu();
            },
            _onMenuClosed: function (e) {

                var self = this;
                /* self.refreshQuery();*/
            },
            _onClearDates: function (e) {
                var self = this;
                console.info("_onClearDates", e);

                self.isDateSelected = false;
                self.dateCheckInQuery = '';
                self.dateCheckOutQuery = '';

                //Delete dates from url
                var newUrl = self.replaceParam(window.location.search, 'checkIn', self.dateCheckInQuery);
                newUrl = self.replaceParam(newUrl, 'checkOut', self.dateCheckOutQuery);
                window.history.replaceState("", "", newUrl);

                //Set dates cookies
                Utils.MiscUtils.setCookie('checkIn', self.dateCheckInQuery, window.SEARCHFILTERS_COOKIE_EXPIRES);
                Utils.MiscUtils.setCookie('checkOut', self.dateCheckOutQuery, window.SEARCHFILTERS_COOKIE_EXPIRES);

                self.updateFilterButtonText('check-in');
                self.updateFilterButtonText('check-out');
            },
            removeParam: function (url, parameter) {
                return url
                    .replace(new RegExp('[?&]' + parameter + '=[^&#]*(#.*)?$'), '')
                    .replace(new RegExp('([?&])' + parameter + '=[^&]*&'), '');
            },
            replaceParam: function (url, parameter, value) {
                //var regex = new RegExp('('+parameter+'=)[^\&]+');
                //return url.replace( regex , '$1' + value);

                var re = new RegExp("[\\?&]" + parameter + "=([^&#]*)"), match = re.exec(url), delimiter, newString;

                if (match === null) {
                    // append new param
                    var hasQuestionMark = /\?/.test(url);
                    delimiter = hasQuestionMark ? "&" : "?";
                    newString = url + delimiter + parameter + "=" + value;
                } else {
                    delimiter = match[0].charAt(0);
                    newString = url.replace(re, delimiter + parameter + "=" + value);
                }

                console.log('replaceParam newString', newString);

                return newString;
            }
        });

        return AppView;
    }
);


