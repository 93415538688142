define(
	'modules/property-lightbox/views/LightboxView',[
		'backbone',
		'utils/AbstractView',

		'modules/property-lightbox/views/LightboxImageView',
		
		'text!../templates/LightboxTemplate.html',

	], 
	function(Backbone, AbstractView, LightboxImageView, Template){

		var AppView = Backbone.View.extend({

			// core vars

			$el: null,
			$container: null,
			options: null,
			abstractView: null,

			// vars

			$carouselCaptionsContainer: null,
			$carouselImagesContainer: null,

			carouselItems: null,
			carouselImages: null,
			carouselCaptions: null,

			winWidth: null,
			winHeight: null,
			containerWidth: null,
			containerHeight: null,

			currentIndex: -1,
			currentCarouselItem: null,
			$currentCarouselImage: null,

			$progressText: null,
			$textContent: null,
			$textBg: null,

			imageRatio: null,
			totalItems: null,
			initialScrollTop: 0,

			// init

			initialize: function(options){

				var self = this;

				self.options = options;
				self.$container = self.options.$container;
				self.startIndex = parseInt(self.options.index) || 0;

				self.template = _.template(Template);
				self.model = $.extend({

				}, self.model);

				//

				self.totalItems = self.model.gallery.length;

				// 

				self.abstractView = new AbstractView({
					view: self,
					autoShow: false,
				});

				//

				self.show();
			},


			onRender: function(){

				var self = this;

				self.carouselItems = [];
				self.carouselImages = [];
				self.carouselCaptions = [];

				self.$carouselCaptionsContainer = self.$el.find('ul.captions');
				self.$carouselImagesContainer = self.$el.find('ul.images');

				self.$carouselCaptions = self.$carouselCaptionsContainer.find('li');
				self.$carouselImages = self.$carouselImagesContainer.find('li');

				for(var i=0; i<self.totalItems; i++){

					var itemModel = $.extend({
						index: i
					}, self.model.gallery[i]);

					var carouselItem = new LightboxImageView({						
						el: self.$carouselImages[i],
						$caption: $(self.$carouselCaptions[i]),
						model: itemModel,
						index: i
					});

					self.carouselItems.push(carouselItem);

					// image

					var $image = $(self.$carouselImages[i]);
					self.carouselImages.push($image);

					TweenMax.set($image, {force3D:true});

					// captions

					var $caption = $(self.$carouselCaptions[i]);
					self.carouselCaptions.push($caption);
				}

				self.$textContent = self.$carouselCaptionsContainer.find('.image-caption.captions');
				self.$progressText = self.$el.find('.progress-indicator .progress-text');
			},

			// 

			onShow: function(){

				var self = this;

				self.onWindowResize();
				self.initialScrollTop = $(window).scrollTop();

				TweenMax.to(self.$el, 0.4, {opacity:1, delay:0.0, ease:Cubic.easeInOut});

				self.seekByIndex(self.startIndex);
				self.trigger('showComplete');
				$(window).resize();
				$('#sticky-nav').addClass('has-lightbox');
			},

			// listeners

			onAddListeners: function(){

				var self = this;

				var $leftArrow = self.$el.find('.arrow-buttons-container .arrow-button.left');
				var $rightArrow = self.$el.find('.arrow-buttons-container .arrow-button.right');
				var $closeModal = self.$el.find('.close-modal');

				$leftArrow.on('click', $.proxy(self._onArrowClick, self));
				$rightArrow.on('click', $.proxy(self._onArrowClick, self));

				$closeModal.on('click', $.proxy(self._onCloseClick, self));

				var options = { preventDefault: true };
                self.$el.find('.images').hammer(options).on('swiperight', $.proxy(self._onSwipe, self));
                self.$el.find('.images').hammer(options).on('swipeleft', $.proxy(self._onSwipe, self));

				window.requestAnimationFrame(function(){
					$(document).on('click', $.proxy(self._onOutClick, self));
					$(document).on('scroll', $.proxy(self._onScroll, self));
				});
			},

			onRemoveListeners: function(){

				var self = this;

				$(document).off('click', $.proxy(self._onOutClick, self));
			},

			// listener methods

			_onArrowClick: function(e){

				var self = this;
				var $target = $(e.currentTarget);

				if($target.hasClass('left')){ self.prevItem(); }
				else { self.nextItem(); }
			},

			_onOutClick: function(e){

				var self = this;

				if(!self.$el[0].contains(e.target)){
					self.closeModal();
					e.preventDefault();
				}
			},

			_onSwipe: function(e){
 
                var self = this;
 
                if(e.type === 'swiperight'){
                    self.prevItem();
                }
                else if(e.type === 'swipeleft'){
                    self.nextItem();
                }
            },

			_onCloseClick: function(e){

				var self = this;
				self.closeModal();

				e.preventDefault();
			},

			_onScroll: function(e){
 
                var self = this;
                var scrollTop = $(window).scrollTop();
                var modalHeight = Math.max(self.winHeight, self.$el.outerHeight());
 
                if(scrollTop < self.initialScrollTop - self.winHeight * 1 || scrollTop > self.initialScrollTop + modalHeight * 1){
                    self.closeModal();
                }
            },

			// carousel controls

			nextItem: function(){

				var self = this;
				var newIndex = self.currentIndex+1;

				self.seekByIndex(newIndex);
			},

			prevItem: function(){

				var self = this;
				var newIndex = self.currentIndex-1;

				self.seekByIndex(newIndex);
			},

			seekByIndex: function(index){

				var self = this;

				if(self.currentIndex != index){
					self.changeActiveItem(index);
				}
			},

			//

			changeActiveItem: function(newIndex){

				var self = this;
				var lastIndex = self.currentIndex;
				var lastCarouselItem = self.currentCarouselItem;

				if(newIndex >= self.totalItems){ newIndex = 0; }
				else if(newIndex < 0){ newIndex = self.totalItems-1; }

				var direction = (newIndex > self.currentIndex) ? 1 : -1;

				self.currentIndex = newIndex;
				self.currentCarouselItem = self.carouselItems[newIndex];
				self.$currentCarouselImage = self.carouselImages[newIndex];
				self.$currentCarouselCaption = self.carouselCaptions[newIndex];

				// progress indicator

				self.$progressText.html((newIndex + 1) + '/' + self.totalItems);

				//

				if(lastCarouselItem){

					var $lastCarouselImage = lastCarouselItem.$el;
					var $lastCarouselCaption = lastCarouselItem.$caption;

					var $curEl = self.currentCarouselItem.$el;
					var $lastEl = lastCarouselItem.$el;

					TweenMax.to($lastCarouselImage, 0.4, {x:0-self.containerWidth * direction, force3D:true, ease:Sine.easeIn});
					TweenMax.to($lastCarouselImage, 0.6, {x:0-self.containerWidth * direction, delay:0.2, ease:Cubic.easeOut, onComplete:function(){
						$lastCarouselImage.removeClass('hidden').addClass('hidden');
						lastCarouselItem.hide();
					}});

					var d = 0.3;

					TweenMax.fromTo(self.$currentCarouselImage, 0.4, {x:self.containerWidth * direction, force3D:true}, {x:0, ease:Sine.easeIn});
					TweenMax.to(self.$currentCarouselImage, 0.6, {x:0, delay:0.2, ease:Cubic.easeOut});
					
					// show new image

					self.$currentCarouselImage.removeClass('hidden');
					self.currentCarouselItem.show();

					// caption swap

					TweenMax.to($lastCarouselCaption, 0.5, {opacity:0, ease:Cubic.easeOut, onComplete:function(){
						$lastCarouselCaption.addClass('hidden');
					}});

					TweenMax.fromTo(self.$currentCarouselCaption, 0.6, {opacity:0}, {opacity:1, delay:0.44, ease:Cubic.easeInOut});
					TweenMax.fromTo(self.$currentCarouselCaption, 0.6, {y:6}, {y:0, delay:0.44, ease:Cubic.easeOut});

					self.$currentCarouselCaption.removeClass('hidden');
				}
				else {

					// show image

					self.$currentCarouselImage.removeClass('hidden');
					self.currentCarouselItem.show();

					// show caption

					TweenMax.fromTo(self.$currentCarouselCaption, 0.6, {opacity:0}, {opacity:1, ease:Cubic.easeInOut});
					TweenMax.fromTo(self.$currentCarouselCaption, 0.6, {y:6}, {y:0, ease:Cubic.easeOut});
					self.$currentCarouselCaption.removeClass('hidden');
				}

				self.onWindowResize();
			},

			// close

			closeModal: function(){

				var self = this;				
				self.trigger('closeModal');
				$('#sticky-nav').removeClass('has-lightbox');
			},

			// window resizing

			/*onWindowResize: function(){

				var self = this;

				self.winWidth = window.innerWidth;
				self.winHeight = window.innerHeight;

				self.containerWidth = self.$el.outerWidth() - 80;
				self.containerHeight = self.$el.outerHeight();
			},*/

			onWindowResize: function(){

				var self = this;

				if($('#media-breaks .break-992').css('opacity') != 1) {
					$('.property-detail-page .prop-left-side').css({'position':'static'});
				}

				self.winWidth = window.innerWidth;
				self.winHeight = window.innerHeight;

				self.containerWidth = self.$el.outerWidth() - 80;
				self.containerHeight = self.$el.outerHeight();
				self.containerOffset = $(self.$el.parent()).offset();

				var ratio = self.$el.find('.images').innerWidth() / self.$el.find('.images').innerHeight();
				var padding = 120; // (self.winWidth >= 480) ? 120 : 60;

				if(ratio < 1.3){
					self.containerHeight = Math.round(self.containerWidth / 1.3) + padding;
				}
				else if(ratio > 1.4){
					self.containerHeight = Math.round(self.containerWidth / 1.4) + padding;
				}

				if (self.winWidth > 768) {
					self.containerHeight = self.containerHeight -100;
				}

				// set width / height

				TweenMax.set(self.$el, {height:self.containerHeight});

				if(self.$el.hasClass('full-width')){
					TweenMax.set(self.$el, {width:self.containerWidth});
				}
				else {
					TweenMax.set(self.$el, {clearProps:'width'});
				}

				// move according to scrolltop

				var heroHeight = $('#hero').height();
				var adjustedScrollTop;
				var considerbackToList = 0; //60;
				self.initialScrollTop = $(window).scrollTop();

				if($('#media-breaks .break-992').css('opacity') == 0){
					considerbackToList = 0; //60; //120;
					if($('#media-breaks .break-768').css('opacity')==1) {
						adjustedScrollTop = self.initialScrollTop + (self.winHeight - self.containerHeight) / 2 - considerbackToList;
					} else {
						adjustedScrollTop = self.initialScrollTop + (self.winHeight - self.containerHeight)/2 - considerbackToList;
					}
				} else {
					if(self.initialScrollTop>110)
						considerbackToList = 0; //60; //120;

					adjustedScrollTop = self.initialScrollTop + (self.winHeight - self.containerHeight)/2 - considerbackToList;
					adjustedScrollTop = Math.max((self.initialScrollTop-considerbackToList), adjustedScrollTop);

					if(self.initialScrollTop<=110) {
						if(
							self.winWidth > 1450 || 
							(self.winWidth>1180 && self.winHeight<800)
							) {
							$(window).scrollTop(110);
							adjustedScrollTop = 110;
						}
					}
				}

				TweenMax.set(self.$el, {top:adjustedScrollTop - self.containerOffset.top});
			},
			
			// ---------------------------------------------------------------------------------  /

		});

		return AppView;
	}
);
