
define(
	'modules/hero-filters/views/LocationsMenuView',[
		'backbone',
		'utils/AbstractView',
		'text!../templates/LocationsMenuTemplate.html',
	], 
	function(Backbone, AbstractView, Template){

		var LocationsMenuView = Backbone.View.extend({

			// core vars

			$el: null,
			$container: null,
			options: null,
			abstractView: null,

			// vars

			serverQuery: null,
			shortResults: null,

			// init

			initialize: function(options){

				var self = this;

				console.log('these are the options');
				console.dir(options);

				self.options = options;
				self.$container = self.options.$container;
				self.el = self.options.el;

				self.template = _.template(Template);
				self.model = $.extend({

				}, self.model);

				// 

				self.abstractView = new AbstractView({
					view: self,
					autoShow: false
				});
			},

			// render

			onRender: function(){

				var self = this;
				self._onWindowResize();
			},

			// listeners

			onAddListeners: function(){

				var self = this;
				self.$el.find('li button').on('click', $.proxy(self._onLocationClick, self));
				self.$el.find('.close').on('click', $.proxy(self._onCloseClick, self));

				$(window).on('resize', $.proxy(self._onWindowResize, self));
				self._onWindowResize();
			},

			onRemoveListeners: function(){

				var self = this;
				self.$el.find('li button').off('click', $.proxy(self._onLocationClick, self));
				self.$el.find('.close').off('click', $.proxy(self._onCloseClick, self));
			},

			//

			triggerOption: function(optionId){

				var self = this;
				var locations = self.model.locations;

				optionId = optionId || locations[0].id;
				self._onLocationClick({currentTarget:self.$el.find('.location[data-id="' + optionId + '"] button')});
			},

			// listener methods

			_onLocationClick: function(e){

				var self = this;
				var $target = $(e.currentTarget);
				var $location = $target.parent();

				if(!$location.hasClass('selected')){

					self.$el.find('.selected').removeClass('selected');
					$location.addClass('selected');

					// return query
					
					self.serverQuery = $location.data('id');
					self.shortResults = $location.find('h2').html();

					self.trigger('optionSelected', {target:self, query:self.serverQuery, results:self.shortResults});
					self.trigger('closeMenu');
				}	
			},

			_onCloseClick: function(e){

				var self = this;
				self.trigger('closeMenu');
				e.preventDefault();
			},

			onShow: function(){

				var self = this;

				if(window.innerWidth < 768){
					
					window.requestAnimationFrame(function(){

						self._onWindowResize();
					
						window.$vent.trigger('lockScrollZone', {$target:self.$el});
						self.trigger('showComplete');
					});
				}
				else {

					var d = 0;
					var $locations = self.$el.find('li');

					_.each($locations, function(location, i){
						
						var $location = $(location);
						var $locationText = $location.find('h2');
						var $locationBorder = $location.find('.border');
						
						TweenMax.fromTo($locationText, 0.2, {opacity:0, y:4}, {opacity:1, y:0, delay:i * 0.02 + d + 0.1, ease:Cubic.easeOut});
						TweenMax.fromTo($locationBorder, 0.5, {opacity:0}, {opacity:1, delay:i * 0.02 + d, ease:Cubic.easeInOut});
					});

					TweenMax.fromTo(self.$el, 0.5, {opacity:0, y:0}, {opacity:1, y:0, ease:Cubic.easeOut, onComplete:function(){
						self.trigger('showComplete');
					}});
				}
			},

			onHide: function(){

				var self = this;

				if(window.innerWidth < 768){
					
					window.$vent.trigger('restoreScrolling', {$target:self.$el});
					self.trigger('hideComplete');
				}
				else {

					TweenMax.to(self.$el, 0.3, {opacity:0, y:0, ease:Cubic.easeOut, onComplete:function(){
						self.trigger('hideComplete');
					}});
				}				
			},

			_onWindowResize: function(){

				var self = this;
				var winWidth = window.innerWidth;
				var winHeight = window.innerHeight;
				var docHeight = $(document).height();

				if(winWidth < 768){
					TweenMax.set(self.$el, {'min-height' : Math.max(winHeight, self.$el.find('.inner-container').height())});
				}
				else {
					TweenMax.set(self.$el, {clearProps:'min-height'});
				}
			}

			// ---------------------------------------------------------------------------------  /

		});

	return LocationsMenuView;
});
