define(
    'modules/home/views/ChangeContentView',[
        'backbone',
        'utils/AbstractView',
    ],
    function (Backbone, AbstractView) {

        var DistrictPopup = Backbone.View.extend({

            // core vars

            /*  $el: null,
             $districtButtons: null,
             $districtSelected: null,
             $localStorageKey: 'district',
             localStorageValue: null,*/

            $localStorage: null,
            $photosToChange: null,

            // init
            initialize: function (options) {
                var self = this;
                console.log("changeContent init");

                self.$localStorage = options.$localStorage;

                $(function () {
                    self.onDomReady();
                    self.onAddListeners();
                });

            },

            //grab the properties
            onDomReady: function () {

                var self = this;
                self.$photosToChange = $('#home-page .image-change-by-district');
                self.$contentToChange = $('#home-page .container-content-toChange');

                if (self.$localStorage) {
                    self.onChangeContent();
                }else{
                    $('.no-opacity').removeClass('no-opacity');
                }

            },

            onRender: function () {

                var self = this;
            },

            //add event handlers to them
            onAddListeners: function () {

                var self = this;

                window.$vent.off('changeContent');
                window.$vent.on('changeContent', $.proxy(self._onChangeContent, self));

            },

            _onChangeContent: function (e, data) {
                var self = this;

                self.$localStorage = data.localStorage;

                self.onChangeContent();
            },

            onChangeContent: function () {
                var self = this;
                console.log("onChangeContent");

                self.changePhotos();
                self.changeContent();

            },

            changePhotos: function () {
                var self = this;
                console.log("changePhotos");

                self.$photosToChange.each(function (i, item) {
                    var currentSrc = $(item).attr('src');
                    var currentCssBackgroundUrl = $(item).css('background-image');

                    if (currentCssBackgroundUrl != 'none') {
                        var newSrc = self.parseSrc(currentCssBackgroundUrl);

                        $(item).css('background-image', newSrc);

                    } else {
                        if (currentSrc && currentSrc != 'undefined') {
                            var newSrc = self.parseSrc(currentSrc);

                            $(item).attr('src', newSrc);
                            window.setTimeout(function () {
                                $(item).removeClass('no-opacity');
                            }, 1);

                        }
                    }

                })

            },

            changeContent: function () {
                var self = this;
                console.log("changeContent");

                self.$contentToChange.each(function (i, contentBox) {
                    var contentToHide = $(contentBox).find('[data-district-content]');
                    var contentToShow = $(contentBox).find('[data-district-content="'+self.$localStorage+'"]');

                    contentToHide.addClass('hide');
                    contentToShow.removeClass('hide');
                });
            },

            parseSrc: function (currentSrc) {
                var self = this;

                var substrFrom = currentSrc.indexOf('home-');
                var substrTo = currentSrc.lastIndexOf('/');
                var substring = currentSrc.substring(substrFrom, substrTo);
                var newSubstring = 'home-' + self.$localStorage;

                var newSrc = currentSrc.replace(substring, newSubstring);

                return newSrc;

            },


        });

        return DistrictPopup;
    });
