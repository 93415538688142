define(
	'modules/favorite-properties/app',[
		'backbone',
		'modules/favorite-properties/views/FavoritePropertyView',
	], 
	function(Backbone, FavoritePropertyView){

		var AppView = Backbone.View.extend({

			// core vars

			$favoritePropertiesContainer: null,

			favoriteProperties: null,
			tweenDelay: null,

			// init

			initialize: function(options){

				var self = this;
				window.$vent.trigger('favoritePropertiesReady');
			},

			// start module --------------------------------------------------------------------  /

			start: function(){

				var self = this;
				
				$(function(){
					self.onDomReady();
				});
			},

			onDomReady: function(){

				var self = this;
				self.$favoritePropertiesContainer = $('.favorite-properties');
				self.favoriteProperties = [];

				_.each(self.$favoritePropertiesContainer, function(favoriteProperty, i){
					
					var $favoritePropertiesContainer = $(favoriteProperty);
					var $favoriteProperties = $favoritePropertiesContainer.find('.favorite-property');

					for(var i=0; i<$favoriteProperties.length; i++){

						var $favoriteProperty = $($favoriteProperties[i]);
						var favoritePropertyView = new FavoritePropertyView({
							el: $favoriteProperty,
							$container: self.$favoritePropertiesContainer
						});

						favoritePropertyView.$el.on('mouseenter', $.proxy(self._onMouseEnter, self));
						favoritePropertyView.$el.on('mouseleave', $.proxy(self._onMouseLeave, self));

						self.favoriteProperties.push(favoritePropertyView);
					}
				});	

				self.$favoriteProperties = self.$favoritePropertiesContainer.find('li');
				

				window.$vent.trigger('domUpdate', {view:self, type:'favorite-properties'});
			},

			_onMouseEnter: function(e){

				var self = this;
				var $target = $(e.currentTarget);

				if(!$target.hasClass('over')){

					var $favoritePropertyContainer = $target.parent();
					var $favoriteProperties = $favoritePropertyContainer.find('li.favorite-property');					
					var $lastTarget = $favoritePropertyContainer.find('.over');

					$lastTarget.removeClass('over');
					$target.addClass('over');

					_.each($favoriteProperties, function(favoriteProperty, i){						
						
						var $favoriteProperty = $(favoriteProperty);

						if(!$favoriteProperty.hasClass('over') && window.innerWidth >= 768){

							var $veil = $favoriteProperty.find('.image-veil');
							
							TweenMax.set($veil, {opacity:1});
							if(self.tweenDelay){ self.tweenDelay.kill(); }
							
							$veil.removeClass('hidden');
						}
					});
				}
			},

			_onMouseLeave: function(e){

				var self = this;
				var $target = $(e.currentTarget);
				
				if($target.hasClass('over')){

					var $favoritePropertyContainer = $target.parent();
					var $favoriteProperties = $favoritePropertyContainer.find('li.favorite-property');
					
					$target.removeClass('over');

					_.each($favoriteProperties, function(favoriteProperty, i){			
						
						var $favoriteProperty = $(favoriteProperty);

						if(!$favoriteProperty.hasClass('over')){

							var $veil = $favoriteProperty.find('.image-veil');
						
							TweenMax.set($veil, {opacity:0});
							
							if(self.tweenDelay){ self.tweenDelay.kill(); }
							self.tweenDelay = TweenMax.delayedCall(0.6, function(){
								$veil.removeClass('hidden').addClass('hidden');
							});
						}
					});
				}
			},
			
			// ---------------------------------------------------------------------------------  /

		});

		return AppView;
	}
);
