define(
	'modules/pdp-mobile-scroll/views/MobileScrollView.js',[
		'backbone',
		'utils/AbstractView',
	], 
	function(Backbone, AbstractView){

		var MobileScroll = Backbone.View.extend({

			// core vars
			$el: null,
			$container: null,

			// init
			initialize: function(options){

				var self = this;

				$(function () {
					self.onDomReady();
					self.onAddListeners();
				});

			},

			//grab the properties
			onDomReady: function() {

				var self = this;

				self.$el					= $('#property-detail-page');
				self.$container				= self.$el.find('.mobile-scroll-pdp');
				window.mobilescrollpdp 		= false;	

				self.bottomBtn				= $('.pdb-bottom-btn');
			},

			onRender: function(){
				var self = this;
			},

			//add event handlers to them
			onAddListeners: function() {
				var self = this;				
				$(window).scroll($.proxy( self._onMobileScroll, self ));
				$(window).on('touchmove', $.proxy( self._onMobileScroll, self ));				
				$(window).on('touchstart', $.proxy( self._onMobileScroll, self ));
				$(window).on('touchend', $.proxy( self._onMobileScroll, self ));
				$(window).on('touchcancel', $.proxy( self._onMobileScroll, self ));
				$(window).on('gesturechange', $.proxy( self._onMobileScroll, self ));
				$(window).on('gesturestart', $.proxy( self._onMobileScroll, self ));
				$(window).on('gestureend', $.proxy( self._onMobileScroll, self ));
			},

			_onMobileScroll: function() {
				var self = this;
				var currentScroll = $(document).scrollTop();
				var targetScroll = $('.mobile-hold-book').offset().top + $('.mobile-hold-book .heart-container').height();

				//console.log(currentScroll, targetScroll, 'ssss');
				
				if (currentScroll > targetScroll) {
					if (!window.mobilescrollpdp) {
						self.$container.addClass('yesvisible');
						window.mobilescrollpdp = true;
						/*self.$container.show();						
						TweenMax.fromTo(self.$container, 0.4, {opacity:0, top:-100}, {opacity:1, top: 0, onComplete: function(){
								window.mobilescrollpdp = true;
						}});*/
						
						self.bottomBtn.addClass('yesvisible');
						
					}
				} else {
					if (window.mobilescrollpdp) {	
						self.$container.removeClass('yesvisible');
						window.mobilescrollpdp = false;
						/*TweenMax.to(self.$container, 0.4, {opacity:0, top:-100, onComplete: function(){
							self.$container.hide();
							window.mobilescrollpdp = false;
						}});*/
						
						self.bottomBtn.removeClass('yesvisible');
					}
				}
			}

		});

	return MobileScroll;
});
