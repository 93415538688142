define(
	'modules/content-tabs/app',[
		'backbone',
		'modules/content-tabs/views/ContentTabs.js',
	], 
	function(Backbone, ContentTabs){

		var AppView = Backbone.View.extend({

			// init

			initialize: function() {

				var self = this;

			},
			
			// start module --------------------------------------------------------------------  /

			start: function(){

				var self = this;
				self.$el = new ContentTabs();
				window.$vent.trigger('contentTabsReady');
			}
		});

		return AppView;
	}
);
