define(
        'modules/property-results-map/app',[
            'backbone',
            'modules/property-results-map/views/MapModalView',
            'text!../../config/map-styles.json'
        ],
        function (Backbone, MapModalView, MapStyles) {

            var AppView = Backbone.View.extend({
                // vars

                mapStyleData: null,
                map: null,
                markers: [],
                mapModal: null,
                $mapContainer: null,
                $mapCanvas: null,
                $resultsGrid: null,
                $modalContainer: null,
                $autoRefreshCheckbox: null,
                $mapButtons: null,
                $zoomInButton: null,
                $zoomOutButton: null,
                $refreshButton: null,
                resizeTimeout: null,
                dragTimeout: null,
                initialBoundsListener: null,
                listingsArray: null,
                clickedMarker: null,
                panTweenObj: null,
                lastRefreshTime: 0,
                visibleListings: null,
                $mapExpandButton: null,
                mapExpand: null,
                $mapOptionsExpand: null,
                mapOptionsExpanded: true,
                paginationIndex: 0,
                isPendingRefresh: true,
                isAutoRefresh: false,
                isRefreshing: false,
                isInitialMapPopulation: true,
                isDraggingMap: false,
                isFirstRefresh: false,
                // init

                initialize: function () {

                    var self = this;

                    self.mapStyleData = JSON.parse(MapStyles);

                    self.$mapContainer = $('#property-listing-map');
                    self.$mapCanvas = self.$mapContainer.find('#map-canvas');
                    self.$resultsGrid = $('#results-grid');
                    self.$modalContainer = $('#map-info-modals');

                    self.$mapButtons = self.$mapContainer.find('.map-buttons');
                    self.$zoomInButton = self.$mapButtons.find('.fa-plus');
                    self.$zoomOutButton = self.$mapButtons.find('.fa-minus');
                    self.$refreshButton = self.$mapButtons.find('.map-refresh');

                    self.panTweenObj = {lat: 0, lng: 0};

                    self.$mapExpandButton = self.$mapContainer.find('.map-expand');
                    self.$mapOptionsExpand = self.$mapContainer.find('.map-options');

                    self.addListeners();
                },
                // start
                events: {
                    "click .map-expand": "_onMapExpandButtonClick",
                    "click .map-options .map-options-head": "_onMapOpionsExpand"
                },
                addListeners: function () {

                    var self = this;

                    window.$vent.on('hoverPinShow', $.proxy(self._onHoverPin, self));

                    self.$zoomInButton.on('click', function () {
                        self.map.setZoom(self.map.zoom + 1);

                        if (!self.mapExpand) {
                            var spanCds = self.map.getBounds().toSpan();
                            var mapCenter = self.map.getCenter();
                            var win_width = $(window).width() * 0.97;
                            var inner_width = $('.pl-content-wrapper').width();
                            var percent = (win_width - inner_width) / win_width;
                            var moveLng = mapCenter.lng() - spanCds.lng() * percent;
                            self.map.panTo(new google.maps.LatLng(mapCenter.lat(), moveLng));
                        }
                    });

                    self.$zoomOutButton.on('click', function () {
                        if (self.map.zoom > 10) {
                            self.map.setZoom(self.map.zoom - 1);

                            if (!self.mapExpand) {
                                var spanCds = self.map.getBounds().toSpan();
                                var mapCenter = self.map.getCenter();
                                var win_width = $(window).width() * 0.97;
                                var inner_width = $('.pl-content-wrapper').width();
                                self.map.panBy((win_width - inner_width) / 2, 0);
                            }
                        }
                    });

                    self.$refreshButton.on('click', function () {

                        self.isAutoRefresh = !self.isAutoRefresh;

                        if (self.isAutoRefresh) {
                            self.$refreshButton.removeClass('selected').addClass('selected');
                            self.refreshListings();
                        } else {
                            self.$refreshButton.removeClass('selected');
                        }
                    });
                },
                start: function () {

                    var self = this;

                    // global event listeners ------------------------------------------------------  /

                    window.$vent.on('reloadListings', $.proxy(self._onQueryListings, self));
                    window.$vent.on('createListingsComplete', $.proxy(self._onListingsQueryComplete, self));
                    window.$vent.on('resizeMap', $.proxy(self.onWindowResize, self));

                    // listeners -------------------------------------------------------------------  /

                    $(window).on('resize', $.proxy(self.onWindowResize, self));
                    self.onWindowResize();

                    // search for auto refresh checkbox

                    self.$autoRefreshCheckbox = $('#map-refresh-toggle');

                    if (self.$autoRefreshCheckbox.length) {
                        self.$autoRefreshCheckbox.on('click', $.proxy(self._onRefreshToggleClick, self));
                        self.checkRefreshToggleStatus();
                    }

                    // load maps -- weird fix thanks to https://mattsurabian.github.io/requirejs-projects-and-asynchronously-loading-the-google-maps-api/

                    window.google_maps_loaded = function () {
                        self.initMaps();
                    };
                    require([window.__ss_map_api_key]);
                },
                initMaps: function () {

                    var self = this;
                    var stylesArray = self.mapStyleData.results;
                    var latlng = new google.maps.LatLng(40.4378271, -3.6795367);
                    var block = true;

                    /*ALWAYS no at first even if box is checked*/
                    self.isPendingRefresh = false;
                    self.isAutoRefresh = false;

                    $('.property-listing-map').hover(function () {
                        if ($('#map-refresh-toggle').hasClass('selected')) {
                            self.isAutoRefresh = true;
                            self.isPendingRefresh = true;
                        }
                    }, function () {
                        self.isPendingRefresh = false;
                        self.isAutoRefresh = false;
                    });
                    /*ONLY IF over the map allow the thing to happen*/

                    var myOptions = {
                        styles: stylesArray,
                        zoom: 14,
                        center: latlng,
                        mapTypeId: google.maps.MapTypeId.ROADMAP,
                        disableDefaultUI: true,
                        scaleControl: true,
                        scrollwheel: false,
                    };

                    self.map = new google.maps.Map(document.getElementById("map-canvas"), myOptions);
                    // TEMP - click to get lat/lng
                    
//                    console.log("HOOHOHOHOHOHOHOHOHOHOHOHO111111111111111111111111111111111111111");
//
//                            var max_lat = Math.max.apply(Math, lats);
//                            var min_lat = Math.min.apply(Math, lats);
//                            var max_lng = Math.max.apply(Math, longs);
//                            var min_lng = Math.min.apply(Math, longs);
//
//                            var minCoords = new google.maps.LatLng(min_lat, min_lng);
//                            var maxCoords = new google.maps.LatLng(max_lat, max_lng);
//                            console.log(minCoords);
//                            console.log(maxCoords);
//                                if (!self.map.getBounds().contains(minCoords) || !self.map.getBounds().contains(maxCoords)) {
//                                    console.log("NOT CONTAINS");
//                                    self.map.setZoom(self.map.zoom - 1);
//
//                                } else {
//                                    console.log("CONTAINS");
//                                }

                    google.maps.event.addListener(self.map, 'click', function (event) {
                        displayCoordinates(event.latLng);
                        self.closeOpenModal();
                    });

                    function displayCoordinates(pnt) {

                        var lat = pnt.lat();
                        lat = lat.toFixed(4);
                        var lng = pnt.lng();
                        lng = lng.toFixed(4);
                        //console.log("Latitude: " + lat + "  lngitude: " + lng);
                    }

                    // map listeners
                    google.maps.event.addListener(self.map, 'center_changed', function (event) {
                        self.updateModalPos();
                    });

                    google.maps.event.addListener(self.map, 'dragstart', function (event) {
                        self.isDraggingMap = true;
                    });

                    google.maps.event.addListener(self.map, 'dragend', function (event) {
                        self.isDraggingMap = false;
                        if (self.isAutoRefresh || self.isPendingRefresh) {
                            self.refreshListings(true, true);
                        }
                    });

                    google.maps.event.addListener(self.map, 'drag', function (event) {
                        if (self.isAutoRefresh) {
                            self.refreshListings();
                        }
                    });

                    google.maps.event.addListener(self.map, 'idle', function (event) {
                        if (self.isAutoRefresh || self.isPendingRefresh) {
                            self.refreshListings(true, true);
                        }
                    });

                    window.$vent.trigger('mapReady');
                },
                onWindowResize: function (e) {

                    var self = this;

                    //Set the canvas size						
                    if (!self.mapExpand) {

                        var mapContainerWidth = $('#property-listing-map').width();
                        var win_width = ($(window).width() * 0.97) - mapContainerWidth;
                        var win_height = $(window).innerHeight();
                        self.$('.map-fixed').css({'width': win_width});
                        self.$mapContainer.css({'height': (win_height - $('#main-menu-wrapper').height())});
                    } else {
                        var listingWidth = $('.property-listing-inner').width();
                        var windowWidth = $(window).width();
                        windowWidth = (windowWidth < 1700) ? windowWidth : 1700;

                        var mapWidth = windowWidth - listingWidth;
                        //console.log('Marp width:', mapWidth);
                        self.$mapContainer.css({'width': mapWidth});
                        setTimeout(function () {
                            self.$mapContainer.find('.map-buttons').css({'width': mapWidth - 20});
                        }, 200);
                    }

                    if (typeof (google) !== 'undefined') {
                        google.maps.event.trigger(self.map, "resize");
                    }

                    if ((e || self.isInitialMapPopulation) && typeof (google) !== 'undefined') {
                        self.initialBoundsListener = google.maps.event.addListener(self.map, 'bounds_changed', self._onMapResize.bind(self));
                        self.isInitialMapPopulation = false;
                    }

                    if (self.listingsArray) {
                        self.filterListingsInBounds();
                    }
                },
                _onMapResize: function () {

                    var self = this;

                    if (self.isAutoRefresh || self.isFirstRefresh) {
                        self.refreshListings();
                    }


                },
                // auto refresh toggle -------------------------------------------------------------  /

                _onRefreshToggleClick: function (e) {

                    var self = this;
                    self.checkRefreshToggleStatus(true);
                },
                checkRefreshToggleStatus: function () {

                    var self = this;

                    if (self.$autoRefreshCheckbox.length) {

                        window.requestAnimationFrame(function () {

                            if (self.$autoRefreshCheckbox.hasClass('selected')) {
                                self.isAutoRefresh = true;
                                self.refreshListings(true, true);
                            } else {
                                self.isAutoRefresh = false;
                            }
                        });
                    }
                },
                // update listings ---------------------------------------------------------------------  /

                refreshListings: function (forceCheck, forceRebuild) {

                    var self = this;
                    var currentTime = (new Date()).getTime();
                    var MIN_REFRESH_TIME = 333;

                    if (self.listingsArray && (forceCheck === true || currentTime - self.lastRefreshTime > MIN_REFRESH_TIME)) {

                        if (self.markers.length) {
                            for (var i = 0; i < self.markers.length; i++) {
                                self.markers[i].setMap(null);
                            }
                        }

                        self.lastRefreshTime = currentTime;
                        self.filterListingsInBounds(forceRebuild);

                        self.isPendingRefresh = false;
                        self.isFirstRefresh = false;
                    }
                },
                _onQueryListings: function () {

                    var self = this;

                    self.closeOpenModal();

                    if (self.initialBoundsListener) {
                        google.maps.event.removeListener(self.initialBoundsListener);
                        self.initialBoundsListener = null;
                    }
                },
                _onListingsQueryComplete: function (e, param1) {

                    var self = this;

                    if (self.$mapContainer.css('display') !== 'block') {
                        window.$vent.trigger('mapResults', [param1, false]);
                    } else {

                        self.listingsArray = param1;
                        self.isPendingRefresh = true;
                        self.refreshListings();

                        if (!self.isAutoRefresh) {

                            var mediumLat = 0;
                            var mediumLng = 0;
                            var lats = [];
                            var longs = [];

                            for (var k = 0; k < self.listingsArray.length; k++) {
                                var listing = self.listingsArray[k];
                                mediumLat += listing.addressLatitude;
                                mediumLng += listing.addressLongitude;
                                lats.push(listing.addressLatitude);
                                longs.push(listing.addressLongitude);
                            }

                            mediumLat = mediumLat / self.listingsArray.length;
                            mediumLng = mediumLng / self.listingsArray.length;
                            
                            google.maps.event.addListener(self.map, 'bounds_changed', function () {

                                if (!self.mapExpand) {
                                    var spanCds = self.map.getBounds().toSpan();
                                    var win_width = $(window).width() * 0.75;
                                    var inner_width = $('.pl-content-wrapper').width();
                                    var percent = (win_width - inner_width) / win_width;

                                    mediumLng += spanCds.lng() * percent;
                                }
                            });



                            /*if(!self.mapExpand){	
                             var spanCds = self.map.getBounds().toSpan();
                             var win_width = $(window).width() * 0.75;
                             var inner_width = $('.pl-content-wrapper').width();
                             var percent = (win_width-inner_width)/win_width;
                             
                             mediumLng += spanCds.lng()*percent;						
                             }*/
                            self.map.setCenter(new google.maps.LatLng(mediumLat, mediumLng));
                        }
                    }
                },
                filterListingsInBounds: function (forceRebuild) {

                    var self = this;

                    if (typeof (self.map) !== 'undefined' && self.map.getBounds()) {

                        var availableListings = self.listingsArray.concat();
                        var newVisibleListings = [];
                        var newListings = _.clone(availableListings);

                        // generate and position markers

                        var oldMarkers = _.uniq(self.markers);
                        self.markers = [];

                        for (var i = 0; i < oldMarkers.length; i++) {

                            var marker = oldMarkers[i];
                            var isWithinBounds = false;

                            if (!self.map.getBounds().contains(marker.position)) {
                                marker.setMap(null);
                            } else {
                                self.markers.push(marker);
                                isWithinBounds = true;
                            }

                            // make ineligible for new marker

                            for (var k = 0; k < self.listingsArray.length; k++) {

                                var listing = self.listingsArray[k];
                                var listingCoords = new google.maps.LatLng(listing.addressLatitude, listing.addressLongitude);

                                if (marker.position.lat() == listingCoords.lat() && marker.position.lng() == listingCoords.lng()) {

                                    var pos = availableListings.indexOf(listing);
                                    //availableListings.splice(pos,1);
                                    newListings.splice(pos, 1);
                                    /*								
                                     if(isWithinBounds){
                                     newVisibleListings.push(listing);
                                     }
                                     */
                                }
                            }
                        }

                        for (var i = 0; i < availableListings.length; i++) {

                            var listing = availableListings[i];
                            var coords = new google.maps.LatLng(listing.addressLatitude, listing.addressLongitude);

//                            if (self.map.getBounds().contains(coords)) {

                                var marker = new google.maps.Marker({
                                    position: coords,
                                    map: self.map,
                                    title: listing.reference,
                                    listingId: listing.id,
                                    icon: {
                                        url: '/assets/marker.png',
                                        anchor: new google.maps.Point(39, 41),
                                        size: new google.maps.Size(68, 48),
                                    },
                                });

                                google.maps.event.addListener(marker, 'click', function () {
                                    var marker = this;
                                    self.openInfoModal(marker);
                                });

                                newVisibleListings.push(listing);
                                self.markers.push(marker);
//                            }
                        }

                        var isDifferent = false;

                        if (!self.visibleListings || !self.visibleListings.length) {
                            self.visibleListings = newVisibleListings;
                            isDifferent = true;
                        } else {
                            isDifferent = !!(_.difference(self.visibleListings, newVisibleListings).length > 0);
                        }

                        if ((forceRebuild === true && self.isAutoRefresh === true)) {

                            if (!self.isDraggingMap) {
                                self.visibleListings = newVisibleListings;
                            }

                            if (newVisibleListings) {
                                window.$vent.trigger('mapResults', [newVisibleListings, self.isDraggingMap]);
                            }
                        } else {
                            window.$vent.trigger('mapResultsUnchanged', [newVisibleListings, self.isDraggingMap]);

                        }

                        //console.log("map query", isDifferent, self.visibleListings);
                    }
                },
                openInfoModal: function (marker) {

                    var self = this;
                    var listing = _.findWhere(self.listingsArray, {id: marker.listingId});

                    if (listing) {

                        var point = self.getMarkerPixelPos(marker);

                        var newModal = new MapModalView({
                            $container: self.$modalContainer,
                            model: listing,
                            x: point.x,
                            y: point.y,
                        });

                        //

                        self.closeOpenModal();

                        self.openModal = newModal;
                        self.clickedMarker = marker;

                        //

                        var isModalOpen = false;

                        TweenMax.killTweensOf(self.panTweenObj);


                        var spanCds = self.map.getBounds().toSpan();
                        var moveLng = self.clickedMarker.position.lng();
                        var moveLat = self.clickedMarker.position.lat();

                        if (!self.mapExpand) {
                            var win_width = $(window).width() * 0.87;
                            var inner_width = $('.pl-content-wrapper').width();
                            var percent = (win_width - inner_width) / win_width;

                            var win_height = $(window).height();
                            var head_height = $('#main-menu-wrapper').height();
                            var percentH = (win_height - head_height) / win_height;

                            moveLng += spanCds.lng() * percent;
                            moveLat += 0.01;

                            //console.log(percentH, 'sssssssss');
                        }

                        TweenMax.fromTo(self.panTweenObj, 0.6, {lat: self.map.getCenter().lat(), lng: self.map.getCenter().lng()},
                                {lat: self.clickedMarker.position.lat(), lng: self.clickedMarker.position.lng(),
                                    onUpdate: function () {
                                        self.map.setCenter(new google.maps.LatLng(self.panTweenObj.lat, self.panTweenObj.lng));
                                    },
                                    ease: Cubic.easeIn});


                        TweenMax.to(self.panTweenObj, 0.6, {lat: moveLat, lng: moveLng, delay: 0.15,
                            onUpdate: function () {

                                self.map.setCenter(new google.maps.LatLng(self.panTweenObj.lat, self.panTweenObj.lng));

                                if (this.ratio >= 0.8 && !isModalOpen) {
                                    isModalOpen = true;
                                    newModal.show();
                                }
                            },
                            ease: Cubic.easeOut});

                        //Tapando el problema del doble click en los enlaces
                        $('#property-listing-map a').click(function() {
                            location.href = $(this).attr('href');
                        });

                    }
                },
                getMarkerPixelPos: function (marker) {

                    var self = this;
                    var scale = Math.pow(2, self.map.getZoom());
                    var nw = new google.maps.LatLng(
                            self.map.getBounds().getNorthEast().lat(),
                            self.map.getBounds().getSouthWest().lng()
                            );
                    var worldCoordinateNW = self.map.getProjection().fromLatLngToPoint(nw);
                    var worldCoordinate = self.map.getProjection().fromLatLngToPoint(marker.getPosition());
                    var pixelOffset = new google.maps.Point(
                            Math.floor((worldCoordinate.x - worldCoordinateNW.x) * scale),
                            Math.floor((worldCoordinate.y - worldCoordinateNW.y) * scale)
                            );

                    return pixelOffset;
                },
                updateModalPos: function () {

                    var self = this;

                    if (self.openModal && self.clickedMarker) {

                        var point = self.getMarkerPixelPos(self.clickedMarker);

                        TweenMax.set(self.openModal.$el, {
                            x: point.x,
                            y: point.y,
                        });
                    }
                },
                closeOpenModal: function () {

                    var self = this;

                    if (self.openModal) {
                        self.openModal.exit();
                    }

                    self.openModal = null;
                    self.clickedMarker = null;
                },
                _onMapExpandButtonClick: function (e) {
                    var self = this;
                    var mapCanvas = self.$mapContainer.find('#map-canvas');
                    var $listingInner = $('.property-listing-inner');
                    var listingWidth = $listingInner.width();
                    var mapWidth = $('#property-listing-map').width();

                    if (mapWidth < 600) {
                        listingWidth -= 600 - mapWidth;
                        mapWidth = 600;
                    }

                    if (!self.mapExpand) {

                        self.mapExpand = {'mapWidth': self.$mapContainer.outerWidth()};

                        TweenMax.to($listingInner, 0.01, {'opacity': 0});
                        TweenMax.to(self.$mapContainer, 1, {'width': listingWidth, onComplete: function () {
                                var center = self.map.getCenter();
                                var lang = $('#lang').text();
                                google.maps.event.trigger(self.map, "resize");
                                self.map.setCenter(center);
                                if(lang == 'es'){
                                    self.$mapExpandButton.find('span').html('Minimizar');
                                }else{
                                    self.$mapExpandButton.find('span').html('Collapse');
                                }
                                self.$mapExpandButton.find('figure').toggleClass('arrow-left arrow-right');
                                TweenMax.to($listingInner, 0, {'width': mapWidth, 'minWidth': 'initial'});
                                $listingInner.addClass('map-expanded');
                                self.$mapContainer.addClass('expanded');
                                TweenMax.to($listingInner, 0.5, {'opacity': 1});
                            },
                            ease: Power2.easeOut}
                        );
                    } else {
                        TweenMax.to($('.property-listing-inner'), 0.01, {'opacity': 1, delay: 0.5});
                        TweenMax.to(self.$mapContainer, 1, {'width': self.mapExpand.mapWidth, onComplete: function () {
                                var center = self.map.getCenter();
                                var lang = $('#lang').text();
                                google.maps.event.trigger(self.map, "resize");
                                self.map.setCenter(center);
                                if(lang == "es"){
                                 self.$mapExpandButton.find('span').html('Expandir');   
                                }else{
                                 self.$mapExpandButton.find('span').html('Expand');   
                                }
                                self.$mapExpandButton.find('figure').toggleClass('arrow-left arrow-right');
                                $listingInner.removeAttr('style');
                                $listingInner.removeClass('map-expanded');
                                self.$mapContainer.removeClass('expanded');
                                self.$mapContainer.removeAttr('style');
                                $(window).trigger('resize');
                            },
                            ease: Power2.easeOut}
                        );
                        self.mapExpand = false;
                    }



                    console.log("AM INTRAT PE RESIZE IN MORTII MATII!");
                    setTimeout(function () {
                        self.$mapContainer.find('.map-buttons').css({'width': self.$mapContainer.width() - 20});
                    }, 2000);
                    e.preventDefault();
                },
                _onMapOpionsExpand: function () {

                    var self = this;
                    var icon = self.$mapOptionsExpand.find('.icon');

                    self.mapOptionsExpanded = !self.mapOptionsExpanded;

                    if (self.mapOptionsExpanded) {
                        TweenMax.to($('.map-options-list'), 0.3, {'opacity': 0});
                        TweenMax.to(icon, 0, {'transform': 'rotate(0deg)', '-webkit-transform': 'rotate(0eg)', 'top': '0'});
                    } else {
                        TweenMax.to($('.map-options-list'), 0.3, {'opacity': 1});
                        TweenMax.to(icon, 0, {'transform': 'rotate(180deg)', '-webkit-transform': 'rotate(180eg)', 'top': '-5px'});
                    }

                },
                _onHoverPin: function (e, param) {
                    var self = this;
                    var clickmarker = _.where(self.markers, {listingId: param});

                    google.maps.event.trigger(clickmarker[0], 'click');
                }

                // ---------------------------------------------------------------------------------  /

            });

            return AppView;
        }
);
