
define(
	'modules/global-scripted-buttons/views/ShowContentsButtonView',[
		'backbone',
		'utils/AbstractView',
		'utils/AbstractButtonView',
	], 
	function(Backbone, AbstractView, AbstractButtonView){

		var ShowContentsButtonView = Backbone.View.extend({

			// core vars

			$el: null,
			$container: null,
			options: null,
			abstractView: null,
			button: null,
			remMoreShow: null,

			// vars

			$targetContainer: null,
			targetClass: null,
			targetId: null,

			isWaitingForDeselect: false,

			// init

			initialize: function(options){

				var self = this;

				self.options = options;
				self.$container = self.options.$container;

				// 

				self.abstractView = new AbstractView({
					view: self,
					autoShow: true
				});

				self.button = new AbstractButtonView({
					abstractView: self.abstractView,
					animateInteractionsWhenSelected: true,
					selectable: true,
				});
			},

			// render

			onRender: function(){

				var self = this;
			},

			//

			onShow: function(){

				var self = this;

				if(self.$el.data('container-id')){
					self.$targetContainer = $('#' + self.$el.data('container-id'));
				}
				else {
					self.$targetContainer = $('body');
				}

				self.targetClass = self.$el.data('target-class') || '';
				self.targetId = self.$el.data('target-id') || '';

				if(self.$el.hasClass('selected')){
					self.select();
				}

				self.trigger('showComplete');				
				
			},

			onAddListeners: function(){

				var self = this;

				window.requestAnimationFrame(function(){
					self.button.on('select', self._onAnimateSelect, self);
					self.button.on('deselect', self._onAnimateDeselect, self);
				});
			},

			//

			remMorePhotos: function(e){
				var self = this;
				var extraPhotos = self.$el.find('.extra-photos');
				e.preventDefault();

				if(extraPhotos.hasClass('display-none')){
					extraPhotos.show();
					TweenMax.fromTo(extraPhotos,0.6,{opacity:0},{opacity:1});
				}else{
					
					TweenMax.to(extraPhotos,0.6,{opacity:0,onComplete:function(){
						extraPhotos.hide();
					}});

				}
			},


			_onAnimateSelect: function(e){

				var self = this;
				var $showState = self.$el.find('.show-state');
				var $hideState = self.$el.find('.hide-state');

				$showState.removeClass('hide');
				$hideState.removeClass('hide').addClass('hide');

				if(self.targetClass){ 
					self.$targetContainer.find('.' + self.targetClass).removeClass('hide');
				}

				if(self.targetId){
					self.$targetContainer.find('#' + self.targetId).removeClass('hide');
				}

				$(window).resize();
			},

			_onAnimateDeselect: function(e){

				var self = this;
				var $showState = self.$el.find('.show-state');
				var $hideState = self.$el.find('.hide-state');

				$showState.removeClass('hide').addClass('hide');
				$hideState.removeClass('hide');

				if(self.targetClass){ 
					self.$targetContainer.find('.' + self.targetClass).removeClass('hide').addClass('hide');
				}

				if(self.targetId){
					self.$targetContainer.find('#' + self.targetId).removeClass('hide').addClass('hide');
				}

				$(window).resize();
			},

			// ---------------------------------------------------------------------------------  /

		});

	return ShowContentsButtonView;
});
