
define(
	'modules/property-detail-bar/views/UpgradeView',[
		'backbone',
		'utils/AbstractView',
		'text!../templates/UpgradeTemplate.html',
		'text!../templates/FeeTemplate.html',
	], 
	function(Backbone, AbstractView, UpgradeTemplate, FeeTemplate){

		var BaseViewTemplate = Backbone.View.extend({

			// core vars

			$el: null,
			$container: null,
			options: null,
			abstractView: null,

			// vars

			$upgradesContainer: null,
			$feesContainer: null,

			$fee: null,

			active: false,

			// init

			initialize: function(options){

				var self = this;

				// options vars

				self.options = options;
				self.$container = self.options.$container;
				self.$upgradesContainer = self.options.$upgradesContainer;
				self.$feesContainer = self.options.$feesContainer;

				// template config

				//self.template = _.template(Template);
				self.model = $.extend({}, self.model);

				// initialize AbstractView

				self.abstractView = new AbstractView({
					view: self,
					autoShow: self.options.autoShow !== false,
				});
			},

			onDomReady: function(){

				var self = this;

			},

			onAddListeners: function(){

				var self = this;
				self.$el.find('.item-price a').on('click', $.proxy(self._onAddClick, self));
			},

			_onAddClick: function(e){

				var self = this;
				self.addUpgrade();

				e.preventDefault();
			},

			addUpgrade: function(){

				var self = this;
				self.active = true;
				self.$el.remove();

				var feeModel = $.extend({}, self.model);

				self.model.item.title = self.model.item.selected.title;
				self.model.item.description = self.model.item.selected.description;

				if(!self.$fee){
					self.$fee = $(_.template(FeeTemplate)(feeModel));
				}

				TweenMax.set(self.$fee, {opacity:0});
				TweenMax.fromTo(self.$fee, 0.6, {opacity:0}, {opacity:1, ease:Cubic.easeInOut});
				self.$feesContainer.append(self.$fee);

				self.trigger('upgradeActive');
			},

		});

	return BaseViewTemplate;
});
