define(
	'modules/timeline/views/TimeLine.js',[
		'backbone',
		'utils/AbstractView',
	], 
	function(Backbone, AbstractView){

		var TimeLineView = Backbone.View.extend({

			// core vars
			$el: null,
			$container: null,
			$applied: null,
			$years: null,
			$currentElement: null,

			currentIndex: 0,

			// init
			initialize: function(options){

				var self = this;

				$(function () {
					self.onDomReady();
					self.onAddListeners();
					self._onWindowResize();
				});

			},

			//grab the properties
			onDomReady: function() {

				var self = this;

				self.$el					= $('.key-milestones');
				self.$years 				= self.$el.find('.timeline-year');
				self.$bigWrapper 			= self.$el.find('.big-wrapper');
				self.$timeImages 			= self.$el.find('.timeline-image');
				self.$description 			= self.$el.find('.year-description');
				self.$currentElement 		= self.$timeImages.eq(0);
				self.$timeImagesDirect		= self.$timeImages.find('img');


			},

			//add event handlers to them
			onAddListeners: function() {

				var self = this;
				
                                
				self.$years.on('click', $.proxy( self._onYearClick, self ));
				$(window).on('resize', $.proxy( self._onWindowResize, self ));
				$(document).ready($.proxy( self._setTimelineHeight, self ));
			},

			_onYearClick: function (e) {
				
				var self = this;
				e.preventDefault();

				self._hideLine();
				self._showLine(e);
			},
			_hideLine: function () {

				var self = this;

				TweenMax.killTweensOf(self.$timeImages);

				self.$years.removeClass('active');
				self.$timeImages.removeClass('active');
				self.$description.removeClass('active');

				TweenMax
					.fromTo(
						self.$currentElement, 
						.2,
						{
							opacity: 	0, 
							top: 		0 
						},
						{
							opacity: 	1, 
							top: 		50,
							onComplete: function() {

								TweenMax
									.set(self.$timeImages, {clearProps:"all"});

							}
						}
					);

			},
			_showLine: function (e) {

				var self = this;

				self.currentIndex = $(e.currentTarget).index();
				self.$yearTarget = $('#year' + $(e.currentTarget).attr('data-year'));
				self.$currentElement = self.$timeImages.eq(self.currentIndex);

				self.$currentElement.addClass('active');
				self.$years.eq(self.currentIndex).addClass('active');	
				self.$yearTarget.addClass('active');

				TweenMax
					.fromTo(
						self.$currentElement, 
						.6, 
						{
							opacity: 	0,
							top: 		50
						},
						{
							opacity: 	1, 
							top: 		0
						},
						.1
					);

			},

			_onWindowResize: function () {

				var self = this;
				self._setTimelineHeight();

			},

			_setTimelineHeight: function () {

				var self = this,
					maxHeight = $(self.$timeImagesDirect[0]).innerHeight();

				for( var i=1; i < self.$timeImagesDirect.length ; i++ ) {
					maxHeight = $(self.$timeImagesDirect[i]).innerHeight();
				}
				
				self.$bigWrapper.height( maxHeight );

			}

		});

	return TimeLineView;
});
