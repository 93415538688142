define(
	'modules/guides-sidepagination/app',[
		'backbone',
		'modules/guides-sidepagination/views/SidePaginationView.js',
	], 
	function(Backbone, SidePaginationView){

		var AppView = Backbone.View.extend({

			// init

			initialize: function() {

				var self = this;

				$(function () {
					self.start();
				});
			},
			
			// start module --------------------------------------------------------------------  /

			start: function(){

				var self = this;
				self.$el = new SidePaginationView();
				window.$vent.trigger('sidePaginationReady');

				window.$vent.on('domUpdate', $.proxy(self._onDomUpdate, self));
			}
		});

		return AppView;
	}
);
