define(
        'modules/favorite-properties/views/FavoritePropertyView',[
            'backbone',
            'utils/AbstractView',
        ],
        function (Backbone, AbstractView) {

            var FavoritePropertyView = Backbone.View.extend({
                // core vars

                $el: null,
                $container: null,
                options: null,
                abstractView: null,
                // options

                index: 0,
                // config

                autoShow: false,
                // flags

                isShowing: false,
                isListening: false,
                isFirstShow: true,
                // vars

                $veil: null,
                $gallery: null,
                $arrowsContainer: null,
                $leftArrow: null,
                $rightArrow: null,
                $galleryImages: null,
                $currentGalleryImage: null,
                $removeButton: null,
                $removeButtonMobile: null,
                galleryRatio: 1.67,
                galleryIndex: 0,
                galleryWidth: 0,
                noProperties: null,
                loadMore: null,
                propElClass: null,
                favoritesTop: null,
                // init

                initialize: function (options) {

                    var self = this;

                    self.options = options;
                    self.$container = self.options.$container;
                    self.el = self.options.el;
                    self.index = self.options.index;
                    self.noProperties = $('.no-fav-row');
                    self.loadMore = $('.fav-load-more');
                    self.propElClass = '.favorite-property';
                    self.favoritesTop = $('.fav-recent');

                    //

                    var galleryWidth = self.$container.data('gallery-width');
                    var galleryHeight = self.$container.data('gallery-height');

                    if (galleryWidth && galleryHeight) {
                        self.galleryRatio = galleryWidth / galleryHeight;
                    }

                    //

                    self.model = $.extend({}, self.model);

                    // 

                    self.abstractView = new AbstractView({
                        view: self,
                        autoShow: true
                    });
                },
                onDomReady: function () {

                    var self = this;

                    self.$veil = self.$el.find('figure.image-veil');

                    self.$gallery = self.$el.find('.gallery');
                    self.$galleryImages = self.$gallery.find('li');
                    self.$currentGalleryImage = $(self.$galleryImages[0]);

                    self.$arrowsContainer = self.$el.find('.arrow-buttons-container');
                    self.$leftArrow = self.$arrowsContainer.find('.arrow-button.left');
                    self.$rightArrow = self.$arrowsContainer.find('.arrow-button.right');

                    self.$removeButton = self.$el.find('.fav-item-close');
                    self.$removeButtonMobile = self.el.find('.delete-fav-property');
                },
                onAddListeners: function () {

                    var self = this;

                    self.$el.on('mouseenter', $.proxy(self._onMouseEnter, self));
                    self.$el.on('mouseleave', $.proxy(self._onMouseLeave, self));

                    self.$leftArrow.on('click', $.proxy(self._onArrowClick, self));
                    self.$rightArrow.on('click', $.proxy(self._onArrowClick, self));

                    self.$removeButton.on('click', $.proxy(self._onCloseClick, self));
                    self.$removeButtonMobile.on('click', $.proxy(self._onCloseClick, self));
                },
                //

                _onMouseEnter: function (e) {

                    var self = this;
                    self.showArrows();
                },
                _onMouseLeave: function (e) {

                    var self = this;
                    self.hideArrows();
                },
                _onArrowClick: function (e) {

                    var self = this;
                    var $target = $(e.currentTarget);

                    if ($target.hasClass('left')) {
                        self.prevItem();
                    } else {
                        self.nextItem();
                    }
                },
                _onCloseClick: function (e) {

                    var self = this;
                    self.removeQuery();

                    self.checkNumbers();
                },
                //

                nextItem: function () {

                    var self = this;
                    var newIndex = self.galleryIndex + 1;

                    self.seekByIndex(newIndex);
                },
                prevItem: function () {

                    var self = this;
                    var newIndex = self.galleryIndex - 1;

                    self.seekByIndex(newIndex);
                },
                seekByIndex: function (index) {

                    var self = this;

                    if (self.galleryIndex != index) {
                        self.changeGalleryImageByIndex(index);
                    }
                },
                // load map query independently of page (if data attribute set) -------------------0  /

                removeQuery: function () {

                    var self = this;

                    console.log('_________________', self);

                    if (self.$removeButtonMobile) {
                        var favId = self.$removeButtonMobile[0].id;
                    } else {
                        var favId = self.$removeButton[0].id;
                    }
                    var data = {};
                    var attrs = favId.split("-");
                    data["propertyId"] = attrs[0];
                    data["propertyType"] = attrs[1];

                    $.ajax({
                        type: 'POST',
                        url: '/userArea/toogleFavoriteAjax',
                        data: JSON.stringify(data),
                        async: false,
                        contentType: "application/json; charset=utf-8",
                        success: function (response) {
                            self.exit();
                        },
                        error: function (e) {
                            console.log("favoriteClick: " + e.message);
                        }

                    });
//
//                    var a = self.$el.data('remove-query');
//
//                    $.ajax({
//                        type: 'GET',
//                        url: self.$el.data('remove-query'),
//                        async: false,
//                        jsonpCallback: 'callBack',
//                        contentType: 'application/json',
//                        dataType: 'jsonp',
//                        success: function (json) {
//                            self.exit();
//                        },
//                        error: function (e) {
//                            console.log('JSON Load Error', self);
//                            console.log(e.message);
//                            self.exit();
//                        }
//                    });
                },
                changeGalleryImageByIndex: function (newIndex) {

                    var self = this;
                    var lastIndex = self.galleryIndex;
                    var $lastGalleryImage = self.$currentGalleryImage;

                    var direction = (newIndex > self.galleryIndex) ? 1 : -1;

                    if (newIndex >= self.$galleryImages.length) {
                        newIndex = 0;
                    } else if (newIndex < 0) {
                        newIndex = self.$galleryImages.length - 1;
                    }

                    self.galleryIndex = newIndex;
                    self.$currentGalleryImage = $(self.$galleryImages[newIndex]);

                    TweenMax.to($lastGalleryImage, 0.3, {x: 0 - self.galleryWidth * direction, force3D: true, ease: Sine.easeIn});
                    TweenMax.to($lastGalleryImage, 0.3, {x: 0 - self.galleryWidth * direction, delay: 0.2, ease: Cubic.easeOut, onComplete: function () {
                            $lastGalleryImage.removeClass('hidden').addClass('hidden');
                        }});

                    TweenMax.fromTo(self.$currentGalleryImage, 0.3, {x: self.galleryWidth * direction, force3D: true}, {x: 0, ease: Sine.easeIn});
                    TweenMax.to(self.$currentGalleryImage, 0.3, {x: 0, delay: 0.2, ease: Cubic.easeOut});
                    self.$currentGalleryImage.removeClass('hidden');
                },
                // show / hide arrows

                showArrows: function () {

                    var self = this;
                    TweenMax.set(self.$arrowsContainer, {opacity: 1});
                },
                hideArrows: function () {

                    var self = this;
                    TweenMax.set(self.$arrowsContainer, {opacity: 0});
                },
                // show / hide white veil

                showVeil: function () {

                    var self = this;

                    // TweenMax.killTweensOf(self.$veil);
                    // TweenMax.set(self.$veil, {opacity:1});
                    // self.$veil.removeClass('hidden');
                },
                hideVeil: function () {

                    var self = this;

                    // TweenMax.killTweensOf(self.$veil);
                    // TweenMax.set(self.$veil, {opacity:0});
                    // TweenMax.to(self.$veil, 0.6, {ease:Cubic.easeOut, onComplete:function(){
                    // 	self.$veil.removeClass('hidden').addClass('hidden');
                    // }});
                },
                onWindowResize: function () {


                    var self = this;
                    self.galleryWidth = parseInt(self.$gallery.outerWidth());

                    //TweenMax.set(self.$gallery, {width:self.galleryWidth, height:parseInt(self.galleryWidth/self.galleryRatio)});
                },
                //

                onShow: function () {
                    var self = this;
                    self.trigger('showComplete');
                },
                onHide: function () {
                    var self = this;
                    TweenMax.to(self.$el, 0.2, {opacity: 0, ease: Cubic.easeOut, onComplete: function () {
                            self.trigger('hideComplete');
                            window.$vent.trigger('domUpdate');
                        }});
                },
                checkNumbers: function () {
                    var self = this;

                    var remaining_prop = self.$container.find(self.propElClass).length;

                    if (remaining_prop < 2) {
                        self.loadMore.fadeOut(function () {
                            self.noProperties.fadeIn(function () {
                                self.favoritesTop.fadeOut()
                            });
                        });
                    }

                }




            });

            return FavoritePropertyView;
        });
