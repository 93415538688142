define(
        'modules/user-pages/views/UserNotesBtn',[
            'backbone',
            'utils/AbstractView',
            'utils/AbstractButtonView',
        ],
        function (Backbone, AbstractView, AbstractButtonView) {

            var UserNotesBtn = Backbone.View.extend({
                // core vars

                $el: null,
                $container: null,
                options: null,
                abstractView: null,
                template: null,
                displayed_class: 'displayed',
                hidden_class: 'not-displayed',
                // init

                initialize: function (options) {

                    var self = this;

                    self.addListeners();
                    //

                    self.model = $.extend({}, self.model);

                    // 

                    self.abstractView = new AbstractView({
                        view: self,
                        autoShow: true
                    });


                    self.displayed_class = 'displayed';
                    self.hidden_class = 'not-displayed';

                },
                addListeners: function () {
                    var self = this;

                    self.$el.on('click', $.proxy(self._onUpdateNote, self));
                },
                onShow: function () {
                    var self = this;
                    if (!self.$el.hasClass(self.displayed_class))
                        self.$el.toggleClass(self.hidden_class + ' ' + self.displayed_class);
                },
                _onUpdateNote: function (e) {
                    var self = this;
                    
                    var favId = self.$el[0].id;
                    var noteText = $('textarea[id='+ favId +']').val();
                    
                    var data = {};
                    var attrs = favId.split("-");
                    data["propertyId"] = attrs[0];
                    data["propertyType"] = attrs[1];
                    data["note"] = noteText;

                    $.ajax({
                        type: 'POST',
                        url: '/userArea/updateFavNoteAjax',
                        data: JSON.stringify(data),
                        async: false,
                        contentType: "application/json; charset=utf-8",
                        success: function (response) {
                            self.exit();
                        },
                        error: function (e) {
                            console.log("favoriteClick: " + e.message);
                        }

                    });
                }

            });

            return UserNotesBtn;
        });
