define(
	'modules/timeline/app',[
		'backbone',
		'modules/timeline/views/TimeLine.js',
	], 
	function(Backbone, TimeLine){

		var AppView = Backbone.View.extend({

			// core vars

			$context: null,

			$moduleContainers: null,
			module: null,

			// init

			initialize: function() {

				var self = this;

				$(function () {
					self.start();
				});
			},
			
			// start module --------------------------------------------------------------------  /

			start: function(){

				var self = this;
				self.$el = new TimeLine();
				window.$vent.trigger('timeLineReady');

				window.$vent.on('domUpdate', $.proxy(self._onDomUpdate, self));
			}
		});

		return AppView;
	}
);
