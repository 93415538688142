define(
	'modules/why-team/views/WhyTeam.js',[
		'backbone',
		'utils/AbstractView',
	], 
	function(Backbone, AbstractView){

		var WhyTeam = Backbone.View.extend({

			// core vars
			$el: null,
			$container: null,
			$currentElement: null,

			// init
			initialize: function(options){

				var self = this;

				$(function () {
					self.onDomReady();
					self.onAddListeners();
				});

			},

			//grab the properties
			onDomReady: function() {

				var self = this;

				self.$el					= $('#why-team');
				self.$currentElement 		= $('.team-tabs-inner a');
				self.$container				= $('.team-wrapper');
				self.$navLine 				= $('.team-tabs').find('.nav-line');

			},

			onRender: function(){
				var self = this;
				TweenMax.set(self.$navLine, {transformOrigin:'0% 0%'});
			},

			//add event handlers to them
			onAddListeners: function() {
				var self = this;				
				self.$currentElement.on('click', $.proxy( self._onTeamClick, self ));
			},

			_onTeamClick: function(e){
				e.preventDefault();
				var self = this;
				var $target = $($(e.currentTarget).attr('href'));

				self.$currentElement.removeClass('active');
				self.$container.removeClass('active');

				$(e.currentTarget).addClass('active');
				$target.addClass('active');

				if( $(e.currentTarget).prev().length > 0) {
					TweenMax.fromTo($target, 0.6, { left: '-100%', opacity:	0 }, { left: 0, opacity: 1, ease:Cubic.easeOut });
				} else {
					TweenMax.fromTo($target, 0.6, { right: '-100%', opacity: 0 }, { right: 0, opacity: 1, ease:Cubic.easeOut });
				}

				var navItemOffset = $(e.currentTarget).position();

				TweenMax.to(self.$navLine, 0.4, {x:navItemOffset.left, opacity:1, ease:Quint.easeOut});

				return false;
			}

		});

	return WhyTeam;
});
