
define(
	'modules/calendar-single/views/CalendarSingleMenuButtonView',[
		'backbone',
		'utils/AbstractView',

		'modules/calendar-single/views/CalendarSingleMenuView',

		'moment',
		'clndr'
	], 
	function(Backbone, AbstractView, CalendarSingleMenuView){

		var CalendarSingleMenuButtonView = Backbone.View.extend({

			// core vars

			$el: null,
			$container: null,
			options: null,
			abstractView: null,

			//

			$context: null,
			$calendarsContainer: null,

			overlayMenu: null,
			$calendarButton: null,

			isModalOpen: false,

			// init

			initialize: function(options){

				var self = this;

				self.options = options;
				self.$container = self.options.$container;
				self.$context = self.options.$context;
				
				if(self.options.$calendarsContainer && self.options.$calendarsContainer.length){
					self.$calendarsContainer = self.options.$calendarsContainer;
				} else {
					self.$calendarsContainer = self.$el;
				}

				//self.template = _.template(Template);
				self.model = $.extend({

				}, self.model);

				// 

				self.abstractView = new AbstractView({
					view: self,
					autoShow: true,
				});

				//

				$(window).on('resize', $.proxy(self._onWindowResize, self));
				self._onWindowResize();
			},

			//

			onDomReady: function(){

				var self = this;

				self.$calendarButton = self.$el.find('.calendar-button');
			},

			onAddListeners: function(){

				var self = this;

				self.overlayMenu = new CalendarSingleMenuView({
					$context: self.$context,
					$container: self.getCalendarContainer(),
					propertyCalendar: false,
					hideMobileWrapper: self.$el.data('mobile-wrapper-id') !== false,
					autoShow: false,
					model: self.model,
				});

				self.overlayMenu.on('optionSelected', self._onDateSelected, self);
				self.overlayMenu.on('closeMenu', self._onCloseMenu, self);
				self.overlayMenu.on('hide', self._onMenuClosed, self);

				// individual button listeners

				self.$calendarButton.on('click', $.proxy(self._onCalendarButtonClick, self));
			},

			_onCalendarButtonClick: function(e){

				var self = this;
				var $target = $(e.currentTarget);

				self.showOverlayMenu($target);
				e.preventDefault();	

				window.requestAnimationFrame(function(){
					$(':focus').blur();
				});
			},

			// listener methods

			showOverlayMenu: function($target){

				var self = this;

				if(!self.isModalOpen){

					self.isModalOpen = true;
					self.overlayMenu.show();

					// default value 

					var val = $target.data('date');
					var date = val ? val : moment().format('YYYY-MM-DD');
					
					self.overlayMenu.triggerOption(date, date);

					//

					self.$calendarsContainer.removeClass('display-none');

					$(document).off('click', $.proxy(self._onOutClick, self));
					$(document).on('click', $.proxy(self._onOutClick, self));

					window.$vent.trigger('openFiltersMenu');
				}
			},

			_onDateSelected: function(e){

				var self = this;
				
				self.isDateSelected = true;
				self.dateQuery = e.query;

				self.updateFilterButtonText('calendar-input', e);
			},

			_onCloseMenu: function(e){

				var self = this;
				self.closeCurrentMenu();
			},

			_onMenuClosed: function(e){

				var self = this;
			},

			_onOutClick: function(e){

				var self = this;

				if(e){
					
					if(!self.$el[0].contains(e.target) && !self.$calendarsContainer[0].contains(e.target) && $('body')[0].contains(e.target)){
						self.closeCurrentMenu();
					}	
				}
				else { self.closeCurrentMenu(); }
			},

			// close menu

			closeCurrentMenu: function(e){

				var self = this;

				if(self.isModalOpen){

					if(self.overlayMenu.$el[0] != self.$el[0]){
						self.overlayMenu.$el.removeClass('display-none').addClass('display-none');
					}

					self.overlayMenu.hide();
					self.isModalOpen = false;

					$(document).off('click', $.proxy(self._onOutClick, self));
				}
			},

			// hide / restore clicked filter button values

			updateFilterButtonText: function(inputClass, e, text){

				var self = this;
				var date = e.date;
				var text = text || e.results;

				var $filterButton = self.$el.find('.' + inputClass);
				var $filterInput = $filterButton.find('input');

				if(!$filterInput.length && $filterButton.is('input')){ $filterInput = $filterButton; }

				if(text){
					
					self.$el.data('date', date);

					if($filterInput.length){ $filterInput.attr('value', text); }
					else { $filterButton.html(text); }

					$filterButton.removeClass('selected').addClass('selected');
				}
				else {

					self.$el.data('date', '');
					
					if($filterInput.length){ $filterInput.attr('value', ''); }
					else { $filterButton.html(''); }

					$filterButton.removeClass('selected');
				}
			},

			//

			getCalendarContainer: function(){

				var self = this;

				if(self.winWidth > window.MOBILE_WIDTH || self.model.noMobile){

					var $filterOverlaysContainer = self.$el.find('.filter-overlays-container');
					if(!$filterOverlaysContainer.length){ 
						$filterOverlaysContainer = $('<div class="filter-overlays-container global-filter-styles"></div>').appendTo(self.$el);
					}

					return $filterOverlaysContainer;
				}
				else {
					return self.$calendarsContainer;
				}

				return self.$el;
			},

			//

			onShow: function(){

				var self = this;

				window.requestAnimationFrame(function(){
					self.trigger('showComplete');
				});
			},

			// window resizing

			_onWindowResize: function(e){

				var self = this;

				self.winWidth = window.innerWidth;
				self.winHeight = window.innerHeight;
				self.docHeight = $(document).outerHeight();

				if(self.winWidth >= window.MOBILE_WIDTH || self.model.noMobile){

					self.isMobile = false;
				}
				else {

					self.isMobile = true;
				}
			},

			//

		});

	return CalendarSingleMenuButtonView;
});
