define(
    'app',[
        'backbone',
        'jquerymousewheel',
        'selectboxit',
        'utils/components/app/ScrollTriggeredElement',
    ],
    function (Backbone) {

        var AppView = Backbone.View.extend({
            // vars

            $el: null,
            $pageContent: null,
            winWidth: 0,
            winHeight: 0,
            scriptsArray: null,
            scriptsLoaded: 0,
            isReady: false,
            // scroll triggering

            lastGroupTriggerTime: 0,
            scrollTriggeredElements: [],
            scrollTriggersQueued: [],
            modules: [],
            // modal window locking

            lockScrollTop: 0,
            maxScrollTop: 0,
            $lockTarget: null,
            lockDepth: null,
            //

            isFirstLoad: true,
            isScriptsChange: false,
            isListening: false,
            isLoggedIn: true,
            // initialize ----------------------------------------------------------------------  /

            initialize: function () {

                var self = this;
                self.$pageContent = $('#page-content-wrapper');
                $("select").selectBoxIt({
                    isMobile: function() {
                        return false;
                    },
                    autoWidth: false,
                    // Uses the jQuery 'fadeIn' effect when opening the drop down
                    showEffect: "fadeIn",

                    // Sets the jQuery 'fadeIn' effect speed to 400 milleseconds
                    showEffectSpeed: 0,

                    // Uses the jQuery 'fadeOut' effect when closing the drop down
                    hideEffect: "fadeOut",

                    // Sets the jQuery 'fadeOut' effect speed to 400 milleseconds
                    hideEffectSpeed: 0


                });
            },
            // start ---------------------------------------------------------------------------  /

            start: function () {

                var self = this;

                self.scriptsArray = [];
                self.modules = [];

                // load modules

                self.reloadScripts();
            },
            // listeners -----------------------------------------------------------------------  /

            addListeners: function () {

                var self = this;

                $(window).on('resize', $.proxy(self._onWindowResize, self));
                $(window).on('scroll', $.proxy(self._onScroll, self));

                window.$vent.on('domUpdate', $.proxy(self._onDomUpdate, self));
                window.$vent.on('seekToTop', $.proxy(self._onSeekToTop, self));
                window.$vent.on('seekToPosition', $.proxy(self._onSeekToPosition, self));
                window.$vent.on('lockScrollZone', $.proxy(self._onLockScrollZone, self));
                window.$vent.on('restoreScrolling', $.proxy(self._onRestoreScrolling, self));
                window.$vent.on('updateScrollZone', $.proxy(self._onUpdateScrollZone, self));

                //events brought from the main menu module
                window.$vent.on('setAlertCount', $.proxy(self._onSetAlertCount, self));
                window.$vent.on('logout', $.proxy(self._onLogout, self));
                window.$vent.on('login', $.proxy(self._onLogin, self));

                self.scrollTriggeredElements = [];
                self.isListening = true;

                self._onWindowResize();
            },
            // listener methods

            _onDomUpdate: function (e, obj) {

                console.log('_onDomUpdate');

                var self = this;

                window.requestAnimationFrame(function () {
                    $(window).resize();
                });

                self.reloadScripts(); // when dom updates check for new scripts (does not remove old scripts)
                self.updateScrollTriggerElements();
                self.sortZIndexElements();
            },
            // script loading ------------------------------------------------------------------  /

            reloadScripts: function ($searchContainer) {

                var self = this;
                var lastScripts = self.scriptsArray.concat();
                var scriptsCount = self.scriptsArray.length;
                self.isReady = false;

                if (!$searchContainer) {
                    $searchContainer = self.$el;
                }
                if ($searchContainer.context === 'undefined') {
                    $searchContainer = $($searchContainer);
                }

                //Main menu is always set
                self.addScript('modules/main-menu/app');
                window.$vent.on('mainMenuReady', $.proxy(self._onScriptLoaded, self));

                // page controllers

                if ($('#property-listing-page').length) {

                    self.addScript('modules/property-results-map/app');
                    window.$vent.on('mapReady', $.proxy(self._onScriptLoaded, self));

                    self.addScript('pagecontrollers/property-listing/app');
                    window.$vent.on('propertyListingPageReady', $.proxy(self._onScriptLoaded, self));

                    // linked scripts
                    self.addScript('modules/property-filters/app');
                    window.$vent.on('propertyFiltersReady', $.proxy(self._onScriptLoaded, self));

                    if ($searchContainer.find('#the-select-target').length) {
                        self.addScript('modules/fake-select-mobile/app');
                        window.$vent.on('fakeSelectReady', $.proxy(self._onScriptLoaded, self));
                    }

                } else if ($('#area-map-page').length) {

                    self.addScript('pagecontrollers/area-map/app');
                    window.$vent.on('areaMapPageReady', $.proxy(self._onScriptLoaded, self));
                }

                // modules that are always available

                self.addScript('modules/global-modals/app');
                window.$vent.on('globalModalsReady', $.proxy(self._onScriptLoaded, self));

                self.addScript('modules/global-form-helpers/app');
                window.$vent.on('globalFormHelpersReady', $.proxy(self._onScriptLoaded, self));

                self.addScript('modules/global-positioning-helpers/app');
                window.$vent.on('globalPositioningHelpersReady', $.proxy(self._onScriptLoaded, self));

                self.addScript('modules/global-scripted-buttons/app');
                window.$vent.on('globalFancyButtonsReady', $.proxy(self._onScriptLoaded, self));

                self.addScript('modules/mobile-interactions/app');
                window.$vent.on('mobileInteractionsReady', $.proxy(self._onScriptLoaded, self));

                self.addScript('modules/spinner/app');
                window.$vent.on('spinnerReady', $.proxy(self._onScriptLoaded, self));

                // modules that might be on the page

                if ($searchContainer.find('.accordian').length) {
                    self.addScript('modules/accordian/app');
                    window.$vent.on('accordianReady', $.proxy(self._onScriptLoaded, self));
                }

                if ($searchContainer.find('.anchor-link').length) {
                    self.addScript('modules/anchor-nav/app');
                    window.$vent.on('anchorNavReady', $.proxy(self._onScriptLoaded, self));
                }

                if ($searchContainer.find('.key-milestones').length) {
                    self.addScript('modules/timeline/app');
                    window.$vent.on('timeLineReady', $.proxy(self._onScriptLoaded, self));
                }

                if ($searchContainer.find('.big-hero').length) {
                    self.addScript('modules/big-hero/app');
                    window.$vent.on('bigHeroReady', $.proxy(self._onScriptLoaded, self));
                }

                if ($searchContainer.find('.blog-detail-carousel').length) {
                    self.addScript('modules/blog-detail-carousel/app');
                    window.$vent.on('blogDetailCarouselReady', $.proxy(self._onScriptLoaded, self));
                }

                if ($searchContainer.find('.blog-header-carousel').length) {
                    self.addScript('modules/blog-header-carousel/app');
                    window.$vent.on('blogHeaderCarouselReady', $.proxy(self._onScriptLoaded, self));
                }

                if ($searchContainer.find('.bottom-image-links').length) {
                    self.addScript('modules/bottom-image-links/app');
                    window.$vent.on('bottomImageLinksReady', $.proxy(self._onScriptLoaded, self));
                }

                if ($searchContainer.find('.calendars-container').length || $searchContainer.find('.calendar-buttons').length) {
                    self.addScript('modules/calendars/app');
                    window.$vent.on('calendarsReady', $.proxy(self._onScriptLoaded, self));
                }

                if ($searchContainer.find('.single-calendar-container').length || $searchContainer.find('.single-calendar-button').length) {
                    self.addScript('modules/calendar-single/app');
                    window.$vent.on('calendarSingleReady', $.proxy(self._onScriptLoaded, self));
                }

                if ($searchContainer.find('.calendar-buttons').length) {
                    self.addScript('modules/calendars/app');
                    window.$vent.on('calendarsReady', $.proxy(self._onScriptLoaded, self));
                }

                if ($searchContainer.find('.city-vertical-gallery').length) {
                    self.addScript('modules/city-vertical-gallery/app');
                    window.$vent.on('cityVerticalGalleryReady', $.proxy(self._onScriptLoaded, self));
                }

                if ($searchContainer.find('.detail-carousel').length) {
                    self.addScript('modules/detail-carousel/app');
                    window.$vent.on('detailCarouselReady', $.proxy(self._onScriptLoaded, self));
                }

                if ($searchContainer.find('.favorite-properties').length) {
                    self.addScript('modules/favorite-properties/app');
                    window.$vent.on('favoritePropertiesReady', $.proxy(self._onScriptLoaded, self));
                }

                if ($searchContainer.find('.featured-properties').length) {
                    self.addScript('modules/featured-properties/app');
                    window.$vent.on('featuredPropertiesReady', $.proxy(self._onScriptLoaded, self));
                }

                if ($searchContainer.find('.hero-filters').length) {
                    self.addScript('modules/hero-filters/app');
                    window.$vent.on('heroFiltersReady', $.proxy(self._onScriptLoaded, self));
                }

                if($searchContainer.find('#user-payments-page').length){
                    self.addScript('modules/owner-area-payouts-filters/app');
                    window.$vent.on('ownerAreaPayoutFiltersReady', $.proxy(self._onScriptLoaded, self));
                }

                if ($searchContainer.find('.guests-container').length || $searchContainer.find('.guests-buttons').length) {
                    self.addScript('modules/guests/app');
                    window.$vent.on('guestsReady', $.proxy(self._onScriptLoaded, self));
                }

                if ($searchContainer.find('.home-inspiration-items').length) {
                    self.addScript('modules/home-inspiration/app');
                    window.$vent.on('homeInspirationReady', $.proxy(self._onScriptLoaded, self));
                }

                //if ($searchContainer.find('.listings').length) {
                //    self.addScript('modules/listings-items/app');
                //    window.$vent.on('listingsItemsReady', $.proxy(self._onScriptLoaded, self));
                //}


                if ($searchContainer.find('.multi-form').length) {
                    self.addScript('modules/multi-form/app');
                    window.$vent.on('multiFormReady', $.proxy(self._onScriptLoaded, self));
                }

                if ($searchContainer.find('#neighborhood-map').length) {
                    self.addScript('modules/neighborhood-map/app');
                    window.$vent.on('neighborhoodMapReady', $.proxy(self._onScriptLoaded, self));
                }

                if ($searchContainer.find('.payment-buttons').length) {
                    self.addScript('modules/payment-buttons/app');
                    window.$vent.on('paymentButtonsReady', $.proxy(self._onScriptLoaded, self));
                }

                if ($searchContainer.find('.promo-module').length) {
                    self.addScript('modules/promo/app');
                    window.$vent.on('promoReady', $.proxy(self._onScriptLoaded, self));
                }

                if ($searchContainer.find('.property-detail-bar').length) {
                    self.addScript('modules/property-detail-bar/app');
                    window.$vent.on('propertyDetailBarReady', $.proxy(self._onScriptLoaded, self));
                }

                if ($searchContainer.find('.properties-grid').length) {
                    self.addScript('modules/properties-grid/app');
                    window.$vent.on('propertiesGridReady', $.proxy(self._onScriptLoaded, self));
                }

                if ($searchContainer.find('.property-lightbox').length) {
                    self.addScript('modules/property-lightbox/app');
                    window.$vent.on('propertyLightboxReady', $.proxy(self._onScriptLoaded, self));
                }

                if ($searchContainer.find('#reservation-sidebar').length) {
                    self.addScript('modules/reservation-sidebar/app');
                    window.$vent.on('reservationSidebarReady', $.proxy(self._onScriptLoaded, self));
                }

                if ($searchContainer.find('#reservation-page').length) {
                    self.addScript('modules/reservation-flow/app');
                    window.$vent.on('reservationFlowReady', $.proxy(self._onScriptLoaded, self));
                }

                if ($searchContainer.find('#sticky-nav').length) {
                    self.addScript('modules/sticky-nav/app');
                    window.$vent.on('stickyNavReady', $.proxy(self._onScriptLoaded, self));
                }

                if ($searchContainer.find('#mobile-reservation-sticky-nav').length) {
                    self.addScript('modules/reservation-sticky-nav/app');
                    window.$vent.on('resStickyNavReady', $.proxy(self._onScriptLoaded, self));
                }

                if ($searchContainer.find('.why-slider').length) {
                    self.addScript('modules/why-slider/app');
                    window.$vent.on('whySliderReady', $.proxy(self._onScriptLoaded, self));
                }

                if ($searchContainer.find('#why_team').length) {
                    self.addScript('modules/why-team/app');
                    window.$vent.on('whyTeamReady', $.proxy(self._onScriptLoaded, self));
                }

                if($searchContainer.find('#home-page').length){
                    self.addScript('modules/home/app');
                    window.$vent.on('homeReady', $.proxy(self._onScriptLoaded, self));
                }

        /*        if ($searchContainer.find('.home-search-box').length) {
                    self.addScript('modules/home-filters/app');
                    window.$vent.on('homeFiltersReady', $.proxy(self._onScriptLoaded, self));
                }*/

                if ($searchContainer.find('.featured-tabs').length) {
                    self.addScript('modules/content-tabs/app');
                    window.$vent.on('contentTabsReady', $.proxy(self._onScriptLoaded, self));
                }

                if ($searchContainer.find('.properties-slider-container').length) {
                    self.addScript('modules/properties-slider/app');
                    window.$vent.on('propertiesSliderReady', $.proxy(self._onScriptLoaded, self));
                }

                if ($searchContainer.find('.user-page').length) {
                    self.addScript('modules/user-pages/app');
                    window.$vent.on('userPageReady', $.proxy(self._onScriptLoaded, self));
                }

                if ($searchContainer.find('.side-pagination').length) {
                    self.addScript('modules/guides-sidepagination/app');
                    window.$vent.on('sidePaginationReady', $.proxy(self._onScriptLoaded, self));
                }

                if ($searchContainer.find('#realestateslider').length) {
                    self.addScript('modules/realestateSlider/app');
                    window.$vent.on('realestateSliderReady', $.proxy(self._onScriptLoaded, self));
                }

                if ($searchContainer.find('.mobile-scroll-pdp').length) {
                    self.addScript('modules/pdp-mobile-scroll/app');
                    window.$vent.on('pdpmobilescrollReady', $.proxy(self._onScriptLoaded, self));
                }

                // count new scripts

                if (self.scriptsArray.length > scriptsCount) {

                    console.log('reloadScripts > load new scripts');
                    console.dir(_.difference(self.scriptsArray, lastScripts));

                    self.checkScriptLoadStatus();
                }
            },
            addScript: function (scriptURL) {

                var self = this;

                if (self.scriptsArray.indexOf(scriptURL) === -1) {
                    self.scriptsArray.push(scriptURL);
                }
            },
            checkScriptLoadStatus: function (e) {

                var self = this;

                console.log("<<<<<<<<<<<<<< checkScriptLoadStatus >>>>>>>>>>>>>>>",e);

                if (self.scriptsLoaded > self.scriptsArray.length) {
                    self.scriptsLoaded = self.scriptsArray.length;
                }

                if (self.scriptsLoaded < self.scriptsArray.length) {
                    require([self.scriptsArray[self.scriptsLoaded]], function (AppView) {

                        var app = new AppView({el: self.el});
                        app.start();

                        self.modules.push(app);
                    });
                }

                if (!self.isReady && (!self.scriptsArray.length || self.scriptsLoaded / self.scriptsArray.length === 1)) {
                    self.isReady = true;
                    self._onModulesLoaded();
                    console.log("self.isReady",self.isReady);
                }
            },
            _onScriptLoaded: function (e) {

                var self = this;

                self.scriptsLoaded++;
                self.checkScriptLoadStatus(e);
            },
            _onModulesLoaded: function () {

                var self = this;

                if (!self.isListening) {
                    self.addListeners();
                }

                $(function () {

                    self._onDomUpdate();

                    if (self.isFirstLoad) {
                        window.$vent.trigger('pageModulesLoaded');
                        self.$pageContent.removeClass('hidden');
                    }

                    self.isFirstLoad = false;
                });
            },
            // z index elements

            sortZIndexElements: function () {

                var self = this;
                var $zIndexElements = self.$el.find('.descending-z-index');

                var sortedZIndexElements = $zIndexElements.sort(
                    function (a, b) {

                        var $a = $(a);
                        var $b = $(b);
                        var aOffset = $a.offset();
                        var bOffset = $b.offset();

                        if (aOffset.top < bOffset.top) {
                            return 1;
                        } else if (aOffset.top == bOffset.top) {

                            if (aOffset.left < bOffset.left) {
                                return -1;
                            } else if (aOffset.left == bOffset.left) {
                                return 0;
                            } else {
                                return 1;
                            }
                        }

                        return -1;
                    }
                );

                _.each(sortedZIndexElements, function (zIndexElement, i) {
                    var $zIndexElement = $(zIndexElement);
                    var zIndex = parseInt($zIndexElement.css('z-index')) || 0;
                    TweenMax.set($zIndexElement, {zIndex: zIndex + (i + 1)});
                });
            },
            // scroll triggered elements

            updateScrollTriggerElements: function () {

                var self = this;
                var $scrollTriggeredElements = self.$el.find('.scroll-triggered');
                var $scrollFadeElements = self.$el.find('.scroll-fade-triggered');

                $scrollTriggeredElements = $scrollTriggeredElements.add($scrollFadeElements);

                var sortedTriggeredElements = $scrollTriggeredElements.sort(
                    function (a, b) {

                        var $a = $(a);
                        var $b = $(b);
                        var aOffset = $a.offset();
                        var bOffset = $b.offset();

                        if (aOffset.top < bOffset.top) {
                            return -1;
                        } else if (aOffset.top == bOffset.top) {

                            if (aOffset.left < bOffset.left) {
                                return -1;
                            } else if (aOffset.left == bOffset.left) {
                                return 0;
                            } else {
                                return 1;
                            }
                        }

                        return 1;
                    }
                );

                var foundElements = [];
                var newElements = [];

                _.each(sortedTriggeredElements, function (scrollElement, i) {

                    var $scrollElement = $(scrollElement);
                    var isFound = false;

                    _.each(self.scrollTriggeredElements, function (scrollTriggeredElement) {

                        if (scrollTriggeredElement.$el[0] === scrollElement) {

                            isFound = true;

                            if (foundElements.indexOf(scrollTriggeredElement) === -1) {
                                foundElements.push(scrollTriggeredElement);
                            }

                            return false;
                        }
                    });

                    if (!isFound) {

                        if ($scrollElement.hasClass('scroll-fade-triggered')) {

                            newElements.push(new ScrollTriggeredElement($scrollElement, $(window), {
                                onBottomTriggerCallback: self._onSimpleScrollTriggered.bind(self),
                                onTopTriggerCallback: self._onQuickTrigger.bind(self),
                                hideClass: 'hidden',
                                triggerFromTop: true,
                            }));
                        } else {

                            newElements.push(new ScrollTriggeredElement($scrollElement, $(window), {
                                onBottomTriggerCallback: self._onScrollElementTriggered.bind(self),
                                onTopTriggerCallback: self._onQuickTrigger.bind(self),
                                hideClass: 'hidden',
                                triggerFromTop: true,
                            }));
                        }
                    }
                });

                // find what's not on screen, destroy

                var removedElements = _.difference(self.scrollTriggeredElements, foundElements);

                _.each(removedElements, function (scrollElement, i) {
                    scrollElement.destroy();
                });

                // combine new and existing

                self.scrollTriggeredElements = _.union(newElements, foundElements);

                _.each(self.scrollTriggeredElements, function (scrollElement) {
                    scrollElement.update();
                });
            },
            _onScrollElementTriggered: function (e, obj) {

                var self = this;
                var $target = e.$el;
                var d = 0;

                if ($target && self.winWidth >= 992) {

                    if ($target.hasClass('scroll-triggered-group')) {
                        d = self.getScrollTriggerDelay($target);
                        self.scrollTriggersQueued.push({$target: $target, func: self.animateScrollTriggeredElement});
                    }

                    self.animateScrollTriggeredElement($target, d);
                }
            },
            _onSimpleScrollTriggered: function (e, obj) {

                var self = this;
                var $target = e.$el;
                var d = 0;

                if ($target && self.winWidth >= 992) {

                    if ($target.hasClass('scroll-triggered-group')) {
                        d = self.getScrollTriggerDelay($target);
                        self.scrollTriggersQueued.push({$target: $target, func: self.animateSimpleTriggeredElement});
                    }

                    self.animateSimpleTriggeredElement($target, d);
                }
            },
            _onQuickTrigger: function (e) {

                var self = this;
                var $target = e.$el;

                TweenMax.to($target, 0.1, {opacity: 1, ease: Cubic.easeOut});
            },
            // grouped trigger timing

            _onQueuedScrollElementTriggered: function ($target) {

                var self = this;
                var pos = -1;

                for (var i = 0; i < self.scrollTriggersQueued.length; i++) {
                    if ($target === self.scrollTriggersQueued[i].$target) {
                        pos = i;
                        break;
                    }
                }

                if (pos >= 0) {

                    self.scrollTriggersQueued.splice(pos, 1);

                    if ($target.offset().top - $(window).scrollTop() <= 0) {

                        self.lastGroupTriggerTime = (new Date()).getTime();

                        for (var i = 0; i < self.scrollTriggersQueued.length; i++) {

                            var queuedItem = self.scrollTriggersQueued[i];
                            var $queuedTarget = queuedItem.$target;
                            var topDistance = $queuedTarget.offset().top - $(window).scrollTop();
                            var d = topDistance <= 0 ? 0 : self.getScrollTriggerDelay($queuedTarget);

                            queuedItem.func.apply(self, [$queuedTarget, d]);
                        }
                    }
                }
            },
            // scroll trigger animation methods

            getScrollTriggerDelay: function ($target) {

                var self = this;
                var d = 0;
                var staggerTime = 0.06;
                var currentTime = (new Date()).getTime();
                var timeDiff = (currentTime - self.lastGroupTriggerTime) / 1000;
                var nextTime = currentTime;

                if (timeDiff <= staggerTime) {
                    nextTime = self.lastGroupTriggerTime + staggerTime * 1000;
                    d = (nextTime - currentTime) / 1000;
                }

                self.lastGroupTriggerTime = nextTime;

                return d;
            },
            animateScrollTriggeredElement: function ($target, d) {

                var self = this;
                d = d || 0;

                TweenMax.killTweensOf($target);

                if ($target.hasClass('submenu')) {

                    TweenMax.fromTo($target, 0.4, {opacity: 0, force3D: true}, {
                        opacity: 1,
                        delay: d,
                        ease: Quint.easeOut
                    });
                    TweenMax.fromTo($target, 0.6, {y: -10, force3D: true}, {
                        y: 0,
                        delay: d,
                        ease: Quint.easeOut,
                        onStart: self._onQueuedScrollElementTriggered.bind(self),
                        onStartParams: [$target]
                    });

                    var $links = $target.find('li');
                    for (var i = 0; i < $links.length; i++) {
                        var $link = $($links[i]);
                        var opacity = $link.css('opacity');
                        TweenMax.fromTo($link, 0.5, {y: 4, opacity: 0, force3D: true}, {
                            y: 0,
                            opacity: opacity,
                            delay: d + 0.12 + 0.03 * i,
                            ease: Cubic.easeOut
                        });
                    }
                } else if ($target.hasClass('color-block')) {
                    TweenMax.fromTo($target, 0.3, {opacity: 0, force3D: true}, {
                        opacity: 1,
                        delay: d,
                        ease: Quad.easeInOut
                    });
                    TweenMax.fromTo($target, 0.8, {y: 12, force3D: true}, {
                        y: 0,
                        delay: d,
                        ease: Quint.easeOut,
                        onStart: self._onQueuedScrollElementTriggered.bind(self),
                        onStartParams: [$target]
                    });
                } else {
                    TweenMax.fromTo($target, 0.6, {opacity: 0, force3D: true}, {
                        opacity: 1,
                        delay: d,
                        ease: Quad.easeInOut
                    });
                    TweenMax.fromTo($target, 0.8, {y: 12, force3D: true}, {
                        y: 0,
                        delay: d,
                        ease: Quint.easeOut,
                        onStart: self._onQueuedScrollElementTriggered.bind(self),
                        onStartParams: [$target]
                    });
                }
            },
            animateSimpleTriggeredElement: function ($target, d) {

                var self = this;
                d = d || 0;

                TweenMax.killTweensOf($target);
                TweenMax.fromTo($target, 0.6, {y: 0, opacity: 0}, {
                    y: 0,
                    opacity: 1,
                    delay: d,
                    ease: Cubic.easeInOut,
                    onStart: self._onQueuedScrollElementTriggered.bind(self),
                    onStartParams: [$target]
                });
            },
            // window seeking

            _onSeekToTop: function () {

                var self = this;
                self.seekToTop();
            },
            _onSeekToPosition: function (e, params) {

                var self = this;
                self.seekToPosition(params.scrollY, params.speed);
            },
            seekToTop: function () {

                var self = this;
                self.seekToPosition(0);
            },
            seekToPosition: function (scrollY, speed) {

                speed = !_.isNumber(speed) ? 3000 : speed;

                var self = this;
                var winHeight = window.innerHeight;
                var currentScrollTop = $(window).scrollTop();
                var tweenObj = {scrollTop: currentScrollTop};
                var time = Math.max(0.6, Math.abs(currentScrollTop - scrollY) / speed);

                TweenMax.to(tweenObj, time, {
                    scrollTop: scrollY, onUpdate: function () {
                        $(window).scrollTop(tweenObj.scrollTop);
                    }, ease: Sine.easeInOut
                });
            },
            // lock scrolling to target

            _onLockScrollZone: function (e, params) {

                var self = this;
                console.log('_onLockScrollZone');
                self.lockScrollZone(params.$target);
            },
            _onRestoreScrolling: function (e, params) {

                var self = this;
                self.restoreScrolling(params.$target, params.hideMobileWrapper);
            },
            _onUpdateScrollZone: function () {

                var self = this;
                self.updateScrollZone();
            },
            lockScrollZone: function ($target) {

                var self = this;

                if (!self.lockDepth) {
                    self.lockDepth = [];
                }

                var $lastLockTarget = self.lockDepth.length ? self.lockDepth[self.lockDepth.length - 1] : null;

                console.log('lockScrollZone', $target[0]);

                if ($target && $target.length && (!$lastLockTarget || $lastLockTarget[0] != $target[0])) {

                    var $window = $(window);

                    $target.data('lockScrollTop', $window.scrollTop());
                    console.log($target, $target.data('lockScrollTop'));

                    self.lockDepth.push($target);
                    self.updateScrollZone();

                    $window.scrollTop(0);
                }
            },
            updateScrollZone: function () {

                var self = this;
                var winHeight = window.innerHeight;

                if (typeof self.lockDepth == undefined || self.lockDepth == null) {
                    return false;
                    //self.lockDepth = [];
                }

                var $lockTarget = self.lockDepth.length ? self.lockDepth[self.lockDepth.length - 1] : null;

                if ($lockTarget && $lockTarget.length) {

                    var validLockTarget = ($lockTarget && $lockTarget.length);
                    var alternativeLockHeight = $lockTarget.find('.inner-container').height();
                    var lockTargetHeight = validLockTarget ? Math.max(winHeight, $lockTarget.height(), alternativeLockHeight) : winHeight;
                    var lockScrollTop = validLockTarget ? $lockTarget.data('lockScrollTop') : 0;

                    self.maxScrollTop = self.lockScrollTop + (lockTargetHeight - winHeight);

                    TweenMax.set($('#page-content-wrapper'), {position: 'absolute', visibility: 'hidden'});
                    TweenMax.set($('#main-wrapper'), {
                        'height': lockTargetHeight,
                        'overflow': 'hidden'
                    });

                    //console.dir($lockTarget);

                    console.log('updateScrollZone', lockTargetHeight);
                }
            },
            restoreScrolling: function ($target, hideMobileWrapper) {

                var self = this;
                var lockTargetPos = self.lockDepth.length ? self.lockDepth.indexOf($target) : -1;
                var $nextLockTarget = lockTargetPos >= 1 ? self.lockDepth[lockTargetPos] : null;

                hideMobileWrapper = (hideMobileWrapper !== false);

                console.log('restoreScrolling');

                if (lockTargetPos >= 0) {

                    var $window = $(window);
                    var validLockTarget = ($target && $target.length);
                    var lockScrollTop = validLockTarget ? $target.data('lockScrollTop') : 0;

                    self.lockDepth.splice(lockTargetPos, Number.MAX_VALUE); // remove lock target and any following

                    if (self.lockDepth.length === 0) {
                        TweenMax.set($('#page-content-wrapper'), {clearProps: 'position, visibility'});
                        TweenMax.set($('#main-wrapper'), {clearProps: 'height, overflow'});
                    }

                    self._onWindowResize();
                    $window.scrollTop(lockScrollTop);
                }
            },
            // resize window

            _onScroll: function (e) {

                var self = this;
                self.scrollTop = $(window).scrollTop();
            },
            _onWindowResize: function (e) {

                var self = this;
                var lastWidth = self.winWidth;
                var lastHeight = self.winHeight;

                self.winWidth = $(window).width();
                self.winHeight = $(window).height();

                _.each(self.scrollTriggeredElements, function (scrollElement, i) {
                    scrollElement.update();
                });

                if (self.lockDepth) {
                    self.updateScrollZone();
                }

                self._onScroll();
            },
            _onSetAlertCount: function (e, obj) {

                var self = this;
                var $alertBubble = $('#main-menu .user-alerts');
                var $countWrappers = $("#main-menu-wrapper .count, #main-menu-expanded-container , #user-favorites-alert");
                var $countWrappersMobile = $("#mobile-profile-menu #user-favorites-alert .count");
                var $favoriteCountWrapper = $("#main-menu-wrapper #favorites-number");
                var countValue = parseInt($countWrappers.first().html());
                var countValueMobile = parseInt($countWrappersMobile.first().html());
                var countAction = obj.count;

                // users must be logged in to set the alert count
                if (!self.isLoggedIn) {
                    window.$vent.trigger('openModal', '/modals/login');
                    e.preventDefault();
                    return false;
                }

                if ($(window).width() > 767) {
                    countValue = countAction == 'increment' ? ++countValue : --countValue;

                    if (countValue > 0) {
                        TweenMax.fromTo($alertBubble, 0.3, {scale: 0, opacity: 1}, {scale: 1, ease: Back.easeOut});

                        $countWrappers.html(countValue);
                        //$favoriteCountWrapper.html(countValue);
                        $alertBubble.removeClass('hide');
                    } else {
                        TweenMax.to($alertBubble, 0.3, {
                            opacity: 0, ease: Cubic.easeOut, onComplete: function () {
                                $alertBubble.removeClass('hide').addClass('hide');
                            }
                        });
                        $countWrappers.html(0);
                        //$favoriteCountWrapper.html(0);
                    }
                } else { //mobile
                    countValueMobile = countAction == 'increment' ? ++countValueMobile : --countValueMobile;

                    if (countValueMobile > 0) {
                        $countWrappersMobile.html(countValueMobile);
                    } else {
                        $countWrappersMobile.html(0);
                    }
                }
            },
            _onLogin: function (e) {

                var self = this;

                if (!self.isLoggedIn) {

                    var $loggedIn = self.$el.find('.logged-in');
                    var $loggedOut = self.$el.find('.logged-out');

                    self.isLoggedIn = true;

                    TweenMax.to($loggedOut, 0.3, {
                        opacity: 0, ease: Cubic.easeOut, onComplete: function () {

                            $loggedOut.removeClass('hide').addClass('hide');

                            TweenMax.fromTo($loggedIn, 0.4, {opacity: 0}, {opacity: 1, ease: Cubic.easeInOut});
                            $loggedIn.removeClass('hide');
                        }
                    });

                    self.closeMenu();
                }
            },
            _onLogout: function (e) {

                var self = this;

                if (self.isLoggedIn) {

                    var $loggedIn = self.$el.find('.logged-in');
                    var $loggedOut = self.$el.find('.logged-out');

                    self.isLoggedIn = false;

                    TweenMax.to($loggedIn, 0.3, {
                        opacity: 0, ease: Cubic.easeOut, onComplete: function () {

                            $loggedIn.removeClass('hide').addClass('hide');

                            TweenMax.fromTo($loggedOut, 0.4, {opacity: 0}, {opacity: 1, ease: Cubic.easeInOut});
                            $loggedOut.removeClass('hide');
                        }
                    });

                    self.closeMenu();
                }
            },
            closeMenu: function () {
                $('#mobile-profile-menu').removeClass('displayed');
            }

            // ---------------------------------------------------------------------------------  /

        });

        return AppView;
    }
);
