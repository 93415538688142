/* Utilities for DOM-related methods */

if (typeof(Utils) === 'undefined' || !Utils) {
    Utils = {};
}

Utils.DOMUtils = {

    // wait for object to change size (for example, text rendering) ----------------------------  /

    onSizeChange: function ($element, callBack, context, maxIntervals, displayWarning) {

        if (!maxIntervals) {
            maxIntervals = 50;
        }

        var intervals = 0;
        var warningLimit = 25;
        var limitHit = false;
        var complete = false;
        var renderInterval = null;

        var initial = {
            width: $element.outerWidth(),
            height: $element.outerHeight(),
        };

        onInterval();
        if (!complete) {
            renderInterval = setInterval(onInterval, 0);
        }

        function onInterval() {

            intervals++;
            limitHit = (intervals == maxIntervals);

            if ($element.outerWidth() != initial.width || $element.outerHeight() != initial.height || limitHit) {
                if (limitHit && displayWarning == true) {
                    console.log("Warning: DOMUtils.onSizeChange limit reached. Giving up.", $element);
                }
                callBack.apply(context, [!limitHit]);
                if (renderInterval) {
                    clearInterval(renderInterval);
                }
                complete = true;
            }
            else if (intervals >= warningLimit) {
                if (displayWarning == true) {
                    console.log("Warning: DOMUtils.onSizeChange has hit", intervals, "for", $element);
                }
                warningLimit *= 10;
            }
        }
    },

    // dom ready that actually works -----------------------------------------------------------  /

    onRender: function ($element, callBack, context, maxIntervals, displayWarning) {

        if (!maxIntervals) {
            maxIntervals = 50;
        }

        var intervals = 0;
        var warningLimit = 25;
        var limitHit = false;
        var complete = false;
        var renderInterval = null;

        onInterval();
        if (!complete) {
            renderInterval = setInterval(onInterval, 0);
        }

        function onInterval() {

            intervals++;
            limitHit = (intervals == maxIntervals);

            if ($element.outerWidth() > 0 || $element.outerHeight() > 0 || limitHit) {
                if (limitHit && displayWarning == true) {
                    console.log("Warning: DOMUtils.onRender limit reached. Giving up.", $element);
                }
                callBack.apply(context, [!limitHit]);
                if (renderInterval) {
                    clearInterval(renderInterval);
                }
                complete = true;
            }
            else if (intervals >= warningLimit) {
                if (displayWarning == true) {
                    console.log("Warning: DOMUtils.onRender has hit", intervals, "for", $element);
                }
                warningLimit *= 10;
            }
        }
    },

    // wait one tick (have callback occur in next code loop) -----------------------------------   /

    wait: function (callBack, delay, context) {

        delay = _.isNumber(delay) ? delay : 0;

        window.setTimeout(function () {
            callBack.apply(context);
        }, delay);
    },

    // page url / path information -------------------------------------------------------------  /

    getURL: function () {
        return document.URL;
    },

    getDirectoryTree: function () {

        var url = document.URL;
        var dotSplit = url.split('.');
        var dirSplit = dotSplit[dotSplit.length - 1].split('/');

        return '/' + dirSplit.slice(1).join('/');
    },

    // set overflow for body based on size of window (removes chances of weird things happening)- /

    setBodyOverflow: function () {

        var self = this;
        var winWidth = $(window).width();
        var winHeight = $(window).height();

        var minWidth = $('html').css('min-width') || $('body').css('min-width');
        if (minWidth) {
            minWidth = parseInt(minWidth);
        }

        var minHeight = $('html').css('min-height') || $('body').css('min-height');
        if (minHeight) {
            minHeight = parseInt(minHeight);
        }

        var overflow = (!minWidth || (minWidth && winWidth > minWidth)) && (!minHeight || (minHeight && winHeight > minHeight)) ? "hidden" : "auto";

        $('body').css({overflow: overflow});
    },

    // gets document width based on min-width and window width ---------------------------------  /

    docWidth: 0, // cache value

    getDocWidth: function (refresh) {

        var self = this;

        if (!self.docWidth || refresh === true) {
            if (!self.docWidth) {
                $(window).resize(onResize);
            }
            onResize();
        }

        return self.docWidth;

        //

        function onResize() {

            var minWidth = parseInt($('html').css('min-width')) || parseInt($('body').css('min-width'));

            if (minWidth) {

                var winWidth = $(window).innerWidth();

                if (winWidth > minWidth) {
                    self.docWidth = winWidth;
                }
                else {
                    self.docWidth = minWidth;
                }
            }
            else {
                self.docWidth = $(window).innerWidth();
            }
        }
    },

    // gets document height based on min-height and window height ----------------------------  /

    docHeight: 0, // cache value

    getDocHeight: function (refresh) {

        var self = this;

        if (!self.docHeight || refresh === true) {
            if (!self.docHeight) {
                $(window).resize(onResize);
            }
            onResize();
        }

        return self.docHeight;

        //

        function onResize() {

            var minHeight = parseInt($('html').css('min-height')) || parseInt($('body').css('min-height'));

            if (minHeight) {

                var winHeight = $(window).innerHeight();

                if (winHeight > minHeight) {
                    self.docHeight = winHeight;
                }
                else {
                    self.docHeight = minHeight;
                }
            }
            else {
                self.docHeight = $(window).innerHeight();
            }
        }
    },

    // a bit over the top, hasn't been useful yet but maybe it will be someday -----------------  /

    getDefinitiveBrowserHeight: function () {
        var D = document;
        return Math.max(
            Math.max(D.body.scrollHeight, D.documentElement.scrollHeight),
            Math.max(D.body.offsetHeight, D.documentElement.offsetHeight),
            Math.max(D.body.clientHeight, D.documentElement.clientHeight)
        );
    },

    // disable / enable scrolling --------------------------------------------------------------  /

    interruptMouseWheel: function (e) {
        e.preventDefault();
    },

    disableMouseWheel: function () {
        var self = this;
        $(window).on('mousewheel', $.proxy(self.interruptMouseWheel, self));
    },

    enableMouseWheel: function () {
        $(window).off('mousewheel', $.proxy(self.interruptMouseWheel, self));
    },

    // ie detection ----------------------------------------------------------------------------  /


    isIE: function () {

        // from http://stackoverflow.com/questions/17907445/how-to-detect-ie11

        return ((navigator.appName == 'Microsoft Internet Explorer') || ((navigator.appName == 'Netscape') && (new RegExp("Trident/.*rv:([0-9]{1,}[\.0-9]{0,})").exec(navigator.userAgent) != null)));
    },

    isOldIE: function () {

        if (!this.isIE()) {
            return false;
        }

        // from http://www.jquerybyexample.net/2014/06/how-to-detect-ie-11-using-javascript-jquery.html

        var sAgent = window.navigator.userAgent;
        var Idx = sAgent.indexOf("MSIE");
        var versionNumber = 0;

        // If IE, return version number.

        if (Idx > 0) {
            // older versions user agent match
            versionNumber = parseInt(sAgent.substring(Idx + 5, sAgent.indexOf(".", Idx)));
        }
        else if (!!navigator.userAgent.match(/Trident\/7\./)) {
            // If IE 11 then look for Updated user agent string.
            versionNumber = 11;
        }

        return (versionNumber < 11);
    },

    isFF: function () {
        return navigator.userAgent.match(/firefox/i) !== null;
    },

    isSafari: function () {

        var uagent = navigator.userAgent.toLowerCase();
        if (/safari/.test(uagent) && !/chrome/.test(uagent)) {
            return true;
        }

        return false;
    },

    // mobile detection -----------------------------------------------------------  /

    isTouch: function () {
        return $('html').hasClass('touch');
    },

    isMobile: function (detectTablets, detectPhones) {

        if (typeof(detectTablets) === 'undefined') {
            detectTablets = false;
        }
        if (typeof(detectPhones) === 'undefined') {
            detectPhones = true;
        }

        if (detectTablets && this.isTablet()) {
            return true;
        }
        if (detectPhones && this.isPhone()) {
            return true;
        }

        return false;
    },

    isDesktop: function () {
        return !this.isMobile(true, true);
    },

    isPhone: function () {
        if (/android|webos|iphone|ipod|blackberry|iemobile|opera mini/i.test(navigator.userAgent.toLowerCase())) {
            return true;
        }
        return false;
    },

    isTablet: function () {
        if (/ipad|(android|chrome) ([.0-9]*)(.*)(mobile)/i.test(navigator.userAgent.toLowerCase())) {
            return true;
        }
        return false;
    },

    // check if video format is playable -------------------------------------------------------  /

    isPlayableVideoFormat: function (path) {

        var valid = true;

        var isFF = navigator.userAgent.match(/firefox/i);
        var fileType = path.substr(path.lastIndexOf('.') + 1).toLowerCase();
        var videoFormats = ['webm', 'mp4', 'ogv', 'ogg', 'mov'];
        var ffFormats = ['ogg'];

        if (isFF) {
            videoFormats = ffFormats;
        } // firefox thinks it can play things it can't

        if (_.contains(videoFormats, fileType)) {

            var canPlay = false;
            var v = document.createElement('video');

            if (v.canPlayType && v.canPlayType('video/' + fileType).replace(/no/, '')) {
                return true;
            }
        }

        return false;
    },

    // force download --------------------------------------------------------------------------  /

    promptDownloadURL: function (url) {

        var hiddenIFrameID = 'hiddenDownloader',
            iframe = document.getElementById(hiddenIFrameID);
        if (iframe === null) {
            iframe = document.createElement('iframe');
            iframe.id = hiddenIFrameID;
            iframe.style.display = 'none';
            document.body.appendChild(iframe);
        }
        iframe.src = url;
    },

    // get URL parameter -----------------------------------------------------------------------  /

    getURLParameter: function (sParam) {

        // thanks to http://stackoverflow.com/questions/19491336/get-url-parameter-jquery

        var sPageURL = decodeURIComponent(window.location.search.substring(1)),
            sURLVariables = sPageURL.split('&'),
            sParameterName,
            i;

        for (i = 0; i < sURLVariables.length; i++) {
            sParameterName = sURLVariables[i].split('=');

            if (sParameterName[0] === sParam) {
                return sParameterName[1] === undefined ? true : sParameterName[1];
            }
        }
    },

    // get URL parameter -----------------------------------------------------------------------  /

    getHashParameter: function (sParam) {

        // thanks to http://stackoverflow.com/questions/19491336/get-url-parameter-jquery

        var sPageURL = decodeURIComponent(window.location.hash.substring(1)),
            sURLVariables = sPageURL.split('&'),
            sParameterName,
            i;

        for (i = 0; i < sURLVariables.length; i++) {
            sParameterName = sURLVariables[i].split('=');

            if (sParameterName[0] === sParam) {
                return sParameterName[1] === undefined ? true : sParameterName[1];
            }
        }
    },

    // -----------------------------------------------------------------------------------------  /

    triggerTrackEvent: function (data) {

        var urlCode = window.location.href;


        console.info("<<<<<=====================TRIGGER-TRACK-EVENT========================>\n", " |trackAction: "+data.trackAction+"\n"," |trackCategory: "+ urlCode +"\n"," |trackEmail: "+ data.trackEmail+"\n"," |propReference:"+ data.trackLabel.propReference+"\n"," |propLocation "+data.trackLabel.propLocation+"\n"," |Cost: "+ data.trackLabel.propCost+"\n"," |checkIn "+data.trackLabel.checkIn+"\n", " |checkOut "+data.trackLabel.checkOut+"\n", " |guests:"+ data.trackLabel.guests );

        window.dataLayer.push({
            'event': 'track',
            'trackAction': data.origin ? data.origin :'' ,
            'trackCategory': urlCode,
            'trackEmail': data.trackEmail ? data.trackEmail : '',
            'trackLabel': {
                'propReference': data.trackLabel.propReference ? data.trackLabel.propReference : '' ,
                'propLocation': data.trackLabel.propLocation ? data.trackLabel.propLocation : '',
                'propCost' : data.trackLabel.propCost ? data.trackLabel.propCost: '',
                'checkIn': data.trackLabel.checkIn ? data.trackLabel.checkIn : '',
                'checkOut': data.trackLabel.checkOut ? data.trackLabel.checkOut : '',
                'guests': data.trackLabel.guests ? data.trackLabel.guests : ''
            }
        });

    }

};
define("utils/utils/DOMUtils", function(){});

