define('modules/property-filters/views/FiltersMenuView',[
        'backbone',
        'utils/AbstractView',
        'text!../templates/FiltersMenuTemplate.html',
        //'text!../templates/FiltersMenuRentTemplate.html',
        'text!../templates/FiltersMenuTemplateShortlet.html',
        'text!../../../config/filters-menu.json',
        'text!../../../config/filters-menu-es.json',
    ],
    function (Backbone, AbstractView, Template, TemplateShortlet, FiltersData, FiltersDataEs) {

        var FiltersMenuView = Backbone.View.extend({
            // core vars

            $el: null,
            $container: null,
            options: null,
            abstractView: null,
            // vars

            filtersData: null,
            $desktopContainer: null,
            $mobileContainer: null,
            $roomsSelector: null,
            $bathsSelector: null,
            $propertyCategorySelector: null,
            $favoritesToggle: null,
            $lastMinToggle: null,
            $tagField: null,
            $closeButton: null,
            $expandButton: null,
            priceSlider: null,
            priceSliderTagModel: null,
            bedroomsTagModel: null,
            bathroomsTagModel: null,
            areasTagModel: null,
            propertyCategoryTagModel:null,
            keywordTags: null,
            maxRangePrice: 0,
            $urlCode: null,
            priceRangeFrom: null,
            priceRangeTo: null,
            //

            initialize: function (options) {

                var self = this;

                self.options = options;
                self.$container = self.options.$container;
                self.el = self.options.el;
                self.id = self.options.id;

                self.$lang = $('#lang').html();
                if (self.$lang == 'en') {
                    self.filtersData = JSON.parse(FiltersData);
                } else {
                    self.filtersData = JSON.parse(FiltersDataEs);
                }

                if (self.options.priceRangeFrom != null) {
                    self.priceRangeFrom = self.options.priceRangeFrom;
                    self.priceRangeTo = self.options.priceRangeTo;
                }
                self.template = _.template(Template);

                console.log('filtersData in filtersMenuView');
                console.dir(self.filtersData);


                self.$urlCode = $('#url-code').html();
                if (self.$urlCode == 'rent-listing') {
                    self.maxRangePrice = 10000;
                } else if (self.$urlCode == 'shortlet-listing') {
                    self.template = _.template(TemplateShortlet);
                    self.maxRangePrice = 10000;
                } else {
                    self.maxRangePrice = 30000000;
                }

                self.model = $.extend({}, self.filtersData);

                //

                self.$desktopContainer = $('#all-filters-wrapper');
                self.$mobileContainer = $('#overlay-content-wrapper');

                //

                self.abstractView = new AbstractView({
                    view: self,
                    autoShow: false
                });
            },
            onDomReady: function () {

                var self = this;

                // select fields

                self.$roomsSelector = self.$el.find('.filter-rooms-wrapper .filters-select');
                self.$roomsSelector.selectBoxIt({
                    isMobile: function () {
                        return false;
                    },
                    autoWidth: false
                });
                self.$roomsSelector.on('change', $.proxy(self._onSelectBedroom, self));

                self.$bathsSelector = self.$el.find('.filter-bathrooms-wrapper .filters-select');
                self.$bathsSelector.selectBoxIt({
                    isMobile: function () {
                        return false;
                    },
                    autoWidth: false
                });
                self.$bathsSelector.on('change', $.proxy(self._onSelectBathroom, self));

                self.$areasSelector = self.$el.find('.filter-areas-wrapper .filters-select');
                self.$areasSelector.selectBoxIt({
                    isMobile: function () {
                        return false;
                    },
                    autoWidth: false
                });
                self.$areasSelector.on('change', $.proxy(self._onSelectArea, self));

                self.$leasetermSelector = self.$el.find('.filter-leaseterm-wrapper .filters-select');
                self.$leasetermSelector.selectBoxIt({
                    isMobile: function () {
                        return false;
                    },
                    autoWidth: false
                });
                self.$leasetermSelector.on('change', $.proxy(self._onSelectLeaseterm, self));

                self.$propertyCategorySelector = self.$el.find('.filter-category-wrapper .filters-select');
                self.$propertyCategorySelector.selectBoxIt({
                    isMobile: function () {
                        return false;
                    },
                    autoWidth: false
                });
                self.$propertyCategorySelector.on('change', $.proxy(self._onSelectPropertyCategory, self));

                // keyword tag field

                var taggingOptions = {
                    "no-duplicate": true,
                    "forbidden-chars": [",", ".", "_", "?"],
                    "no-spacebar": true,
                    "edit-on-delete": false,
                    "tag-template": '<div class="tag"><figure class="tag-symbol">#</figure><span class="tag-text"><%= text %></span><input type="hidden" name="<%= inputName %>" value="<%= inputText %>" /><button class="tag-i tag-x-button"></button></div>',
                };

                self.$tagField = self.$el.find('.keyword-field');

                self.$tagField.tagging(taggingOptions);
                self.$tagField.on('addTag', $.proxy(self._onAddKeywordTag, self));
                self.$tagField.on('removeTag', $.proxy(self._onRemoveKeywordTag, self));
                self.$tagField.on('blur', $.proxy(self._onBlurKeywordTag, self));

                self.keywordTags = [];

                // checkboxes

                self.$el.find('.checkbox-wrappers input').on('click', $.proxy(self._onCheckBoxClick, self));

                // toggle favorites

                self.$favoritesToggle = self.$el.find('.favorites-toggle');
                self.$lastMinToggle = self.$el.find('.last-min-toggle');

                self.$el.find('.toggle-selector .on').on('click', $.proxy(self._onFavoriteToggleClick, self));
                self.$el.find('.toggle-selector .off').on('click', $.proxy(self._onFavoriteToggleClick, self));

                // close

                self.$closeButton = self.$el.find('.filter-btn-close');
                self.$closeButton.on('click', $.proxy(self._onCloseClick, self));

                //more

                self.$moreButton = self.$el.find('.filter-btn-more');
                self.$moreButton.on('click', $.proxy(self._onMoreClick, self));

                self.$expandButton = self.$el.find('.expand-filter');
                self.$expandButton.on('click', $.proxy(self._onExpandButtonClick, self));

                // range slider
                // selecting initial filter menu items
                // range slider
                var step;
                if (self.$urlCode == 'buy-listing') {
                    step = 10000;
                } else {
                    step = 100;
                }

                if (self.priceRangeFrom == null) {
                    self.priceRangeFrom = 0
                }
                if (self.priceRangeTo == null) {
                    self.priceRangeTo = self.maxRangePrice
                }
                var from = self.priceRangeFrom;
                var to = self.priceRangeTo;
                var max = self.maxRangePrice;
                var prefix = "&euro;";
                console.log('self.options', self.options);
                //var prefix = self.options.currency ? self.options.currency : "&euro;";
                if (self.priceSliderTagModel) {
                    var tagValue = self.priceSliderTagModel.id.split(':')[1].split('.')[0].replace('%20', ' ');
                    from = tagValue.split('-')[0];
                    to = tagValue.split('-')[1];
                }
                self.loadPriceSlider(from, to, step, max, prefix);

                $(window).on('resize', $.proxy(self.onWindowResize, self));
            },

            loadPriceSlider: function(from, to, step, max, prefix) {

                var self = this;

                $("#price-range").ionRangeSlider({

                    type: "double",
                    min: 0,
                    max: max,
                    from: from,
                    to: to,
                    step: step,
                    prettify_separator: ",",
                    prefix: prefix,

                    onFinish: function (data) {

                        var lastTagModel = self.priceSliderTagModel;
                        if (lastTagModel) {
                            self.trigger('removeTag', lastTagModel);
                        }

                        self.priceSliderTagModel = {
                            id: 'pricerange:' + (data.from + '-' + data.to),
                            type: 'price-range',
                            label: (prefix + data.from + ' - ' + prefix + data.to)
                        };
                        self.trigger('addTag', _.clone(self.priceSliderTagModel));
                    },
                });

                self.priceSlider = $("#price-range").data("ionRangeSlider");
            },
            // event listeners -----------------------------------------------------------------  /

            _onExpandButtonClick: function (e) {

                var self = this;
                var $target = $(e.currentTarget);
                var checkboxWrappers = self.$el.find($target.data('wrapper')).find('.checkbox-wrappers');
                var icon = $target.find('i');

                if (checkboxWrappers.hasClass('hidden-xs')) {

                    TweenMax.to(checkboxWrappers, 0, {opacity: 0});
                    checkboxWrappers.removeClass('hidden-xs');
                    TweenMax.to(checkboxWrappers, 0.6, {opacity: 1});
                } else {

                    TweenMax.fromTo(checkboxWrappers, 0.3, {opacity: 1}, {
                        opacity: 0, onComplete: function () {
                            checkboxWrappers.addClass('hidden-xs');
                            TweenMax.to(checkboxWrappers, 0, {opacity: 1});
                        }
                    });
                }

                icon.toggleClass('expand collapse');
            },
            _onSelectLeaseterm: function (e, obj) {

                var self = this;
                console.log("_onSelectLeaseterm");
                if (obj) {

                    var value = $(obj.selectboxOption[0]).val();
                    var tagModel = _.clone(_.findWhere(self.filtersData.leaseterm, {id: value}));
                    var lastTagModel = self.bedroomsTagModel;
                    tagModel.id = 'leaseterm:' + tagModel.id;

                    if (!self.bedroomsTagModel || value != self.bedroomsTagModel.id) {

                        if (lastTagModel) {
                            self.trigger('removeTag', lastTagModel);
                        }
                        if (tagModel) {
                            self.trigger('addTag', $.extend({type: 'leaseterm'}, tagModel));
                        }

                        self.bedroomsTagModel = tagModel;
                    }
                }
            },
            _onSelectBedroom: function (e, obj) {

                var self = this;
                if (obj) {
                    console.log("obj");
                    var value = $(obj.selectboxOption[0]).val();
                    var tagModel = _.clone(_.findWhere(self.filtersData.bedrooms, {id2: value}));
                    console.log(tagModel);
                    var lastTagModel = self.bedroomsTagModel;
                    console.log(tagModel);
                    if (tagModel) {
                        tagModel.id = 'bedrooms:' + tagModel.id;

                        if (!self.bedroomsTagModel || value != self.bedroomsTagModel.id) {

                            if (lastTagModel) {
                                self.trigger('removeTag', lastTagModel);
                            }
                            if (tagModel) {
                                self.trigger('addTag', $.extend({type: 'bedrooms'}, tagModel));
                            }

                            console.log("pre self.bedroomsTagModel");
                            self.bedroomsTagModel = tagModel;
                            console.log("post self.bedroomsTagModel");
                        }
                    } else if (lastTagModel)
                        self.trigger('removeTag', lastTagModel);
                } else {
                    console.log("no obj");
                    var value = self.$roomsSelector.val();
                    var tagModel = _.clone(_.findWhere(self.filtersData.bedrooms, {id2: value}));
                    var lastTagModel = self.bedroomsTagModel;

                    if (tagModel) {
                        tagModel.id = 'bedrooms:' + tagModel.id;

                        if (!self.bedroomsTagModel || value != self.bedroomsTagModel.id) {

                            if (lastTagModel) {
                                self.trigger('removeTag', lastTagModel);
                            }
                            if (tagModel) {
                                self.trigger('addTag', $.extend({type: 'bedrooms'}, tagModel));
                            }

                            self.bedroomsTagModel = tagModel;
                        }
                    }
                }
            },
            _onSelectBathroom: function (e, obj) {

                var self = this;

                console.log('_onSelectBathroom');

                if (obj) {

                    var value = $(obj.selectboxOption[0]).val();
                    var tagModel = _.clone(_.findWhere(self.filtersData.bathrooms, {id2: value}));
                    var lastTagModel = self.bathroomsTagModel;

                    if (tagModel) {
                        tagModel.id = 'bathrooms:' + tagModel.id;

                        if (!self.bathroomsTagModel || value != self.bathroomsTagModel.id) {

                            if (lastTagModel) {
                                self.trigger('removeTag', lastTagModel);
                            }
                            if (tagModel) {
                                self.trigger('addTag', $.extend({type: 'bathrooms'}, tagModel));
                            }

                            self.bathroomsTagModel = tagModel;
                        }
                    } else if (lastTagModel)
                        self.trigger('removeTag', lastTagModel);
                } else {

                    var value = self.$bathsSelector.val();
                    var tagModel = _.clone(_.findWhere(self.filtersData.bathrooms, {id2: value}));
                    var lastTagModel = self.bathroomsTagModel;

                    if (tagModel) {
                        tagModel.id = 'bathrooms:' + tagModel.id;

                        if (!self.bathroomsTagModel || value != self.bathroomsTagModel.id) {

                            if (lastTagModel) {
                                self.trigger('removeTag', lastTagModel);
                            }
                            if (tagModel) {
                                self.trigger('addTag', $.extend({type: 'bathrooms'}, tagModel));
                            }

                            self.bathroomsTagModel = tagModel;
                        }
                    }
                }
            },
            _onSelectArea: function (e, obj) {

                var self = this;

                if (obj) {

                    var value = $(obj.selectboxOption[0]).val();
                    var tagModel = _.clone(_.findWhere(self.filtersData.areas, {shortLabel: value}));
                    var lastTagModel = self.areasTagModel;

                    if (tagModel) {

                        tagModel.id = 'areas:' + tagModel.id;

                        console.log('_onSelectArea', self.filtersData.areas, value);

                        if (!self.areasTagModel || value != self.areasTagModel.id) {

                            if (lastTagModel) {
                                self.trigger('removeTag', lastTagModel);
                            }
                            if (tagModel) {
                                self.trigger('addTag', $.extend({type: 'areas'}, tagModel));
                            }

                            self.areasTagModel = tagModel;
                        }
                    } else {
                        if (lastTagModel) {
                            self.trigger('removeTag', lastTagModel);
                        }
                    }
                } else {

                    var value = self.$areasSelector.val();
                    var tagModel = _.clone(_.findWhere(self.filtersData.areas, {shortLabel: value}));
                    var lastTagModel = self.areasTagModel;

                    if (tagModel) {

                        tagModel.id = 'areas:' + tagModel.id;

                        console.log('_onSelectArea', self.filtersData.areas, value);

                        if (!self.areasTagModel || value != self.areasTagModel.id) {

                            if (lastTagModel) {
                                self.trigger('removeTag', lastTagModel);
                            }
                            if (tagModel) {
                                self.trigger('addTag', $.extend({type: 'areas'}, tagModel));
                            }

                            self.areasTagModel = tagModel;
                        }
                    } else {
                        if (lastTagModel) {
                            self.trigger('removeTag', lastTagModel);
                        }
                    }
                }
            },
            _onSelectPropertyCategory: function (e, obj) {
                var self = this;

                if (obj) {

                    var value = $(obj.selectboxOption[0]).val();
                    var tagModel = _.clone(_.findWhere(self.filtersData.propertyCategory, {shortLabel: value}));
                    var lastTagModel = self.propertyCategoryTagModel;

                    if (tagModel) {

                        tagModel.id = 'propertyCategory:' + tagModel.id;

                        console.log('propertyCategory', self.filtersData.propertyCategory, value);

                        if (!self.propertyCategoryTagModel || value != self.propertyCategoryTagModel.id) {

                            if (lastTagModel) {
                                self.trigger('removeTag', lastTagModel);
                            }
                            if (tagModel) {
                                self.trigger('addTag', $.extend({type: 'propertyCategory'}, tagModel));
                            }

                            self.propertyCategoryTagModel = tagModel;
                        }
                    } else {
                        if (lastTagModel) {
                            self.trigger('removeTag', lastTagModel);
                        }
                    }
                } else {

                    var value = self.$propertyCategorySelector.val();
                    var tagModel = _.clone(_.findWhere(self.filtersData.propertyCategory, {shortLabel: value}));
                    var lastTagModel = self.$propertyCategorySelector;

                    if (tagModel) {

                        tagModel.id = 'propertyCategory:' + tagModel.id;

                        console.log('propertyCategory', self.filtersData.propertyCategory, value);

                        if (!self.propertyCategoryTagModel || value != self.propertyCategoryTagModel.id) {

                            if (lastTagModel) {
                                self.trigger('removeTag', lastTagModel);
                            }
                            if (tagModel) {
                                self.trigger('addTag', $.extend({type: 'propertyCategory'}, tagModel));
                            }

                            self.propertyCategoryTagModel = tagModel;
                        }
                    } else {
                        if (lastTagModel) {
                            self.trigger('removeTag', lastTagModel);
                        }
                    }
                }
            },

            _onAddKeywordTag: function (e, obj) {

                var self = this;

                if (!_.findWhere(self.keywordTags, {label: obj.text})) {

                    var tagModel = {id: obj.text, label: obj.text, type: 'keyword'};
                    if (tagModel) {
                        tagModel.id = 'keyword:' + tagModel.id
                    }

                    self.keywordTags.push(tagModel);
                    self.trigger('addTag', tagModel);
                }
            },
            _onRemoveKeywordTag: function (e, obj) {

                var self = this;
                var tagModel = _.findWhere(self.keywordTags, {id: obj.text});

                if (tagModel) {

                    self.keywordTags = _.without(self.keywordTags, tagModel);
                    self.trigger('removeTag', tagModel);
                }
            },
            _onBlurKeywordTag: function (e, obj) {

                var self = this;
                var $target = $(e.currentTarget);
                var keyword = $target.val();
                if (keyword) {
                    self.$tagField.trigger('addTag', {id: 'keyword:', label: keyword, text: keyword});
                }
            },
            _onCheckBoxClick: function (e) {

                var self = this;
                var $target = $(e.currentTarget);
                var isChecked = e.currentTarget.checked;

                /*
                 var tagModel = _.findWhere(self.filtersData.category, {id:$target.data('id')});
                 if(!tagModel){ tagModel = _.findWhere(self.filtersData.features, {id:$target.data('id')}); }
                 tagModel = _.clone(tagModel);
                 */
                var tagModel = _.clone(_.findWhere(self.filtersData[$target.data('type')], {id: $target.data('id')}));
                if (tagModel) {
                    tagModel.id = $target.data('type') + ':' + tagModel.id
                }

                if (isChecked) {
                    self.trigger('addTag', $.extend({type: 'checkbox'}, tagModel));
                } else {
                    self.trigger('removeTag', tagModel);
                }
            },
            _onFavoriteToggleClick: function (e) {

                var self = this;
                var $target = $(e.currentTarget);

                if (!$target.hasClass('selected')) {

                    var $lastSelected = $target.parent().find('.selected');
                    $lastSelected.removeClass('selected');

                    $target.removeClass('selected').addClass('selected');
                }

                // add / remove tag

                var tagModel = _.clone(_.findWhere(self.filtersData.favorites, {id: $target.parent().data('id')}));

                if ($target.parent().find('.on').hasClass('selected')) {
                    self.trigger('addTag', $.extend({type: 'favorite'}, tagModel));
                } else {
                    self.trigger('removeTag', tagModel);
                }
            },
            _onCloseClick: function (e) {

                var self = this;
                self.hide();
            },
            _onMoreClick: function (e) {

                var self = this;

                TweenMax.to(self.$('.more-filters-options'), 0, {
                    'display': 'block', 'opacity': 0, onComplete: function () {
                        TweenMax.to(self.$('.more-filters-options'), 0.8, {'opacity': 1});
                    }
                });
                TweenMax.to(self.$moreButton, 0, {'display': 'none'});
                TweenMax.to($('#more_container'), 0, {'display': 'none'});
            },
            // public controls -----------------------------------------------------------------  /

            removeTag: function (tagModel) {

                var self = this;

                console.log("removeTag tagModel", tagModel);

                if (tagModel) {

                    var selectBoxIt;

                    switch (tagModel.type) {

                        case 'areas':
                            self.areasTagModel = null;
                            self.$areasSelector.data("selectBox-selectBoxIt").selectOption(0);
                            break;

                        case 'bedrooms':
                            self.bedroomsTagModel = null;
                            self.$roomsSelector.data("selectBox-selectBoxIt").selectOption(0);
                            break;

                        case 'bathrooms':
                            self.bathroomsTagModel = null;
                            self.$bathsSelector.data("selectBox-selectBoxIt").selectOption(0);
                            break;
                        case 'propertyCategory':
                            self.propertyCategoryTagModel = null;
                            self.$propertyCategorySelector.data("selectBox-selectBoxIt").selectOption(0);
                            break;
                        case 'keyword':
                            tagModel.label = tagModel.label.replace(/ /g, "-");
                            self.keywordTags = _.without(self.keywordTags, tagModel);
                            self.$tagField.tagging('remove', tagModel.label);
                            self.$el.find('input[name="keyword"]').val("");
                            break;

                        case 'checkbox':
                            var $checkBox = self.$el.find('.facilities-checkboxes .checkbox-wrappers input[data-id="' + tagModel.id.split(":")[1] + '"]');
                            $checkBox.trigger('click');
                            break;

                        case 'favorite':
                            self.$favoritesToggle.find('.on').removeClass('selected');
                            self.$favoritesToggle.find('.off').removeClass('selected').addClass('selected');
                            break;

                        case 'lastmindeals':
                            self.$lastMinToggle.find('.on').removeClass('selected');
                            self.$lastMinToggle.find('.off').removeClass('selected').addClass('selected');
                            break;

                        case 'price-range':
                            self.priceSlider.reset();
                            break;
                    }
                }
            },
            // animation in / out --------------------------------------------------------------  /

            onShow: function () {

                var self = this;

                if ($(window).width() <= 767) {
                    self.$el.parent('#overlay-content-wrapper').show();
                }

                TweenMax.fromTo(self.$el, 0.6, {opacity: 0}, {
                    opacity: 1, ease: Cubic.easeOut, onComplete: function () {
                        self.trigger('showComplete');
                    }
                });

                window.$vent.trigger('openFiltersMenu');
                self.onWindowResize();
            },
            onHide: function () {
                var self = this;

                TweenMax.to(self.$el, 0.3, {
                    opacity: 0, ease: Cubic.easeOut, onComplete: function () {
                        if ($(window).width() <= 767) {
                            self.$el.parent('#overlay-content-wrapper').hide();
                        }
                        self.trigger('hideComplete');
                    }
                });

                window.$vent.trigger('closeFiltersMenu');
                self.onWindowResize();
            },
            // window resize -------------------------------------------------------------------  /

            onWindowResize: function () {

                var self = this;
                var winWidth = window.innerWidth;
                var $resultsGrid = $('#results-grid');

                if (self.abstractView.isShowing) {

                    if (winWidth >= 768) {

                        $resultsGrid.css('pointer-events', 'auto');

                        if (self.$container[0] !== self.$desktopContainer[0]) {
                            self.$desktopContainer.append(self.$el);
                            self.$container[0] = self.$desktopContainer[0];
                        }
                    } else {

                        $resultsGrid.css('pointer-events', 'none');

                        if (self.$container[0] !== self.$mobileContainer[0]) {
                            self.$mobileContainer.append(self.$el);
                            self.$container[0] = self.$mobileContainer[0];
                        }
                    }
                } else {
                    $resultsGrid.css('pointer-events', 'auto');
                }
            },
        });

        return FiltersMenuView;
    });
