define(
	'modules/fake-select-mobile/views/FakeSelectView.js',[
		'backbone',
		'utils/AbstractView',
	], 
	function(Backbone, AbstractView){

		var FakeSelectView = Backbone.View.extend({
			// core vars
			$el: null,
			$currentElement: null,
			$option: null,

			// init
			initialize: function(options){
				var self = this;

				$(function () {
					self.onDomReady();
				 	self.onAddListeners();
				});

			},

			//grab the properties
			onDomReady: function() {
				var self = this;

				self.$el					= $('.mobile-pl-selector');
				self.$currentElement 		= $('#the-select-target');
				self.$option 				= self.$el.find('li');
			},

			onRender: function(){
				var self = this;
			},

			//add event handlers to them
			onAddListeners: function() {
				console.log('init');
				var self = this;

				self.$currentElement.on('click', $.proxy( self._displayDDwn, self ));
				self.$option.on('click', $.proxy( self._redirectURL, self ));
			},

			_displayDDwn: function(){
				var self = this;
				var dropdownH = $( self.$el.find('.fake-selector-holder') );
				var dropdown = $( dropdownH.find('.fake-selector') );

				if (dropdown.is(':visible')){					
					TweenMax.to(dropdown, 0.6, {height: 0, opacity: 0, onComplete:function(){
							dropdownH.fadeOut();
							self.$el.removeClass('active');
						}});
				} else {
					dropdownH.fadeIn();
					self.$el.addClass('active');
					TweenMax.fromTo(dropdown, 0.6, {height: 0, opacity: 0}, {height: 100, opacity: 1});
				}
			},

			_redirectURL: function(e){
				var self = this;
				var elemClicked = $( e.currentTarget );
				var targetPage = elemClicked.attr('data-target');

				window.location = targetPage;
			}

		});

	return FakeSelectView;
});
