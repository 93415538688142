
/*

	*** AbstractView.js by David Robbins @BasicAgency.com ***

	---

	Fufills common animation and interaction requirements automatically.
	Orders rendering, event setting, visibility settings, and animation in/out in proper order.

	Compose this object in your view as follows:

	---

	var abstractView = new AbstractView({view:this});

	---

	Options can be passed into the initial object. Check variable list below under 'options' for more.

	---

	Your view can have certain configuration variables that AbstractView will check for.

	view.autoShow				// render and show view immediately (otherwise view.show() will need to be used)

	---

	AbstractView will attempt to call various methods in your connected view. None of these are required.

	onInitialize();
	onRender();
	onDomReady();
	onAddListeners();
	onRemoveListeners();
	onAddHideClass();
	onRemoveHideClass();
	onShow(); 					// REQUIREMENT: trigger event 'showComplete' when animation completes.
	onHide(); 					// REQUIREMENT: trigger event 'hideComplete' when animation completes.

	---

	Last Updated 05.05.2015

*/

define(
	'utils/AbstractView',[
		'backbone',
	], 
	function(Backbone){

		var AbstractView = Backbone.View.extend({

			// core vars

			view: null,
			options: null,

			// options

			autoShow: true,
			hideClass: 'hidden',
			delayListenersUntilShow: true,
			configViewMethods: true,

			// flags

			isRendered: false,
			isShowing: false,
			isListening: false,

			// status

			isDomReady: false,
			isDying: false,
			isDead: false,

			// built-in initialization

			initialize: function(options){

				var self = this;

				self.options = options || {};
				self.view = self.options.view;

				// options

				if(typeof(self.options.autoShow) !== 'undefined'){ self.autoShow = self.options.autoShow; }
				if(typeof(self.options.hideClass) !== 'undefined'){ self.hideClass = self.options.hideClass; }
				if(typeof(self.options.delayListenersUntilShow) !== 'undefined'){ self.delayListenersUntilShow = self.options.delayListenersUntilShow; }

				// begin

				if(self.view){

					// extract necessary variables from view

					if(!self.view.$container){ 
						if(self.view.el){ self.view.$container = self.view.$el.parent(); }
						else { console.log("ERROR: No el or $container defined!", self); }
					}

					if(self.configViewMethods){						
						self.view.show = self.show.bind(self);
						self.view.hide = self.hide.bind(self);
						self.view.exit = self.exit.bind(self);
						self.view.exitImmediately = self.exitImmediately.bind(self);
					}

					if(typeof(self.view.onInitialize) === 'undefined'){ }
					else { self.view.onInitialize(); }

					self.render();
				}
				else {
					console.log("ERROR: No view defined.", self);
				}
			},

			// built-in render

			render: function(){

				var self = this;				

				if(self.view.el && self.view.template){ 
					self.view.$el.remove(); 
					self.removeListeners();
				}

				if(self.view.$container && self.view.template){

					self.view.$el = $(self.view.template(self.view.model));
					self.setInitialRenderStatus();
					self.view.$container.append(self.view.$el);
				}
				else {
					self.setInitialRenderStatus();
				}
				
				if(typeof(self.view.onRender) === 'undefined'){ }
				else { self.view.onRender(); }

				self.isRendered = true;
				self.triggerEvent('render');

				$(function(){
					self.domReady();
				});
			},

			// check render status - set show or hide classes

			setInitialRenderStatus: function(){

				var self = this;

				if(!self.autoShow && !self.isShowing){ 
					self.addHideClass();				
				}
			},

			// core dom ready

			domReady: function(){

				var self = this;

				if(typeof(self.view.onDomReady) === 'undefined'){ }
				else { self.view.onDomReady(); }

				$(window).on('resize', $.proxy(self._onWindowResize, self));
				self._onWindowResize();

				self.isDomReady = true;
				self.triggerEvent('domReady');

				if(self.autoShow && !self.isShowing){ self.show(); }
			},

			// add listeners

			addListeners: function(){

				var self = this;

				if(!self.isListening){

					if(typeof(self.view.onAddListeners) === 'undefined'){ }
					else { self.view.onAddListeners(); }

					self.isListening = true;
					self.triggerEvent('addListeners');
				}
			},

			// remove

			removeListeners: function(){

				var self = this;

				if(self.isListening){

					if(typeof(self.view.onRemoveListeners) === 'undefined'){ }
					else { self.view.onRemoveListeners(); }

					self.isListening = false;
					self.triggerEvent('removeListeners');
				}
			},

			// add / remove hide class

			addHideClass: function(){

				var self = this;

				if(typeof(self.view.onAddHideClass) === 'undefined'){
					self.view.$el.removeClass(self.hideClass).addClass(self.hideClass); 
				}
				else { self.view.onAddHideClass(); }
			},

			removeHideClass: function(){

				var self = this;

				if(typeof(self.view.onRemoveHideClass) === 'undefined'){
					self.view.$el.removeClass(self.hideClass); 
				}
				else { self.view.onRemoveHideClass(); }
			},

			// show ----------------------------------------------------------------------------  /

			show: function(){
				
				var self = this;

				if(!self.isShowing){

					if(self.isDomReady){ 

						self.isShowing = true;
						self.off('domReady', self.show, self); 
						
						if(!self.delayListenersUntilShow){ self.addListeners(); }
						
						// wait until show complete to add listeners (if option is set)

						self.view.once('showComplete', function(){						
							if(self.delayListenersUntilShow){ self.addListeners(); }
						});

						self._onShow();
						self.removeHideClass();
					}
					else { 
						self.once('domReady', self.show, self); 
					}
				}
			},

			_onShow: function(){

				var self = this;
				self.triggerEvent('show');

				if(typeof(self.view.onShow) === 'undefined'){

					TweenMax.fromTo(self.view.$el, 0.6, {opacity:0}, {opacity:1, ease:Cubic.easeOut});
					
					window.requestAnimationFrame(function(){
						self.triggerEvent('showComplete');
					});
				}
				else { self.view.onShow(); }
			},

			// hide ----------------------------------------------------------------------------  /

			hide: function(){

				var self = this;

				if(self.isShowing){

					self.isShowing = false;
					self.removeListeners();
					
					// wait for animation to complete then add hide class

					self.view.once('hideComplete', function(){
						self.addHideClass();
					});

					self._onHide();
				}
			},

			_onHide: function(){

				var self = this;
				self.triggerEvent('hide');

				if(typeof(self.view.onHide) === 'undefined'){

					TweenMax.to(self.view.$el, 0.3, {opacity:0, ease:Cubic.easeOut});
					
					window.requestAnimationFrame(function(){
						self.triggerEvent('hideComplete');
					});
				}
				else { self.view.onHide(); }
			},

			// trigger events ------------------------------------------------------------------  /

			triggerEvent: function(eventName, options){

				var self = this;

				self.trigger(eventName, {target:self});
				self.view.trigger(eventName, {target:self.view});
			},

			// window resize listener ----------------------------------------------------------  /

			_onWindowResize: function(e){

				var self = this;

				if(typeof(self.view.onWindowResize) === 'undefined'){ }
				else { self.view.onWindowResize(e); }
			},

			// destroy -------------------------------------------------------------------------  /

			exit: function(){

				var self = this;

				if(!self.isDead && !self.isDying){

					self.isDying = true;
					self.triggerEvent('destroy');

					self.view.once('hideComplete', function(){
						
						self.view.$el.remove();
						self.isDead = true;

						$(window).off('resize', $.proxy(self._onWindowResize, self));

						if(typeof(self.view.onDestroyComplete) === 'undefined'){ }
						else { self.view.onDestroyComplete(); }

						self.triggerEvent('destroyComplete');
					});

					self.hide();
				}
			},

			exitImmediately: function(){

				var self = this;				

				if(!self.isDead){

					self.isDying = true;
					self.triggerEvent('destroy');
					self.removeListeners();
					self.view.$el.remove();
					self.isDead = true;

					$(window).off('resize', $.proxy(self._onWindowResize, self));

					if(typeof(self.view.onDestroyComplete) === 'undefined'){ }
					else { self.view.onDestroyComplete(); }

					self.triggerEvent('destroyComplete');
				}
			}

			// ---------------------------------------------------------------------------------  /

		});

	return AbstractView;
});
