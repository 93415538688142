
define(
	'modules/global-form-helpers/views/ContactFormView',[
		'backbone',
		'utils/AbstractView'
	], 
	function(Backbone, AbstractView){

		var ContactFormView = Backbone.View.extend({

			// core vars

			$el: null,
			$container: null,
			options: null,
			abstractView: null,

			// vars

			$descriptionContainer: null,
			$formContainer: null,

			// init

			initialize: function(options){

				var self = this;

				// options vars

				self.options = options;
				self.$container = self.options.$container;

				// template config

				self.model = $.extend({}, self.model);

				// initialize AbstractView

				self.abstractView = new AbstractView({
					view: self,
					autoShow: self.options.autoShow !== false
				});
			},

			onDomReady: function(){

				var self = this;

				self.$descriptionContainer = self.$el.find('.form-description-container');
				self.$formContainer = self.$el.find('.mf-form-contents-container');

				self.$el.find('form').on('submit', $.proxy(self._onSubmit, self));

				self.showIntroDescription();
			},

			showIntroDescription: function(){

				var self = this;
				var introTemplate = _.template($('#intro-description-template').html());

				var $introContent = window.$rootContext(introTemplate());
				$introContent.find('select').selectBoxIt();

				TweenMax.to(self.$descriptionContainer, 0.3, {opacity:0, ease:Cubic.easeInOut, onComplete:function(){
					
					self.$descriptionContainer.html($introContent);
					TweenMax.to(self.$descriptionContainer, 0.3, {opacity:1, ease:Cubic.easeOut});

					window.$vent.trigger('domUpdate');
				}});
			},

			showSuccessDescription: function(){

				var self = this;
				var successTemplate = _.template($('#success-description-template').html());

				var $successContent = window.$rootContext(successTemplate());
				$successContent.find('select').selectBoxIt();

				TweenMax.to(self.$descriptionContainer, 0.3, {opacity:0, ease:Cubic.easeInOut, onComplete:function(){
					
					self.$descriptionContainer.html($successContent);
					TweenMax.to(self.$descriptionContainer, 0.3, {opacity:1, ease:Cubic.easeOut});

					window.$vent.trigger('domUpdate');
				}});
			},

			removeForm: function(){

				var self = this;

				TweenMax.to(self.$formContainer, 0.3, {opacity:0, ease:Cubic.easeInOut, onComplete:function(){
					self.$formContainer.empty();
					TweenMax.set(self.$formContainer, {opacity:1});
					
					window.$vent.trigger('domUpdate');
				}});
			},

//			_onSubmit: function(e){
//
//				var self = this;
//				var form = self.$el.find('#contact-form');
//				var data = {};
//			   var formData = form.serializeArray();
//			   console.log(formData);
//				//strips 'cont-' from name data
//				for (i = 0; i < formData.length; i++) {
//					formData[i].name = formData[i].name.slice(5);
//    			}
//
//			   $.each(formData, function() {
//			       if (formData[this.name]) {
//			           if (!data[this.name].push) {
//			               data[this.name] = [data[this.name]];
//			           }
//			           data[this.name].push(this.value || '');
//			       } else {
//			           data[this.name] = this.value || '';
//			       }
//			   });
//
//
//				//data = JSON.stringify(data);
////    			console.log(data);
//
//				$.ajax({
//			         url:'http://localhost:9090/contact/send',
//			         type:'POST',
//			         dataType : "json",
//    				 contentType: "application/json; charset=utf-8",
//    				 data : JSON.stringify(data),
//			         crossDomain: true,
//			         success:function () {
//			               console.log("ajax success");
//			         }
//
//				});
//
//				self.removeForm();
//				self.showSuccessDescription();
//
//				e.preventDefault();
//				return false;
//			}

		});

	return ContactFormView;
});
