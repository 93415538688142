define(
	'modules/anchor-nav/app',[
		'backbone',
	], 
	function(Backbone){

		var AppView = Backbone.View.extend({

			// vars

			$anchorNavs: null,
			$navButtons: null,

			anchorPrefix: '',

			// init

			initialize: function(options){

				var self = this;
			},

			// start module --------------------------------------------------------------------  /

			start: function(){

				var self = this;
				window.$vent.trigger('anchorNavReady');

				$(function(){
					self.onDomReady();
					self.onAddListeners();
				});
			},

			onDomReady: function(){

				var self = this;

				self.$anchorNavs = self.$el.find('.anchor-nav');
				self.$navButtons = self.$el.find('.anchor-link');
			},			

			onAddListeners: function(){

				var self = this;

				_.each(self.$navButtons, function(navButton, i){
					var $navButton = $(navButton);
					$navButton.on('click', $.proxy(self._onClick, self));					
				});

				window.$vent.on('navSeekToAnchor', $.proxy(self._onSeekToAnchor, self));
				window.$vent.on('seekToElement', $.proxy(self._onSeekToElement, self));
			},

			// listener methods 

			_onClick: function(e){

				var self = this;
				var $target = $(e.currentTarget);
				var href = $target.attr('href').split('#')[1];

				self.seekToAnchorId(e, href);
				e.preventDefault();
			},

			_onSeekToAnchor: function(e, obj){

				var self = this;

				if(obj){
					self.seekToAnchorId({currentTarget:obj.target}, obj.anchorId);
				}
			},

			_onSeekToElement: function(e, obj){

				var self = this;

				if(obj.$content && typeof(obj.$content.context) !== 'undefined'){
					self.seekToElement(obj.$content, obj.anchorYOffset);
				}
				else if(typeof(obj.context) !== 'undefined'){
					self.seekToElement(obj);
				}
			},

			// seeking methods

			seekToAnchorId: function(e, anchorId){

				var self = this;
				var $target = $(e.currentTarget);
				var DEFAULT_OFFSET = 130;

				// find nav controller based on anchor-nav's

				var $nav = null;

				_.each(self.$anchorNavs, function(anchorNav){
					if(anchorNav.contains(e.currentTarget)){
						$nav = $(anchorNav);
					}
				});

				// pull config from dom

				var prefix = '';
				var anchorYOffset = DEFAULT_OFFSET;

				console.log('seekToAnchorId', anchorId, anchorYOffset);

				if(!$nav && $target){ 

					var $parent = $target.parent();
					var noNavPrefix = $parent.data('type');	
					var noNavYOffset = $parent.data('anchor-y-offset');

					if(_.isUndefined(noNavPrefix)){ noNavPrefix = $target.data('type'); }
					if(_.isUndefined(noNavYOffset)){ noNavYOffset = $target.data('anchor-y-offset'); }

					if(!_.isUndefined(noNavPrefix)){ prefix = noNavPrefix; }
					if(!_.isUndefined(noNavYOffset)){ anchorYOffset = parseInt(noNavYOffset); }
				}
				else if($nav) {
					
					prefix = $nav.data('type') || prefix;
					anchorYOffset = parseInt($nav.data('anchor-y-offset')) || anchorYOffset;
				}

				// find location of content block based on parent prefix, click to scroll

				var $content = self.$el.find('*[data-' + prefix + '="' + anchorId + '"]');

				if(!$content.length){
					if(prefix){ prefix = prefix + '-'; }
					$content = self.$el.find('#' + prefix + anchorId);
				}

				console.log($content, ' assssssss');

				if($content.length){
				
					// individual content settings override master settings

					var contentAnchorYOffset = $content.data('anchor-y-offset');
					
					if(!_.isUndefined(contentAnchorYOffset) && _.isNumber(contentAnchorYOffset)){ 
						anchorYOffset = parseInt(contentAnchorYOffset); 
					}

					// 

					var winHeight = window.innerHeight;
					var contentOffset = Math.round($content.offset().top) - anchorYOffset;
					var currentScrollTop = $(window).scrollTop();
					var tweenObj = {scrollTop:currentScrollTop};
					var time = Math.max(0.6, Math.abs((currentScrollTop - contentOffset)/3000));

					TweenMax.to(tweenObj, time, {scrollTop:contentOffset, onUpdate:function(){
						$(window).scrollTop(tweenObj.scrollTop);
					}, ease:Sine.easeInOut});
				}
			},

			seekToElement: function($content, anchorYOffset){

				var self = this;

				if(_.isUndefined(anchorYOffset)){ anchorYOffset = 130; }

				console.log('seekToElement', $content, anchorYOffset);

				if($content.length){

					var winHeight = window.innerHeight;
					var contentOffset = Math.round($content.offset().top) - anchorYOffset;
					var currentScrollTop = $(window).scrollTop();
					var tweenObj = {scrollTop:currentScrollTop};
					var time = Math.max(0.6, Math.abs((currentScrollTop - contentOffset)/3000));

					TweenMax.to(tweenObj, time, {scrollTop:contentOffset, onUpdate:function(){
						$(window).scrollTop(tweenObj.scrollTop);
					}, ease:Sine.easeInOut});
				}
			},
			
			// ---------------------------------------------------------------------------------  /

		});

		return AppView;
	}
);
