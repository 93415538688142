define(
	'modules/guides-sidepagination/views/SidePaginationView.js',[
		'backbone',
		'utils/AbstractView',
		''
	], 
	function(Backbone, AbstractView){

		var SidePaginationView = Backbone.View.extend({

			// core vars
			$el: null,
			$container: null,
			$sidePagination: null,
			$leftRule: null,
			$topRule: null,
			$links: null,

			// init
			initialize: function(options){

				var self = this;

				$(function () {
					self.onDomReady();
					self.onRender();
					self.onAddListeners();
				});

			},

			//grab the properties
			onDomReady: function() {

				var self = this;

				self.$el					= $('.guides-page');
				self.$container 			= $('.container-wrapper');
				self.$sidePagination 		= self.$el.find('.side-pagination');
				self.$leftRule 				= self.$el.find('.rule-div');
				self.$topRule 				= self.$el.find('.top-rule');
				self.$links					= self.$el.find('.guide-anchor');
				self.$rows					= $('.selling-guide-item');
				 	
			},

			onRender: function(){
				var self = this;

				self._checkLeftPosition();
				self._checkTopPosition();	
				self._customScrollSpy();
			},

			//add event handlers to them
			onAddListeners: function() {
				var self = this;	

				$(window).on('resize', $.proxy( self._checkLeftPosition, self ));
				$(window).on('resize', $.proxy( self._checkTopPosition, self ));
				$(window).on('scroll', $.proxy( self._checkTopPosition, self ));
				$(window).on('scroll', $.proxy( self._customScrollSpy, self ));		
				self.$links.on('click', $.proxy( self._goToScrollSpy, self ));
			},

			_checkLeftPosition: function() {
				var self = this;
				var leftPos = self.$leftRule.offset().left;				

				TweenMax.set(self.$sidePagination, {left:leftPos});
			},

			_checkTopPosition: function() {
				var self = this;
				var topPos = self.$topRule.offset().top;
				var currPos = $(document).scrollTop();
				var leftPos = self.$leftRule.offset().left;

				var lastEl = self.$sidePagination.find('.nav li:last-child a');
				var $target = $(lastEl.attr('href'));
				var targettopPos = $target.offset().top;
				var targetMaxPos = $target.innerHeight() + targettopPos;

				if ($(window).width <= 1199) {
					targetMaxPos = targetMaxPos - 400;
				} else {
					targetMaxPos = targetMaxPos - 350;
				}
														
				if(currPos >= topPos && currPos < (self.$topRule.height()+topPos-920)){										
					if(!self.$sidePagination.hasClass('fixed')){					
						self.$sidePagination.css({top:120});
						self.$sidePagination.addClass('fixed');
					}
				}else{
					if(self.$sidePagination.hasClass('fixed')){
						var offset = self.$sidePagination.offset().top - self.$topRule.offset().top;
						self.$sidePagination.removeClass('fixed');							
						
						if(offset < 120){ offset = 120; }
												
						self.$sidePagination.css({top: (offset) });
					}
				}
				
				if ( !self.$sidePagination.is(':visible') ){
										
					if ( (currPos + $target.innerHeight()*1.2) >= topPos){						
						self.$sidePagination.show();
						TweenMax.fromTo(self.$sidePagination, 0.6, { opacity: 0 }, { opacity: 1, ease:Cubic.easeOut, onComplete: function(){															
						} });
					}
				} else {
					if ( (currPos + $target.innerHeight()) < topPos || currPos > targetMaxPos ){						
						
						TweenMax.to(self.$sidePagination, 0.6, { opacity: 0, ease:Cubic.easeOut, onComplete: function(){							
							self.$sidePagination.hide();
						} });						
					}
				}

			},

			_customScrollSpy: function(){
				var self = this;				
				var currPos = $(document).scrollTop();

				self.$links.each(function(){
					var $target = $($(this).attr('href'));
					var topPos = $target.offset().top;
					var maxPos = $target.innerHeight() + topPos;

					console.log(currPos, topPos, maxPos, $target.attr('id'));

					if ( currPos >= (topPos - 240) && currPos < (maxPos - 150) )
					{
						$(this).addClass('active');
						$target.removeClass('inactive').addClass('active');
						self.$rows.not($target).removeClass('active').addClass('inactive');
					} 
					else 
					{
						$(this).removeClass('active');
					}
				});
			},

			_goToScrollSpy: function(e){
				e.preventDefault();

				var self = this;
				var $curr = $(e.currentTarget);
				var $target = $($curr.attr('href'));
				
				$target.removeClass('inactive').addClass('active');
				self.$rows.not($target).removeClass('active').addClass('inactive');

				$('body,html').animate({
					scrollTop: $target.offset().top - 250
				},200);
			},

		});

	return SidePaginationView;
});
