
define(
	'modules/global-scripted-buttons/views/REMShowPhotos',[
		'backbone',
		'utils/AbstractView',
		'utils/AbstractButtonView',
	], 
	function(Backbone, AbstractView, AbstractButtonView){

		var REMShowPhotos = Backbone.View.extend({

			// core vars

			$el: null,
			$container: null,
			options: null,
			abstractView: null,
			remMoreShow: null,

			// vars

			$targetContainer: null,
			targetClass: null,
			targetId: null,

			isWaitingForDeselect: false,

			// init

			initialize: function(options){

				var self = this;

				self.options = options;
				self.$container = self.options.$container;

				// 

				self.abstractView = new AbstractView({
					view: self,
					autoShow: true
				});

				self.button = new AbstractButtonView({
					abstractView: self.abstractView,
					animateInteractionsWhenSelected: true,
					selectable: true,
				});
			},

			// render

			onRender: function(){

				var self = this;
			},

			//

			onShow: function(){

				var self = this;

				self.remMoreShow = self.$container.find('.remMorePhotos');

				self.trigger('showComplete');			
				
			},

			onAddListeners: function(){

				var self = this;
				self.remMoreShow.on('click', $.proxy(self.remMorePhotos, self));
			},

			//

			remMorePhotos: function(e){
				var self = this;
				var extraPhotos = $('.extra-photos');
				e.preventDefault();

				if(extraPhotos.hasClass('display-none')){
					extraPhotos.removeClass('display-none');
					TweenMax.fromTo(extraPhotos,0.6,{opacity:0},{opacity:1});
					$(e.currentTarget).html('Less Images');
				}else{					
					TweenMax.to(extraPhotos,0.6,{opacity:0,onComplete:function(){
						extraPhotos.addClass('display-none');
					}});
					$(e.currentTarget).html('Load More Images');
				}
			},

			// ---------------------------------------------------------------------------------  /

		});

	return REMShowPhotos;
});
