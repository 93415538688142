define(
    'modules/property-detail-bar/views/SidebarStep1View',[
        'backbone',
        'utils/AbstractView',
    ],
    function (Backbone, AbstractView) {

        var SidebarStep1View = Backbone.View.extend({
            // core vars

            $el: null,
            $container: null,
            options: null,
            abstractView: null,
            lang:null,
            // vars

            $centeredContent: null,
            $floatingSocials: null,
            $bookingButton: null,
            $favoriteButton: null,
            $closeNote: null,
            $addNote: null,
            $viewingButton: null,
            $closeViewingBtn: null,
            $cancelViewingBtn: null,
            $emailValidate: null,
            $phoneValidate: null,

            $checkIn:null,
            $checkOut:null,

            //mobile buttons
            $requestBook:null,

            //required inputs for arrange a viewing
            $reqInput: null,
            $arrangeForm: null,
            // init

            initialize: function (options) {

                var self = this;

                self.lang = $('#lang').html();
                self.options = options;
                self.$container = self.options.$container;
                self.el = self.options.el;

                self.model = $.extend({}, self.model);
                self.abstractView = new AbstractView({
                    view: self,
                    autoShow: self.options.autoShow === true
                });

            },
            initPromise: function () {

                var self = this;

                var deferred = $.Deferred();

                var title = ($('.title')).text();
                var location = ($('.intro-location')).text();
                var headline = ($('.headline')).text();
                var cost = ($('.cost')).text();
                var min_stay = ($('.minstay')).text();
                var agent_name = ($('.agent-name')).text();
                var agent_tel = ($('.agent-tel')).text();
                var agent_email = ($('.agent-email')).text();
                var agent_photo = ($('.agent-photo')).text();
                var idBo = ($('.idBo')).text();
                var crmId = ($('.crmId')).text();
                var refno = ($('.refno')).text();
                var selected_check_in = ($('.selected-check-in')).text();
                var selected_check_out = ($('.selected-check-out')).text();
                var lang = ($('.lang')).text();
                var city = ($('.city')).text();
                var district = $('.js-district').text();

                if ($('.ss-property-detail-rent').length > 0) {
                    console.log("Entra en el if de rent");
                    $.ajax({
                        url: "/rent/sidebar1Ajax",
                        data: $.param({
                            title: title,
                            location: location,
                            headline: headline,
                            cost: cost,
                            min_stay: min_stay,
                            agent_name: agent_name,
                            agent_tel: agent_tel,
                            agent_email: agent_email,
                            agent_photo: agent_photo,
                            reference: refno,
                            crmId: crmId,
                            type: "rent",
                            district: district,
                        }),
                        success: function (data) {
                            self.template = _.template(data);

                            self.model = $.extend({}, self.model);

                            //

                            self.abstractView = new AbstractView({
                                view: self,
                                autoShow: self.options.autoShow === true,
                            });

                            deferred.resolve();
                        }
                    });


                } else if ($('.ss-property-detail-short').length > 0) {
                    console.log("Entra en el if de short");
                    $.ajax({
                        url: "/shortlet/sidebar1Ajax",
                        data: $.param({
                            title: title,
                            location: location,
                            headline: headline,
                            cost: cost,
                            min_stay: min_stay,
                            agent_name: agent_name,
                            agent_tel: agent_tel,
                            agent_email: agent_email,
                            agent_photo: agent_photo,
                            idBo: idBo,
                            refno: refno,
                            selected_check_in: selected_check_in,
                            selected_check_out: selected_check_out,
                            redirectUrl: window.location.href
                        }),
                        success: function (data) {
                            self.template = _.template(data);

                            self.model = $.extend({}, self.model);

                            //

                            self.abstractView = new AbstractView({
                                view: self,
                                autoShow: self.options.autoShow === true,
                            });

                            deferred.resolve();
                        }
                    });


                } else if ($('.ss-property-detail-buy').length > 0) {
                    console.log("Entra en el if de buy");
                    $.ajax({
                        url: "/buy/sidebar1Ajax",
                        data: $.param({
                            title: title,
                            location: location,
                            headline: headline,
                            cost: cost,
                            min_stay: min_stay,
                            agent_name: agent_name,
                            agent_tel: agent_tel,
                            agent_email: agent_email,
                            agent_photo: agent_photo,
                            reference: refno,
                            crmId: crmId,
                            type: "sale",
                            lang: lang,
                            city: city,
                            district: district,
                        }),
                        success: function (data) {
                            self.template = _.template(data);

                            self.model = $.extend({}, self.model);

                            //

                            self.abstractView = new AbstractView({
                                view: self,
                                autoShow: self.options.autoShow === true
                            });

                            deferred.resolve();
                        }
                    });

                }

                return deferred.promise();

            },
            onRender: function () {
                console.log("ONRENDER EN EL SIDEBAR1");

                var self = this;

                self.$bookingButton = self.$el.find('.btn-req-booking');

                self.$centeredContent = self.$el.find('.vert-center-content');
                self.$floatingSocials = self.$el.find('.floating-socials');
                self.$favoriteButton = self.$el.find('.btn-add-to-favorites');
                self.$viewingButton = self.$el.find('.btn-arrange').not('.btn-requested');
                self.$closeViewingBtn = self.$el.find('.shortlet-booking-requested .close-me');
                self.$cancelViewingBtn = self.$el.find('.btn-cancel-arrange');
                self.$closeNote = self.$el.find('.close-note');
                self.$addNote = self.$el.find('.add-note');

                self.$emailValidate = self.$el.find('.email-validate');
                self.$phoneValidate = self.$el.find('.phone-validate');
                self.$reqInput = self.$el.find('.req-input');
                self.$arrangeForm = self.$el.find('#pdp-arrange-viewing');

                self.isShort = $('#property-detail-shortlet').length ? true : false;

                self.$facebookShare = self.$el.find('.facebook-share');
                self.$twitterShare = self.$el.find('.twitter-share');
                self.socialUrl = $('#social-url').text();

                //mobile btn
                self.$requestBook = self.$el.find('.btn-mobile-book');
            },
            onDomReady: function () {
                console.log("ONDOMREADY EN EL SIDEBAR1");

                var self = this;

                window.$vent.trigger('domUpdate');
            },
            // listeners

            onAddListeners: function () {

                var self = this;
                self.$bookingButton.on('click', $.proxy(self._onBookingReqClick, self));
                self.$viewingButton.on('click', $.proxy(self._onViewingClick, self));
                self.$closeViewingBtn.on('click', $.proxy(self._onCloseArrangeClick, self));
                self.$cancelViewingBtn.on('click', $.proxy(self._onCloseArrangeClick, self));
                self.$favoriteButton.on('click', $.proxy(self._onFavoriteMouseOver, self));
                self.$closeNote.on('click', $.proxy(self._onCloseNote, self));
                self.$addNote.on('click', $.proxy(self._onAddNote, self));

                self.$emailValidate.on('keyup', $.proxy(self._validateEmail, self));
                self.$phoneValidate.on('blur', $.proxy(self._validatePhone, self));
                self.$arrangeForm.on('submit', $.proxy(self._submitArrangeForm, self));
                self.$container.on('mousemove', $.proxy(self._checkReqInput, self));

                self.$favoriteButton.on('click', $.proxy(self._onFavoriteClick, self));

                self.$facebookShare.on('click', $.proxy(self._onFacecbookShareClick, self));
                self.$twitterShare.on('click', $.proxy(self._onTwitterShareClick, self));

                window.$vent.on('click', $.proxy(self._onTwitterShareClick, self));

                window.$vent.on('optionDateSelectedApp', $.proxy(self._onUpdateDates, self));

            },
            onRemoveListeners: function () {

                var self = this;

                self.$bookingButton.off('click', $.proxy(self._onBookingReqClick, self));
                self.$favoriteButton.off('click', '.favorite', $.proxy(self._onFavoriteClick, self));
                self.$favoriteButton.off('click', '.not-favorite', $.proxy(self._onSelectedFavoriteClick, self));
                self.$favoriteButton.off('click', $.proxy(self._onFavoriteMouseOver, self));
                self.$viewingButton.off('click', $.proxy(self._onViewingClick, self));
                self.$closeViewingBtn.off('click', $.proxy(self._onCloseArrangeClick, self));
                self.$cancelViewingBtn.off('click', $.proxy(self._onCloseArrangeClick, self));
                self.$closeNote.off('click', $.proxy(self._onCloseNote, self));
                self.$addNote.off('click', $.proxy(self._onAddNote, self));

                self.$emailValidate.off('keyup', $.proxy(self._validateEmail, self));
                self.$phoneValidate.off('blur', $.proxy(self._validatePhone, self));
                self.$arrangeForm.off('submit', $.proxy(self._submitArrangeForm, self));
                self.$container.off('mousemove', $.proxy(self._checkReqInput, self));

                self.$favoriteButton.off('click', $.proxy(self._onFavoriteClick, self));
            },

            _onFavoriteClick: function (e) {
                var self = this;
                var $target = $(e.currentTarget);

                if ($("#userIsLogged").length > 0) {
                    var attrs = self.$favoriteButton[0].id.split("-");

                    var data = {};
                    data["propertyId"] = attrs[0];
                    data["propertyType"] = attrs[1];

                    $.ajax({
                        type: 'POST',
                        url: '/userArea/toogleFavoriteAjax',
                        data: JSON.stringify(data),
                        async: false,
                        contentType: "application/json; charset=utf-8",
                        success: function (response) {
                            if ($('#isFav').text() == 'false') {
                                $('#isFav').text('true');
                            } else {
                                $('#isFav').text('false');
                            }
//
//
//                    if ($target.hasClass('selected')) {
//                        //window.$vent.trigger('setAlertCount', {count: 'decrement'});
//                        $target.removeClass('selected');
//                    } else {
//                        //window.$vent.trigger('setAlertCount', {count: 'increment'});
//                        $target.addClass('selected');
//                    }
                        },
                        error: function (e) {
                            console.log("favoriteClick: " + e.message);
                        }

                    });
                } else {
                    window.$vent.trigger('openModal', {modalId: '/modals/login'});
                }

                e.preventDefault();
                return false;
            },

            _onFacecbookShareClick: function () {
                var self = this;

                var popup = window.open("https://www.facebook.com/sharer/sharer.php?u=" + self.socialUrl, "pop", "width=600, height=400, scrollbars=no");
            },
            _onTwitterShareClick: function () {
                var self = this;

                var popup = window.open("https://twitter.com/share?url=" + self.socialUrl, "pop", "width=600, height=400, scrollbars=no");

            },

            _onFavoriteMouseOver: function (e) {
                var self = this;
                var $target = $(e.currentTarget);
                var $note = $target.siblings('.add-favorite-note');
                if ($('#isFav').text() == 'false' && ($("#userIsLogged").length > 0)) {
                    $note.show();
                    if (!$target.hasClass('shortlet')) {
                        TweenMax.set($note, {top: $target.position().top + parseInt(40) - 10});
                    } else {
                        TweenMax.set($note, {top: -10});
                    }
                    TweenMax.fromTo($note, 0.6, {bottom: -60, opacity: 0}, {
                        bottom: 0,
                        opacity: 1,
                        ease: Quint.easeOut
                    });
                } else {
                    self._onCloseNote()
                }
            },
            _onCloseNote: function () {
                var self = this;
                var $note = self.$container.find('.add-favorite-note');
                if ($note.is(':visible')) {
                    TweenMax.fromTo($note, 0.6, {opacity: 1}, {
                        opacity: 0, ease: Quint.easeOut, onComplete: function () {
                            $note.hide();
                        }
                    });
                }
            },
            _onAddNote: function () {
                var self = this;
                var $note = self.$container.find('.add-favorite-note');

                var attrs = self.$favoriteButton[0].id.split("-");
                var data = {};
                data["propertyId"] = attrs[0];
                data["propertyType"] = attrs[1];
                data["note"] = $('textarea[name=note]').val();

                $.ajax({
                    type: 'POST',
                    url: '/userArea/updateFavNoteAjax',
                    data: JSON.stringify(data),
                    async: false,
                    contentType: "application/json; charset=utf-8",
                    success: function (response) {
                    },
                    error: function (e) {
                        console.log("favoriteClick: " + e.message);
                    }

                });

                if ($note.is(':visible')) {
                    TweenMax.fromTo($note, 0.6, {opacity: 1}, {
                        opacity: 0, ease: Quint.easeOut, onComplete: function () {
                            $note.hide();
                        }
                    });
                }
            },
            _onViewingClick: function () {
                var self = this;

                var origin ='property-detail-buy';
                var id = ($('.idBo')).text();
                var location = ($('.intro-location')).text();

                var data = {
                    'trackAction': origin ,
                    'trackLabel': {
                        'propReference': id ,
                        'propLocation':location,
                    }
                }

                Utils.DOMUtils.triggerTrackEvent(data);

                self.trigger('gotoArrangeViewing');
            },
            _onBookingReqClick: function () {
                var self = this;
                var origin ='availability';
                var id = ($('.idBo')).text();
                var location = ($('.intro-location')).text();


                var  data = {
                    'trackAction': origin ,
                    'trackLabel': {
                        'propReference': id,
                        'propLocation': location ,
                        'checkIn':self.$checkIn ,
                        'checkOut': self.$checkOut
                    }
                }
                Utils.DOMUtils.triggerTrackEvent(data);
                self.trigger('gotoStep2');
            },
            _onCloseArrangeClick: function () {
                var self = this;
                var defaultSidebar1 = self.$container.find('.property-bar-step-1 .prop-req-booking');
                var availabilitySidebar1 = self.$container.find('.property-bar-step-1 .shortlet-booking-requested');

                //show the default sidebar2 content
                //hide the availability sidebar2 content

                self.trigger('closeArrangeViewing');
                return false;
            },
            _validateEmail: function () {
                var self = this;
                var $email = self.$emailValidate;
                var re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                self.$lang = $('.lang').html();

                if (self.$lang == "es") {
                    var message = 'Formato incorrecto';
                } else {
                    var message = 'Bad format';
                }
                if (!re.test($email.val())) {
                    $email.parent('div').addClass('bad-format').append('<span class="bad-format-text">' + message + '</span>');
                    TweenMax.set($email, {
                        paddingRight: self.$el.find('.bad-format-text').outerWidth(true) + 10,
                    });
                    return false;
                } else {
                    $email.parent('div').removeClass('bad-format').remove('.bad-format-text');
                    TweenMax.set($email, {
                        paddingRight: $email.css('padding-left'),
                    });
                    return true;
                }
            },
            _validatePhone: function () {
                var self = this;
                var $phone = self.$phoneValidate;
                self.$lang = $('.lang').html();

                if (self.$lang == "es") {
                    var message = 'Formato incorrecto';
                } else {
                    var message = 'Bad format';
                }
                if (isNaN($phone.val()) || (!isNaN($phone.val()) && $phone.val().length !== 9)) {
                    $phone.parent('div').addClass('bad-format').append('<span class="bad-format-text">' + message + '</span>');
                    TweenMax.set($phone, {
                        paddingRight: self.$el.find('.bad-format-text').outerWidth(true) + 10,
                    });
                    return false;
                } else {
                    $phone.parent('div').removeClass('bad-format').remove('.bad-format-text');
                    TweenMax.set($phone, {
                        paddingRight: $phone.css('padding-left'),
                    });
                    return true;
                }
            },
            _checkReqInput: function () {
                var self = this;
                var check = 0;
                var $formInput = self.$el.find('.req-input');
                var checkbox = $('#ss_tos');
                var $mainErrors = self.$arrangeForm.find('.btn-arrange-submit span.main-errors');
                var $tosErrors = self.$arrangeForm.find('.btn-arrange-submit span.tos-errors');
                var $noErrors = self.$arrangeForm.find('.btn-arrange-submit span.no-errors');
                $formInput.each(function () {
                    var value = $(this).val();
                    value = value.replace(/\s+/g, '');
                    if (value === '') {
                        check = (check + 2) - 1;
                    } else {
                        //acceptance of terms check
                        if ($(this).hasClass('checkbox-input')) {
                            if (!$(this).is(':checked')) {
                                check = (check + 2) - 1;
                            }
                        }

                        if ($(this).hasClass('email-validate')) {
                            if (!self._validateEmail()) {
                                check = (check + 2) - 1;
                            }
                        }

                        if ($(this).hasClass('phone-validate')) {
                            if (!self._validatePhone()) {
                                check = (check + 2) - 1;
                            }
                        }
                    }
                });

                //checking if TOS is the last one to be checked
                if (check === 1 && !checkbox.is(':checked')) {
                    $noErrors.hide().parent().removeClass('btn-no-errors').attr('disabled', true);
                    $mainErrors.hide();
                    $tosErrors.show();
                    /*TweenMax.fromTo($tosErrors, 0.6, {y:-60, opacity:0}, {y:0, opacity:1, ease:Quint.easeOut});*/
                    if (!self.isShort)
                        return false;
                } else if (check <= 0) {
                    $mainErrors.hide();
                    $tosErrors.hide();
                    $noErrors.show().parent().addClass('btn-no-errors').attr('disabled', false);
                    /*TweenMax.fromTo($noErrors, 0.6, {y:-60, opacity:0}, {y:0, opacity:1, ease:Quint.easeOut});*/
                    if (!self.isShort)
                        return false;
                } else {
                    $tosErrors.hide();
                    $noErrors.hide().parent().removeClass('btn-no-errors').attr('disabled', true);
                    $mainErrors.show();
                    /*TweenMax.fromTo($mainErrors, 0.6, {y:-60, opacity:0}, {y:0, opacity:1, ease:Quint.easeOut});*/
                    if (!self.isShort)
                        return false;
                }
            },
            _submitArrangeForm: function () {
                var self = this;
                var $viewingReqBtn = self.$container.find('.btn-requested');
                //do something to submit the form here

                var form = $("#pdp-arrange-viewing");
                var data = {};
                var formData = form.serializeArray();

                $.each(formData, function () {
                    if (formData[this.name]) {
                        if (!data[this.name].push) {
                            data[this.name] = [data[this.name]];
                        }
                        data[this.name].push(this.value || '');
                    } else {
                        data[this.name] = this.value || '';
                    }
                });

                data = JSON.stringify(data);
                console.log(data);


                $.ajax({
                    url: '/global/rentBuyRequestViewing',
                    type: 'POST',
                    dataType: "json",
                    contentType: "application/json",
                    data: data,
                    crossDomain: true,
                    success: function ( ) {

                        var origin ='Arrange View';
                        var id = ($('.idBo')).text();
                        var location = ($('.intro-location')).text();


                        var  data = {
                            'trackAction': origin ,
                            'trackLabel': {
                                'propReference': id,
                                'propLocation': location ,
                            }
                        }
                        Utils.DOMUtils.triggerTrackEvent(data);
                    },
                    error: function () {
                        //add
                    }

                });
                //going back to original screen after data has been processed
                var defaultSidebar1 = self.$container.find('.property-bar-step-1 .prop-req-booking');
                var availabilitySidebar1 = self.$container.find('.property-bar-step-1 .shortlet-booking-requested');

                self.$viewingButton.hide();
                $viewingReqBtn.show();

                self.trigger('closeArrangeViewing');


                return false;
            },
            //

            allowBooking: function () {
                var self = this;
                self.$bookingButton.removeClass('disabled');
            },
            disableBooking: function () {
                var self = this;
                self.$bookingButton.removeClass('disabled').addClass('disabled');
            },
            showErrorMessage: function (text) {
                var self = this;
                var content = $('.property-bar-step-1 #date-message-container');
                $('.floating-socials').removeClass('shortlet').addClass('error');

                TweenMax.to(content, 0, {
                    opacity: 0, ease: Cubic.easeOut, onComplete: function () {
                        $(content).html(text);
                        TweenMax.fromTo(content, 0, {y: 0}, {y: 0, opacity: 1, ease: Sine.easeOut});
                    }
                })
            },
            hideErrorMessage: function () {
                var self = this;
                var content = $('.property-bar-step-1 #date-message-container');
                var text = "";
                TweenMax.to(content, 0, {
                    opacity: 0, ease: Cubic.easeOut, onComplete: function () {
                        $(content).html(text);
                        TweenMax.fromTo(content, 0, {y: 0}, {y: 0, opacity: 1, ease: Sine.easeOut});
                    }
                })
            },
            // show / hide

            onShow: function () {

                var self = this;
                self.trigger('showComplete');

                window.requestAnimationFrame(self.onWindowResize.bind(self));
            },
            getMinHeight: function () {

                var self = this;
                var centeredContentHeight = self.$centeredContent.outerHeight(true);

                return centeredContentHeight + 65 * 2;
            },
            centerTheContent: function () {

                var self = this;
                var winHeight = window.innerHeight;

                self.$centeredContent = self.$el.find('.vert-center-content:visible');
                var centeredContentHeight = self.$centeredContent.outerHeight(true) + 45;

                if (winHeight > centeredContentHeight) {
                    console.log("entra por el 1 if");

                    TweenMax.set(self.$el, {
                        clearProps: 'height'
                    });

                    TweenMax.set(self.$centeredContent, {
                        top: Math.max(105, Math.round((winHeight - centeredContentHeight) / 2)),
                    });

                    TweenMax.set(self.$floatingSocials, {
                        //top: winHeight - 45,
                        //top: winHeight - self.$floatingSocials.outerHeight(true),
                    });

                    self.trigger('setFixed');
                } else {
                    console.log("entra por el 2 if");

                    TweenMax.set(self.$el, {
                        height: self.getMinHeight()
                    });

                    TweenMax.set(self.$centeredContent, {
                        top: 105
                    });

                    TweenMax.set(self.$floatingSocials, {
                        clearProps: 'top',
                    });

                    self.trigger('setAbsolute');
                }

            },
            // window resize

            onWindowResize: function () {

                var self = this;
                var winHeight = window.innerHeight;
                var centeredContentHeight = self.$centeredContent.outerHeight(true) + 45;

                if (winHeight > centeredContentHeight) {

                    TweenMax.set(self.$el, {
                        clearProps: 'height'
                    });

                    TweenMax.set(self.$centeredContent, {
                        top: Math.max(105, Math.round((winHeight - centeredContentHeight) / 2)),
                    });

                    TweenMax.set(self.$floatingSocials, {
                        //top: winHeight - 45,
                        //top: winHeight - self.$floatingSocials.outerHeight(true),
                    });

                    self.trigger('setFixed');
                } else {

                    TweenMax.set(self.$el, {
                        height: self.getMinHeight()
                    });

                    TweenMax.set(self.$centeredContent, {
                        top: Math.max(105, Math.round((winHeight - centeredContentHeight) / 2 + 105)),
                    });

                    TweenMax.set(self.$floatingSocials, {
                        clearProps: 'top',
                    });

                    self.trigger('setAbsolute');
                }
            },

            // Calendar

            _onUpdateDates : function (e,data) {
                var self = this;
                console.log("DATA: ", data);
                self.$checkIn =  moment(data.checkIn, 'DD/MM/YY').format('MM/DD/YY');

                self.$checkOut =  moment(data.checkOut, 'DD/MM/YY').format('MM/DD/YY');
            },
            // ---------------------------------------------------------------------------------  /



        });

        return SidebarStep1View;
    });

