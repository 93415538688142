define(
	'pagecontrollers/area-map/app',[
		'backbone',
		'pagecontrollers/area-map/views/EditorialView',
		'pagecontrollers/area-map/views/CuratedGuideView',
		'pagecontrollers/area-map/views/MapMenuView',

		'utils/utils/ArrayUtils'
	], 
	function(Backbone, EditorialView, CuratedGuideView, MapMenuView){

		var AppView = Backbone.View.extend({

			// consts

			HEADER_HEIGHT: 60,

			// initial script loading

			scriptsArray: null,
			scriptsLoaded: 0,
			isReady: false,

			// vars

			winWidth: 0,
			winHeight: 0,
			scrollTop: 0,
			heightOffset: 0,
			
			isSticky: false,

			$gridContainer: null,
			$resultsGrid: null,
			$mapContainer: null,
			$mapCanvas: null,
			$resultsText: null,
			$rightColumn: null,
			$editorialContainer: null,
			$mapCard: null,
			$mobileContainer: null,
			$propertiesContainer: null,

			currentGuideId: null,
			currentEditorialId: null,

			currentEditorial: null,
			currentGuide: null,

			listingsArray: null,
			inBoundsListings: null,
			resizeTimeout: null,
			cardDelay: null,

			tabMenu: null,

			isQuerying: false,
			isPendingRefresh: false,
			isDomReady: false,
			isTicked: false,
			isMapMovedCardHide: true,

			// initialize ----------------------------------------------------------------------  /

			initialize: function(){
				
				var self = this;

				$('#main-menu-wrapper').addClass('fixed');

				window.$vent.on('pageModulesLoaded', $.proxy(self._onModulesLoaded, self));
				window.$vent.trigger('areaMapPageReady');

				self.currentGuideId = self.$el.find('#area-map-page').data('initial-guide');
			},

			// start ---------------------------------------------------------------------------  /

			start: function(){

				var self = this;

				$(function(){
					self.onDomReady();
				});
			},

			// script loading ------------------------------------------------------------------  /

			_onModulesLoaded: function(){

				var self = this;			
				
				window.$vent.off('pageModulesLoaded', $.proxy(self._onModulesLoaded, self));

				self.addListeners();
				self.reloadListings();
			},			

			// dom ready -----------------------------------------------------------------------  /

			onDomReady: function(){

				var self = this;

				self.$gridContainer = self.$el.find('#prop-grid-container');
				self.$resultsGrid = self.$el.find('#results-grid');
				self.$mapContainer = self.$el.find('#neighborhood-map');
				self.$mapCanvas = self.$mapContainer.find('#map-canvas');
				self.$resultsText = self.$gridContainer.find('.grid-subtitle');
				self.$rightColumn = self.$el.find('#editorial-col');
				self.$editorialContainer = self.$rightColumn.find('#editorial-container');
				self.$mapCard = self.$el.find('.map-ncard');
				self.$mobileContainer = self.$el.find('.mobile-places-container');

				// menu

				self.tabMenu = new MapMenuView({
					el: self.$el.find('#tabs-menu-container'),
					$expandedContent: self.$el.find('#expanded-tabs-container'),
					autoShow: true,
				});

				// show current guide

				self.changeCuratedGuide(self.currentGuideId);

				self.isDomReady = true;
				self.onWindowResize();
			},

			// listeners -----------------------------------------------------------------------  /

			addListeners: function(){
				
				var self = this;

				window.$vent.on('filterResults', $.proxy(self._onFilterResults, self));
				window.$vent.on('mapFilteredListings', $.proxy(self._onMapFilteredListings, self));
				window.$vent.on('mapResultsUnchanged', $.proxy(self._onUnchangedMapResults, self));
				window.$vent.on('mapResults', $.proxy(self._onMapResults, self));
				window.$vent.on('openFiltersMenu', $.proxy(self._onOpenFiltersMenu, self));
				window.$vent.on('closeFiltersMenu', $.proxy(self._onCloseFiltersMenu, self));
				window.$vent.on('newListingsQuery', $.proxy(self._onNewListingsQuery, self));
				window.$vent.on('requestListings', $.proxy(self._onListingsRequest, self));
				window.$vent.on('requestLocation', $.proxy(self._onRequestLocation, self));
				window.$vent.on('markerSelect', $.proxy(self._onMarkerSelect, self));
				window.$vent.on('changeMapLocation', $.proxy(self._onChangeMapLocation, self));

				self.$mobileContainer.on('click', '.mobile-place', $.proxy(self._onMobileMarkerSelect, self) );

				$(window).on('resize', $.proxy(self.onWindowResize, self));
				$(window).on('scroll', $.proxy(self._onScroll, self));
				$(window).mousewheel($.proxy(self._onMouseWheel, self));

				self.$mapCard.on('click', $.proxy(self._onCardClick, self));

				self.onWindowResize();

				// two ways to query listings:
				// 1) desktop: page queries filters -> send results to map -> display
				// 2) mobile: page queries filters -> display
				
				// TODO: collect all filters and map specs for each server query
			},

			// listener methods

			_onFilterResults: function(){

				var self = this;
			},

			_onMapResults: function(e, listingsArray, isDraggingMap){

				var self = this;
				self.listingsArray = listingsArray;				

				if(isDraggingMap){ self.prepListingsForRefresh(); }
				else { self.rebuildListingsGrid(listingsArray); }
			},

			_onUnchangedMapResults: function(){

				var self = this;				
				self.restorePreppedListings();
			},

			_onOpenFiltersMenu: function(){

				var self = this;
				self.prepListingsForRefresh();
			},

			_onCloseFiltersMenu: function(){

				var self = this;
				self.restorePreppedListings();
			},

			_onNewListingsQuery: function(e, obj){

				var self = this;
				console.log('_onNewListingsQuery', obj);

				self.reloadListings(obj.queryURL);
			},

			_onMarkerSelect: function(e, marker, markerId){

				var self = this;
				self.collapseCard();
				self.changeEditorial(markerId);
			},

			_onMobileMarkerSelect: function () {
				if(window.innerWidth < 768) {
					window.location.href = '?page=area_guide_map&editorial-mobile';
				}
			},

			_onListingsRequest: function(){

				var self = this;
				self.reloadListings();
			},

			_onRequestLocation: function(){

				var self = this;
				var locationParam = Utils.DOMUtils.getURLParameter('location');
				
			},

			_onMapFilteredListings: function(e, inBoundsListings, isDraggingMap){

				var self = this;
				self.inBoundsListings = inBoundsListings;
				self.paginationIndex = 0;

				console.log('_onMapFilteredListings', inBoundsListings, self.listingsArray);

				if(self.listingsArray){
					window.$vent.trigger('paginateListings', {startIndex:0, endIndex:self.listingsArray.length});
				}
			},

			_onChangeMapLocation: function(){

				var self = this;
				self.isMapMovedCardHide = true;
				self.hideCard();
			},

			_onCardClick: function(e){

				var self = this;
				self.expandCard();
			},

			// filter controls ---------------------------------------------------------------------  /

			reloadListings: function(queryURL){
                                console.log("REALOADLISTINGS 1");
				var self = this;

				if(!self.isQuerying){

					self.isQuerying = true;

					if(!queryURL){ queryURL = self.tabMenu.$el.data('default-query-url'); }

					console.log('reloadListings...');
					window.$vent.trigger('reloadListings');

					$.when($.proxy(self.queryListings, self)(queryURL)).then(function(json){
						
						console.log('reloadListings', json.results);
						
						self.listingsArray = json.results;
						self.isQuerying = false;
						
						self.populateMobile( self.listingsArray );
/*
						console.log('self.listingsArray =====================================================');
						console.dir(self.listingsArray);

						self.populateNearbyApartments( self.listingsArray );
*/


						if(self.isMapMovedCardHide){
							self.populateCard(json.card);
							self.isMapMovedCardHide = false;
						}

						window.$vent.trigger('listingsQueryComplete', [self.listingsArray]);
					});
				}
			},		

			queryListings: function(queryURL){

				var self = this;
				var dfd = $.Deferred();

				$.ajax({

					type: 'GET',
					url: queryURL,
					async: false,
					jsonpCallback: 'callBack',
					contentType: 'application/json',
					dataType: 'jsonp',

					success: function(json) {
						dfd.resolve(json);
					},
					error: function(e) {
						console.log('JSON Load Error', self);
						console.log(e.message);
						dfd.reject();
					}
				});

				return dfd.promise();
			},

			// change values in map ncard

			populateCard: function(obj){

				var self = this;

				if(self.cardDelay){ self.cardDelay.kill(); }
				self.cardDelay = TweenMax.delayedCall(0.5, function(){

					self.$mapCard.find('.ncard-title').html(obj.title);
					self.$mapCard.find('.ncard-subtitle').html(obj.subtitle);
					self.$mapCard.find('.ncard-description').html(obj.description);
					self.$mapCard.find('.map-ncont').removeClass('hide');

					TweenMax.fromTo(self.$mapCard, 0.4, {opacity:0}, {opacity:1, ease:Cubic.easeOut});
					self.$mapCard.removeClass('hide');
				});
			},

			hideCard: function(){

				var self = this;
				TweenMax.to(self.$mapCard, 0.3, {opacity:0, ease:Cubic.easeOut});
			},

			collapseCard: function(){

				var self = this;
				self.$mapCard.find('.map-ncont').removeClass('hide').addClass('hide');
			},

			expandCard: function(){

				var self = this;
				self.$mapCard.find('.map-ncont').removeClass('hide');
			},

			// change mobile listing grid

			populateMobile: function(results){

				var self = this;
				console.dir(results);

				var template = _.template($('#mobile-grid-template').html());

				self.$mobileContainer.html(template({results:results}));
			},

			populateNearbyApartments: function (results) {

				var self = this;
				var template = _.template( $("#nearby-properties-template").html() );
				
				console.log('==================================================================');
				console.dir(results);

				self.$propertiesContainer.html( template({nearbyProperties: results}) );
			},

			prepListingsForRefresh: function(){

				var self = this;

				if(!self.isPendingRefresh){

					self.isPendingRefresh = true;

					// _.each(self.gridItems, function(gridItem, i){
					// 	gridItem.showVeil();
					// });

					// hide results count

					TweenMax.to(self.$resultsText, 0.3, {opacity:0, ease:Cubic.easeOut});
				}
			},

			restorePreppedListings: function(){

				var self = this;

				if(self.isPendingRefresh){

					self.isPendingRefresh = false;

					// _.each(self.gridItems, function(gridItem, i){
					// 	gridItem.hideVeil();
					// });

					TweenMax.to(self.$resultsText, 0.3, {opacity:1, ease:Cubic.easeOut});
				}
			},

			// rebuild grid

			rebuildListingsGrid: function(listingsArray){

				var self = this;
				
				if(self.gridItems){ 

					// hide results count

					TweenMax.to(self.$resultsText, 0.3, {opacity:0, ease:Cubic.easeOut});
				}
				else { self.createListingGrid(listingsArray); }
			},

			createListingGrid: function(listingsArray){

				var self = this;

				self.onWindowResize();

				self.isQuerying = false;
				self.isPendingRefresh = false;
			},

			// check map boundaries

			checkMapBounds: function(){

				var self = this;
				window.$vent.trigger('resizeMap');
			},

			// content pages

			changeCuratedGuide: function(guideId){

				var self = this;

				if(!self.currentGuide || guideId != self.currentGuideId){

					if(self.currentGuide){ self.hideCuratedGuide(); }
					else { self.showCuratedGuide(guideId); }
				}
			},

			showCuratedGuide: function(guideId){

				var self = this;
				var guideModel = {
					queryURL: 'modules/data/curated-guides.json',
				}; 

				self.currentGuideId = guideId;
				self.currentGuide = new CuratedGuideView({
					$container: self.$editorialContainer,
					model: guideModel
				});

				window.$vent.trigger('domUpdate');
			},

			hideCuratedGuide: function(){

				var self = this;
				
				if(self.currentGuide){
					self.currentGuide.$el.addClass('absolute');
					self.currentGuide.exit();
					self.currentGuide = null;
				}
			},

			// editorial

			changeEditorial: function(editorialId){

				var self = this;

				if(!self.currentEditorial || editorialId != self.currentEditorialId){

					if(self.currentGuide){ self.hideCuratedGuide(); }
					if(self.currentEditorial){ self.hideEditorial(); }

					TweenMax.delayedCall(0.3, function(){
						self.showEditorial(editorialId);
					});
				}
			},

			showEditorial: function(editorialId){

				console.dir(editorialId);

				var self = this;
				var editorialModel = _.findWhere(self.listingsArray, {id:editorialId});

				editorialModel = $.extend({}, editorialModel);

				self.currentEditorialId = editorialId;
				self.currentEditorial = new EditorialView({
					$container: self.$editorialContainer,
					model: editorialModel
				});

				window.$vent.trigger('domUpdate');
			},

			hideEditorial: function(){

				var self = this;
				
				if(self.currentEditorial){
					self.currentEditorial.$el.addClass('absolute');
					self.currentEditorial.exit();
					self.currentEditorial = null;
				}
			},

			// resize window

			_onMouseWheel: function(e){

				var self = this;
				self.scrollTick();
			},

			_onScroll: function(){

				var self = this;
				self.scrollTick();
			},

			scrollTick: function(){

				var self = this;

				if(!self.isTicked){
					self.isTicked = true;
					window.requestAnimationFrame(self.scrollNow.bind(self));
				}
			},

			scrollNow: function(){
				
				var self = this;
				self.scrollTop = $(window).scrollTop();
				
				if(self.scrollTop > self.HEADER_HEIGHT){
					
					if(!self.isSticky){

						self.isSticky = true;

						TweenMax.set(self.$mapContainer, {
							position: 'fixed',
							//top: self.HEADER_HEIGHT,
						});
					}
				}
				else {

					if(self.isSticky){

						self.isSticky = false;

						TweenMax.set(self.$mapContainer, {
							clearProps: 'position',
							//top: self.HEADER_HEIGHT,
						});
					}
				}

				var $footerStartPos = $('#page-footer');
				var footerPos = $footerStartPos.offset().top;
				var newHeightOffset = 0;

				if(self.scrollTop + self.winHeight > footerPos){ 
					newHeightOffset = (self.scrollTop + self.winHeight) - footerPos;
				} else { 
					newHeightOffset = 0;
				}

				if(self.heightOffset != newHeightOffset){					
					
					self.heightOffset = newHeightOffset;
					self.onWindowResize();

					window.$vent.trigger('resizeMap');
				}

				self.isTicked = false;
			},

			onWindowResize: function(e){

				var self = this;
				var lastWidth = self.winWidth;
				var lastHeight = self.winHeight;

				self.winWidth = $(window).width();
				self.winHeight = window.innerHeight;

				if(self.isDomReady){

					TweenMax.set(self.$rightColumn, {
						'min-height': self.winHeight - self.HEADER_HEIGHT,
					});

					self._onScroll();

					var rightContainerWidth = self.$rightColumn.offset().left + self.$rightColumn.innerWidth();

					TweenMax.set(self.$mapContainer, {
						//width: self.winWidth - rightContainerWidth,
						height: self.winHeight - self.heightOffset,
					});

					TweenMax.set(self.$mapCanvas, {
						//width: self.$mapContainer.outerWidth(),
						height: self.$mapContainer.outerHeight(),
					});

					if(e && ((lastWidth != self.winWidth || lastHeight != self.winHeight) || self.isQuerying)){
						if(self.resizeTimeout){ window.clearTimeout(self.resizeTimeout); }
						self.resizeTimeout = window.setTimeout(self.checkMapBounds.bind(self), 200);
					}
				}
			},

		});

		return AppView;
	}
);
