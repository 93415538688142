define(
    'modules/property-detail-bar/app',[
        'backbone',
        'modules/property-detail-bar/views/SidebarStep1View',
        'modules/property-detail-bar/views/SidebarStep2View',
        'modules/calendars/views/CalendarMenuView',
        'modules/property-detail-bar/views/GuestsMenuView',
        'text!../../config/propertyDetailMessages-es.json',
        'text!../../config/propertyDetailMessages-en.json',
        'moment',
        'clndr',
        'selectboxit',
        'affix'
    ],
    function (Backbone, SidebarStep1View, SidebarStep2View, CalendarMenuView, GuestsMenuView, MessagesEs, MessagesEn) {

        var AppView = Backbone.View.extend({
            // core vars

            // vars

            lang: null,
            messages: null,

            $context: null,
            $container: null,
            containerBounds: null,
            $filterOptions: null,
            $filterOverlays: null,
            $currentStepEl: null,
            $activeFilterButton: null,
            $preFooter: null,
            $pageFooter: null,
            $pageHero: null,
            sidebarStep1: null,
            sidebarStep2: null,
            currentStep: null,
            prevStep: null,
            currentStepIndex: 0,
            propertyConfig: null,
            currentMenu: null,
            winWidth: null,
            winHeight: null,
            docHeight: null,
            sidebarHeight: null,
            currentStepHeight: null,
            heroHeight: null,
            footerHeight: null,
            scrollTop: null,
            calendarResults: null,
            guestsResults: null,
            isModalOpen: false,
            isFixedPosition: false,
            isFiltersInitialized: false,
            isConfigLoaded: false,
            isPendingScroll: false,
            isTicked: false,
            initializeWithDefaults: true,
            scrollOffset: 0,
            BOTTOM_PADDING: 0,
            checkIn: null,
            checkOut: null,
            // init

            initialize: function (options) {

                var self = this;
                self.lang = $('#lang').html();
                self.$context = $;
                self.$el = self.el = $('#property-detail-bar');
                self.$container = $(self.$el.parent());
                self.$innerContainer = self.$el.find('.inner-container');

                if (self.lang == "es") {
                    self.messages = JSON.parse(MessagesEs);
                } else {
                    self.messages = JSON.parse(MessagesEn);
                }

                window.$vent.trigger('propertyDetailBarReady');
            },
            // start module --------------------------------------------------------------------  /

            start: function () {

                var self = this;

                $(function () {
                    self.onDomReady();
                });
            },
            onDomReady: function () {
                var self = this;

                self.$preFooter = $('.prop-apts-wrap');
                self.$pageFooter = $('#page-footer');
                self.$pageHero = $('.prop-detail-hero');

                //

                $(window).on('resize', $.proxy(self._onWindowResize, self));
                $(window).on('scroll', $.proxy(self._onScroll, self));
                $(window).mousewheel($.proxy(self._onMouseWheel, self));


                window.$vent.on('modalMobileLoaded', $.proxy(self._onMobileLoaded, self));
                //

                self.showLoading();
                self.loadPropertyConfig();

            },
            // loading state (while config loads)

            showLoading: function () {

                var self = this;
            },
            hideLoading: function () {

                var self = this;
            },
            showError: function () {

                var self = this;
            },
            // components to load

            assetLoadComplete: function () {

                var self = this;

                if (self.isConfigLoaded) {
                    self.moduleReady();
                }
            },
            // all loads complete

            moduleReady: function () {
                var self = this;
                self.showIncorrectDatesMessage = false;

                // step 1
                self.sidebarStep1 = new SidebarStep1View({
                    $container: self.$innerContainer,
                    model: self.propertyConfig,
                    autoShow: false
                });

                // step 2
                self.sidebarStep2 = new SidebarStep2View({
                    $container: self.$innerContainer,
                    model: self.propertyConfig,
                    messages: self.messages,
                    autoShow: false
                });

                //Check if dates exist
                self.checkIn = Utils.MiscUtils.getCookie('checkIn');
                self.checkOut = Utils.MiscUtils.getCookie('checkOut');

                if (self.checkIn && self.checkOut && self.checkIn.length && self.checkOut.length) {
                    //Compruebo que las fechas cumplen
                    if (self.isDatesAvailable(self.checkIn, self.checkOut)) {
                        self.calendarResults = {
                            checkInDate: moment(self.checkIn, 'MM/DD/YY'),
                            checkOutDate: moment(self.checkOut, 'MM/DD/YY')
                        };
                        self.showCurrentStep = 'step2';
                    } else {
                        self.showCurrentStep = 'step1';
                        self.showIncorrectDatesMessage = true;
                    }
                } else {
                    if (!self.subTotal) {
                        self.showCurrentStep = 'step1';

                    }
                }

                self.sidebarStep1.initPromise().done(function () {
                    self.sidebarStep1.on('gotoStep2', self._onGotoStep2, self);
                    self.sidebarStep1.on('setFixed', self._onSetFixed, self);
                    self.sidebarStep1.on('setAbsolute', self._onSetAbsolute, self);
                    self.sidebarStep1.on('change', self._onSidebarChange, self);
                    self.sidebarStep1.on('hold', self._onHold, self);
                    self.sidebarStep1.on('gotoArrangeViewing', self._GotoArrangeViewing, self);
                    self.sidebarStep1.on('closeArrangeViewing', self._CloseViewing, self);

                    // listen for global commands to open menus

                    window.$vent.on('openCalendar', $.proxy(self._onOpenCalendar, self));
                    window.$vent.on('openGuests', $.proxy(self._onOpenGuests, self));

                    if (self.showCurrentStep == 'step1') {
                        self.currentStep = self.sidebarStep1;
                        self.currentStep.show();
                        self.$currentStepEl = self.currentStep.$el;
                        //Function to initialize the calendar
                        self.resetFilters();
                        if (self.showIncorrectDatesMessage) {
                            self.currentStep.showErrorMessage(self.messages.period_dates_error);
                            self.currentStep.disableBooking();
                        }
                    }


                });

                self.sidebarStep2.initPromise().done(function () {

                    self.sidebarStep2.on('gotoStep1', self._onGotoStep1, self);
                    self.sidebarStep2.on('setFixed', self._onSetFixed, self);
                    self.sidebarStep2.on('setAbsolute', self._onSetAbsolute, self);
                    self.sidebarStep2.on('change', self._onSidebarChange, self);
                    self.sidebarStep2.on('hold', self._onHold, self);


                    if (self.showCurrentStep == 'step2') {
                        self.currentStep = self.sidebarStep2;
                        //comprobamos que tenemos calculado algún campo...
                        self.subTotal = self.$el.find('.subtotal-item .item-price');

                        if (self.subTotal) {
                            self.currentStep.show();
                            self.$currentStepEl = self.currentStep.$el;
                        }
                        self.resetFilters();
                    }
                });

            },
            _onOpenCalendar: function () {

                var self = this;
                self.openMenu(self.calendarMenu);

                window.$vent.trigger('seekToTop');
            },
            _onOpenGuests: function () {

                var self = this;
                self.openMenu(self.guestsMenu);

                window.$vent.trigger('seekToTop');
            },
            _GotoArrangeViewing: function () {
                var self = this;
                self.shiftLeftToArrangeViewing();
            },
            _CloseViewing: function () {
                var self = this;
                self.shiftRightFromArrangeViewing();
            },
            // load property configuration

            loadPropertyConfig: function () {
                var idBo = ($('.idBo')).text();
                var self = this;
                if ($('.ss-property-detail-short').length > 0) {
                    $.ajax({
                        type: 'GET',
                        url: "/shortlet/getDisabledDatesShort?id=" + idBo,
                        async: false,
                        jsonpCallback: 'callBack',
                        contentType: 'application/json',
                        dataType: 'json',
                        success: function (json) {
                            console.log("JSON", json);
                            self._onPropertyConfigComplete(json);
                        },
                        error: function (e) {
                            console.log('JSON Load Error', self);
                            console.log(e.message);
                            self._onPropertyConfigFail(e);
                        }
                    });
                } else {
                    $.ajax({
                        type: 'GET',
                        url: "/buy/getJsonData",
                        async: false,
                        jsonpCallback: 'callBack',
                        contentType: 'application/json',
                        dataType: 'json',
                        success: function (json) {
                            console.log("JSON", json);
                            self._onPropertyConfigComplete(json);
                        },
                        error: function (e) {
                            console.log('JSON Load Error', self);
                            console.log(e.message);
                            self._onPropertyConfigFail(e);
                        }
                    });
                }


            },
            _onPropertyConfigComplete: function (data) {
                var self = this;
                /*
                 var userFirstName = $('#user-data').data('first-name');
                 var userLastName = $('#user-data').data('last-name');
                 var minPrice = data.defaultPrice;
                 var maxPrice = data.defaultPrice;

                 _.each(data.dateRangePrices, function (dateRangePrice) {
                 minPrice = Math.min(minPrice, dateRangePrice.price);
                 maxPrice = Math.max(maxPrice, dateRangePrice.price);
                 });
                 */

                var userFirstName = "";
                var userLastName = "";
                var minPrice = data.defaultPrice;
                var maxPrice = data.defaultPrice;


                self.propertyConfig = $.extend({
                    userFirstName: userFirstName,
                    userLastName: userLastName,
                    minPrice: minPrice,
                    maxPrice: maxPrice

                }, data);

                self.isConfigLoaded = true;
                self.assetLoadComplete();
            },
            _onPropertyConfigFail: function (e) {

                var self = this;
                self.showError();
            },
            _onSidebarChange: function (e) {
                var self = this;
                self.currentStep.recalculate(self.calendarResults, self.guestsResults);
            },
            _onHold: function () {

                var self = this;

                // create formatted guests information

                var guestsInformation = '';

                for (var prop in self.guestsResults) {

                    var validProp = false;
                    var formattedProp = '';
                    var count = self.guestsResults[prop];

                    if (prop === 'adults') {

                        validProp = true;

                        if (count === 1) {
                            formattedProp = 'Adult';
                        } else {
                            formattedProp = 'Adults';
                        }
                    } else if (prop === 'children' && count > 0) {

                        validProp = true;

                        if (count === 1) {
                            formattedProp = 'Child';
                        } else {
                            formattedProp = 'Children';
                        }
                    } else if (prop === 'infants' && count > 0) {

                        validProp = true;

                        if (count === 1) {
                            formattedProp = 'Infant';
                        } else {
                            formattedProp = 'Infants';
                        }
                    }

                    if (validProp) {
                        guestsInformation += count + ' ' + formattedProp + ', ';
                    }
                }

                guestsInformation = guestsInformation.substr(0, guestsInformation.length - 2);

                // create formatted calendar information

                var checkInDate = self.calendarResults.checkInDate.format('ddd, MMMM D, YYYY');
                var checkOutDate = self.calendarResults.checkOutDate.format('ddd, MMMM D, YYYY');
                var datesInformation = checkInDate + ' to ' + checkOutDate;

                //

                var holdModel = $.extend({
                    guestsInformation: guestsInformation,
                    datesInformation: datesInformation,
                }, self.propertyConfig);

                window.$vent.trigger('openModal', {modalId: 'views/modals/PropertyHold1', config: holdModel});
            },
            // listeners

            _onGotoStep1: function () {

                var self = this;
                self.shiftRight();
            },
            _onGotoStep2: function () {

                var self = this;
                self.shiftLeft();
            },
            _onSetFixed: function () {
                var self = this;
                self.isFixedPosition = true;

                self._onWindowResize();
                self._onScroll();
            },
            _onSetAbsolute: function () {
                var self = this;
                self.isFixedPosition = false;

                self._onWindowResize();
                self._onScroll();
            },
            // shift left / right

            shiftLeft: function () {
                var self = this;

                if (self.currentStepIndex !== 2) {

                    var elWidth = self.$el.outerWidth();

                    self.currentStepIndex = 2;

                    self.currentStep = self.sidebarStep2;
                    self.currentStep.recalculate(self.calendarResults, self.guestsResults);
                    self.currentStep.show();

                    self.$currentStepEl = self.currentStep.$el;

                    TweenMax.fromTo(self.currentStep.$el, 0.6, {x: elWidth, force3D: 'auto'}, {
                        x: 0,
                        ease: Quint.easeOut
                    });
                    TweenMax.fromTo(self.sidebarStep1.$el, 0.6, {x: 0, force3D: 'auto'}, {
                        x: 0 - elWidth, ease: Quint.easeOut, onComplete: function () {
                            self.sidebarStep1.hide();
                        }
                    });

                    self.resetFilters();
                }
            },
            shiftLeftToArrangeViewing: function () {

                var self = this,
                    elWidth = self.$el.outerWidth(),
                    defaultSidebar1 = self.$el.find('.property-bar-step-1 .prop-req-booking'),
                    arrangeSidebar1 = self.$el.find('.property-bar-step-1 .shortlet-booking-requested'),
                    selectBtn = self.$el.find('.property-bar-step-1 .shortlet-booking-requested .cont-prefix');


                //hide the default sidebar2 content
                //show the availability sidebar2 content
                defaultSidebar1.hide();
                arrangeSidebar1.show();

                //set the correct stepIndex
                self.currentStepIndex = 2;
                self.currentStep = self.sidebarStep1;
                //self.currentStep.recalculate(	self.calendarResults, self.guestsResults	);

                //recalculate the sidebar height
                self.setSidebarHeight(60);

                //center the content
                self.currentStep.centerTheContent();

                //animate the sidebar sections

                var top = self.heroHeight;

                //TweenMax.to(self.$el, 0.6, {top:top, ease:Quint.easeOut});
                self.checkPosition();
                TweenMax.fromTo(arrangeSidebar1, 0.6, {x: elWidth, force3D: 'auto'}, {x: 0, ease: Quint.easeOut});

                //Firefox has a positioning issue for the sidebar, it triggers when you go through the booking flow the second time
                //I'm trying to avoid that issue by repositioning the window scroll to the `top` of the page
                $('html, body').animate({
                    scrollTop: 0
                }, 0.2);

                self.resetFilters();
                self.checkPosition();
                selectBtn.selectBoxIt();
            },
            shiftRight: function () {

                var self = this;

                if (self.currentStepIndex !== 1) {

                    var elWidth = self.$el.outerWidth();

                    self.currentStepIndex = 1;
                    self.currentStep = self.sidebarStep1;
                    self.currentStep.show();
                    self.$currentStepEl = self.currentStep.$el;

                    TweenMax.fromTo(self.sidebarStep2.$el, 0.6, {x: 0, force3D: 'auto'}, {
                        x: 0 + elWidth,
                        ease: Quint.easeOut
                    });
                    TweenMax.fromTo(self.currentStep.$el, 0.6, {x: 0 - elWidth, force3D: 'auto'}, {
                        x: 0, ease: Quint.easeOut, onComplete: function () {
                            self.currentStep.hide();
                        }
                    });

                    self.resetFilters();
                }
            },
            shiftRightFromArrangeViewing: function () {

                var self = this,
                    elWidth = self.$el.outerWidth(),
                    defaultSidebar1 = self.$el.find('.property-bar-step-1 .prop-req-booking'),
                    arrangeSidebar1 = self.$el.find('.property-bar-step-1 .shortlet-booking-requested');

                //hide the default sidebar2 content
                //show the availability sidebar2 content
                arrangeSidebar1.hide();
                defaultSidebar1.show();

                //set the correct stepIndex
                self.currentStepIndex = 1;
                self.currentStep = self.sidebarStep1;
                //self.currentStep.recalculate(	self.calendarResults, self.guestsResults	);

                //recalculate the sidebar height
                self.setSidebarHeight(60);

                //center the content
                self.currentStep.centerTheContent();

                //animate the sidebar sections

                var top = self.heroHeight;

                //TweenMax.to(self.$el, 0.6, {top:top, ease:Quint.easeOut});
                self.checkPosition();
                TweenMax.fromTo(defaultSidebar1, 0.6, {x: 0 - elWidth, force3D: 'auto'}, {x: 0, ease: Quint.easeOut});

                //Firefox has a positioning issue for the sidebar, it triggers when you go through the booking flow the second time
                //I'm trying to avoid that issue by repositioning the window scroll to the `top` of the page
                $('html, body').animate({
                    scrollTop: 0
                }, 0.2);

                self.resetFilters();
                self.checkPosition();
            },
            // load availability data ----------------------------------------------------------  /

            setAvailabilityData: function (json) {

                var self = this;

                self.availabilityData = json;

                if (self.isEditBlock) {

                    var dateRange = self.availabilityData.ownerOccupiedDates[self.selectedBlockIndex];
                    var startDate = moment(dateRange['startDate']);
                    var endDate = moment(dateRange['endDate']);

                    self.leftCalendar.setMonth(startDate.format('MM') - 1);
                    self.rightCalendar.setMonth(endDate.format('MM') - 1);

                    self.multidayArray[0].startDate = startDate.format('YYYY-MM-DD');
                    self.multidayArray[0].endDate = endDate.format('YYYY-MM-DD');
                }
            },
            loadDefaultDates: function () {
                var self = this;
                self.calendarResults = {};
                self.calendarResults.checkInDate = moment();
                self.calendarResults.checkOutDate = moment().add(1, 'month');
                self.calendarResults.priceBreakdown = {
                    ranges: [],
                    totalNights: 0,
                    totalPrice: 0
                };
            },
            // calendar and guests filters
            resetFilters: function () {
                var self = this;
                var hasFilters;
                console.info("------- calendarResults ----------- resetFilters ", self.calendarResults);

                if (self.calendarResults) {
                    self.updateFilterButtonText('check-in', self.calendarResults.checkInDate.format('DD/MM/YY'));
                    self.updateFilterButtonText('check-out', self.calendarResults.checkOutDate.format('DD/MM/YY'));
                    window.$vent.trigger('dateChanged', [{calendarResults: self.calendarResults}, self]);
                }

                if (self.isFiltersInitialized) {
                    self.removeExistingFilters();
                }

                self.$filterOptions = self.$currentStepEl.find('.filter-options');
                self.$filterOverlays = self.$currentStepEl.find('.filter-overlays');


                /**
                 * add abailability to place default dates there
                 */
                self.calendarMenu = new CalendarMenuView({
                    $container: self.$filterOverlays,
                    $context: self.$context,
                    model: self.propertyConfig,
                    selectedDates: self.calendarResults,
                    shortDate: true,
                    autoShow: false,
                    hasOverlay: true
                });

                console.info("self.calendarMenu ", self.calendarMenu.length);
                console.info("self.$filterOverlays ", self.$filterOverlays.length);
                console.info("self.$filterOptions ", self.$filterOptions.length);

                self.guestsMenu = new GuestsMenuView({
                    $container: self.$filterOverlays,
                    model: self.propertyConfig,
                    selectedGuests: self.guestsResults,
                    autoShow: false,
                });

                self.calendarMenu.on('optionSelected', self._onDateSelected, self);
                self.calendarMenu.on('closeMenu', self._onCloseMenu, self);
                self.calendarMenu.on('clearDates', self._onClearDates, self);
                self.calendarMenu.on('hide', self._onMenuClosed, self);

                self.guestsMenu.on('optionSelected', self._onGuestsSelected, self);
                self.guestsMenu.on('closeMenu', self._onCloseMenu, self);
                self.guestsMenu.on('hide', self._onMenuClosed, self);

                //

                _.each(self.$filterOptions.find('li'), function (filterButton, i) {

                    var $filterButton = $(filterButton);

                    if (!$filterButton.data('default-text')) {
                        var defaultText = $filterButton.find('figcaption').html();
                        $filterButton.attr('data-default-text', defaultText);
                    }

                    $filterButton.on('click', $.proxy(self._onFilterOptionClick, self));
                });

                self.startingScrollTop = $(window).scrollTop();
                self.isFiltersInitialized = true;

                /*self._onWindowResize();*/
            },
            removeExistingFilters: function () {

                var self = this;

                if (self.calendarMenu) {

                    self.calendarMenu.off('optionSelected', self._onDateSelected, self);
                    self.calendarMenu.off('closeMenu', self._onCloseMenu, self);
                    self.calendarMenu.off('hide', self._onMenuClosed, self);

                    self.calendarMenu.exit();
                }

                if (self.guestsMenu) {

                    self.guestsMenu.off('optionSelected', self._onGuestsSelected, self);
                    self.guestsMenu.off('closeMenu', self._onCloseMenu, self);
                    self.guestsMenu.off('hide', self._onMenuClosed, self);

                    self.guestsMenu.exit();
                }

                self.calendarMenu = null;
                self.guestsMenu = null;

                self.isFiltersInitialized = false;
            },
            _onFilterOptionClick: function (e) {

                var self = this;
                var $target = $(e.currentTarget);
                var $nextFilterButton = $target;
                var nextMenu;

                if ($target.hasClass('check-in')) {

                    $nextFilterButton = self.$filterOptions.find('.date-option');
                    nextMenu = self.calendarMenu;

                } else if ($target.hasClass('check-out')) {

                    $nextFilterButton = self.$filterOptions.find('.date-option');
                    nextMenu = self.calendarMenu;

                } else if ($target.hasClass('guests')) {

                    nextMenu = self.guestsMenu;
                }

                if (nextMenu && nextMenu !== self.currentMenu) {

                    if (e) {
                        e.preventDefault();
                        e.stopPropagation();
                    }

                    self.openMenu(nextMenu, $nextFilterButton);
                } else if (!nextMenu) {
                    self.isModalOpen = false;
                }
            },
            _onDateSelected: function (e) {
                var self = this;
                self.isDateSelected = true;

                self.dateQuery = e.query;

                console.log(e.results);

                Utils.MiscUtils.setCookie('checkIn', moment(e.results, 'DD/MM/YY').format('MM/DD/YY'), window.SEARCHFILTERS_COOKIE_EXPIRES);
                Utils.MiscUtils.setCookie('checkOut', moment(e.results2, 'DD/MM/YY').format('MM/DD/YY'), window.SEARCHFILTERS_COOKIE_EXPIRES);

                self.updateFilterButtonText('check-in', e.results);
                self.updateFilterButtonText('check-out', e.results2);

                self.calendarResults = e.resultsObj;

                window.$vent.trigger('optionDateSelectedApp',[{checkIn: e.results ,checkOut:e.results2 }, self]);
            },
            _onGuestsSelected: function (e) {

                var self = this;

                self.isGuestsSelected = true;
                self.guestsQuery = e.query;

                self.updateFilterButtonText('guests', e.results);

                self.guestsResults = e.resultsObj;
            },
            openMenu: function (nextMenu, $nextFilterButton) {
                var self = this;

                if (self.currentMenu) {
                    self.closeCurrentMenu(nextMenu);
                }

                self.isModalOpen = true;
                self.currentMenu = nextMenu;
                self.$activeFilterButton = $nextFilterButton;

                self.currentMenu.show();
                //self.hideCurrentFilterButton();

                $(document).off('click', $.proxy(self._onOutClick, self));

                window.requestAnimationFrame(function () {
                    $(document).on('click', $.proxy(self._onOutClick, self));
                });

                self.$filterOverlays.removeClass('hidden');
                self.$el.removeClass('overflow').addClass('overflow');

                window.$vent.trigger('openFiltersMenu');
            },
            // close menu

            closeCurrentMenu: function (nextMenu) {

                var self = this;

                if (self.currentMenu && self.isModalOpen) {

                    //self.restoreCurrentFilterButton();

                    if (!nextMenu) {

                        self.currentMenu.on('hideComplete', function (e) {
                            self.$filterOverlays.removeClass('hidden').addClass('hidden');
                            self.$el.removeClass('overflow');
                        });
                    }

                    self.currentMenu.hide();
                    self.currentMenu = null;

                    $(document).off('click', $.proxy(self._onOutClick, self));
                }
            },
            // menu listeners

            _onMenuClosed: function (e) {

                var self = this;
                self.recalculate();
            },
            _onOutClick: function (e) {
                var self = this;
                if (e) {
                    if (!self.$filterOverlays[0].contains(e.target) && document.contains(e.target)) {
                        self.closeCurrentMenu();
                        if (self.isDateSelected && self.calendarResults) {
                            if (self.isDatesAvailable(self.calendarResults.checkInDate.format('MM/DD/YY'), self.calendarResults.checkOutDate.format('MM/DD/YY'))) {
                                self.currentStep.hideErrorMessage();
                                self.currentStep.allowBooking();
                                window.$vent.trigger('dateChanged', [{calendarResults: self.calendarResults}, self]);
                            } else {
                                console.log("self.currentStep ", self.currentStep.$container);
                                self.currentStep.hideErrorMessage();
                                self.currentStep.showErrorMessage(self.messages.period_dates_error);
                                self.currentStep.disableBooking();
                                window.$vent.trigger('resetCostData');
                            }


                        }
                    }
                } else {
                    self.closeCurrentMenu();
                    window.$vent.trigger('dateChanged', [{calendarResults: self.calendarResults}, self]);
                }
            },
            // hide / restore clicked filter button values

            hideCurrentFilterButton: function () {

                var self = this;

                _.each(self.$activeFilterButton, function (filter, i) {
                    var $filter = $(filter);
                    TweenMax.to($filter.find('a'), 0.3, {opacity: 0, ease: Cubic.easeOut});
                });
            },
            restoreCurrentFilterButton: function () {

                var self = this;

                _.each(self.$activeFilterButton, function (filter, i) {
                    var $filter = $(filter);
                    TweenMax.fromTo($filter.find('a'), 0, {opacity: 0}, {opacity: 1, ease: Quint.easeInOut});
                });
            },
            updateFilterButtonText: function (buttonClass, text) {

                var self = this;
                var $filterButton = self.$currentStepEl.find('.' + buttonClass);
                if (text) {
                    $filterButton.find('figcaption').html(text);
                    $filterButton.removeClass('selected').addClass('selected');
                } else {
                    $filterButton.find('figcaption').html($filterButton.data('default-text'));
                    $filterButton.removeClass('selected');
                }
            },
            _onClearDates : function (e){

                var self = this;

                self.updateFilterButtonText('check-in');
                self.updateFilterButtonText('check-out');
            },
            // recalculate costs ---------------------------------------------------------------  /

            recalculate: function (res1, res2) {

                var self = this;

                if (self.currentStep === self.sidebarStep2) {
                    //self.currentStep.recalculate(self.calendarResults, self.guestsResults);
                    self.currentStep.recalculate(res1, res2);
                }
            },
            // scroll --------------------------------------------------------------------------  /

            _onMouseWheel: function (e) {

                var self = this;
                self.scrollTick();
            },
            _onScroll: function () {
                var self = this;
                self.scrollTop = Math.max(0, $(document).scrollTop());
                self.scrollTick();
            },
            _onScrollTick: function () {

                var self = this;
                self.isTicked = true;
                self.scrollNow();
            },
            scrollTick: function () {

                var self = this;

                if (self.isTicked) {
                    self.scrollNow();
                } else if (!self.isTicked && !self.isPendingScroll) {
                    self.isPendingScroll = true;
                    window.requestAnimationFrame(self._onScrollTick.bind(self));
                }
            },
            scrollNow: function () {

                var self = this;

                if (self.isTicked) {

                    self.scrollTop = Math.max(0, $(document).scrollTop());

                    self.checkPosition();
                    self.isTicked = false;
                    self.isPendingScroll = false;
                }
            },
            // check position - should bar be fixed or absolute

            checkPosition: function () {

                var self = this;
                var heightDiff = Math.max((self.sidebarHeight + self.BOTTOM_PADDING - self.winHeight) - 80, 0);

                if (self.scrollTop > self.maxScroll && self.winWidth >= 768) {

                    if (self.$el.hasClass('fixed-important')) {
                        self.$el.removeClass('fixed-important');
                    }

                    if (!self.$el.hasClass('absolute-important')) {
                        self.$el.addClass('absolute-important');
                    }

                    TweenMax.set([self.$el, self.$innerContainer], {
                        clearProps: 'height, margin, y'
                    });

                    // TweenMax.set(self.$el, {
                    // 	height: self.sidebarHeight,
                    // });

                    // TweenMax.set(self.$el, {y:0});

                    if (!self.$el.hasClass('bottom')) {

                        self.$el.addClass('bottom');

                        // special case for smaller screens

                        var $commute = $('.commute-calcultor');

                        if (self.winWidth <= 992) {
                            TweenMax.set(self.$el, {'margin-bottom': 0 - parseInt($('.sidebar-cutoff').outerHeight(true))});
                        } else if ($commute.length) {
                            TweenMax.set(self.$el, {'margin-bottom': 0 - parseInt($commute.outerHeight(true)) - 80});
                        }
                    }

                } else if (self.scrollTop > 0 && self.scrollTop + self.scrollOffset >= self.containerBounds.top + heightDiff + 80 && self.winWidth >= 768) {

                    if (!self.$el.hasClass('fixed-important')) {
                        self.$el.addClass('fixed-important');
                    }

                    if (self.$el.hasClass('absolute-important')) {
                        self.$el.removeClass('absolute-important');
                    }

                    if (self.$el.hasClass('bottom')) {
                        self.$el.removeClass('bottom');
                    }

                    TweenMax.set([self.$el, self.$innerContainer], {
                        clearProps: 'height, margin, y'
                    });

                    TweenMax.set(self.$el, {
                        'margin-top': 80 - heightDiff,
                    });

                    if (self.sidebarHeight > self.winHeight) {

                        TweenMax.set(self.$innerContainer, {
                            'margin-top': -120,
                        });
                    }


                    // var yPos = self.scrollOffset - heightDiff;
                    // TweenMax.set(self.$el, {
                    // 	y: yPos
                    // });
                } else {
                    if (self.$el.hasClass('fixed-important')) {
                        self.$el.removeClass('fixed-important');
                    }

                    if (!self.$el.hasClass('absolute-important')) {
                        self.$el.addClass('absolute-important');
                    }

                    if (self.$el.hasClass('bottom')) {
                        self.$el.removeClass('bottom');
                    }

                    TweenMax.set([self.$el, self.$innerContainer], {
                        clearProps: 'height, margin, y'
                    });
                    if (self.winWidth >= 992) {

                        TweenMax.set(self.$el, {

                            'margin-top': 0,

                        });
                    }


                    // TweenMax.set(self.$el, {y:0});
                }
            },
            // window resize -------------------------------------------------------------------  /

            _onWindowResize: function () {

                var self = this;
                var menuHeight = 0;

                self.winHeight = window.innerHeight;
                self.winWidth = window.innerWidth;
                self.docHeight = $(document).outerHeight();
                self.containerBounds = self.$container.offset();

                self.setSidebarHeight();

                if (self.winWidth >= 992) {
                    self.footerHeight = self.docHeight - self.$preFooter.offset().top;
                    self.heroHeight = 0;
                    self.scrollOffset = 50;
                } else {
                    self.footerHeight = self.docHeight - self.$pageFooter.offset().top + 50;
                    self.heroHeight = self.$pageHero.outerHeight();
                    self.scrollOffset = 0;
                }

                ////
                if (self.winWidth < 992) {
                    if ($('#sticky-nav').css("position") === "fixed") {
                        var top = self.heroHeight + menuHeight;
                    } else {
                        var top = self.heroHeight + menuHeight;
                    }

                    var backButton = $('.property-go-back');
                    if (backButton.length) {
                        top += backButton.height();
                    }

                    self.affixOptions = {
                        offset: {
                            top: function () {
                                return top;
                            },
                            bottom: function () {
                                return self.footerHeight;
                            }
                        }
                    };

                    self.el.affix(self.affixOptions);
                    self.el.data('affix').options = self.affixOptions;
                }

                if (self.currentStep) {

                    self.currentStepHeight = self.currentStep.getMinHeight();

                    var availableScreenHeight = self.winHeight - 60;

                    if (self.sidebarHeight <= availableScreenHeight) {

                        self.currentStepHeight = self.winHeight;
                        self.$el.removeClass('fixed-important').addClass('fixed-important');

                        if (self.winWidth < 992 && self.winWidth >= 768) {
                            self.currentStepHeight -= 60;
                        }
                    } else {
                        self.$el.removeClass('absolute-important').addClass('absolute-important');
                    }

                    //

                    var sidebarCutoff = (self.winWidth >= 992) ? $('.sidebar-cutoff').offset().top : $('#page-footer').offset().top;
                    var viewportHeight = sidebarCutoff;

                    self.maxScroll = sidebarCutoff - self.sidebarHeight + self.scrollOffset;

                    //console.log("MAX SCROLL", sidebarCutoff, self.maxScroll, "MIN HEIGHT", self.sidebarHeight, viewportHeight, (self.sidebarHeight || viewportHeight));

                    //
                }

                self.scrollTick();
                self.checkPosition();
            },
            setSidebarHeight: function (addPixels) {

                var self = this;

                if (typeof addPixels === 'undefined') {
                    addPixels = 0;
                }

                if (self.currentStep) {
                    self.sidebarHeight = self.currentStepHeight = Math.max(self.currentStep.getMinHeight(), self.winHeight);
                }

                var availableScreenHeight = self.winHeight - self.scrollOffset;

                if (self.currentStepHeight <= availableScreenHeight) {

                    self.sidebarHeight = self.winHeight;

                    if (self.winWidth >= 992) {
                        self.sidebarHeight -= self.scrollOffset;
                    }
                }

                self.sidebarHeight += addPixels;

                //console.log(self.sidebarHeight, self.currentStepHeight, self.winHeight);

                TweenMax.set([self.$innerContainer], {
                    'min-height': self.sidebarHeight + 'px'
                });

                TweenMax.set([self.$el], {
                    'top': 0 - self.scrollOffset,
                    'min-height': self.sidebarHeight + 80 + 'px'
                });

            },
            // ---------------------------------------------------------------------------------  /
            formatDatesFromListing: function (date) {
                var self = this;

                var dateArray = date.split("/");
                var dateFormatted = "20" + dateArray[2] + "-" + dateArray[1] + "-" + dateArray[0];

                return dateFormatted;

            },
            allowBooking: function () {

                var self = this;
                self.$el.find('.btn-req-booking').removeClass('disabled');
            },

            // calculate next occupied date  ---------------------------------------------------  /

            isDatesAvailable: function (checkInDateString, checkOutDateString) {
                var self = this;
                //
                //var checkInDateSplit = checkInDateString.split('/');
                //var checkOutDateSplit = checkOutDateString.split('/');

                //var checkInDate = moment.utc('20' + checkInDateSplit[2] + '-' +  checkInDateSplit[0] + '-' +  checkInDateSplit[1]);
                //var checkOutDate = moment.utc('20' + checkOutDateSplit[2] + '-' +  checkOutDateSplit[0] + '-' +  checkOutDateSplit[1]);

                var checkInDate = moment(checkInDateString, 'MM/DD/YY');
                var checkOutDate = moment(checkOutDateString, 'MM/DD/YY');
                self.endDate = checkOutDate;

                if (self.propertyConfig) {

                    for (var i = 0; i < self.propertyConfig.dateRangePrices.length; i++) {
                        var dateRange = self.propertyConfig.dateRangePrices[i];
                        if (moment(dateRange.startDate).format('x') <= checkInDate.format('x')) {
                            self.propertyConfig.minNightsStay = dateRange.minNightsStay;
                        }
                    }
                    ;

                    var nextOccupiedDate = self.getNextOccupiedDate(checkInDate, checkOutDate);
                    var numDays = (checkOutDate.format('x') - checkInDate.format('x')) / 86400000;

                    if (!self.isAvailableDate(checkOutDate.format('x'))) {
                        return false;
                    } else {
                        if (numDays > self.propertyConfig.maxNightsStay) {
                            return false;
                        }
                        else if (checkOutDate.format('x') < checkInDate.format('x')) {
                            return false;
                        }
                        else if (numDays < self.propertyConfig.minNightsStay) {
                            return false;
                        }
                        else if (checkOutDate.format('x') > nextOccupiedDate.format('x')) {
                            return false;
                        }
                        else {
                            return true;
                        }
                    }
                } else {
                    return false;
                }

            },

            getNextOccupiedDate: function (dateTime, minDate) {

                var self = this;

                if (self.propertyConfig) {

                    minDate = minDate || self.endDate;
                    minDate = self.getMinRangeTime(self.propertyConfig.myBlockDates, dateTime, minDate);
                    minDate = self.getMinRangeTime(self.propertyConfig.availability.bookedOccupiedDates, dateTime, minDate);
                    minDate = self.getMinRangeTime(self.propertyConfig.availability.holdOccupiedDates, dateTime, minDate);
                    minDate = self.getMinRangeTime(self.propertyConfig.availability.ownerOccupiedDates, dateTime, minDate);

                    return minDate;
                }

                return false;
            },

            getMinRangeTime: function (rangeObj, dateTime, minDate) {

                var self = this;
                var minDateTime = minDate ? minDate.format('x') : false;

                for (var i = 0; i < rangeObj.length; i++) {

                    var dateRange = rangeObj[i];
                    var startDate = moment(dateRange['startDate']);

                    var rangeStartTime = startDate.format('x');

                    if (rangeStartTime > dateTime.format('x') && (rangeStartTime < minDateTime || !minDateTime)) {
                        minDate = startDate;
                        minDateTime = dateTime;
                    }
                }

                return minDate;
            },

            isAvailableDate: function (dateTime) {
                var self = this;
                var isInBlocked = self.isWithinDateRange(self.propertyConfig.myBlockDates, dateTime);
                var isInBooked = self.isWithinDateRange(self.propertyConfig.availability.bookedOccupiedDates, dateTime);
                var isInHold = self.isWithinDateRange(self.propertyConfig.availability.holdOccupiedDates, dateTime);
                var isInOwner = self.isWithinDateRange(self.propertyConfig.availability.ownerOccupiedDates, dateTime);

                return !(isInBooked || isInHold || isInOwner || isInBlocked);
            },

            isWithinDateRange: function (rangeObj, dateTime) {

                var self = this;

                for (var i = 0; i < rangeObj.length; i++) {

                    var dateRange = rangeObj[i];
                    var startDateTime = moment(dateRange['startDate']).format('x');
                    var endDateTime = moment(dateRange['endDate']).format('x');

                    if (dateTime >= startDateTime && dateTime <= endDateTime) {
                        return true;
                    }
                }

                return false;
            },

            // Moible loaded   ----------------------------------------------------------------  /
            _onMobileLoaded: function () {
                var self = this;

                self.idBo = $('.idBo').text();
                self.shorletPropertyName = $('.shorletPropertyName').text();
                self.numGuests = $('.num-guests').text();
                self.$currentStepEl = $('#request-booking-modal');

                self.$currentStepEl.find('#request-booking-no-logged').on('click', $.proxy(self._onOpenLoginModal, self));
                self.$currentStepEl.find('#btn-availability-no-logged').on('click', $.proxy(self._onOpenLoginModal, self));
                self.$currentStepEl.find('#btn-book-submit').on('click', $.proxy(self._onHoldRequest, self));
                self.$currentStepEl.find('#btn-availability-modal').on('click', $.proxy(self._onAvailabilityRequest, self));
                window.$vent.on('dateSelectedInMobile', $.proxy(self._onDateSelectedInMobile, self));

                if (self.showIncorrectDatesMessage) {
                    self.showErrorMessageMobile(self.messages.period_dates_error);
                    self.disableMobileBooking();
                } else {
                    if (self.calendarResults) {
                        /* In mobile the calendars/app triggers when it is loaded and the function sendSelectedDates sends the
                         selected dates to print them in the calendar
                         * */
                        window.$vent.off("calendarMobileLoaded", $.proxy(self._onSendSelectedDates, self));
                        window.$vent.on("calendarMobileLoaded", $.proxy(self._onSendSelectedDates, self));
                        self.recalculateCostMobile();
                        self.allowMobileBooking();
                    }
                }

            },
            _onDateSelectedInMobile: function (e, data) {
                var self = this;
                if (data.datesSelected) {
                    self.calendarResults = {
                        checkInDate: moment(data.datesSelected.checkInQuery, 'MM/DD/YY'),
                        checkOutDate: moment(data.datesSelected.checkOutQuery, 'MM/DD/YY')
                    }
                    Utils.MiscUtils.setCookie('checkIn', self.calendarResults.checkInDate.format('MM/DD/YY'), window.SEARCHFILTERS_COOKIE_EXPIRES);
                    Utils.MiscUtils.setCookie('checkOut', self.calendarResults.checkOutDate.format('MM/DD/YY'), window.SEARCHFILTERS_COOKIE_EXPIRES);

                    if (self.isDatesAvailable(self.calendarResults.checkInDate.format('MM/DD/YY'), self.calendarResults.checkOutDate.format('MM/DD/YY'))) {
                        self.hideErrorMessageMobile();
                        self.allowMobileBooking();
                        self.recalculateCostMobile();
                        //Save cookie dates
                    } else {
                        console.log("self.currentStep ", self.currentStep.$container);
                        self.hideErrorMessageMobile();
                        self.showErrorMessageMobile(self.messages.period_dates_error);
                        self.disableMobileBooking();
                        self.resetCostDataMobile();
                    }


                }
            },
            _onSendSelectedDates: function () {
                var self = this;

                window.$vent.trigger('defaultDatesMobile', [{selectedDates: self.calendarResults}, self]);
            },
            _onOpenLoginModal: function (e) {
                var self = this;

                window.$vent.trigger('showSpinner', [e.currentTarget]);
                window.$vent.trigger('openModal', '/modals/login?');

            },
            _onHoldRequest: function(e){
                var self = this;
                var checkIn = self.calendarResults.checkInDate.format('DD/MM/YY');
                var checkOut = self.calendarResults.checkOutDate.format('DD/MM/YY');

                window.$vent.trigger('showSpinner', [e.currentTarget]);
                window.$vent.trigger('openModal', '/shortlet/PropertyHoldModal?shorletPropertyName=' + self.shorletPropertyName + '&checkIn=' + checkIn + '&checkOut=' + checkOut + '&idBo=' + self.idBo + '&maxNumPeople=' + self.numGuests);
            },
            _onAvailabilityRequest: function(e){
                var self = this;
                var checkIn = self.calendarResults.checkInDate.format('DD/MM/YY');
                var checkOut = self.calendarResults.checkOutDate.format('DD/MM/YY');

                window.$vent.trigger('showSpinner', [e.currentTarget]);
                window.$vent.trigger('openModal', '/shortlet/PropertyAvailabilityModal?shorletPropertyName=' + self.shorletPropertyName + '&checkIn=' + checkIn + '&checkOut=' + checkOut + '&idBo=' + self.idBo + '&maxNumPeople=' + self.numGuests);
            },


            allowMobileBooking: function () {
                var self = this;
                self.$currentStepEl.find('.btn-hold').removeClass('disabled');
            },
            disableMobileBooking: function () {
                var self = this;
                self.$currentStepEl.find('.btn-hold').removeClass('disabled').addClass('disabled');
            },
            showErrorMessageMobile: function (text) {
                var self = this;
                var content = $('.request-booking-modal #date-message-container');
                TweenMax.to(content, 0, {
                    opacity: 0, ease: Cubic.easeOut, onComplete: function () {
                        $(content).html(text);
                        TweenMax.fromTo(content, 0, {y: 0}, {y: 0, opacity: 1, ease: Sine.easeOut});
                    }
                })
            },
            hideErrorMessageMobile: function () {
                var self = this;
                var content = $('.request-booking-modal #date-message-container');
                var text = "";
                TweenMax.to(content, 0, {
                    opacity: 0, ease: Cubic.easeOut, onComplete: function () {
                        $(content).html(text);
                        TweenMax.fromTo(content, 0, {y: 0}, {y: 0, opacity: 1, ease: Sine.easeOut});
                    }
                })
            },
            recalculateCostMobile: function (e) {
                var self = this;

                var newCheckIn = self.calendarResults.checkInDate.format('DD/MM/YY');
                var newCheckOut = self.calendarResults.checkOutDate.format('DD/MM/YY');

                if ((newCheckIn && newCheckOut) && newCheckIn != newCheckOut) {
                    var monthlysubtotalReplaced = self.$currentStepEl.find('.monthly-subtotal-event');
                    var durationReplaced = self.$currentStepEl.find('.replace-months-event');
                    var costMonthly = self.$currentStepEl.find('.replace-cost-event');
                    var discountAmount = self.$currentStepEl.find('.replace-discount-amount-event');
                    var discountStay = self.$currentStepEl.find('.replace-discount-stay-event');
                    var deposit = self.$currentStepEl.find('.replace-deposit-event');
                    var bookingfee = self.$currentStepEl.find('.replace-bookingfee-event');
                    var clientType = self.$currentStepEl.find('#client-type');

                    $.ajax({
                        type: 'POST',
                        url: "/shortlet/getBookingCost?newCheckIn=" + newCheckIn +
                        "&newCheckOut=" + newCheckOut +
                        "&id=" + self.idBo,
                        context: document.body,
                        success: function (data) {
                            console.log('booking cost data', data);
                            monthlysubtotalReplaced.each(function (i, item) {
                                $(item).text(" " + data.monthlySubtotal + " ");
                            });
                            durationReplaced.each(function (i, item) {
                                $(item).text(" " + data.duration + " ");
                            });
                            costMonthly.each(function (i, item) {
                                $(item).text(" " + data.monthlyFeeRaw + " ");
                            });
                            if (data.discountAmount && data.discountStay) {
                                $('#discountSection').show();
                                var appliedDiscountId = "#discount-" + data.discountId;
                                $('.blue').addClass('hide');
                                $(appliedDiscountId).removeClass('hide');
                                discountAmount.each(function (i, item) {
                                    $(item).text(" " + data.discountAmount + " ");
                                });
                                discountStay.each(function (i, item) {
                                    $(item).text(" " + data.discountStay + " ");
                                });
                            } else {
                                $('#discountSection').hide();
                                $('.blue').addClass('hide');
                            }
                            deposit.each(function (i, item) {
                                $(item).text(" " + data.deposit + " ");
                            });
                            bookingfee.each(function (i, item) {
                                $(item).text(" " + data.bookingfee + " ");
                            });

                        },
                        error: function (e) {
                            self.resetCostDataMobile();
                            var errorMessage = self.messages.calculate_costs_error;
                            self.showErrorMessageMobile(errorMessage);
                            self.disableMobileBooking();
                        }
                    });

                    return false;

                }
            },
            resetCostDataMobile: function () {
                var self = this;

                var monthlysubtotalReplaced = self.$currentStepEl.find('.monthly-subtotal-event');
                var durationReplaced = self.$currentStepEl.find('.replace-months-event');
                var discountAmount = self.$currentStepEl.find('.replace-discount-amount-event');
                var discountStay = self.$currentStepEl.find('.replace-discount-stay-event');
                var deposit = self.$currentStepEl.find('.replace-deposit-event');
                var bookingfee = self.$currentStepEl.find('.replace-bookingfee-event');
                var clientType = self.$currentStepEl.find('#client-type');


                monthlysubtotalReplaced.each(function (i, item) {
                    $(item).text("");
                });
                durationReplaced.each(function (i, item) {
                    $(item).text("");
                });
                deposit.each(function (i, item) {
                    $(item).text("");
                });
                bookingfee.each(function (i, item) {
                    $(item).text("");
                });
                $('#discountSection').hide();
                $('.blue').addClass('hide');

            },

        });

        return AppView;
    }
);

