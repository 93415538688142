define(
	'modules/calendars/app',[
		'backbone',
		'modules/calendars/views/CalendarMenuView',
		'modules/calendars/views/CalendarMenuButtonsView',

		'clndr',
		'moment'
	], 
	function(Backbone, CalendarMenuView, CalendarMenuButtonsView){

		var AppView = Backbone.View.extend({
			// core vars
			$context: null,
			$calendarContainers: null,
			$calendarButtonContainers: null,
			calendars: null,
			calendarButtons: null,

			// init
			initialize: function(options){
				var self = this;
				self.$context = $;
			},

			// start module --------------------------------------------------------------------  /
			start: function(){

				var self = this;
				self.calendars = [];
				self.calendarButtons = [];
				self._onDomUpdate();
				window.$vent.trigger('calendarsReady');
				window.$vent.on('domUpdate', $.proxy(self._onDomUpdate, self));
			},

			_onDomUpdate: function(){
				var self = this;

				window.$vent.off('defaultDatesMobile', $.proxy(self.setSelectedDates,self));
				window.$vent.on('defaultDatesMobile', $.proxy(self.setSelectedDates,self));
				window.$vent.trigger("calendarMobileLoaded");

				// Owners listing calendars
				if ($('#listings-landing-page')) {
					console.log('*********** #listings-landing-page');
					$('.btn-show-availability').click(function (event) {
						event.preventDefault();
						var availability_button = $(event.target);
						var availability_container = availability_button.parent();
						var propertyId = availability_container.data('prop-id');
						var calendar_section = $('.availability-prop-' + propertyId);
						console.log('btn-show-availability clicked calendar_section', calendar_section);

						if (calendar_section.hasClass('hide')) {
							calendar_section.removeClass('hide');
							self.checkCalendarButtons();
							self.checkCalendars(propertyId, calendar_section);
							availability_button.text(availability_button.data('calendar-hide'))
						}
						else {
							calendar_section.addClass('hide');
							availability_button.text(availability_button.data('calendar-view'))
						}

					});
				}
				else {
					self.checkCalendarButtons();
					self.checkCalendars();
				}
			},
			setSelectedDates: function(e,data){
				var self = this;

				console.info(">>>>>>>>> setSelectedDates in calendars app ",data.selectedDates.checkInDate);
				console.info(">>>>>>>>> setSelectedDates in calendars app ",e);
				if(data.selectedDates){
					self.selectedDates = data.selectedDates;
				}

			},
			// calendars
			checkCalendars: function(propertyId, calendarElement){
				var self = this;
				self.$calendarContainers = calendarElement ? calendarElement.find('.calendars-container') : $('.calendars-container');

				console.log('calendars app checkCalendars', self.$calendarContainers);

				_.each(self.$calendarContainers, function(calendarContainer, i){
					var $calendarContainer = $(calendarContainer);
					var calendarMenu;
					if(!self.checkExisting($calendarContainer, self.calendars, true)){
						calendarMenu = new CalendarMenuView({
							$container: $calendarContainer,
							$context: self.$context,
							isEmbedded: true,
                            shortDate: true,
							autoShow: true,
							selectedDates: self.selectedDates,
							propertyId: propertyId
						});
						self.calendars.push(calendarMenu);
					}
				});
			},

			// calendar buttons
			checkCalendarButtons: function(){
				var self = this;
				self.$calendarButtonContainers = $('.calendar-buttons');

				_.each(self.$calendarButtonContainers, function(calendarButtonsContainer, i){
					var $calendarButtonsContainer = $(calendarButtonsContainer);
					if(!self.checkExisting($calendarButtonsContainer, self.calendarButtons)){
						var $calendarsContainer = $('#' + $calendarButtonsContainer.data('mobile-overlay-id'));
						var obj = {
							el: $calendarButtonsContainer,
							$context: self.$context,
							$calendarsContainer: $calendarsContainer,
							selectedDates: self.selectedDates
						};

						var calendarButtons = new CalendarMenuButtonsView(obj);
						self.calendarButtons.push(calendarButtons);
					}
				});
			},

			// check for existing buttons ------------------------------------------------------  /
			checkExisting: function(el, searchArray, useContainer){
				var self = this;
				var exists = false;

				if(typeof(el.context) !== 'undefined'){ el = el[0]; }
					_.each(searchArray, function(view, i){
						var viewEl = view.$el[0];
						if(useContainer === true){ viewEl = view.$container[0]; }
						if(typeof(viewEl.context) !== 'undefined'){ viewEl = viewEl[0]; }
						if(viewEl === el){
							exists = true;
						}
					});
				return exists;
			},


			loadDefaultDates: function() {
				var self = this;
				self.defaultcalendarResults = {};
				self.defaultcalendarResults.checkInDate = moment().format('YYYY-MM-DD');
				self.defaultcalendarResults.checkOutDate = moment().add(1, 'month').format('YYYY-MM-DD');
				self.defaultcalendarResults.priceBreakdown = {
					ranges: [],
					totalNights: 0,
					totalPrice: 0
				};
			},

		});
		return AppView;
	}
);
