define(
	'modules/pdp-mobile-scroll/app',[
		'backbone',
		'modules/pdp-mobile-scroll/views/MobileScrollView.js',
	], 
	function(Backbone, MobileScroll){

		var AppView = Backbone.View.extend({

			// init

			initialize: function() {

				var self = this;
				window.$vent.trigger('pdpmobilescrollReady');

				$(function () {
					self.start();
				});
			},
			
			// start module --------------------------------------------------------------------  /

			start: function(){

				var self = this;
				self.$el = new MobileScroll();
			}
		});

		return AppView;
	}
);
